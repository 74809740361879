import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

export default function ContextMenu(props) {

    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = (opt_name) => {
        setAnchorEl(null)
        const opt = props.options.find(item => item.name === opt_name)
        // console.log("opt:", opt, props.row_id)
        if(opt)opt.clickHandler(props.row_id)
        
    }

    return (
        <div>
            <IconButton>
                <MoreVertIcon onClick={handleClick} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {
                    props.options.map(item => {
                        return <MenuItem key={item.name} onClick={()=>handleClose(item.name)}>{item.nick_name}</MenuItem>
                    })
                }
            </Menu>
        </div>
    )
}