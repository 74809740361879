import React, { Component } from "react";
import * as Style from "../../Common/StyledComponents";
import styled from "styled-components";
import VEllipsisMenuDocVersion from "./../Menu/VerticalEllipsisMenuDocVersion";
import Utils from "../../../Common/Utils.js";
import Store from "../../../Common/Store.js";
import AlertBox from "../../Common/AlertBox";

class Version extends Component {
  state = { ready: false, versions: [], alert_param: null, lock_doc: 0 };
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setComponentState(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) return;
    this.setComponentState(this.props);
  }

  setComponentState = (propdata) => {
    console.log("propdata.versions", propdata.versions);
    let lock_doc = this.state.lock_doc;
    if(propdata.lock_doc === 1){
       lock_doc = Store.getStoreData('role') === "admin_manager" ? 0 : 1;
    }
    this.setState({ ready: true, versions: propdata.versions, lock_doc: lock_doc });
  };

  viewDocs = (version_id, doc_id) => {
    this.props.quickmenuClick(doc_id, version_id, "view");
  };

  promoteVersion = (version_id, doc_id) => {
    let obj = {};
    // { version_id, doc_id } = obj;
    obj["version_id"] = version_id;
    obj["doc_id"] = doc_id;
    //(obj.version_id = version_id), (obj.doc_id = doc_id);
    let alert_param = { title: "Alert", message: "Are you sure you want to make this the current version?", ok_text: "OK", confirm: true, alertHandler: this.versionChange, stack: { params: obj } };
    this.setState({ alert_param: alert_param });
    //this.props.quickmenuClick(doc_id, version_id, "promote");
  };

  versionChange = (result, stack) => {
    // alert(result);
    if (!result || stack.prevent) {
      this.setState({ alert_param: null });
      return;
    }
    this.setState({ alert_param: null });
    let params = stack.params;
    this.props.quickmenuClick(params.doc_id, params.version_id, "promote");
  };
  render() {
    if (!this.state.ready) {
      return <div>Loading...</div>;
    }
    return (
      <div style={{ width: "100%", padding: "10px" }}>
        <Style.Flexbox style={{ border: "1px solid black" }}>
          <div style={{ width: "15%" }}>
            <p>VERSION</p>
          </div>
          <div style={{ width: "50%" }}>
            <p>NAME</p>
          </div>
          <div style={{ width: "30%" }}>
            <p>UPLOADED AT</p>
          </div>
          <div style={{ width: "5%" }}>
          </div>
        </Style.Flexbox>
        {this.state.versions.map((v) => {
          return (
            <Style.Flexbox style={{ backgroundColor: "white" }}>
              <div style={{ width: "15%" }}>
                <p>{v.doc_version} </p>
              </div>
              <div style={{ width: "50%" }}>
                <p>{String(v.name).slice(0, 20) + "..."} </p>
              </div>
              <div style={{ width: "30%" }}>
                <p>{Utils.unixtimeTodate(v.created_at)} </p>
              </div>
              <div style={{ width: "5%" }}>
                  <VEllipsisMenuDocVersion is_main={v.is_main} v_id={v.id} lock_doc={this.state.lock_doc} viewDocs={() => this.viewDocs(v.id, v.document_id)} promoteVersion={() => this.promoteVersion(v.id, v.document_id)} />
              </div>
            </Style.Flexbox>
          );
        })}
        <AlertBox alertParam={this.state.alert_param} />
      </div>
    );
  }
}

export default Version;
