import Store from './Store.js';
import Utils from './Utils.js';
import ComDecom from './ComDecom.js';

class CadrsLoader {
	
	isGCinclude(gcs, doc_gcs){
	  let is_include = true;
	  for(let key in doc_gcs){
	    is_include = false
	    is_include = gcs.hasOwnProperty(key);
	    if(is_include)break;
	  }
	  return is_include;
	}

	folderTrevarse(trees,c, is_deleted=0){
		let contact = Store.getStoreData('contact');
		let companies_assoc = Store.getStoreData('companies_assoc');
		let role = Store.getStoreData('role');
		let period = Store.getStoreData('period')
		let t = Utils.timespanRecentDocument(period);
	    for(let i = 0; i < trees.length; i++){
	    	let members = trees[i].members;
	    	let docs = trees[i].docs;
	    	let child_del = trees[i].is_deleted === 1 ? 1 : is_deleted;
	    	if(trees[i].is_deleted === 1 || child_del === 1){
	    		switch(role){
	    			case "admin_manager":
	    			for(let d in docs){
	    				if(docs[d].last_action_time >= t.start && docs[d].last_action_time <= t.end){
	    					c["recent_deleted"] = c["recent_deleted"] + 1;
	    				}
	    				c['recycle_bin'] = c['recycle_bin'] + 1;
	    				let pinned = JSON.parse(docs[d].user_pins)
	    				let is_pinned = pinned[contact.ID.toString()] === undefined ? 0 : -1
	    				c["pinned_doc"] = c["pinned_doc"] + is_pinned ;
	    			}
	    			break;
	    			case "document_manager":
	    			for(let d in docs){
	    				let gc = JSON.parse(docs[d].gcs);
	    				if(Object.keys(gc).length === 0){
	    					if(docs[d].last_action_time >= t.start && docs[d].last_action_time <= t.end){
	    						c["recent_deleted"] = c["recent_deleted"] + 1;
	    					}
	    					c['recycle_bin'] = c['recycle_bin'] + 1;
	    				}else{
	    					let is_gc = this.isGCinclude(companies_assoc,gc);
	    					if(is_gc) {
	    						if(docs[d].last_action_time >= t.start && docs[d].last_action_time <= t.end){
	    							c["recent_deleted"] = c["recent_deleted"] + 1;
	    						}
	    						c["recycle_bin"] = c["recycle_bin"] + 1;
	    					}
	    				}
	    				let pinned = JSON.parse(docs[d].user_pins)
	    				let is_pinned = pinned[contact.ID.toString()] === undefined ? 0 : 1
	    				c["pinned_doc"] = c["pinned_doc"] - is_pinned ;
	    			}
	    			break;
	    			case "team":
	    			for(let d in docs){
	    				if(docs[d].visibility === 0 || (docs[d].visibility === 1 && docs[d].doc_owner === contact.ID)){
	    					let gc = JSON.parse(docs[d].gcs);
	    					if(Object.keys(gc).length === 0){
	    						if(docs[d].last_action_time >= t.start && docs[d].last_action_time <= t.end){
	    							c["recent_deleted"] = c["recent_deleted"] + 1;
	    						}
	    						c['recycle_bin'] = c['recycle_bin'] + 1;
	    					}else{
	    						let is_gc = this.isGCinclude(companies_assoc,gc);
	    						if(is_gc) {
	    							if(docs[d].last_action_time >= t.start && docs[d].last_action_time <= t.end){
	    								c["recent_deleted"] = c["recent_deleted"] + 1;
	    							}
	    							c["recycle_bin"] = c["recycle_bin"] + 1;
	    						}
	    					}
	    					let pinned = JSON.parse(docs[d].user_pins)
	    					let is_pinned = pinned[contact.ID.toString()] === undefined ? 0 : 1
	    					c["pinned_doc"] = c["pinned_doc"] - is_pinned ;
	    				}
	    			}
	    			break;
	    		}
	    	}

	    	for(let j = 0; j < members.length; j++){
	    		
	    		let child_members = members[j].members;
	    		let child_docs = members[j].docs;
	    		let child_del_parent = members[j].is_deleted === 1 ? 1 : child_del;
	    		if(child_del_parent === 1 ){
	    			switch(role){
	    				case "admin_manager":
	    				for(let d in child_docs){
	    					if(child_docs[d].last_action_time >= t.start && child_docs[d].last_action_time <= t.end){
	    						c["recent_deleted"] = c["recent_deleted"] + 1;
	    					}
	    					c['recycle_bin'] = c['recycle_bin'] + 1;
	    					let pinned = JSON.parse(child_docs[d].user_pins)
	    					let is_pinned = pinned[contact.ID.toString()] === undefined ? 0 : -1
	    					c["pinned_doc"] = c["pinned_doc"] + is_pinned ;
	    				}
	    				break;
	    				case "document_manager":
	    				for(let d in child_docs){
	    					let gc = JSON.parse(child_docs[d].gcs);
	    					if(Object.keys(gc).length === 0){
	    						if(child_docs[d].last_action_time >= t.start && child_docs[d].last_action_time <= t.end){
	    							c["recent_deleted"] = c["recent_deleted"] + 1;
	    						}
	    						c['recycle_bin'] = c['recycle_bin'] + 1;
	    					}else{
	    						let is_gc = this.isGCinclude(companies_assoc,gc);
	    						if(is_gc) {
	    							if(child_docs[d].last_action_time >= t.start && child_docs[d].last_action_time <= t.end){
	    								c["recent_deleted"] = c["recent_deleted"] + 1;
	    							}
	    							c["recycle_bin"] = c["recycle_bin"] + 1;
	    						}
	    					}
	    					let pinned = JSON.parse(child_docs[d].user_pins)
	    					let is_pinned = pinned[contact.ID.toString()] === undefined ? 0 : 1
	    					c["pinned_doc"] = c["pinned_doc"] - is_pinned ;
	    				}
	    				break;
	    				case "team":
	    				for(let d in child_docs){
	    					if(child_docs[d].visibility === 0 || (child_docs[d].visibility === 1 && child_docs[d].doc_owner === contact.ID)){
	    						let gc = JSON.parse(child_docs[d].gcs);
	    						if(Object.keys(gc).length === 0){
	    							if(child_docs[d].last_action_time >= t.start && child_docs[d].last_action_time <= t.end){
	    								c["recent_deleted"] = c["recent_deleted"] + 1;
	    							}
	    							c['recycle_bin'] = c['recycle_bin'] + 1;
	    						}else{
	    							let is_gc = this.isGCinclude(companies_assoc,gc);
	    							if(is_gc) {
	    								if(child_docs[d].last_action_time >= t.start && child_docs[d].last_action_time <= t.end){
	    									c["recent_deleted"] = c["recent_deleted"] + 1;
	    								}
	    								c["recycle_bin"] = c["recycle_bin"] + 1;
	    							}
	    						}
	    						let pinned = JSON.parse(child_docs[d].user_pins)
	    						let is_pinned = pinned[contact.ID.toString()] === undefined ? 0 : 1
	    						c["pinned_doc"] = c["pinned_doc"] - is_pinned ;
	    					}
	    				}
	    				break;
	    			}
	    		}
	    		
	    		this.folderTrevarse(child_members, c, child_del_parent);
	    	}


	    }
	    return c;
	}

	convertListToTree(list) {
	    var companyIDs = {};
	    list.forEach(function(x) {
	        companyIDs[x['id']] = 1;
	    });
	    var treeList = [];
	    var lookup = {};
	    list.forEach(function(obj) {
	        if(!(obj['parent_id'] in companyIDs) ) {
	            obj['parent_id'] = 0;
	        }
	        lookup[obj['id']] = obj;
	        obj['members'] = [];
	    });

	    list.forEach(function(obj) {

	      if (obj['parent_id'] !== 0) {

	          lookup[obj['parent_id']]['members'].push(obj);
	      } else {
	        // console.log("lookup", obj)
	          treeList.push(obj);
	      }
	    });
	    
	    return treeList;
	}

	getTree(docs){
		// let docs = Store.getStoreData('docs');
		let res = {}
		let folders = [];
		let documents = [];
		for(let key in docs){
			if(docs[key].type === -1){
				folders.push(docs[key])
			}else{
				documents.push(docs[key])
			}
		}
		let reorting = [];
		for(let r of folders){
			reorting.push({
			    id : r.id.toString(),
			    parent_id : r.parent_id.toString(),
			    is_deleted: r.is_deleted,
			    docs: this.getDocuments(documents, r.id)
			})
		}
		var tree = this.convertListToTree(reorting);
    	return tree;
	}


	getDocuments(documents, folder_id){
		let reorting = [];
		for(let r of documents) {
			// console.log("rrrr", r.is_deleted)
			// console.log("rrrr", r.folder_id)
		    if(parseInt(r.is_deleted) === 0 && parseInt(r.folder_id) === parseInt(folder_id)){
		    	reorting.push({
		    	    id : r.id,
		    	    last_action_time : r.last_action_time,
		    	    gcs: r.gcs,
		    	    user_pins: r.user_pins,
		    	    visibility: r.visibility,
		    	    doc_owner: r.doc_owner

		    	})
		    }

		}
		return reorting
	}

	divideCards(){
		let docs = Store.getStoreData('docs');
		let trees = this.getTree(docs);
		let c = {}; c['recent_deleted'] = 0;c['recycle_bin'] = 0;c['pinned_doc'] = 0;
		let card = this.folderTrevarse(trees, c)
		let companies_assoc = Store.getStoreData('companies_assoc');
		let role = Store.getStoreData('role');
		let contact = Store.getStoreData('contact');
		let period = Store.getStoreData('period')
		let t = Utils.timespanRecentDocument(period);

		let cards = [];
		for(let key in docs){
			if(docs[key].type !== -1){
				cards.push(docs[key])
			}
		}

		card["recent_doc"] = 0;
		switch(role){
			case "admin_manager":
			for(let key in cards){
				if(cards[key].last_action_time >= t.start && cards[key].last_action_time <= t.end){
					if(cards[key].is_deleted === 0){
						card["recent_doc"] = card["recent_doc"] + 1;
					}
					card["recent_deleted"] = card["recent_deleted"] + parseInt(cards[key].is_deleted);
				}
				if(cards[key].is_deleted === 0){
					let pinned = JSON.parse(cards[key].user_pins)
					let is_pinned = pinned[contact.ID.toString()] === undefined ? 0 : 1
					console.log("is_pinned", is_pinned, pinned[contact.ID.toString()], cards[key].id)
					card["pinned_doc"] = card["pinned_doc"] + is_pinned ;
				}
				card["recycle_bin"] = card["recycle_bin"] + parseInt(cards[key].is_deleted);
			}
			break;
			case "document_manager":
			for(let key in cards){
					let gc = JSON.parse(cards[key].gcs);
					if(Object.keys(gc).length === 0){
						if(cards[key].last_action_time >= t.start && cards[key].last_action_time <= t.end){
							if(cards[key].is_deleted === 0){
								card["recent_doc"] = card["recent_doc"] + 1;
							}
							card["recent_deleted"] = card["recent_deleted"] + parseInt(cards[key].is_deleted);
						}
						if(cards[key].is_deleted === 0){
							let pinned = JSON.parse(cards[key].user_pins)
							let is_pinned = pinned[contact.ID.toString()] === undefined ? 0 : 1
							card["pinned_doc"] = card["pinned_doc"] + is_pinned ;
						}
						card["recycle_bin"] = card["recycle_bin"] + parseInt(cards[key].is_deleted);
					}else if(Object.keys(cards[key].gcs).length > 0){
				        let is_gc = this.isGCinclude(companies_assoc,gc)
				        if(is_gc) {
				        	if(cards[key].last_action_time >= t.start && cards[key].last_action_time <= t.end){
				        		if(cards[key].is_deleted === 0){
				        			card["recent_doc"] = card["recent_doc"] + 1;
				        		}
				        		card["recent_deleted"] = card["recent_deleted"] + parseInt(cards[key].is_deleted);
				        	}
				        	if(cards[key].is_deleted === 0){
				        		let pinned = JSON.parse(cards[key].user_pins)
				        		let is_pinned = pinned[contact.ID.toString()] === undefined ? 0 : 1
				        		card["pinned_doc"] = card["pinned_doc"] + is_pinned ;
				        	}
				        	card["recycle_bin"] = card["recycle_bin"] + parseInt(cards[key].is_deleted);
				        }
					}


				// }
			}
			break;
			case "team":
			for(let key in cards){
				if(cards[key].visibility === 0 || (cards[key].visibility === 1 && cards[key].doc_owner === contact.ID)){
					let gc = JSON.parse(cards[key].gcs);
					if(Object.keys(gc).length === 0){
						if(cards[key].last_action_time >= t.start && cards[key].last_action_time <= t.end){
							if(cards[key].is_deleted === 0){
								card["recent_doc"] = card["recent_doc"] + 1;
							}
							cards["recent_deleted"] = cards["recent_deleted"] + parseInt(cards[key].is_deleted);
						}
						if(cards[key].is_deleted === 0){
							let pinned = JSON.parse(cards[key].user_pins)
							let is_pinned = pinned[contact.ID.toString()] === undefined ? 0 : 1
							card["pinned_doc"] = card["pinned_doc"] + is_pinned ;
						}
						card["recycle_bin"] = card["recycle_bin"] + parseInt(cards[key].is_deleted);
					}else if(Object.keys(cards[key].gcs).length > 0){
						let is_gc = this.isGCinclude(companies_assoc,gc)
				        if(is_gc) {
				        	if(cards[key].last_action_time >= t.start && cards[key].last_action_time <= t.end){
				        		if(cards[key].is_deleted === 0){
				        			card["recent_doc"] = card["recent_doc"] + 1;
				        		}
				        		cards["recent_deleted"] = cards["recent_deleted"] + parseInt(cards[key].is_deleted);
				        	}
				        	if(cards[key].is_deleted === 0){
				        		let pinned = JSON.parse(cards[key].user_pins)
				        		let is_pinned = pinned[contact.ID.toString()] === undefined ? 0 : 1
				        		card["pinned_doc"] = card["pinned_doc"] + is_pinned ;
				        	}
				        	card["recycle_bin"] = card["recycle_bin"] + parseInt(cards[key].is_deleted);
				        }
					}
				}
			}
			break;
		}
		Store.updateStore("doc_cards", card);


	}
}

export default new CadrsLoader();