import React from 'react';
import styled from 'styled-components';

const SelectedRadio = styled.div`
	background-color: #37ADA7;
	border: 1px solid #1f615e;
	padding: 5px 10px;
	color: #ffffff;
	display: inline-block;
	cursor: pointer;
`;
const UnselectedRadio = styled.div`
	background-color: #F4F4F4;
	border: 1px solid #8c8c8c;
	padding: 5px 10px;
	color: #262626;
	display: inline-block;
	cursor: pointer;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;
const GeneralInnerContainer = styled.div`
	background-color: #ffffff;
	padding: 10px 30px 10px;
`;
const GeneralLabel = styled.div`
	font-weight: 600;
	color: #595959;
	margin-top: 5px;
`;
const GeneralsubLabel = styled.div`
	font-weight: 400;
	color: #595959;
	margin-top: 5px;
`;
const RadioBlock = styled.div`
	margin-top: 5px;
`;
const ResponseDropdown = styled.select`
	box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    color: #b5b5b5;
`;

class General extends React.Component
{
	state = {general: null, timespans: {}};

	constructor(props) {
	    super(props);

	}

	componentDidMount()
	{
		let timespans = {};
		timespans['this_month'] = "This month";
		timespans['last_month'] = "Last month";
		timespans['last_30_days'] = "Last 30 days";
		timespans['last_12_month'] = "Last 12 month";
		timespans['this_year'] = "This Year";
		timespans['last_year'] = "Last Year";
		timespans['quarter_one'] = "Quarter One";
		timespans['quarter_two'] = "Quarter Two";
		timespans['quarter_three'] = "Quarter Three";
		timespans['quarter_four'] = "Quarter Four";
		this.setState({general: this.props.general, timespans: timespans});
	}

	componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {
			let timespans = {};
			timespans['this_month'] = "This month";
			timespans['last_month'] = "Last month";
			timespans['last_30_days'] = "Last 30 days";
			timespans['last_12_month'] = "Last 12 month";
			timespans['this_year'] = "This Year";
			timespans['last_year'] = "Last Year";
			timespans['quarter_one'] = "Quarter One";
			timespans['quarter_two'] = "Quarter Two";
			timespans['quarter_three'] = "Quarter Three";
			timespans['quarter_four'] = "Quarter Four";
			this.setState({general: this.props.general, timespans: timespans});
		}
	}

	switchRadio = (key) => (event) => {
		event.preventDefault();
		let {general} = this.state;
		general[key] = general[key] ? false : true;
		this.props.updateGeneral(general);
	}

	onChangeIntervalIQ  = (values, handle) => {
		let value = Math.floor(parseFloat(values[handle]));
		let {general} = this.state;
		general['recent_doc_enable'] = value;
		console.log("value", value);
		this.props.updateGeneral(general);
	}

	changeDropResponse = (event) => {
		event.preventDefault();
		let {general} = this.state;
		console.log("action_value", event.target.value);
		general.recent_doc_period = event.target.value
		this.props.updateGeneral(general);
	}

	

	render()
	{
		if (this.state.general === null) {
			return (<div>Loading...</div>);
		}

		return (
			<GeneralContainer>
							<GeneralInnerContainer>
								<GeneralLabel>Recent Documents</GeneralLabel>
								{
									(() => {
										if (this.state.general.recent_doc_enable) {
											return (
												<RadioBlock>
													<SelectedRadio>Yes</SelectedRadio>
													<UnselectedRadio onClick={this.switchRadio('recent_doc_enable')}>No</UnselectedRadio>
												</RadioBlock>
											);
										} else {
											return (
												<RadioBlock>
													<UnselectedRadio onClick={this.switchRadio('recent_doc_enable')}>Yes</UnselectedRadio>
													<SelectedRadio>No</SelectedRadio>
												</RadioBlock>
											);
										}
									})()
								}
							</GeneralInnerContainer>
							{
								(() => {
									if(this.state.general.recent_doc_enable) {
										return(<GeneralInnerContainer>
												<GeneralLabel>Action after no response</GeneralLabel>
												<div style={{paddingTop: '10px'}}>
													<ResponseDropdown value={this.state.general.recent_doc_period} onChange={this.changeDropResponse}>
														{
															(() => {
																let times = [];
																for (let key in this.state.timespans) {
																  times.push(
																    <option key={key} value={key}>
																      {this.state.timespans[key]}
																    </option>
																  );
																}
																return times;
															})()
														}
													</ResponseDropdown>
												</div>
											</GeneralInnerContainer>)
									}

								})()
							}
							<GeneralInnerContainer>
								<GeneralLabel>Record Document Approval</GeneralLabel>
								<GeneralsubLabel>Your users will have the option to record the approval of their documents. For example, Committee approval for new policies or third party reviews of legal documents.</GeneralsubLabel>
								{
									(() => {
										if (this.state.general.record_doc_approval) {
											return (
												<RadioBlock>
													<SelectedRadio>Yes</SelectedRadio>
													<UnselectedRadio onClick={this.switchRadio('record_doc_approval')}>No</UnselectedRadio>
												</RadioBlock>
											);
										} else {
											return (
												<RadioBlock>
													<UnselectedRadio onClick={this.switchRadio('record_doc_approval')}>Yes</UnselectedRadio>
													<SelectedRadio>No</SelectedRadio>
												</RadioBlock>
											);
										}
									})()
								}
							</GeneralInnerContainer>
							
			</GeneralContainer>
		);
	}
}

export default General;
