import React from "react";
import styled from "styled-components";
import Store from "./Common/Store.js";
import APICall from "./Common/APICall.js";
import APIContact from "./Common/APIContact";
import Utils from "./Common/Utils.js";
import ComDecom from './Common/ComDecom.js';
import Index from "./Components/Index";
import ModuleConfigHOC from "./Components/Moduleconfig/ModuleConfigHOC";
import AlertBox from "./Components/Common/AlertBox";
import CSLLoader from "./Components/Common/CSLLoader";
import CSLPageloader from "./Components/Common/CSLPageloader";
import CadrsLoader from "./Common/CadrsLoader.js";
import Reload from './Common/Reload.js';
import authService from "mcc-front-aux/dist/authService";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";


/*global localStorage*/

const moment = require('moment');
const last_action_time = moment().unix();
class AppContent extends React.Component {
  state = {
    ready: false,
    configs: {},
    alert_param: null,
  };

  constructor(props) {
    super(props);
    Utils.setEnv("docmgmt");
    Store.updateStore("module_name", "docmgmt");
    this.callContact();
    setTimeout(Utils.docsCallRunner, 30000)
  }

  callContact() {
    let postData = { command: "contactlist"};
    let api = new APIContact();
    let j = api.command(postData, this.processContactData);
  }
  processContactData = (result) => {
    let cl = ComDecom.deCompress(result.result['contactlist'], true, 'ID');
    result.result['contactlist'] = cl
    localStorage.setItem("contactlist", JSON.stringify(result));
  }

  componentDidMount() {
    let api = new APICall();

    let postData = { command: "index"};
    api.command(postData, this.processIndex);
  }
  processIndex = (result) => {
    console.log("processIndex", result);
    if (result.error_code === 0) {
      if (result.result["role"] === "no_access") {
        let alert_param = {title: 'No Access', message: '', ok_text: 'Ok', confirm: false,
                           alertHandler: this.alertHandler, stack: {id: 0}};
        alert_param["message"] = result.error_msg;
        this.setState({ ready: true, alert_param: alert_param});
        return false;

      }

      Store.updateStore("role", result.result["role"]);
      Store.updateStore("contact", result.result["contact"]);
      Store.updateStore("company", result.result["company"]);

      let selected_types = result.result["doc_types"].selected_types;
      let st = [];
      for(let k in selected_types){
        st.push({id: k, name: selected_types[k]});
      }

      // let type_order = Object.keys(types).sort(function(a,b){return types[b]-types[a]});
      st.sort(this.compare);
      // console.log("type_order", st)

      Store.updateStore("doc_types", result.result["doc_types"].types);
      Store.updateStore("selected_doc_types", result.result["doc_types"].selected_types);
      Store.updateStore("types", st);
      Store.updateStore("doc_v", result.result["doc_v"]);
      Store.updateStore("period", result.result["period"]);
      Store.updateStore("record_doc_approval", result.result["record_doc_approval"]);
      Store.updateStore("is_gc", result.result["is_gc"]);

      let contact_assoc = {};
      let modify_assoc = {};
      let r = {};
      r["result"] = {};
      console.log('result in app.js' )
      let cts = localStorage.getItem("contactlist") !== null ? JSON.parse(localStorage.getItem("contactlist")) : r;
      console.log('cts in app.js' )

      let user_roles = {};
      let list_contacts = {};
      let active_contacts = {};
      let attest_role = "team";
      if ("contactlist" in cts.result && "user_roles" in cts.result) {
        user_roles = cts.result.user_roles["docmgmt"];
        for (let k in cts.result.contactlist) {
          if(cts.result.user_roles["docmgmt"][k.toString()] !== undefined){
            if(cts.result.contactlist[k.toString()].IsDeleted === false && cts.result.contactlist[k.toString()].IsActive === true){
              if (cts.result.user_roles["docmgmt"][k.toString()] === "admin_manager" || cts.result.user_roles["docmgmt"][k.toString()] === "document_manager") {
                modify_assoc[k] = cts.result.contactlist[k.toString()].ContactName;
              }
              if (cts.result.user_roles["docmgmt"][k.toString()] !== "no_access") {
                contact_assoc[k] = cts.result.contactlist[k.toString()].ContactName;
              }
              active_contacts[k] = cts.result.contactlist[k.toString()].ContactName;
            }
            // list_contacts[k] = cts.result.contactlist[k.toString()].ContactName;
            list_contacts[k] = cts.result.contactlist[k.toString()].IsActive ? cts.result.contactlist[k.toString()].ContactName : cts.result.contactlist[k.toString()].ContactName + "(Inactive)";
          }
        }
        if("attest" in cts.result.user_roles && cts.result.user_roles["attest"][result.result["contact"].ID] !== undefined){
          attest_role = cts.result.user_roles["attest"][result.result["contact"].ID];
        }


      }

      console.log("user roles: ", user_roles);

      console.log('result in app.js after', attest_role )
      let is_attest_access = (attest_role === "manager" || attest_role === "am") ? true : false;

      Store.updateStore("is_attest_access", is_attest_access);
      Store.updateStore("user_roles", user_roles ?? {});
      Store.updateStore("listcontacts", list_contacts);
      Store.updateStore("active_contacts", active_contacts);
      Store.updateStore("contact_assoc", contact_assoc);
      Store.updateStore("modify_assoc", modify_assoc);
      Store.updateStore("used_doc_type", result.result["used_doc_type"]);

      let assignments = "assignments" in result.result ? result.result.assignments : {};
      let user_assignments = "user_assignments" in result.result ? result.result.user_assignments : {};
      console.log("user_assignments", user_assignments)
      Store.updateStore("assignments", assignments);
      Store.updateStore("user_assignments", user_assignments);

      let companies_assoc = "companies_assoc" in result.result ? result.result.companies_assoc : {};
      for (let c_id in companies_assoc) {
        let include = false;
        if ("companies" in assignments && c_id in assignments.companies) {
          include = true;
        } else {
          if ("tags_config" in companies_assoc[c_id]) {
            for (let t_id in companies_assoc[c_id].tags_config) {
              for (let v_id in companies_assoc[c_id].tags_config[t_id]) {
                if ("tags" in assignments && t_id in assignments.tags && v_id in assignments.tags[t_id]) include = true;
              }
            }
          }
        }
        if (!include && result.result["role"]!=="admin_manager") delete companies_assoc[c_id];
      }

      Store.updateStore("companies_assoc", companies_assoc);
      let gc_users = {};
      for (let k in user_assignments) {
        if (!(k in gc_users)) gc_users[k] = {};
        for (let key in companies_assoc) {
          if (key in user_assignments[k].companies) {
            gc_users[k][key.toString()] = 1;
          }
        }
      }

      Store.updateStore("gc_users", gc_users);
      let tag_values = "tag_values" in result.result ? result.result.tag_values : {};
      for (let t_id in tag_values) {
        if ("tags" in assignments && !(t_id in assignments.tags)) {
          delete tag_values[t_id];
        } else {
          for (let v_id in tag_values[t_id].values) {
            if ("tags" in assignments && !(v_id in assignments.tags[t_id])) delete tag_values[t_id].values[v_id];
          }
        }
      }
      Store.updateStore("tag_values", tag_values);
      let docs = ComDecom.deCompress(result.result['docs'], false, 'id');
      Store.updateStore("docs", docs)
      let folders = [];
      let documents = [];
      for(let key in docs){
        if(docs[key].type === -1){
          folders.push(docs[key])
        }else{
          documents.push(docs[key])
        }
      }


      let doc_last_actin_time = 0;
      let folder_last_actin_time = 0;
      if(documents.length > 0){
        doc_last_actin_time = documents.sort((a, b) => b.last_action_time - a.last_action_time)[0].last_action_time;

      }
      if(folders.length > 0){
        folder_last_actin_time = folders.sort((a, b) => b.last_action_time - a.last_action_time)[0].last_action_time;
      }
      Store.updateStore("doc_last_actin_time", doc_last_actin_time);
      Store.updateStore("folder_last_actin_time", folder_last_actin_time);



      let f = {};
      for(let k in folders) {
        if(folders[k].is_deleted === 0) f[folders[k].id.toString()] = folders[k].name;
      }
      Store.updateStore("folders_assoc", f)
      console.log('docs---1', f)





      CadrsLoader.divideCards();

      this.setState({ ready: true });
    } else {
      let alert_param = {title: 'System Error', message: '', ok_text: 'Ok', confirm: false,
                 alertHandler: this.alertHandler, stack: {id: 0}}
      alert_param["message"] = result.error_msg;
      this.setState({ ready: true, alert_param: alert_param, task_loading: false });
      return;
    }
  };


  compare(a, b) {
      const bandA = a.name.toUpperCase();
      const bandB = b.name.toUpperCase();

      let comparison = 0;
      if (bandA > bandB) {
        comparison = 1;
      } else if (bandA < bandB) {
        comparison = -1;
      }
      return comparison;
   }

  alertHandler = (result, stack) => {
    // this.setState({ alert_param: null });
    // window.location = "/select/ServiceBasedLanding";
    Reload.ReloadPage();
  };

  render() {
    if (Store.getStoreData("docmgmt-index-select") === null) {
      return (
        <div>
          <CSLPageloader />
        </div>
      );
    } else {
      if (!this.state.ready) {
        return (
          <div>
            <CSLLoader />
          </div>
        );
      }
    }

    if (this.state.alert_param !== null) {
      return <AlertBox alertParam={this.state.alert_param} />;
    }

    return (
      <Router>
        <Switch>
          <Route path="/moduleconfig" exact>
            <ModuleConfigHOC />
          </Route>
          <Route path="/" exact>
            <Index />
          </Route>
          <Route path="/:taskId" component={Index} exact />
          <Route path="/:docId/:versionId" component={Index} exact />
        </Switch>
      </Router>
    );
  }
}

class App extends React.Component {
  state = { ready: false}

  setComponentState = () => {
    console.log('this.props', this.props)
    let is_debug = false
    let api_url = process.env.REACT_APP_API_URL;
    Store.updateStore('is_debug', is_debug)
    Store.updateStore('api_url', api_url)

    authService.refresh().then(() => {
      this.setState({ready: true})
    })
  }

  componentDidMount = () => {
    this.setComponentState()
  }

  componentDidUpdate = (prevprops) => {
        if(this.props === prevprops) return
        this.setComponentState()
    }

  render() {
    if(!this.state.ready) {
        return <div>Loading...</div>
    }
    // let appContent = <AppContent />
    //
    // return <ErrorBoundary><SiteLayout AppContent={appContent} /></ErrorBoundary>

    return <AppContent />
  }
}

export default App;
