import React from "react";
import Store from "../../../Common/Store.js";
import * as Style from "../../Common/StyledComponents";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import CSLLoader from "../../Common/CSLLoader";
import AlertBox from "../../Common/AlertBox";
import CSLTable from "../../Common/CSLTable";
import APICall from "../../../Common/APICall.js";

const moment = require("moment");
const last_action_time = moment().unix();

const FolderContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 50%;
  position: fixed;
  margin: 80px 100px;
  top: 8px;
  z-index: 1001;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 20px #a7a6a6;
`;
const DocHeader = styled.div`
  border-width: 1px;
  background-color: white;
  border-color: #e9e9e9;
  display: block;
  font-size: 14px;
  font-weight: 700;
  height: 50px;
  border-radius: 5px;
`;
const ModalHeaderCloseBtn = styled.div`
  float: right;
  box-sizing: border-box;
  color: #282828;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
`;

const DocBody = styled.div`
  display: block;
  box-sizing: border-box;
  background-color: #f3f7fb;
  padding: 10px;
  border-radius: 4px;
  height: 690px;
  overflow-y: auto;
`;

const DocFormContainer = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
`;

const SpacerL = styled.div`
  height: 10px;
`;
const SpacerXL = styled.div`
  height: 20px;
`;

const ContactRadio = styled.input`
  border: 1px solid #ffffff;
  border-radius: 3px;
  color: #979797;
  font-family: montserrat;
  font-size: 14px;
  font-weight: 600;
  margin: 10px;
`;

const UserFormFooter = styled.div`
  position: relative;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #e9ecef;
  height: 79px;
  border-radius: 5px;
`;

const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #046de4;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 127px;
  font-size: 13px;
  height: 39px;
  border-radius: 4px;
  float: right;
  margin-top: -30px;
  cursor: pointer;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 3000px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;

const RadioBlock = styled.div`
  float: right;
  margin-top: -5px;
`;
const SelectedRadio = styled.div`
  background-color: #37ada7;
  border: 1px solid #1f615e;
  padding: 5px 10px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
`;
const UnselectedRadio = styled.div`
  background-color: #f4f4f4;
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  color: #262626;
  display: inline-block;
  cursor: pointer;
`;

class EditSingle extends React.Component {
  state = {
    ready: false,
    alert_param: null,
    saving: false,
    types: {},
    contacts: {},
    document_info: null,
    all_company: true,
    gcs: {},
    users: []
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.callDefaultinfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.callDefaultinfo();
    }
  }

  sortAssociativeArrayByValues = (obj) => {
      let x = [];
      for (let key in obj) {
        x.push({id: key, name: obj[key]});
      }
      return x;
  }

  arraySortByname = (arr) => {
    arr.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      // 

      if (nameA < nameB) {
        return 1; 
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0; 
    });
    console.log("arraySortByname", arr)
    return arr;
  }


  callDefaultinfo() {
    // let doc_types = Store.getStoreData("selected_doc_types");
    let doc_types = Store.getStoreData("types");
    let contact_assoc = Store.getStoreData("contact_assoc");
    let companies_assoc = Store.getStoreData("companies_assoc");
    let gc_users = Store.getStoreData("gc_users");
    let role = Store.getStoreData("role");
    let contact = Store.getStoreData("contact");
    let user_assignments = Store.getStoreData("user_assignments");
    let show_all = role === "admin_manager" ? true : false;
    let document_info = this.props.single_obj;
    let user_list = {};
    console.log("contact_assoc ==>", document_info);
    if (Object.keys(gc_users).length !== 0) {
      for (let key in contact_assoc) {
        if (show_all || this.hasCommonCompanyCheckFirst(contact.ID, key, gc_users)) {
          if (key in gc_users) {
            user_list[key.toString()] = contact_assoc[key.toString()];
          }
        }else{
          if (key in gc_users) {
            user_list[key.toString()] = contact_assoc[key.toString()];
          }
        }
      }
    } else {
      user_list = contact_assoc;
    }

    let users = this.sortAssociativeArrayByValues(user_list);
    users.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1; // a should come before b
      }
      if (nameA > nameB) {
        return 1; // a should come after b
      }
      return 0; // names are equal, no change in order
    });

    console.log("users", users)
    let user_roles = Store.getStoreData('user_roles');
    let is_manager = user_roles[document_info["doc_owner"].toString()] === "admin_manager" ? true : false; 

    // console.log("user_list", user_assignments[Object.keys(user_list)[0].toString()].companies)
    let owner_gcs = Object.keys(user_assignments).length === 0 ? {} : user_assignments[Object.keys(user_list)[0].toString()].companies;
    let gcs = {};
    let gc = document_info["gc_ids"];

    for (let k in companies_assoc) {
      // if(companies_assoc[k].is_active === 1){
        if(is_manager){
          gcs[k] = { id: k, name: companies_assoc[k].company_name, selected: (k in gc) ? true: false  };
        }else{
          if (k in owner_gcs) {
            gcs[k] = { id: k, name: companies_assoc[k].company_name, selected: (k in gc) ? true: false  };
          }
        }
      // }

    }

    // document_info["folder_id"] = this.props.folder_id;

    // let folders = this.props.folders;
    // let folder_order = this.props.folder_order;
    // let parent_id = this.props.parent_id;
    // let folder_id = this.props.folder_id;


    this.setState({
      ready: true,
      types: doc_types,
      contacts: user_list,
      document_info: document_info,
      gcs: gcs,
      all_company : Object.keys(document_info['gc_ids']).length === 0 ? true : false,
      users: users
    });
  }

  hasCommonCompanyCheckFirst = (manager_id, user_id, gc_users) => {
    if (Object.keys(gc_users).length === 0) return true;
    if (manager_id === "") return true;
    let manager_companies = gc_users[manager_id];
    let user_companies = gc_users[user_id];
    for (let cid in manager_companies) {
      for (let uid in user_companies) {
        if (cid.toString() === uid.toString()) return true;
      }
    }
    return false;
  };

  processData = (result) => {
    let cur_contact_info = result.contact_info;
    this.contact_info = cur_contact_info;
    this.cur_contact_info = cur_contact_info;
    let contact_name = cur_contact_info.ContactName;
    let job_titles = result.job_titles;
    let regulatory_status = result.regulatory_status;
    this.setState({
      ready: true,
      cur_contact_info: cur_contact_info,
      regulatory_status: regulatory_status,
      job_titles: job_titles,
      contact_name: contact_name,
      userId: this.props.userId,
    });
  };

  processIndexData = (result) => {
    window.location.reload();
  };

  refreshState = (cur_view) => {
    this.setState({ view_count: this.state.view_count + 1 });
  };

  returnSection = (section) => (event) => {
    event.preventDefault();
    this.setState({ active_section_id: section });
  };

  afterUserUpdate = (result, stack) => {
    this.setState({ alert_param: null });
    window.location.reload();
  };

  closeModal = (event) => {
    if (event) event.preventDefault();
    this.props.closeSingleModal();
  };

  setDirtyFlag = (contact_info) => {
    this.user_is_dirty = true;
    this.contact_info = contact_info;
  };

  handleChangeDocOwner = (event) => {
    if (event) event.preventDefault();
    let companies_assoc = Store.getStoreData("companies_assoc");
    let user_assignments = Store.getStoreData("user_assignments");
    let document_info = this.state.document_info;
    document_info[event.target.name] = parseInt(event.target.value);
    // let owner_gcs = user_assignments[event.target.value.toString()].companies;
    let owner_gcs = Object.keys(user_assignments).length === 0 ? {} : user_assignments[event.target.value.toString()].companies;
    let gcs = {};
    let user_roles = Store.getStoreData('user_roles');
    let is_manager = user_roles[event.target.value.toString()] === "admin_manager" ? true : false; 

    for (let k in companies_assoc) {
      // if(companies_assoc[k].is_active === 1){
      if(is_manager){
        gcs[k] = { id: k, name: companies_assoc[k].company_name, selected: false };
      }else{
        if (k in owner_gcs) {
          gcs[k] = { id: k, name: companies_assoc[k].company_name, selected: false };
        }
      }
    // }

    }

    console.log("event.target.value", event.target.value);

    this.setState({ document_info: document_info, gcs: gcs });
  };

  handleChange = (event) => {
    let document_info = this.state.document_info;
    document_info[event.target.name] = event.target.value;
    this.setState({ document_info: document_info });
  };
  handleRadio = (event) => {
    let document_info = this.state.document_info;
    document_info["visibility"] = event.target.name === "public" ? 0 : 1;
    this.setState({ document_info: document_info });
  };
  handlelockRadio = (event) => {
    let document_info = this.state.document_info;
    document_info["lock_doc"] = event.target.name === "no" ? 0 : 1;
    this.setState({ document_info: document_info });
  };


  processORcancel = (result, stack) => {
    if (!result || stack.prevent) {
      this.setState({ alert_param: null });
      return;
    }
    this.setState({ alert_param: null });
    let bin_files = this.state.binFiles;
    this.refs.dropZone.uploadFilesFromChild(bin_files);
  }

  AlertCompleteHandler = (result, stack) => {
    this.setState({ alert_param: null });
  };



  alertRemove = (result, stack) => {
    this.setState({ alert_param: null });
  };


  AlertremoveHandler = (result, stack) => {
    this.setState({ alert_param: null });
    this.props.refreshParent();
  };



  closeUserDialog = () => {
    this.setState({ show_attestation: null });
  };

  reloadDocuments = (doc_id, version_id) => {
    // this.getDocDetails(doc_id, version_id);
    this.setState({ show_attestation: null });
    this.props.refreshParent();
  };

  switchRadio = (event) => {
    event.preventDefault();
    let all_company = this.state.all_company ? false : true;
    let document_info = this.state.document_info;
    if(all_company){
      document_info['gc_ids'] = {}
    }
    this.setState({ all_company: all_company,  document_info: document_info});
  };

  selectGc = (event) => {
    event.preventDefault();
    let gc_id = event.target.value;
    let gcs = this.state.gcs;
    gcs[gc_id].selected = gcs[gc_id].selected ? false : true;
    let document_info = this.state.document_info;
    let gc_ids = document_info.gc_ids;
    gc_ids[gc_id] = 1;
    document_info.gc_ids = gc_ids;
    this.setState({ gcs: gcs, document_info: document_info });
  };

  tableData = () => {
    let table = { data: [], columns: [] };
    let gcs = this.state.gcs;

    table.columns = [
      { Header: "Name", accessor: "name", headerStyle: { textAlign: "left" }, style: { whiteSpace: "unset" } },
      {
        Header: "Status",
        accessor: "status",
        width: 80,
        headerStyle: { textAlign: "center" },
        Cell: (row) => (
          <div>
            <input type="checkbox" checked={row.original.selected} value={row.original.id} onClick={this.selectGc} />
          </div>
        ),
        style: { display: "flex", alignItems: "center", justifyContent: "center" },
      },
    ];

    for (let key in gcs) {
      let elem = {
        name: gcs[key].name,
        id: key,
        selected: "selected" in gcs[key] ? gcs[key].selected : false,
      };
      table.data.push(elem);
    }
    return table;
  };

  handleSaveDoc = (event) => {
    event.preventDefault();
    let document_info = this.state.document_info;
    // let gcs = this.state.gcs;
    // let g = {};
    // for (let key in gcs) {
    //   if (this.state.all_company) {
    //     g[key] = 1;
    //   } else {
    //     if (gcs[key].selected) g[key] = 1;
    //   }
    // }
    // document_info["gc_ids"] = g;
    this.props.saveDocInfo(document_info)
    console.log("document_info", document_info)

  };


  folderChange = (event) => {
    event.preventDefault();
    let folder_id = event.target.value === "back" ? this.state.document_info['parent_id'] : event.target.value;
    let api = new APICall();
    let postData = { command: "get_folders", folder_id: folder_id, reorder: event.target.value === "back" ? 0 : 1 };
    api.command(postData, this.processFolders);
    let folder_order = this.state.folder_order;
    let document_info = this.state.document_info;

    if(event.target.value === "back"){
      delete document_info.folder_order[document_info['folder_id']]
    }
    this.setState({ document_info});
  };

  processFolders = (result) => {
      console.log("result", result, this.state.documents)
      let folders = result.result;
      if(result.folder_id > 0){
        folders = [...[{id: 'back', name: "...."}], ...folders]
      }
      let document_info = this.state.document_info;
      if(result.reorder === 1) document_info['folder_order'][result.folder_id] = Object.keys(document_info['folder_order']).length + 1;
      document_info.folder_id = result.folder_id
      document_info.folders = folders
      document_info.parent_id = result.parent_id

      console.log("folder_order", document_info)
      this.setState({ document_info });
  }



  /**new code */

  render() {
    console.log("contact_info", this.state);
    if(this.state.document_info === null) return(<div>loading</div>)

    let folders_assoc = Store.getStoreData('folders_assoc')
    let y = ''
    let i = 0;
    for(let k in this.state.document_info['folder_order']){
      if(k > 0){
        y  = y.concat(folders_assoc[k])
        if(i < Object.keys(this.state.document_info['folder_order']).length - 1){
          y += ' / '
        }
      }
      i++
    }

    return (
      <FolderContainer>
        <div>
          <DocHeader>
            <div style={{ fontSize: "14px", float: "left", marginTop: "15px", marginLeft: "10px" }}>Update document details</div>
            <ModalHeaderCloseBtn onClick={this.props.closeSingleModal}>
              <MdClose />
            </ModalHeaderCloseBtn>
          </DocHeader>
          <DocBody>
            <DocFormContainer>
              {
                (() => {
                  if(this.state.loading) return <CSLLoader />;
                })()
              }
              <Style.ModalNameLabelDiv>Document Name</Style.ModalNameLabelDiv>
              <SpacerL />
              <Style.ModalNameInputDiv>
                <Style.ModalInput type="text" name="name" onChange={this.handleChange} value={this.state.document_info["name"]} />
              </Style.ModalNameInputDiv>
              <SpacerL />
              <Style.ModalNameLabelDiv>Document Type</Style.ModalNameLabelDiv>
              <SpacerL />
              <Style.ModalNameInputDiv>
                <Style.MRModalSelect onChange={this.handleChange} name="doc_type" value={this.state.document_info["doc_type"]}>
                  {(() => {
                    if (this.state.types.length !== 0) {
                      let regulatory_status = [];
                      for (let key in this.state.types) {
                        regulatory_status.push(
                          <option key={this.state.types[key].id} value={this.state.types[key].id}>
                            {this.state.types[key].name}
                          </option>
                        );
                      }
                      return regulatory_status;
                    }
                  })()}
                </Style.MRModalSelect>
              </Style.ModalNameInputDiv>
              <SpacerL />
              <Style.ModalNameLabelDiv>Document Owner</Style.ModalNameLabelDiv>
              <SpacerL />
              <Style.MRModalSelect onChange={this.handleChangeDocOwner} name="doc_owner" value={this.state.document_info["doc_owner"]}>
                {(() => {
                  if (this.state.users.length !== 0) {
                    let regulatory_status = [];
                    for (let key in this.state.users) {
                      regulatory_status.push(
                        <option key={this.state.users[key].id} value={this.state.users[key].id}>
                          {this.state.users[key].name}
                        </option>
                      );
                    }
                    return regulatory_status;
                  }
                })()}
              </Style.MRModalSelect>
              <SpacerL />
              <Style.ModalNameLabelDiv>Document Description</Style.ModalNameLabelDiv>
              <SpacerL />
              <Style.ModalNameInputDiv>
                <Style.ModalTextArea name="description" onChange={this.handleChange} value={this.state.document_info["description"]} />
              </Style.ModalNameInputDiv>
              <SpacerL />
              <Style.ModalNameLabelDiv>Visibility</Style.ModalNameLabelDiv>
              <Style.ModalNameInputDiv>
                <ContactRadio type="radio" value="0" name="public" checked={this.state.document_info["visibility"] === 0 ? true : false} onClick={this.handleRadio} /> Public
                <ContactRadio type="radio" value="1" name="private" checked={this.state.document_info["visibility"] === 1 ? true : false} onClick={this.handleRadio} /> Private
              </Style.ModalNameInputDiv>
              <SpacerL />
              {(() => {
                if (Store.getStoreData("is_gc")) {
                  return (
                    <div>
                      <Style.ModalNameLabelDiv>Apply to all companies</Style.ModalNameLabelDiv>
                      <SpacerL />
                      <SpacerL />
                      <div style={{ float: "left" }}>
                        {(() => {
                          if (this.state.all_company && Object.keys(this.state.document_info['gc_ids']).length === 0) {
                            return (
                              <RadioBlock>
                                <SelectedRadio>Yes</SelectedRadio>
                                <UnselectedRadio onClick={this.switchRadio}>No</UnselectedRadio>
                              </RadioBlock>
                            );
                          } else {
                            return (
                              <RadioBlock>
                                <UnselectedRadio onClick={this.switchRadio}>Yes</UnselectedRadio>
                                <SelectedRadio>No</SelectedRadio>
                              </RadioBlock>
                            );
                          }
                        })()}
                      </div>
                      <SpacerL />
                      <SpacerL />
                      <Style.HomeText style={{ float: "none", marginLeft: "0px" }}>Companies are restricted based on the document owner's group company associations</Style.HomeText>
                      <SpacerL />
                      {(() => {
                        if (!this.state.all_company) {
                          return <CSLTable add={false} processData={this.tableData} tableRows={10} offset={true} />;
                        }
                      })()}
                    </div>
                  );
                }
              })()}
              <SpacerL />
              <SpacerL />
              {
                (() => {
                if (Store.getStoreData("role") === "admin_manager") {
                  return (
                    <div>
                      <Style.ModalNameLabelDiv>Lock document</Style.ModalNameLabelDiv>
                      <Style.ModalNameInputDiv>
                        <ContactRadio type="radio" value="0" name="no" checked={this.state.document_info["lock_doc"] === 0 ? true : false} onClick={this.handlelockRadio} /> No
                        <ContactRadio type="radio" value="1" name="yes" checked={this.state.document_info["lock_doc"] === 1 ? true : false} onClick={this.handlelockRadio} /> Yes
                      </Style.ModalNameInputDiv>
                    </div>
                  );
                }
              })()
            }
            <div>
              <div style={{ float: "left", width: "95%", boxSizing: "border-box" }}>
                  <div style={{ fontWeight: "600", paddingBottom: "10px", fontSize: "16px" }}>Select Folder</div>
                  <div style={{paddingBottom: "5px"}}>Files will be placed in the folder shown. You can select different folder here.</div>
                  <div style={{ fontWeight: "600", paddingBottom: "5px"}}> {y} </div>
              </div>
              <Style.SpacerL></Style.SpacerL>
              <Style.MRModalSelect onChange={this.folderChange} name="folder_id" value={this.state.document_info['folder_id']}>
                {(() => {
                  let regulatory_status = [<option value="0" disabled selected hidden>Select Folder</option>]
                  for (let f of this.state.document_info['folders']) {
                    regulatory_status.push(
                      <option key={f.id} value={f.id}>
                        {f.name}
                      </option>
                    );
                  }
                  return regulatory_status;
                })()}
              </Style.MRModalSelect>
            </div>
            </DocFormContainer>
          </DocBody>
          <UserFormFooter>
            <MRButton style={{ top: "55px", right: "150px", position: "absolute", backgroundColor: "#FFFFFF", color: "#757575" }} onClick={this.props.closeSingleModal} disabled={this.state.saving}>
              CANCEL
            </MRButton>
            <MRButton style={{ top: "55px", right: "10px", position: "absolute", backgroundColor: "#11ABA6", color: "white" }} onClick={this.handleSaveDoc} disabled={this.state.saving}>
              UPDATE
            </MRButton>
          </UserFormFooter>
        </div>
        <AlertBox alertParam={this.state.alert_param} />
      </FolderContainer>
    );
  }
}

export default EditSingle;