import React from "react";
import * as Style from "../Common/StyledComponents";
import APICall from "../../Common/APICall.js";
import Store from "../../Common/Store.js";
import styled from "styled-components";
import { FaTimes, FaPlusCircle, FaLock } from "react-icons/fa";
import { FcFolder } from "react-icons/fc";
import { MdClose } from "react-icons/md";
import { IoIosArrowForward, IoIosLock, IoIosClose } from "react-icons/io";
import CSLLoader from "../Common/CSLLoader";
import Dropzone from "../Common/Dropzone.js";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import AlertBox from "../Common/AlertBox";
import Utils from "../../Common/Utils.js";
import DocumentViewModal from "./DocumentViewModal";
import VEllipsisMenu from "./Menu/FolderdocsEllipsisMenu";

const moment = require("moment");
const last_action_time = moment().unix();

const FolderContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 75%;
  position: fixed;
  margin: 8px;
  top: 60px;
  z-index: 1001;
  background-color: #f7f7f7;
  border-radius: 5px;
  border-style: solid;
  border-color: #495e75;
  border-width: 3px;
  box-shadow: 0 0 20px #a7a6a6;
`;

const MRButton = styled.button`
  font-family: montserrat;
  display: inline-block;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #fff;
  border: 1px solid #dadada;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  margin-left: 5px;
`;

const Flexbox = styled.button`
  display: flex;
  width: 100%;
  padding: 5px;
  border: none;
`;

const Iconbox = styled.button`
  border: 1px solid #dcdcdc;
  margin: auto;
  padding: 5px;
  font-size: 14px;
  background-color: #fff;
  text-align: center;
  font-weight: 600;
  margin-right: 20px;
  font-family: "Montserrat", sans-serif;
`;
const FloatLeft = styled.div`
    float: left;
    margin-bottom: 10px;
    width: 30%
    text-align: left;
    margin-left: 10px;
`;
const FloatRight = styled.div`
    float: right;
    margin-bottom: 10px;
    width: 65%
    text-align: left;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 3000px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;
const NoWrap = styled.div`
  white-space: nowrap;
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const ContactRadio = styled.input`
  border: 1px solid #ffffff;
  border-radius: 3px;
  color: #979797;
  font-family: montserrat;
  font-size: 14px;
  font-weight: 600;
  margin: 5px;
`;

class FolderViewModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      alert_param: null,
      folder_info: null,
      addeddocs: [],
      folderSize: null,
      ready: false,
      document_view: null,
      doc_id: 0,
      version_id: 0,
      show_edit: true
    };
  }

  componentDidMount() {
    this.callDefaultinfo(this.props.folder_id);
  }

  callDefaultinfo(folder_id) {
    let api = new APICall();
    let postData = { command: "get_folder_info", folder_id: folder_id };
    api.command(postData, this.processDocuments);
  }

  processDocuments = (result) => {
    console.log("result", result);
    let folder = result.result["folder"];
    let docs = result.result["docs"];
    let show_edit = this.state.show_edit;
    if (folder["lock_folder"] === 1) {
      show_edit = Store.getStoreData("role") === "admin_manager" ? true : false;
    }

    this.setState({ folder_info: folder, addeddocs: docs, folderSize: this.getTotalfileSize(docs),show_edit: show_edit, ready: true });
  };

  closeModal = (event) => {
    if (event) event.preventDefault();
    this.props.closeUserDialog();
  };

  changeEdit = () => {
    let edit = this.state.edit ? false : true;
    this.setState({ edit });
  };

  handleChange = (event) => {

    let folder_info = this.state.folder_info;
    folder_info[event.target.name] = event.target.value;
    this.setState({ folder_info: folder_info });
  };

  updateFolder = (event) => {
    event.preventDefault();
    let postData = { command: "folder_update" };
    postData["folder_info"] = this.state.folder_info;
    postData["mode"] = "update";
    postData["last_actin_time"] = Store.getStoreData('folder_last_actin_time')
    let api = new APICall();
    console.log("postData", postData);
    api.command(postData, this.processDocument);
  };

  AlertremoveHandler = (result, stack) => {
    this.setState({ alert_param: null });
    // window.location.reload();
    let folder_id = stack.id;
    this.props.closeUserDialogFolder(folder_id);
  };
  processDocument = (result) => {
    console.log("result folders", result.folders);
    let folders = result.folders;
    let folder_last_actin_time = folders.sort((a, b) => b.last_action_time - a.last_action_time)[0].last_action_time;
    Store.updateStore("folder_last_actin_time", folder_last_actin_time)
    let documents = Store.getStoreData('docs');

    for(let key in documents){
      if(parseInt(documents[key].id) === parseInt(result.id)){
        documents[key] = folders[0]
      }
    }
    // let docs = [...folders, ...documents];
    Store.updateStore('docs', documents);
    let msg = result.error_code === 0 ? "Folder updated successfully" : "An error occurred, please contact system admin";
    let alert_param = { title: result.error_code === 0 ? "Success" : "Error", message: msg, ok_text: "OK", confirm: false, alertHandler: this.AlertremoveHandler, stack: { id: result.parent_id } };
    this.setState({ alert_param: alert_param });
  };

  getFile_extIcon = (filename) => {
    const file_parts = filename.split(/\.(?=[^\.]+$)/);
    let ext = "PDF";
    let extention = file_parts[file_parts.length - 1];
    switch (extention) {
      case "jpeg":
      case "jpg":
        ext = "JPG";
        break;
      case "png":
        ext = "PNG";
        break;
      case "docx":
        ext = "DOCX";
        break;
      case "doc":
        ext = "DOC";
        break;
      case "msg":
        ext = "MSG";
        break;
      case "txt":
        ext = "TXT";
        break;
      case "ppt":
        ext = "PPT";
        break;
      case "pptx":
        ext = "PPTX";
        break;
      case "xls":
        ext = "XLS";
        break;
      case "xlsx":
        ext = "XLSX";
        break;
      default:
        ext = "PDF";
        break;
    }
    return ext;
  };

  getTotalfileSize = (docs) => {
    let sum = 0;
    for (let y of docs) {
      //alert(JSON.parse(y.json_data).size);
      sum += JSON.parse(y.json_data).size;
    }

    return this.formatSize(sum);
  };

  formatSize = (data) => {
    if (data > 1000000) {
      let fileSizeinMB = (data / 1000000).toFixed(2);

      let fileSize = fileSizeinMB.toString().concat("MB");

      return fileSize;
    } else if (data > 1000) {
      let fileSizeinKb = (data / 1000).toFixed(2);

      let fileSize = fileSizeinKb.toString().concat("KB");
      return fileSize;
    } else {
      let fileSize = data.toString().concat("Byte");
      return fileSize;
    }
  };

  ViewonlyDoc = (doc_id, version_id) => {
    this.setState({ document_view: "document", doc_id: doc_id, version_id: version_id });
  };

  closeUserDialog = () => {
    this.setState({ document_view: null, doc_id: 0, version_id: 0 });
  };
  getIconurl = (file) => {
    const file_parts = file.split(/\.(?=[^\.]+$)/);
    let ext = "PDF";
    let extention = file_parts[file_parts.length - 1];
    switch (extention) {
      case "jpeg":
      case "jpg":
        ext = "JPG";
        break;
      case "png":
        ext = "PNG";
        break;
      case "docx":
        ext = "DOCX";
        break;
      case "doc":
        ext = "DOC";
        break;
      case "msg":
        ext = "MSG";
        break;
      case "txt":
        ext = "TXT";
        break;
      case "ppt":
        ext = "PPT";
        break;
      case "pptx":
        ext = "PPTX";
        break;
      case "xls":
        ext = "XLS";
        break;
      case "xlsx":
        ext = "XLS";
        break;
      default:
        ext = "PDF";
        break;
    }
    let file_icon_var = ext === "DOCX" ? "DOC" : ext;
    file_icon_var = ext === "XLSX" ? "XLS" : ext;
    let file_icon = `/dropzoneImages/${ext}.png`;
    return file_icon;
  };

  handlelockRadio = (event) => {
    let folder_info = this.state.folder_info;
    folder_info["lock_folder"] = event.target.name === "no" ? 0 : 1;
    this.setState({ folder_info: folder_info });
  };
  handlevisibilityRadio = (event) => {
    let folder_info = this.state.folder_info;
    folder_info["visibility"] = event.target.name === "public" ? 0 : 1;
    this.setState({ folder_info: folder_info });
  }
  render() {
    console.log("contact_info", this.state);
    if (this.state.folder_info === null) return <div></div>;
    return (

      <FolderContainer style={{ backgroundColor: "#F1F6F7" }}>
        {console.log( 'folder info +++++++=>' , this.state.folder_info)}
        <div>
          <Style.DocHeader>
            <div style={{ fontSize: "14px", float: "left", marginTop: "24px", marginLeft: "10px" }}>Folder Details</div>
            <Style.ModalHeaderCloseBtn onClick={this.closeModal}>
              <MdClose />
            </Style.ModalHeaderCloseBtn>
          </Style.DocHeader>
          <Style.DocBody style={{ padding: "0px" }}>
            <Style.Flexbox style={{ backgroundColor: "#F1F6F7" }}>
              <div style={{ width: "50%", border: "1px solid #e6e6e6", minHeight: "50px" }}>
                <Style.Flexbox style={{ width: "100%", backgroundColor: "#F1F6F7", padding: "10px" }}>
                  <div style={{ width: "40%" }}>
                    <FcFolder style={{ height: "150px", width: "100px" }} />
                  </div>
                  <div style={{ width: "60%", paddingTop: "40px" }}>
                    <Style.ModalNameLabelDiv style={{ fontSize: "14px", float: "left", width: "300px", fontWeight: "600" }}>
                      <NoWrap> {this.state.folder_info["name"]} </NoWrap>
                    </Style.ModalNameLabelDiv>

                    <Style.SpacerL></Style.SpacerL>
                    <Style.ModalNameLabelDiv style={{ fontSize: "14px", float: "left", width: "300px", paddingTop: "10px", color: "#B2B2B2" }}>
                      Created by {Store.getStoreData("contact_assoc")[this.state.folder_info["contact_id"]]} on {Utils.unixtimeTodate(this.state.folder_info["created_at"])}
                    </Style.ModalNameLabelDiv>
                  </div>
                </Style.Flexbox>
                <Style.DivLine></Style.DivLine>
                <Style.Flexbox style={{ width: "100%", backgroundColor: "#F1F6F7", padding: "10px", height: "450px", overflowY: "auto" }}>
                  <div style={{ width: "100%", backgroundColor: "#FFF", border: "1px solid #e6e6e6", padding: "20px" }}>
                    {this.state.addeddocs.map((d) => {
                      return (
                        <div style={{ display: "flex" }}>
                          <div style={{ width: "20%", paddingTop: "15px" }}>
                            <img src={this.getIconurl(d.file_name)} style={{ height: "60px" }} />
                          </div>

                          <div style={{ width: "70%", "text-align": "left" }}>
                            <p style={{ fontWeight: "600", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b" }}>{d.file_name}</p>
                            <p style={{ fontWeight: "400", fontFamily: "Montserrat', sans-serif", color: "#ccc", fontSize: "14px" }}>{this.formatSize(JSON.parse(d.json_data).size)} </p>
                          </div>

                          <div style={{ width: "10%", "text-align": "left" }}>
                            <VEllipsisMenu doc_id={d.version_id} viewdoc={() => this.ViewonlyDoc(d.doc_id, d.version_id)} />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </Style.Flexbox>
              </div>
              <div style={{ width: "3%" }}></div>

              <div style={{ width: "45%" }}>
                <Style.DocBody style={{ padding: "0px" }}>
                  <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
                    <FloatLeft style={{ fontWeight: "600", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b" }}>Folder Name</FloatLeft>
                    {(() => {
                      if (this.state.edit) {
                        return (
                          <span style={{ color: "#11ABA6", paddingLeft: "40px", cursor: "pointer" }} onClick={this.changeEdit}>
                            <u>Back</u>
                          </span>
                        );
                      }
                    })()}
                    {(() => {
                      if (this.state.edit) {
                        return <Style.ModalInput type="text" name="name" onChange={this.handleChange} value={this.state.folder_info["name"]} style={{ fontWeight: "400", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b", fontSize: "14px" }} />;
                      } else {
                        return (
                          <FloatRight style={{ fontWeight: "400", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b", fontSize: "14px" }}>
                            {this.state.folder_info["name"]}
                            {(() => {
                              if (Store.getStoreData("role") !== "team" && this.state.show_edit) {
                                return (
                                  <span style={{ color: "#11ABA6", paddingLeft: "40px", cursor: "pointer" }} onClick={this.changeEdit}>
                                    <u>Edit</u>
                                  </span>
                                );
                              }
                            })()}
                          </FloatRight>
                        );
                      }
                    })()}
                  </Style.ModalNameLabelDiv>
                  <Style.SpacerL></Style.SpacerL>
                  <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
                    <FloatLeft style={{ fontWeight: "600", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b" }}>Added By</FloatLeft>
                    <FloatRight style={{ fontWeight: "400", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b", fontSize: "14px" }}>{Store.getStoreData("contact_assoc")[this.state.folder_info["contact_id"]]}</FloatRight>
                  </Style.ModalNameLabelDiv>
                  <Style.SpacerL></Style.SpacerL>
                  <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
                    <FloatLeft style={{ fontWeight: "600", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b" }}>Added Date</FloatLeft>
                    <FloatRight style={{ fontWeight: "400", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b", fontSize: "14px" }}>{Utils.unixtimeTodate(this.state.folder_info["created_at"])}</FloatRight>
                  </Style.ModalNameLabelDiv>
                  <Style.SpacerL></Style.SpacerL>
                  <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
                    <FloatLeft style={{ fontWeight: "600", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b" }}>Folder Size</FloatLeft>
                    <FloatRight style={{ fontWeight: "400", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b", fontSize: "14px" }}>{this.state.folderSize}</FloatRight>
                  </Style.ModalNameLabelDiv>
                  <Style.SpacerL></Style.SpacerL>
                  <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
                    <FloatLeft style={{ fontWeight: "600", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b" }}>File Count</FloatLeft>
                    <FloatRight style={{ fontWeight: "400", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b", fontSize: "14px" }}>{this.state.addeddocs.length}</FloatRight>
                  </Style.ModalNameLabelDiv>
                  <Style.SpacerL></Style.SpacerL>
                  <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
                    <FloatLeft style={{ fontWeight: "600", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b" }}>Visibility</FloatLeft>

                    <FloatRight style={{ fontWeight: "400", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b", fontSize: "14px" }}>
                      <ContactRadio type="radio" value="0" name="public" checked={this.state.folder_info["visibility"] === 0 ? true : false} onClick={this.handlevisibilityRadio} /> Public
                      <ContactRadio type="radio" value="1" name="private" checked={this.state.folder_info["visibility"] === 1 ? true : false} onClick={this.handlevisibilityRadio} /> Private
                    </FloatRight>

                  </Style.ModalNameLabelDiv>
                  <Style.SpacerL></Style.SpacerL>
                    {
                      (() => {
                      if (Store.getStoreData("role") === "admin_manager") {
                        return (
                          <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
                            <FloatLeft style={{ fontWeight: "600", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b" }}>Lock Folder</FloatLeft>
                            <FloatRight style={{ fontWeight: "400", fontFamily: "Montserrat', sans-serif", color: "#7a8a9b", fontSize: "14px" }}>
                              <ContactRadio type="radio" value="0" name="no" checked={this.state.folder_info["lock_folder"] === 0 ? true : false} onClick={this.handlelockRadio} /> No
                              <ContactRadio type="radio" value="1" name="yes" checked={this.state.folder_info["lock_folder"] === 1 ? true : false} onClick={this.handlelockRadio} /> Yes
                            </FloatRight>
                            </Style.ModalNameLabelDiv>
                        );
                      }
                    })()
                  }
                </Style.DocBody>
              </div>
            </Style.Flexbox>
          </Style.DocBody>
        </div>
        <div style={{ clear: "both" }}></div>
        <Style.DocFooter style={{ textAlign: "right", height: "35px", padding: "10px" }}>
          <MRButton onClick={this.closeModal} style={{ color: "#848484" }}>
            CLOSE
          </MRButton>
          {(() => {
            if (this.state.edit) {
              return (
                <MRButton onClick={this.updateFolder} style={{ backgroundColor: "#1a3552" }}>
                  UPDATE
                </MRButton>
              );
            }
          })()}
        </Style.DocFooter>
        <AlertBox alertParam={this.state.alert_param} />
        {(() => {
          if (this.state.document_view !== null && this.state.document_view === "document") {
            return (
              <div>
                <InactiveOverlay />
                <DocumentViewModal closeUserDialog={this.closeUserDialog} doc_id={this.state.doc_id} version_id={this.state.version_id} />
              </div>
            );
          }
        })()}
      </FolderContainer>
    );
  }
}

export default FolderViewModal;
