import React, { useState, useEffect } from "react";
import { FcFile, FcFolder } from "react-icons/fc";
import { RiPushpin2Fill } from "react-icons/ri";
import Utils from "../../Common/Utils.js";
import Store from "../../Common/Store.js";
import VEllipsisMenu from "./Menu/VerticalEllipsisMenu";
import VEllipsisMenuFolder from "./Menu/VerticalEllipsisMenuFolder";
import * as Style from "../Common/StyledComponents";
import styled from "styled-components";
const divwrapper = {
  "min-width": "15%",
  margin: "20px",
  padding: "5px",
  backgroundColor: "#F6F9FD",
  border: "1px solid #ACC4D8",
  position: "relative",
  height: "240px",
};

const divwrapperpinned = {
  "min-width": "15%",
  margin: "20px",
  padding: "5px",
  backgroundColor: "#F6F9FD",
  border: "1px solid #1A3552",
  position: "relative",
  height: "240px",
};

const pinstyle = {
  position: "absolute",
  "font-size": "20px",
  top: "10px",
  right: "10px",
  color: "#11ABA6",
};

const displayright = {
  position: "absolute",
  right: "5px",
  top: "5px",
};

const NoWrap = styled.div`
  white-space: nowrap;
  width: 90% ;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const GridView = (props) => {
  const { Data } = props;
  const changeObjkeyNaming = (obj) => {
    obj["document_type"] = obj.type;
    return obj;
  };

  useEffect(() => {
    //alert(JSON.stringify(Data));
  });

  return (
    <div style={{display: "flex","flex-wrap": "wrap",width: "100%",padding: "10px"}}>
      {Data.map((item) => {
        let user_pins = JSON.parse(item.user_pins);
        let contact = Store.getStoreData('contact');
        let is_pinned = user_pins[contact.ID.toString()] === 1 ? true : false;
        let is_deleted = ('is_deleted' in item) ? item.is_deleted : 0;
        console.log("user_pins", user_pins)
        return (
          <div style={is_pinned == 1 ? divwrapperpinned : divwrapper}>
            {(() => {
              if (item.type === -1) {
                return <input type="checkbox" style={{ position: "absolute", top: -10, left: -10, cursor: "pointer"  }} checked={item.select} onClick={() => props.checkDocument(item.id, "folder")} />;
              } else {
                return <input type="checkbox" style={{ position: "absolute", top: -10, left: -10, cursor: "pointer"  }} checked={item.select} onClick={() => props.checkDocument(item.doc_id, "file")} />;
              }
            })()}

            {is_pinned == 1 ? <RiPushpin2Fill style={pinstyle} /> : ""}

            {is_deleted === 0 ? <div>{item.type == -1 ? <FcFolder style={{ height: "150px", width: "100px", cursor: "pointer" }} alt="Card image cap" onClick={() => props.onClickDocumentName(changeObjkeyNaming(item))} /> : <FcFile style={{ height: "150px", width: "100px", cursor: "pointer" }} alt="Card image cap" onClick={() => props.onClickDocumentName(changeObjkeyNaming(item))} />}</div> : <div>{item.type == -1 ? <FcFolder style={{ height: "150px", width: "100px"}}/> : <FcFile style={{ height: "150px", width: "100px" }} />}</div>}

            <div style={{backgroundColor: "white",position: "absolute",bottom: 0,left: 0,width: "100%",height: "85px"}}>
              <div style={{display: "flex",width: "80%"}}>
                <div style={{ width: "95%", padding: "5px", position: "relative" }}>
                  <NoWrap>{item.name}</NoWrap>

                  <p style={{ "font-size": "10px" }}>
                    {" "}
                    Uploaded by {Store.getStoreData("contact_assoc")[item.created_by]} on {Utils.unixtimeTodate(item.uploaded)}
                  </p>
                </div>

                {is_deleted === 0 ? <div style={{ width: "5%" }}>{item.type == -1 ? <VEllipsisMenuFolder style={{ displayright }} viewdoc={() => props.viewdoc(changeObjkeyNaming(item))} DeleteDocs={() => props.DeleteDocs(item.id, -1)} type={item.type} /> : <VEllipsisMenu style={{ displayright }} viewdoc={() => props.viewdoc(changeObjkeyNaming(item))} MoveDocstoFolder={props.MoveDocstoFolder} DeleteDocs={() => props.DeleteDocs(item.doc_id, 1)} PinnedDocs={() => props.PinnedDocs(item.doc_id)} UnpinnedDocs={() => props.UnpinnedDocs(item.doc_id)} type={item.type} info={item} is_pinned={is_pinned} />}</div> : ""}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default GridView;
