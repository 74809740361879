import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { saveAs } from "file-saver";
import APICall from "../../../Common/APICall.js";
import { AiOutlineFolderView } from "react-icons/ai";
import { HiDownload } from "react-icons/hi";
let options = [<AiOutlineFolderView title = {"View details"}/>, <HiDownload title = {"Download"}/>];

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget);
  };
  useEffect(() => {});


  const handleClose = (index) => {
    // event.preventDefault();
    setAnchorEl(null);
    if (index == 0) {
      props.viewdoc();
    }else if (index == 1) {
      getDocumentFiledatabyId(props.doc_id);
    } 
  };

  const handleDownload = (filedata) => {
    let dbinfile = JSON.parse(filedata)[0];
    let dnld = dbinfile.bin_file.replace(/ /g, "+");
    let binaryString = window.atob(dnld);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, dbinfile.name);
  };

  const getDocumentFiledatabyId = (id) => {
    let api = new APICall();
    let postData = { command: "get_doc", doc_id: id };
    api.command(postData, processDocumentData);
  };

  const processDocumentData = (result) => {
    console.log("result", result)
    let versions = result.result;
    let file = versions.bin_file;
    handleDownload(file);
  };


  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose} PaperProps={{style: { maxHeight: ITEM_HEIGHT * 4.5,width: "70px"}}}>
        {options.map((option, index) => (
          <MenuItem key={option} selected={option === "Pyxis"} onClick={() => handleClose(index)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
