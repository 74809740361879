import React from 'react';
import styled from 'styled-components';
import {FaPlusCircle} from 'react-icons/fa';
import CSLTable from '../../Common/CSLTable';
import SaveApproval from './SaveApproval';
import APICall from '../../../Common/APICall';
import Store from '../../../Common/Store';
import AlertBox from '../../Common/AlertBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, thin } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import ContextMenu from "../../Moduleconfig/ContextMenu";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import moment from 'moment';

 const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
 const fileExtension = '.xlsx';

const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const MenuBox = styled.div`
    padding: 5px;
    background-color: #ffffff;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    // width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;

class ApprovarList extends React.Component
{
    state = {   approvars: [], ready: false, document_id: 0, show_modal: false, 
                record: { id : 0, auto_id: null, doc_version: "1.0",comments:"", approvar: "",isCommitteeApproved: 0, approvar_json:{approval_committee:null,approval_committee_member:null},    doc_id: 0, add: 1},
                alert_param: null
            };

	componentDidMount()
	{
        this.manageApprovars();
    }

    componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {

            this.manageApprovars();
		}
	}

    manageApprovars = () => {
        let record = this.state.record;
        record['doc_id'] = this.props.document_id;
        this.setState({approvars: this.props.approvars, ready: true, show_modal: false, document_id: this.props.document_id, record: record});
    }

    hasCommonCompanyCheckFirst = (manager_id, user_id, gc_users) => {
      if (Object.keys(gc_users).length === 0) return true;
      if (manager_id === "") return true;
      let manager_companies = gc_users[manager_id];
      let user_companies = gc_users[user_id];
      for (let cid in manager_companies) {
        for (let uid in user_companies) {
          if (cid.toString() === uid.toString()) return true;
        }
      }
      return false;
    };

    listFollowupTasks = (document_id) => {
        this.props.updateFollowupListing(document_id)
    }

    showApprovalModal = (id, mode) => (event) => {
        event.preventDefault();
        let approvars = JSON.parse(JSON.stringify(this.state.approvars));
        let sin = approvars.find(x => x.id === id);
        sin['add'] = mode === "edit" ? 0 : 2
        console.log("showApprovalModal", sin)
        this.setState({show_modal: true, record: sin})
    };

    // showViewModal = ()



    deleteApprovalTask = (index) => (event) => {
        event.preventDefault();
        let msg = "Are you sure you want to delete this approval?";
        let title = "Confirm";
        let alert_param = { title: title, message: msg, ok_text: "OK", confirm: true, alertHandler: this.confirmunHandler, stack: { id: index } };
        this.setState({ alert_param: alert_param });
    }

    
    confirmunHandler = (result, stack) => {
      if (!result || stack.prevent) {
        this.setState({ alert_param: null, show_modal: false });
        return;
      }
      this.setState({ alert_param: null});
      let task_id = stack.id;
      let postData = { command: "delete_approval", id: task_id };
      let api = new APICall();
      api.command(postData, this.removeApproval);

    }

    removeApproval = (result) => {
        console.log("removeApproval", result)
        let alert_param = { title: "Success", message: "Approval has been deleted", ok_text: "OK", confirm: false, alertHandler: this.AlertremoveHandler, stack: { id: 0 } };
        this.setState({ alert_param: alert_param, loading: false });
    }

    AlertremoveHandler = (result, stack) => {
      this.setState({ alert_param: null });
      this.props.updateApprovalListing(this.state.document_id)
    };

    getDate = (input_date) => {
      if(input_date != null){
        let dateStr = input_date.split('T')[0];
        if(dateStr === "1970-01-01"){
            return '';
        }
        let dateParts = dateStr.split('-'); 
        let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
        return ret;
      }else{
        return '';
      }
     
    }

    recordApprovalHistoryData = () => {
      let table = { data: [], columns: [] };
      table.columns = [
        { Header: "#", accessor: "index",width: 30, headerStyle: { textAlign: "left" }, style: {textAlign: "left", whiteSpace: "unset" } },
        { Header: "Version", accessor: "version",minWidth: 70, headerStyle: { textAlign: "left" }, style: {textAlign: "left", whiteSpace: "unset" } },
        { Header: "Approver", accessor: "approver",minWidth: 120, headerStyle: { textAlign: "left" }, style: {textAlign: "left", whiteSpace: "unset" } },
        { Header: "Date", accessor: "approvedate", minWidth: 120, headerStyle: { textAlign: "left" }, style: {textAlign: "left", whiteSpace: "unset" } },
        { Header: "Comments", accessor: "approvecomments",minWidth: 240, headerStyle: { textAlign: "left" }, style: {textAlign: "left", whiteSpace: "unset", textOverflow: "ellipsis", whiteSpace: "nowrap" } },
        {
          Header: "Actions",      
          width: 80,
          headerStyle: { textAlign: "center" },
          Cell: (row) => (
            <div>
                <FontAwesomeIcon icon={solid('pen')} style={{cursor: "pointer"}} onClick={this.showApprovalModal(row.original.id, 'edit')}/>
                
                {
                    (() => {
                        if (Store.getStoreData("role") === "admin_manager"){
                            return <FontAwesomeIcon icon={solid('trash-can')} style={{cursor: "pointer", marginLeft:"7px"}} onClick={this.deleteApprovalTask(row.original.id)}/>
                        }
                    })()
                }
                <FontAwesomeIcon icon={solid("eye")} style={{cursor: "pointer"}} style={{cursor: "pointer", marginLeft:"7px"}} onClick={this.showApprovalModal(row.original.id, 'view')}/>

                
            </div>
          ),
          style: { display: "flex", alignItems: "center", justifyContent: "center" },
        },
      ];
      let i=0;
      for(let record of this.state.approvars) {           
        i++;              
        let elem = {'index' : i, 
                    'id':record.id,
                    'auto_id':record.auto_id,
                    'version':record.doc_version,
                    'approver' : record.approvar,
                    'approvedate': this.getDate(record.approval_date),
                    'approvecomments' : record.comments,
               };
               table.data.push(elem);
    }
      return table;
    };
    showFollowupTaskModal = (event) => {
        event.preventDefault()
        this.setState({'show_tasks_modal' : true})
    }

    listApprovals = (doc_id) => {
        this.props.updateApprovalListing(doc_id)
    }

     exportToExcel = () => {
        // console.log(this.state.approvars);
                    var data=[
   
                              ["Row number","Document name","Document version","Approver","Approval date","Comments"]    

                             ];
                     var i=1;
                     for(let record of this.state.approvars) {
                      
                      let slno=i++;
                      let version=record.doc_version;             
                      let approver=record.approvar; 
                      let approvedate=this.getDate(record.approval_date); 
                      let approvecomments=record.comments;   
                      let documentname=this.props.doc_name; 
                      let elem=[slno,documentname,version,approver,approvedate,approvecomments];
                      data.push(elem);

                     }
                     const format = "DD-MM-YYYY";
                     let dtString = moment().format(format);
                     var fileName="Approval History ";                     
                     const ws: XLSX.WorkSheet =XLSX.utils.aoa_to_sheet(data);
                     const wb: XLSX.WorkBook = XLSX.utils.book_new();
                     XLSX.utils.book_append_sheet(wb, ws, 'Approval History');    
                     const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                     const data1: Blob = new Blob([excelBuffer], {type: fileType});
                     FileSaver.saveAs(data1, fileName +dtString+ fileExtension)
 


                              }
    
    render()
	{
            const menu_options = [
                                  {name: "export", nick_name: "Export", clickHandler: this.exportToExcel}
                                 ]
        if (this.state.alert_param !== null) {
          return <AlertBox alertParam={this.state.alert_param} />;
        }
		return (
            <div>
                                
                <SaveApproval document_id ={this.state.document_id} show_modal={this.state.show_modal} current_approval_data={this.state.record} reloadApprovers={this.listApprovals} />
                <OverviewInfoBody>
                       <div style={{float: "right", cursor: "pointer",fontSize: "25px",color: "#e4e4e4",marginBottom:"-30px"}}> <ContextMenu  options={menu_options} /> </div>
                       <div style={{ clear: "both" }}></div>
                    <CSLTable add={false} processData={this.recordApprovalHistoryData} headerText={''} tableRows="5" />
                </OverviewInfoBody>
            </div>
        );
    }
}

export default ApprovarList;