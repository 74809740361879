import React from "react";
import styled from "styled-components";
import APICall from "../../Common/APICall";
import Store from "../../Common/Store.js";
import { FiFileText } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
const FCThumbnailsContainer = styled.div`
  display: block;
  box-sizing: border-box;
  padding: 20px;
  width: 100%;
  height: 100%;
`;
const FCThumbnail = styled.div`
  float: left;
  box-sizing: border-box;
  margin-left: 10px;
  width: calc(25% - 10px);
  height: auto;
  background-color: #1a3552;
  border-radius: 3px;
`;
const FCThumbnailHeader = styled.div`
  padding: 20px 20px 0px 20px;
  font-weight: 600;
  color: #ffffff;
`;
const FCThumbnailbody = styled.div`
  padding: 21px;
  font-weight: 600;
  font-size: 56px;
  color: #ffffff;
`;

const FCThumbnailRight = styled.div`
  float : right;
  color:white
  font-size:40px
`;

class DOCcards extends React.Component {
  state = { stats: null, pindoc: false, recyclebin: false, recentlydeleted: false,is_recently: false, selection:{pindoc: 0,recyclebin: 0,recentlydeleted: 0} };
  constructor(props) {
    super(props);
  }
  /*componentDidMount() {
    let doc_cards = Store.getStoreData("doc_cards");
    let st = Store.getAllStoreData();

    console.log(st);
    this.setState({ stats: [] });
  }*/

  ToggleRecentDocs = (event) => {
    event.preventDefault();
    this.setState({pindoc: false,  recyclebin: false, recentlydeleted: false})
    this.props.filtersrecentlydeletedCards(false);
  }


  TogglePinnedDocs = (event) => {
    event.preventDefault();
    let selection = this.state.selection
    let pindoc = this.state.pindoc;
    pindoc = pindoc ? false : true;
    console.log("toggle", pindoc);
    if(pindoc){
      this.setState({ pindoc: pindoc,  recyclebin: false, recentlydeleted: false});
    }else{
      this.setState({pindoc : pindoc})
    }
    this.props.filtersCards(pindoc);
  };

  ToggleRecycleDocs = (event) => {
    event.preventDefault();
    let recyclebin = this.state.recyclebin;
    recyclebin = recyclebin ? false : true;
    // console.log("toggle", recyclebin);
    // this.setState({ recyclebin });
    if(recyclebin){
      this.setState({ pindoc: false,  recyclebin: recyclebin, recentlydeleted: false});
    }else{
      this.setState({recyclebin : recyclebin})
    }
    this.props.filtersRecycleCards(recyclebin);
  };

  TogglerecentlydeletedDocs = (event) => {
    event.preventDefault();
    let recentlydeleted = this.state.recentlydeleted;
    recentlydeleted = recentlydeleted ? false : true;
    console.log("toggle", recentlydeleted);
    // this.setState({ recentlydeleted });
    if(recentlydeleted){
      this.setState({ pindoc: false,  recyclebin: false, recentlydeleted: recentlydeleted});
    }else{
      this.setState({recentlydeleted : recentlydeleted})
    }
    this.props.filtersrecentlydeletedCards(recentlydeleted);
  };



  processData = (result) => {
    this.setState({ stats: result });
  };

  render() {
    let doc_cards = Store.getStoreData("doc_cards") === null ? {recent_doc: 0, pinned_doc: 0,recent_deleted: 0,recycle_bin: 0} : Store.getStoreData("doc_cards");
    let toggle = this.props.toggle;
    console.log("toggle doccards", toggle)
    let is_recently = (toggle['pindoc'] || toggle['recentlydeleted'] || toggle['recyclebin']) ? false : true;
    console.log("toggle doccards", is_recently)
    return (
      <FCThumbnailsContainer>
        <FCThumbnail id="thumb1" onClick={this.ToggleRecentDocs} style={{ marginLeft: "0px", backgroundColor: is_recently ? "#1A3552" : "#c4c4c4",  cursor: is_recently ? "" : "pointer" }}>
          <FCThumbnailHeader>Recent Documents</FCThumbnailHeader>
          <FCThumbnailbody>
            {doc_cards["recent_doc"]}
            <FCThumbnailRight>
              <svg xmlns="http://www.w3.org/2000/svg" width="50" viewBox="0 0 45.311 48.497">
                <g id="recent-docs" transform="translate(-293.689 -200.551)">
                  <g id="document" transform="translate(293.689 200.551)">
                    <path id="Path_1261" data-name="Path 1261" d="M.109,57.673v40.1a.771.771,0,0,0,.771.771h29.3a.771.771,0,0,0,.771-.771V65.385c0-.017-.008-.032-.01-.049a.727.727,0,0,0-.039-.2c-.009-.025-.015-.049-.025-.073a.763.763,0,0,0-.154-.226l-7.712-7.712a.748.748,0,0,0-.227-.154.7.7,0,0,0-.071-.025.765.765,0,0,0-.2-.04c-.013,0-.028-.006-.044-.006H.881A.771.771,0,0,0,.109,57.673Zm28.214,6.941H23.244V59.535ZM1.652,58.445H21.7v6.941a.771.771,0,0,0,.771.771h6.941V97H1.652Zm0,0" transform="translate(-0.109 -52.275)" fill="#fff" />
                    <path id="Path_1262" data-name="Path 1262" d="M84.77,40.1v1.542h2.313a.771.771,0,0,0,.771-.771V.771A.771.771,0,0,0,87.083,0h-29.3a.771.771,0,0,0-.771.771V3.085H58.55V1.542H86.312V40.1Zm0,0" transform="translate(-52.381)" fill="#fff" />
                    <path id="Path_1263" data-name="Path 1263" d="M75.977,189.668H94.484v1.542H75.977Zm0,0" transform="translate(-69.807 -174.245)" fill="#fff" />
                    <path id="Path_1264" data-name="Path 1264" d="M75.977,132.766h6.169v1.543H75.977Zm0,0" transform="translate(-69.807 -121.969)" fill="#fff" />
                    <path id="Path_1265" data-name="Path 1265" d="M75.977,246.566H88.315v1.543H75.977Zm0,0" transform="translate(-69.807 -226.516)" fill="#fff" />
                    <path id="Path_1266" data-name="Path 1266" d="M246.676,246.566H251.3v1.543h-4.627Zm0,0" transform="translate(-226.625 -226.516)" fill="#fff" />
                    <path id="Path_1267" data-name="Path 1267" d="M75.977,303.465H94.484v1.543H75.977Zm0,0" transform="translate(-69.807 -278.788)" fill="#fff" />
                    <path id="Path_1268" data-name="Path 1268" d="M75.977,417.266H94.484v1.542H75.977Zm0,0" transform="translate(-69.807 -383.334)" fill="#fff" />
                    <path id="Path_1269" data-name="Path 1269" d="M161.324,360.367h11.568v1.542H161.324Zm0,0" transform="translate(-148.214 -331.063)" fill="#fff" />
                    <path id="Path_1270" data-name="Path 1270" d="M75.977,360.367h5.4v1.542h-5.4Zm0,0" transform="translate(-69.807 -331.063)" fill="#fff" />
                    <path id="Path_1271" data-name="Path 1271" d="M75.977,474.168h3.085v1.542H75.977Zm0,0" transform="translate(-69.807 -435.609)" fill="#fff" />
                    <path id="Path_1272" data-name="Path 1272" d="M132.875,474.168h13.881v1.542H132.875Zm0,0" transform="translate(-122.079 -435.609)" fill="#fff" />
                  </g>
                  <circle id="Ellipse_5" data-name="Ellipse 5" cx="10" cy="10" r="10" transform="translate(319 229.048)" fill="#1a3552" />
                  <g id="wall-clock" transform="translate(321.321 230.978)">
                    <g id="Group_2098" data-name="Group 2098">
                      <g id="Group_2097" data-name="Group 2097">
                        <path id="Path_1273" data-name="Path 1273" d="M7.921,0a7.921,7.921,0,1,0,7.921,7.921A7.93,7.93,0,0,0,7.921,0Zm0,14.686a6.765,6.765,0,1,1,6.765-6.765A6.773,6.773,0,0,1,7.921,14.686Z" fill="#11aba6" />
                      </g>
                    </g>
                    <g id="Group_2100" data-name="Group 2100" transform="translate(6.958 3.874)">
                      <g id="Group_2099" data-name="Group 2099">
                        <path id="Path_1274" data-name="Path 1274" d="M228.519,129.244h-2.505v-3.469a.578.578,0,0,0-1.156,0v4.047a.578.578,0,0,0,.578.578h3.084a.578.578,0,1,0,0-1.156Z" transform="translate(-224.857 -125.197)" fill="#11aba6" />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </FCThumbnailRight>
          </FCThumbnailbody>
        </FCThumbnail>

        <FCThumbnail id="thumb2" onClick={this.TogglePinnedDocs} style={{ backgroundColor: this.state.pindoc ? "#1A3552" : "#c4c4c4", cursor: "pointer" }}>
          <FCThumbnailHeader>Pinned Documents</FCThumbnailHeader>
          <FCThumbnailbody>
            {doc_cards["pinned_doc"]}
            <FCThumbnailRight>
              <svg xmlns="http://www.w3.org/2000/svg" width="50" viewBox="0 0 45.311 48.497">
                <g id="pinned-docs" transform="translate(-293.689 -200.551)">
                  <g id="document" transform="translate(293.689 200.551)">
                    <path id="Path_1261" data-name="Path 1261" d="M.109,57.673v40.1a.771.771,0,0,0,.771.771h29.3a.771.771,0,0,0,.771-.771V65.385c0-.017-.008-.032-.01-.049a.727.727,0,0,0-.039-.2c-.009-.025-.015-.049-.025-.073a.763.763,0,0,0-.154-.226l-7.712-7.712a.748.748,0,0,0-.227-.154.7.7,0,0,0-.071-.025.765.765,0,0,0-.2-.04c-.013,0-.028-.006-.044-.006H.881A.771.771,0,0,0,.109,57.673Zm28.214,6.941H23.244V59.535ZM1.652,58.445H21.7v6.941a.771.771,0,0,0,.771.771h6.941V97H1.652Zm0,0" transform="translate(-0.109 -52.275)" fill="#fff" />
                    <path id="Path_1262" data-name="Path 1262" d="M84.77,40.1v1.542h2.313a.771.771,0,0,0,.771-.771V.771A.771.771,0,0,0,87.083,0h-29.3a.771.771,0,0,0-.771.771V3.085H58.55V1.542H86.312V40.1Zm0,0" transform="translate(-52.381)" fill="#fff" />
                    <path id="Path_1263" data-name="Path 1263" d="M75.977,189.668H94.484v1.542H75.977Zm0,0" transform="translate(-69.807 -174.245)" fill="#fff" />
                    <path id="Path_1264" data-name="Path 1264" d="M75.977,132.766h6.169v1.543H75.977Zm0,0" transform="translate(-69.807 -121.969)" fill="#fff" />
                    <path id="Path_1265" data-name="Path 1265" d="M75.977,246.566H88.315v1.543H75.977Zm0,0" transform="translate(-69.807 -226.516)" fill="#fff" />
                    <path id="Path_1266" data-name="Path 1266" d="M246.676,246.566H251.3v1.543h-4.627Zm0,0" transform="translate(-226.625 -226.516)" fill="#fff" />
                    <path id="Path_1267" data-name="Path 1267" d="M75.977,303.465H94.484v1.543H75.977Zm0,0" transform="translate(-69.807 -278.788)" fill="#fff" />
                    <path id="Path_1268" data-name="Path 1268" d="M75.977,417.266H94.484v1.542H75.977Zm0,0" transform="translate(-69.807 -383.334)" fill="#fff" />
                    <path id="Path_1269" data-name="Path 1269" d="M161.324,360.367h11.568v1.542H161.324Zm0,0" transform="translate(-148.214 -331.063)" fill="#fff" />
                    <path id="Path_1270" data-name="Path 1270" d="M75.977,360.367h5.4v1.542h-5.4Zm0,0" transform="translate(-69.807 -331.063)" fill="#fff" />
                    <path id="Path_1271" data-name="Path 1271" d="M75.977,474.168h3.085v1.542H75.977Zm0,0" transform="translate(-69.807 -435.609)" fill="#fff" />
                    <path id="Path_1272" data-name="Path 1272" d="M132.875,474.168h13.881v1.542H132.875Zm0,0" transform="translate(-122.079 -435.609)" fill="#fff" />
                  </g>
                  <circle id="Ellipse_5" data-name="Ellipse 5" cx="10" cy="10" r="10" transform="translate(319 229.048)" fill="#1a3552" />
                  <g id="paper-push-pin" transform="translate(251.912 233.81)">
                    <path id="Path_1923" data-name="Path 1923" d="M80.348,5.86a1.524,1.524,0,0,0-1.2-.666V1.731a.832.832,0,0,0,.609-.257.832.832,0,0,0,.257-.609.832.832,0,0,0-.257-.609A.831.831,0,0,0,79.147,0H74.819a.832.832,0,0,0-.609.257.832.832,0,0,0-.257.609.832.832,0,0,0,.257.609.832.832,0,0,0,.609.257V5.194a1.525,1.525,0,0,0-1.2.666,2.342,2.342,0,0,0-.531,1.5.439.439,0,0,0,.433.433h2.732l.514,3.28a.2.2,0,0,0,.216.183h.007a.189.189,0,0,0,.139-.057.25.25,0,0,0,.071-.139l.345-3.267h2.9a.439.439,0,0,0,.433-.433A2.342,2.342,0,0,0,80.348,5.86Zm-4.014-.882a.216.216,0,1,1-.433,0V1.948a.216.216,0,1,1,.433,0Z" fill="#11aba6" />
                  </g>
                </g>
              </svg>
            </FCThumbnailRight>
          </FCThumbnailbody>
        </FCThumbnail>

        <FCThumbnail id="thumb3" onClick={this.TogglerecentlydeletedDocs} style={{ backgroundColor: this.state.recentlydeleted ? "#1A3552" : "#c4c4c4", cursor: "pointer" }}>
          <FCThumbnailHeader>Recently Deleted</FCThumbnailHeader>
          <FCThumbnailbody>
            {doc_cards["recent_deleted"]}
            <FCThumbnailRight>
              <svg xmlns="http://www.w3.org/2000/svg" width="50" viewBox="0 0 44.215 54.003">
                <g id="recently-deleted" transform="translate(-428.785 -202.997)">
                  <g id="trash_1_" data-name="trash (1)" transform="translate(369.907 202.997)">
                    <g id="Group_2106" data-name="Group 2106" transform="translate(58.878)">
                      <path
                        id="Path_1276"
                        data-name="Path 1276"
                        d="M97.461,8.924H95.972V7.243a2.309,2.309,0,0,0-2.306-2.306H86.89V3.055A3.058,3.058,0,0,0,83.835,0H79.818a.765.765,0,0,0,0,1.53h4.017a1.526,1.526,0,0,1,1.524,1.524V4.937H72.627V3.055A1.526,1.526,0,0,1,74.151,1.53h2.1a.765.765,0,0,0,0-1.53h-2.1A3.058,3.058,0,0,0,71.1,3.055V4.937H64.32a2.309,2.309,0,0,0-2.306,2.306V8.924H60.525a1.649,1.649,0,0,0-1.647,1.647v4.414a1.649,1.649,0,0,0,1.647,1.647h.127l3.285,31.082a5.055,5.055,0,0,0,5.038,4.533H89.012a5.055,5.055,0,0,0,5.038-4.533l2.677-25.332a.765.765,0,0,0-1.522-.161L92.528,47.553a3.528,3.528,0,0,1-3.516,3.164H68.974a3.528,3.528,0,0,1-3.516-3.164L62.191,16.632H95.8l-.219,2.075a.765.765,0,1,0,1.522.161l.236-2.236h.127a1.649,1.649,0,0,0,1.647-1.647V10.571a1.649,1.649,0,0,0-1.647-1.647ZM63.544,7.243a.777.777,0,0,1,.776-.776H93.666a.776.776,0,0,1,.776.776V8.924h-30.9Zm34.034,7.742a.117.117,0,0,1-.117.117H60.525a.117.117,0,0,1-.117-.117V10.571a.117.117,0,0,1,.117-.117H97.461a.117.117,0,0,1,.117.117v4.414Z"
                        transform="translate(-58.878)"
                        fill="#fff"
                      />
                      <path id="Path_1277" data-name="Path 1277" d="M159.065,192.832a.765.765,0,0,0-.7.822l2.067,26.463a.765.765,0,0,0,.762.706l.06,0a.765.765,0,0,0,.7-.822l-2.067-26.463A.765.765,0,0,0,159.065,192.832Z" transform="translate(-148.208 -173.153)" fill="#fff" />
                      <path id="Path_1278" data-name="Path 1278" d="M320.454,193.536,318.387,220a.765.765,0,0,0,.7.822l.06,0a.765.765,0,0,0,.762-.706l2.067-26.463a.765.765,0,0,0-1.526-.119Z" transform="translate(-291.904 -173.153)" fill="#fff" />
                      <path id="Path_1279" data-name="Path 1279" d="M248.5,193.6v26.463a.765.765,0,1,0,1.53,0V193.6a.765.765,0,1,0-1.53,0Z" transform="translate(-229.151 -173.154)" fill="#fff" />
                    </g>
                  </g>
                  <circle id="Ellipse_6" data-name="Ellipse 6" cx="10" cy="10" r="10" transform="translate(453 237)" fill="#1a3552" />
                  <g id="Group_2107" data-name="Group 2107" transform="translate(134 7.952)">
                    <g id="wall-clock" transform="translate(321.321 230.978)">
                      <g id="Group_2098" data-name="Group 2098">
                        <g id="Group_2097" data-name="Group 2097">
                          <path id="Path_1273" data-name="Path 1273" d="M7.921,0a7.921,7.921,0,1,0,7.921,7.921A7.93,7.93,0,0,0,7.921,0Zm0,14.686a6.765,6.765,0,1,1,6.765-6.765A6.773,6.773,0,0,1,7.921,14.686Z" fill="#11aba6" />
                        </g>
                      </g>
                      <g id="Group_2100" data-name="Group 2100" transform="translate(6.958 3.874)">
                        <g id="Group_2099" data-name="Group 2099">
                          <path id="Path_1274" data-name="Path 1274" d="M228.519,129.244h-2.505v-3.469a.578.578,0,0,0-1.156,0v4.047a.578.578,0,0,0,.578.578h3.084a.578.578,0,1,0,0-1.156Z" transform="translate(-224.857 -125.197)" fill="#11aba6" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
            </FCThumbnailRight>
          </FCThumbnailbody>
        </FCThumbnail>

        <FCThumbnail id="thumb4" onClick={this.ToggleRecycleDocs} style={{ backgroundColor: this.state.recyclebin ? "#1A3552" : "#c4c4c4", cursor: "pointer" }}>
          <FCThumbnailHeader>Recycle Bin</FCThumbnailHeader>
          <FCThumbnailbody>
            {doc_cards["recycle_bin"]}
            <FCThumbnailRight>
              <svg xmlns="http://www.w3.org/2000/svg" width="50" viewBox="0 0 44.215 54.003">
                <g id="recently-deleted" transform="translate(-428.785 -202.997)">
                  <g id="trash_1_" data-name="trash (1)" transform="translate(369.907 202.997)">
                    <g id="Group_2106" data-name="Group 2106" transform="translate(58.878)">
                      <path
                        id="Path_1276"
                        data-name="Path 1276"
                        d="M97.461,8.924H95.972V7.243a2.309,2.309,0,0,0-2.306-2.306H86.89V3.055A3.058,3.058,0,0,0,83.835,0H79.818a.765.765,0,0,0,0,1.53h4.017a1.526,1.526,0,0,1,1.524,1.524V4.937H72.627V3.055A1.526,1.526,0,0,1,74.151,1.53h2.1a.765.765,0,0,0,0-1.53h-2.1A3.058,3.058,0,0,0,71.1,3.055V4.937H64.32a2.309,2.309,0,0,0-2.306,2.306V8.924H60.525a1.649,1.649,0,0,0-1.647,1.647v4.414a1.649,1.649,0,0,0,1.647,1.647h.127l3.285,31.082a5.055,5.055,0,0,0,5.038,4.533H89.012a5.055,5.055,0,0,0,5.038-4.533l2.677-25.332a.765.765,0,0,0-1.522-.161L92.528,47.553a3.528,3.528,0,0,1-3.516,3.164H68.974a3.528,3.528,0,0,1-3.516-3.164L62.191,16.632H95.8l-.219,2.075a.765.765,0,1,0,1.522.161l.236-2.236h.127a1.649,1.649,0,0,0,1.647-1.647V10.571a1.649,1.649,0,0,0-1.647-1.647ZM63.544,7.243a.777.777,0,0,1,.776-.776H93.666a.776.776,0,0,1,.776.776V8.924h-30.9Zm34.034,7.742a.117.117,0,0,1-.117.117H60.525a.117.117,0,0,1-.117-.117V10.571a.117.117,0,0,1,.117-.117H97.461a.117.117,0,0,1,.117.117v4.414Z"
                        transform="translate(-58.878)"
                        fill="#fff"
                      />
                      <path id="Path_1277" data-name="Path 1277" d="M159.065,192.832a.765.765,0,0,0-.7.822l2.067,26.463a.765.765,0,0,0,.762.706l.06,0a.765.765,0,0,0,.7-.822l-2.067-26.463A.765.765,0,0,0,159.065,192.832Z" transform="translate(-148.208 -173.153)" fill="#fff" />
                      <path id="Path_1278" data-name="Path 1278" d="M320.454,193.536,318.387,220a.765.765,0,0,0,.7.822l.06,0a.765.765,0,0,0,.762-.706l2.067-26.463a.765.765,0,0,0-1.526-.119Z" transform="translate(-291.904 -173.153)" fill="#fff" />
                      <path id="Path_1279" data-name="Path 1279" d="M248.5,193.6v26.463a.765.765,0,1,0,1.53,0V193.6a.765.765,0,1,0-1.53,0Z" transform="translate(-229.151 -173.154)" fill="#fff" />
                    </g>
                  </g>
                </g>
              </svg>
            </FCThumbnailRight>
          </FCThumbnailbody>
        </FCThumbnail>
        <div style={{ clear: "both" }}></div>
      </FCThumbnailsContainer>
    );
  }
}
export default DOCcards;
