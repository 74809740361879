import React from 'react';
import styled from 'styled-components';
import CSLTablemodule from '../Common/CSLTablemodule';
import RoleChanger from './RoleChanger';

const SearchInput = styled.input`
    height: 30px;
    width: 100%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 3px;
    color: #222222;
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
`;
const PermissionDropdown = styled.select`
    display: block;
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    font-family: Montserrat,sans-serif;
    color: #222222;
`;
const GeneralContainer = styled.div`
	width: 90%;
`;

const sourceroleMap = {admin_manager: 'Admin Manager', document_manager: 'Document Manager',team:'Team', no_access: 'No Access'}


class Permissions extends React.Component
{
	state = {permissions: null, prefered_columns: null};

	componentDidMount()
	{
		console.log("this.props.permissions", this.props.permissions)
		let prefered_columns ={};
		prefered_columns['name'] = 'Name';
		prefered_columns['role_nickname'] = 'Role';
		prefered_columns['email'] = 'Email Address';
		this.setState({permissions: this.props.permissions,prefered_columns:prefered_columns });
	}

	componentDidUpdate(prevProps) {
		console.log("this.props.permissions", this.props.permissions)
		if (prevProps !== this.props) {
			let prefered_columns ={};
			prefered_columns['name'] = 'Name';
			prefered_columns['role_nickname'] = 'Role';
			prefered_columns['email'] = 'Email Address';
			this.setState({permissions: this.props.permissions,prefered_columns:prefered_columns });
		}
	}

	changeRole = (obj) => {

		let permissions = JSON.parse(JSON.stringify(this.state.permissions));
		permissions.company_users.forEach((user) => {
			if (parseInt(user.user_id) === parseInt(obj.user_id)) {
				user.role = obj.role;
			}
		})
		console.log("Role changed ====>", permissions);
		this.props.updatePermissions(permissions, obj);
		// this.setState({permissions});
		// console.log("Role changed");
	}



	tableData = () => {
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'Name', accessor: 'name', headerStyle: {textAlign: 'left'}},
                        {'Header' : 'Permission Level', accessor: 'role_nickname', Cell: row => (
                           <RoleChanger key={row.original.user_id} roles={this.state.permissions.roles} active_role={row.original.active_role} user_id={row.original.user_id} changeRole={this.changeRole} />
                        ), headerStyle:{textAlign: 'left'}}];
        this.state.permissions.company_users.sort(this.compare).forEach((user) => {
        	let elem = {user_id: user.user_id, company_id: user.company_id, name: user.name, active_role: user.role, email: user.email, role_nickname: sourceroleMap[user.role]};
        	ret.data.push(elem);
        })
        return ret;
	}

	compare(a, b) {
	    const bandA = a.name.toUpperCase();
	    const bandB = b.name.toUpperCase();

	    let comparison = 0;
	    if (bandA > bandB) {
	      comparison = 1;
	    } else if (bandA < bandB) {
	      comparison = -1;
	    } 
	    return comparison;
	 }

	searchUsers = (event) => {
		event.preventDefault();
		this.props.searchUsers(event.target.value);
	}

	changeAllRoles = (event) => {
		event.preventDefault();
		this.props.changeAllRoles(event.target.value);
	}

	render()
	{
		if (this.state.permissions === null) {
			return (<div>Loading...</div>);
		}

		return (
			<GeneralContainer>
				<SearchInput placeholder="Search..." onChange={this.searchUsers} />
				<div style={{marginTop: "20px"}}>
					{/*<div style={{float: "left", boxSizing: "border-box", width: "50%", paddingRight: "10px"}}>
						<div style={{fontWeight: "600", color: "#222222", marginBottom: "10px"}}>Company</div>
						<PermissionDropdown>
							{
								this.state.permissions.gc_companies.map((cmp) => {
									return (<option key={cmp.id} value={cmp.id}>{cmp.company_name}</option>);
								})
							}
						</PermissionDropdown>
					</div>*/}
					{/*<div style={{float: "left", boxSizing: "border-box", width: "50%", paddingLeft: "10px"}}>*/}
						<div style={{fontWeight: "600", color: "#222222", marginBottom: "10px"}}>Set permission level for all chosen users</div>
						<PermissionDropdown onChange={this.changeAllRoles}>
							<option key="0" value="0">Select</option>
							{
								this.state.permissions.roles.map((role) => {
									return (<option key={role.name} value={role.name}>{role.nickname}</option>);
								})
							}
						</PermissionDropdown>
					{/*</div>*/}
					{/*<div style={{clear: "both"}}></div>*/}
				</div>
				<div style={{marginTop: "20px", backgroundColor: "#ffffff", width: "100%", borderRadius: "3px"}}>
					<div style={{fontWeight: "600", color: "#222222", padding: "20px"}}>Users</div>
					<CSLTablemodule add={false} export={true} processData={this.tableData} headerText={''} tableRows="20" refreshCallback={this.refreshState} prefered_columns={this.state.prefered_columns} />
				</div>
			</GeneralContainer>
		);
	}
}

export default Permissions;