import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { saveAs } from "file-saver";
import APICall from "../../../Common/APICall.js";
import Store from "../../../Common/Store.js";
let options = [];

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget);
  };
  const [Options, setOptions] = useState([]);
  useEffect(() => {
    // options = ["View Details", "Delete", "Pin Document", "Download"];
    options = [
                {"name": "view", "display": "View Details"}, 
                {"name": "delete", "display": "Delete"},
                {"name":"pindoc", "display": "Pin Document"},
                {"name":"download", "display":"Download"}
              ];
   
    if(props.is_pinned){
      // options = ["View Details", "Delete", "Unpin Document", "Download"];
      options = [
                      {"name": "view", "display": "View Details"}, 
                      {"name": "delete", "display": "Delete"},
                      {"name":"unpindoc", "display": "Unpin Document"},
                      {"name":"download", "display":"Download"}
                ];
    }
    if(Store.getStoreData('role') === "document_manager"){
      options = [
                  {"name": "view", "display": "View Details"}, 
                  {"name":"pindoc", "display": "Pin Document"},
                  {"name":"download", "display":"Download"}
                ];
      if(props.is_pinned){
        // options = ["View Details","Unpin Document", "Download"];
        options = [
                    {"name": "view", "display": "View Details"}, 
                    {"name":"unpindoc", "display": "Unpin Document"},
                    {"name":"download", "display":"Download"}
                  ];
      }
    }
    if(Store.getStoreData('role') === "team"){
      options = [
                  {"name": "view", "display": "View Details"}, 
                  {"name":"unpindoc", "display": "Unpin Document"},
                  {"name":"download", "display":"Download"}
                ];
    }
    setOptions(options);
  }, []);
  const handleClose = (option) => {
    console.log("option", option)
    setAnchorEl(null);
    let role = Store.getStoreData('role');
    switch(role){
      case "admin_manager":
      if (option == "view") {
        props.viewdoc();
      } else if (option == "delete") {
        DeleteDocs();
      } else if (option == "pindoc") {
        PinnedDocs();
      } else if (option == "download") {
        getDocumentFiledatabyId(props.info.version_id);
      } else if(option == "unpindoc") {
        UnpinnedDocs();
      }
      break;
      case "document_manager":
      if (option == "view") {
        props.viewdoc();
      } else if (option == "pindoc") {
        PinnedDocs();
      } else if (option == "download") {
        getDocumentFiledatabyId(props.info.version_id);
      } else if(option == "unpindoc") {
        UnpinnedDocs();
      }
      break;
      case "team":
      if (option == "view") {
        props.viewdoc();
      }  else if (option == "pindoc") {
        PinnedDocs();
      } else if (option == "download") {
        getDocumentFiledatabyId(props.info.version_id);
      } else if(option == "unpindoc") {
        UnpinnedDocs();
      }
    }

  

  };



  const DeleteDocs = (event) => {
    //event.preventDefault();
    props.DeleteDocs("deletedocs");
  };

  const PinnedDocs = (event) => {
    //console.log(event)
    //event.preventDefault();
    props.PinnedDocs("pinneddocs");
  };

  const UnpinnedDocs = (event) => {
    props.UnpinnedDocs("unpinneddocs")
  }

  const getDocumentFiledatabyId = (id) => {
    let api = new APICall();
    let postData = { command: "get_doc", doc_id: id };
    console.log("postData", postData)
    api.command(postData, processDocumentData);
  };

  const processDocumentData = (result) => {
    let dbinfile = result.result;
    console.log("Attachment", dbinfile)
    let bin_file = JSON.parse(dbinfile.bin_file)
    let dnld = bin_file[0].bin_file.replace(/ /g, "+");
    let binaryString = window.atob(dnld);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, bin_file[0].name);
  };

  console.log("Options", Options)

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: "0px"}}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {Options.map((option) => (
          <MenuItem
            key={option.display}
            selected={option.display === "Pyxis"}
            onClick={() => handleClose(option.name)}
          >
            {option.display}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}