import React, { Component } from 'react';
import styled from 'styled-components';
import {FaPlusCircle} from 'react-icons/fa';
import AddRecordApprovalModal from './AddRecordApprovalModal';
const OverviewInfoHeader = styled.div`
    padding: 12px 15px;
    background-color: #04ada8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    // margin-top: 50px;
    // width: 100%;
    margin: 0 auto;
    margin-top: 30px;
`;

class RecordApprovalHeader extends React.Component
{
    state = { showModal: false}

    showRecordApprovalModal = () => (event) => {    
        event.preventDefault();
        this.setState({ showModal: true },()=>{console.log('showmodal=',this.state.showModal)});

    }

    refreshState = (approvalobj) => {
        //event.preventDefault();
        this.setState({ showModal: false})
       this.props.refreshCallback(approvalobj);
    }

    render() {
        return(<div>
            <OverviewInfoHeader>
                <div style={{float: "left", color: "#ffffff", fontWeight: "600"}} className="hd_ad_edit">Approval History</div>
                <div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showRecordApprovalModal()}><FaPlusCircle /></div>
                <div style={{clear: "both"}}></div>
            </OverviewInfoHeader>            
            {
                (() => {
                    if(this.state.showModal) {
                        return(
                             <AddRecordApprovalModal headerText={'Add'}  currentApprovalData={null} refreshParentCallback={this.refreshState} />
                        );
                    }
                })()
            }
        </div>);
    }
}
export default RecordApprovalHeader;