import React from "react";
import Store from "../../Common/Store.js";
import styled from "styled-components";
import { FaPlus, FaTimes } from "react-icons/fa";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 5000px;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.8;
`;

const OkButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #04aca7;
  color: #ffffff;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #37ada7;
  margin-left: 5px;
  font-size: 11px;
  cursor: pointer;
  float: right;
  font-size: 18px;
`;

const OkDangerButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #ea5a6e;
  color: #ffffff;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #37ada7;
  margin-left: 5px;
  font-size: 11px;
  cursor: pointer;
  float: right;
  font-size: 18px;
`;

const CancelButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: white;
  color: #646a7e;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #37ada7;
  margin-left: 5px;
  font-size: 11px;
  cursor: pointer;
  float: right;
  font-size: 18px;
`;

const AlertBoxContainer = styled.div`
  position: fixed;
  top: 40vh;
  left: 40vw;
  width: 450px;
  box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #f7f7f7;
  color: #2d2d2d;
  border-radius: 10px;
  border-style: solid;
  border-color: #f7f7f7;
  z-index: 2000;
`;
const AlertBoxHeader = styled.div`
  width: calc(100% - 15px);
  background-color: #f7f7f7;
  color: #646a7e;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  height: 35px;
  padding-left: 15px;
  padding-top: 15px;
  font-size: 20px;
  font-weight: 600;
  text-align: left;
`;
const AlertBoxBody = styled.div`
  width: calc(100% - 30px);
  background-color: #f7f7f7;
  color: #646a7e;
  font-size: 18px;
  min-height: 100px;
  text-align: center;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
`;
const AlertsubBoxBody = styled.div`
  width: calc(100% - 30px);
  background-color: #f7f7f7;
  color: #646a7e;
  font-size: 18px;
  min-height: 50px;
  text-align: center;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
`;
const AlertBoxFooter = styled.div`
  width: calc(100% - 15px);
  background-color: #edf1f2;
  color: #2d2d2d;
  height: 50px;
  padding-right: 15px;
  padding-top: 10px;
`;

class AlertBox extends React.Component {
  state = { view_count: 0 };

  constructor(props) {
    super(props);
  }
  componentDidMount() {
    if (this.props.alertParam !== null) {
      let title = "title" in this.props.alertParam ? this.props.alertParam.title : "Alert";
      let confirm = "confirm" in this.props.alertParam ? this.props.alertParam.confirm : false;
      let danger = "danger" in this.props.alertParam ? this.props.alertParam.danger : false;
      let multi_button = "multi_button" in this.props.alertParam ? this.props.alertParam.multi_button : false;
      let ok_text = "ok_text" in this.props.alertParam ? this.props.alertParam.ok_text : "Ok";
      let no_button = "no_button" in this.props.alertParam ? true : false;
      let cancel_text = "cancel_text" in this.props.alertParam ? this.props.alertParam.cancel_text : "Cancel";
      this.targetElement = document.querySelector("#CSLAlertBox");
      console.log("props", this.props);
      disableBodyScroll(this.targetElement);

      this.setState({ title: title, confirm: confirm, danger: danger, ok_text: ok_text, cancel_text: cancel_text, message: this.props.alertParam.message, multi_button:multi_button, no_button: no_button, sub_message: this.props.alertParam.sub_message});
    }
  }
  componentDidUpdate(prev_props) {
    if (this.props !== prev_props && this.props.alertParam !== null) {
      let title = "title" in this.props.alertParam ? this.props.alertParam.title : "Alert";
      let confirm = "confirm" in this.props.alertParam ? this.props.alertParam.confirm : false;
      let danger = "danger" in this.props.alertParam ? this.props.alertParam.danger : false;
      let multi_button = "multi_button" in this.props.alertParam ? this.props.alertParam.multi_button : false;
      let no_button = "no_button" in this.props.alertParam ? true : false;
      let ok_text = "ok_text" in this.props.alertParam ? this.props.alertParam.ok_text : "Ok";
      let cancel_text = "cancel_text" in this.props.alertParam ? this.props.alertParam.cancel_text : "Cancel";
      this.targetElement = document.querySelector("#CSLAlertBox");
      console.log("props", this.props);
      disableBodyScroll(this.targetElement);

      this.setState({ title: title, confirm: confirm, danger: danger, ok_text: ok_text, cancel_text: cancel_text, message: this.props.alertParam.message, sub_message: this.props.alertParam.sub_message, multi_button:multi_button, no_button: no_button });
    }
  }

  processOk = () => {
    enableBodyScroll(this.targetElement);
    this.props.alertParam.alertHandler(true, this.props.alertParam.stack);
  };

  processOriginal = () => {
    enableBodyScroll(this.targetElement);
    let stack = this.props.alertParam.stack;
    stack['original'] = true;
    this.props.alertParam.alertHandler(true, stack);
  }

  processDifferent = () => {
    enableBodyScroll(this.targetElement);
    let stack = this.props.alertParam.stack;
    stack['original'] = false;
    this.props.alertParam.alertHandler(true, stack);
  }

  processCancel = () => {
    enableBodyScroll(this.targetElement);
    this.props.alertParam.alertHandler(false, this.props.alertParam.stack);
  };

  render() {
    console.log("this.state.alertParam", this.state);
    if (this.props.alertParam === null) return <div></div>;

    return (
      <div>
        <InactiveOverlay />
        <AlertBoxContainer id="CSLAlertBox">
          <AlertBoxHeader>{this.state.title}</AlertBoxHeader>
          <AlertBoxBody>{this.state.message}</AlertBoxBody>
          {
            (() => {
              if(this.state.sub_message){
                return <AlertsubBoxBody>{this.state.sub_message}</AlertsubBoxBody>
              }
            })()
          }
          
          <AlertBoxFooter>
            {(() => {
              if (this.state.danger) {
                return <OkDangerButton onClick={this.processOk}>{this.state.ok_text}</OkDangerButton>;
              } else {
                if(this.state.multi_button){
                  return(<div>
                    <OkButton onClick={this.processOriginal}>Original</OkButton>
                    <OkButton onClick={this.processDifferent}>Different</OkButton>
                  </div>)
                }else{
                  if(!this.state.no_button)return <OkButton onClick={this.processOk}>{this.state.ok_text}</OkButton>;
                  else return <div></div>
                }
                
              }
            })()}
            {(() => {
              if (this.state.confirm) return <CancelButton onClick={this.processCancel}>{this.state.cancel_text}</CancelButton>;
            })()}
          </AlertBoxFooter>
        </AlertBoxContainer>
      </div>
    );
  }
}
export default AlertBox;
