import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-flow: row;
    // width:100%;
    // height: 100%;
    background-color: transparent;
    padding: 10px 20px;
    cursor: pointer;
	margin-top: 2px;
    width: inherit;
    height: inherit;

`;

const ClearBoth = styled.div`
	clear: both;
`;

const CSLLabel = styled.label`
	cursor: pointer;
	text-indent: -9999px;
	width: 200px;
	height: 100px;
	background: grey;
	display: block;
	border-radius: 100px;
	position: relative; 

    &:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        width: 90px;
        height: 90px;
        background: #fff;
        border-radius: 90px;
        transition: 0.3s;
    }
    
    &:active:after {
        width: 130px;
    }

`;

const CSLInput = styled.input`

    height: 0;
    width: 0;
    visibility: hidden;

    &:checked + ${CSLLabel} {
        background: #bada55;
    }

    &:checked + ${CSLLabel}:after {
        left: calc(100% - 5px);
        transform: translateX(-100%);
    }

`;

class CSLToggle extends React.Component {




	render(){
        return(
            <div>
            checkbox
            </div>
        )
    }
}

export default CSLToggle;