import React from "react";
import APICall from "../../../Common/APICall.js";
import Store from "../../../Common/Store.js";
import * as Style from "../../Common/StyledComponents";
import styled from "styled-components";
import DatePicker from 'react-date-picker';
import { MdClose } from "react-icons/md";
import CSLLoader from "../../Common/CSLLoader";
import AlertBox from "../../Common/AlertBox";
// import Dropzone from "../../Common/Dropzone.js";
import Dropzone from "../../Dropzone/Dropzone.js"

const moment = require("moment");
const last_action_time = moment().unix();

const ModalWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

const ModalContainer = styled.div`
    display: block;
    box-sizing: border-box;
    max-height: 90vh;
    height: 100%;
    width: 90%;
    max-width: 800px;
    position: fixed;
    overflow-y: auto;
    margin: auto;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;

const DocHeader = styled.div`
  border-width: 1px;
  background-color: white;
  border-color: #e9e9e9;
  display: block;
  font-size: 14px;
  font-weight: 700;
  height: 50px;
  border-radius: 5px;
`;
const ModalHeaderCloseBtn = styled.div`
  float: right;
  box-sizing: border-box;
  color: #282828;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
`;

const DocBody = styled.div`
  display: block;
  box-sizing: border-box;
  background-color: #f3f7fb;
  padding: 10px;
  border-radius: 4px;
`;

const DocFormContainer = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
`;


const UserFormFooter = styled.div`
  position: relative;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #e9ecef;
  height: 79px;
  border-radius: 5px;
`;

const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #046de4;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 127px;
  font-size: 13px;
  height: 39px;
  border-radius: 4px;
  float: right;
  margin-top: -30px;
  cursor: pointer;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 3000px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;
const CSLDateCover = styled.div`
    margin-top: 8px;
    width: 200px;
    border-radius: 5px;
    background-color: #ffffff;
    height: 33px;
    padding-top: 5px;
    padding-left: 10px;
    `;
const SpacerL = styled.div`
  height: 10px;
`;

class SendAttestationModal extends React.Component
{
    state = {
      ready: false,
      alert_param: null,
      saving: false,
      binFiles: [],
      contacts: {},
      addnew: true,
      is_add: false,
      document_info: {
        name: "",
        doc_type: 0,
        doc_owner: 0,
        description: "",
        visibility: 0,
        file_name: "",
        doc_version: "",
        lock_doc: 0
      },
      due_date: new Date(),
      loading: false
    };

    constructor(props) {
      super(props);
    }

    componentDidMount() {
      this.callDefaultinfo();
    }

    componentDidUpdate(prevProps) {
      if (prevProps !== this.props) {
        this.callDefaultinfo();
      }
    }

    filesLoaded = (files) => {
      let contact_id = Store.getStoreData("contact").ID;
      let t = {};
      for (let i = 0; i < files.length; i++) {
        files[i].name = files[i].name.replace("&", "");
        if (!("user_id" in files[i])) {
          files[i].user_id = contact_id;
          files[i].upload_date = last_action_time;
        }
        t[files[i].uid.toString()] = files[i].name
      }
      let document_info = this.state.document_info;
      document_info["file_name"] = t;
      this.setState({
        binFiles: files,
        addnew: false,
        document_info: document_info,
      });
    };

    callDefaultinfo() {
      let doc_info = this.props.doc_info;  
      let binFiles = this.props.bin_files;
      let t = {};
      t[binFiles[0].uid.toString()] = binFiles[0].name;
      let contact_assoc = Store.getStoreData("contact_assoc");
      let document_info = doc_info;
      document_info['file_name'] = t;
      this.setState({
        binFiles: binFiles,
        addnew: binFiles.length === 0 ? true : false,
        is_add: this.props.is_add,
        ready: true,
        contacts: contact_assoc,
        document_info: doc_info,
      });
    }


    closeModal = (event) => {
      if (event) event.preventDefault();
      this.props.closeUserDialog();
    };


    handleChange = (event) => {
      let document_info = this.state.document_info;
      document_info[event.target.name] = event.target.value;
      this.setState({ document_info: document_info });
    };

    sendAttestation = (event) => {
      event.preventDefault();
      let bin_files = this.state.binFiles;
      let dnld = bin_files[0].bin_file.replace(/ /g, "+");
      let binaryString = window.atob(dnld);
      var fileSize = binaryString.length;
      if(fileSize > 5000000){
        let alert_param = { title: "Alert", message: "This file may take some time to upload and for users to download. Would you like to continue to upload this document?", ok_text: "OK", confirm: true, alertHandler: this.processORcancel, stack: {} };
        this.setState({ alert_param: alert_param });
      }else{
        this.refs.dropZone.uploadFilesFromChild(bin_files);
      }







      /*let postData = { command: "send_attestation" };
      let d_info = {};
      d_info['file_name'] = this.state.document_info['file_name'][Object.keys(this.state.document_info['file_name'])[0].toString()];
      d_info['visibility'] = this.state.document_info['visibility'];
      d_info['name'] = this.state.document_info['name'];
      d_info['description'] = this.state.document_info['description'];
      d_info['doc_type'] = this.state.document_info['typeinno'];
      d_info['doc_owner'] = this.state.document_info['doc_ownerin_no'];
      d_info['gc_ids'] = ('gc_ids' in this.state.document_info) ? this.state.document_info['gc_ids'] : {};
      d_info['folder_id'] = ('folder_id' in this.state.document_info) ? this.state.document_info['folder_id'] : 0;
      d_info['lock_doc'] = ('lock_doc' in this.state.document_info) ? this.state.document_info['lock_doc'] : 0;

      postData['document_info'] = d_info;
      postData['is_add'] = this.state.is_add;

      postData["bin_files"] = this.state.binFiles;
      postData["doc_id"] = this.state.document_info['doc_id'];
      postData["version_id"] = this.state.document_info['version_id'];
      postData["file_name"] = this.state.document_info['file_name'];
      postData["at_type"] = 'Document';
      postData["is_add"] = this.state.is_add;
      let curmonth = parseInt(this.state.due_date.getMonth());
      let currmonth = curmonth + 1;
      var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
      dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate(); 

      let headerinfo = {};
      headerinfo.document_name = this.state.document_info['name'];
      headerinfo.document_version = this.state.document_info['doc_version'];
      headerinfo.description = this.state.document_info['description'];
      headerinfo.last_change_date = dueDateStr;
      headerinfo.next_review_date = dueDateStr;
      headerinfo.assignedTo = this.state.document_info['doc_ownerin_no'];
      headerinfo.Type = this.state.document_info['type'];
      headerinfo.isNextReviewAssignTask = 0;
      postData["headerinfo"] = headerinfo
      console.log("postData",postData, this.state.document_info)
      let api = new APICall();
      api.command(postData, this.processAttestation);*/
      
    }

    AlertCompleteHandler = (result, stack) => {
      this.setState({ alert_param: null });
    };

    processORcancel = (result, stack) => {
      if (!result || stack.prevent) {
        this.setState({ alert_param: null });
        return;
      }
      this.setState({ alert_param: null });
      let bin_files = this.state.binFiles;
      this.refs.dropZone.uploadFilesFromChild(bin_files);
    }

    onUploadComplete = (files) => {
      let returnedFiles = JSON.parse(files);
      let bin_files = this.state.binFiles;
      if (typeof bin_files !== 'undefined' && bin_files.length > 0) {
        bin_files.forEach((file) => {
          let fileName = file.name;
          let refID = returnedFiles.find((o) => o.inserted_file_name == fileName)['ref_id'];
          delete file.bin_file;
          file.ref_id = refID;
        });
      }
      this.setState({loading: true})
      let postData = { command: "send_attestation" };
      let d_info = {};
      d_info['file_name'] = this.state.document_info['file_name'][Object.keys(this.state.document_info['file_name'])[0].toString()];
      d_info['visibility'] = this.state.document_info['visibility'];
      d_info['name'] = this.state.document_info['name'];
      d_info['description'] = this.state.document_info['description'];
      d_info['doc_type'] = this.state.document_info['typeinno'];
      d_info['doc_owner'] = this.state.document_info['doc_ownerin_no'];
      d_info['gc_ids'] = ('gc_ids' in this.state.document_info) ? this.state.document_info['gc_ids'] : {};
      d_info['folder_id'] = ('folder_id' in this.state.document_info) ? this.state.document_info['folder_id'] : 0;
      d_info['lock_doc'] = ('lock_doc' in this.state.document_info) ? this.state.document_info['lock_doc'] : 0;

      postData['document_info'] = d_info;
      postData['is_add'] = this.state.is_add;

      postData["bin_files"] = bin_files;
      postData["doc_id"] = this.state.document_info['doc_id'];
      postData["version_id"] = this.state.document_info['version_id'];
      postData["file_name"] = this.state.document_info['file_name'];
      postData["at_type"] = 'Document';
      postData["is_add"] = this.state.is_add;
      let curmonth = parseInt(this.state.due_date.getMonth());
      let currmonth = curmonth + 1;
      var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear()+'/'+currmonth+'/' : this.state.due_date.getFullYear()+'/0'+currmonth+'/';
      dueDateStr += this.state.due_date.getDate() < 10 ? '0' + this.state.due_date.getDate() : this.state.due_date.getDate(); 

      let headerinfo = {};
      headerinfo.document_name = this.state.document_info['name'];
      headerinfo.document_version = this.state.document_info['doc_version'];
      headerinfo.description = this.state.document_info['description'];
      headerinfo.last_change_date = dueDateStr;
      headerinfo.next_review_date = dueDateStr;
      headerinfo.assignedTo = this.state.document_info['doc_ownerin_no'];
      headerinfo.Type = this.state.document_info['type'];
      headerinfo.isNextReviewAssignTask = 0;
      postData["headerinfo"] = headerinfo
      console.log("postData",postData, this.state.document_info)
      let api = new APICall();
      api.command(postData, this.processAttestation);

    }

    processAttestation = (result) => {
      let msg = "Policy Attestation has been saved but not submitted for creation yet. You may modify and create it later.";
      let alert_param = {title: "Success",message: msg,ok_text: "OK",confirm: false,alertHandler: this.AlertremoveHandler,stack: { id: 0 }};
      this.setState({ alert_param: alert_param, loading: false }); 
    }

    AlertremoveHandler = (result, stack) => {
      this.setState({ alert_param: null});
      this.props.reloadDocuments(this.state.document_info['doc_id'],this.state.document_info['version_id']);
    };

    onDateChangeDueBy = (date) => {
        this.setState({due_date: date})
    }

    render()
	{

        if (!this.state.ready)return<div></div>
        return(      
        <ModalWrapper>
            <ModalContainer>
        <div>
          <DocHeader>
            <div style={{fontSize: "14px",float: "left",marginTop: "15px",marginLeft: "10px"}}>Record New Attestation</div>
            <ModalHeaderCloseBtn onClick={this.closeModal}><MdClose /></ModalHeaderCloseBtn>
          </DocHeader>
          <DocBody>
            <DocFormContainer>
            {
              (() => {
                if(this.state.loading) return <CSLLoader />;
              })()
            }
              <div style={{ padding: "10px" }}>
                <Style.ModalNameLabelDiv>Upload Attachment</Style.ModalNameLabelDiv>
                <Style.ModalNameInputDiv>
                  <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.binFiles} addnew={this.state.addnew} buttontxt="Upload Attachment" onUploadComplete={this.onUploadComplete} ref="dropZone" />

                </Style.ModalNameInputDiv>
              </div>
              <Style.ModalNameLabelDiv>Document Name</Style.ModalNameLabelDiv>
              <SpacerL />
              <Style.ModalNameInputDiv>
                <Style.ModalInput type="text" name="name" onChange={this.handleChange} value={this.state.document_info["name"]}/>
              </Style.ModalNameInputDiv>
              <SpacerL />
              <Style.ModalNameLabelDiv>Document Version</Style.ModalNameLabelDiv>
              <SpacerL />
              <Style.ModalNameInputDiv>
                <Style.ModalInput type="text" name="version" onChange={this.handleChange} value={this.state.document_info["doc_version"]}/>
              </Style.ModalNameInputDiv>
              <SpacerL />
              <Style.ModalNameLabelDiv>Description</Style.ModalNameLabelDiv>
              <SpacerL />
              <Style.ModalNameInputDiv>
                <Style.ModalTextArea name="description" onChange={this.handleChange} value={this.state.document_info["description"]}/>
              </Style.ModalNameInputDiv>
              <SpacerL />
               <Style.ModalNameLabelDiv>Last Change Date</Style.ModalNameLabelDiv>
               <SpacerL />
               <CSLDateCover>
                   <DatePicker
                           onChange={this.onDateChangeDueBy}
                           clearIcon={null}
                           calendarIcon={null}
                           locale={"en-GB"}
                           value={this.state.due_date}
                           />
               </CSLDateCover>
            </DocFormContainer>
          </DocBody>
          <UserFormFooter>
            <MRButton style={{top: "40px",right: "150px",position: "absolute",backgroundColor: "#FFFFFF",color: "#757575"}} onClick={this.closeModal} disabled={this.state.saving}>
              Cancel
            </MRButton>
            <MRButton style={{top: "40px",right: "10px",position: "absolute",backgroundColor: "#1A3552",color: "white"}} onClick={this.sendAttestation} disabled={this.state.saving}>
              Save and Close
            </MRButton>
          </UserFormFooter>
        </div>
        <AlertBox alertParam={this.state.alert_param} />
      </ModalContainer>
      </ModalWrapper>);
    }

}
export default SendAttestationModal;