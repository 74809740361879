import React from 'react';
import styled from "styled-components";
import * as Style from "../Common/StyledComponents";
import { FaPlusCircle, FaTimes } from "react-icons/fa";
import DatePicker from "react-date-picker";
import AlertBox from "../Common/AlertBox";
import Utils from "../../Common/Utils.js";
import Store from "../../Common/Store.js";
import APICall from "../../Common/APICall.js";

const OverviewInfoHeader = styled.div`
  padding: 12px;
  background-color: #04ada8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const MRModalCancelBtn = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #dadada;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  font-size: 11px;
  float: right;
  cursor: pointer;
`;
const MRModalNextBtn = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #37ada7;
  color: #ffffff;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #37ada7;
  margin-left: 5px;
  font-size: 11px;
  margin-left: 10px;
  float: right;
  cursor: pointer;
`;

const CSLDateCover = styled.div`
  margin-top: 8px;
  width: 200px;
  border-radius: 5px;
  background-color: #ffffff;
  height: 33px;
  padding-top: 5px;
  padding-left: 10px;
`;
const ScoreContainer = styled.div`
  background-color: #ecf0f1;
  height: auto;
  border: 1px solid #d4dcde;
  border-radius: 5px;
`;
const ScoreBodyContainer = styled.div`
  padding: 15px;
  text-align: left;
  overflow-y: scroll;
  height: 450px;
`;

const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #046de4;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 127px;
  font-size: 13px;
  height: 39px;
  border-radius: 4px;
  float: right;
  margin-top: -30px;
  cursor: pointer;
`;

const FormDescriptionText=styled.div`
  font-weight: 600;
  margin-top: 35px;
  float: left;
  color: #929292;
  margin-left: 13px;
  font-size: 13px;
`



class AddApprovalModal extends React.Component {

  state = {
    showModal: false,
    ready: false,
    approval_date: new Date(),
    doc_id: 0,
    record: { id : 0, auto_id: null, doc_version: "1.0",approval_date: "",comments:"", approvar: "", doc_id: 0, isCommitteeApproved: 0, approvar_json:{approval_committee:null,approval_committee_member:null},add: 1},
    alert_param: null,
    loading: false,
    approvalCommittee:[],    
    approvalCommitteeMembers:[]
    

  };

  constructor(props) {
    super(props);
 
  }

  componentDidMount() {

  this.callApprovaldata();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.callApprovaldata();
    }
  }
  callApprovaldata = () => {
    let postData = {'command' : 'list_committee'};
    let api = new APICall();
    api.command(postData, this.processCommittee);
  }

  processCommittee = (result) => {
    let resultData=result.result;
    let selectedCommitteeMembers=[];
    for(var i=0;i<resultData.length;i++){
      if(this.state.record["approvar_json"]["approval_committee"]==resultData[i]['committeeId']){
           selectedCommitteeMembers=resultData[i].committeemembers;
      }
    }
    let record = this.props.current_approval_data === null ? this.state.record : this.props.current_approval_data;
    let approval_date = this.props.current_approval_data != null ? new Date(this.props.current_approval_data["approval_date"]) : this.state.approval_date;
    this.setState({ record: record, showModal: this.props.show_modal, approval_date: approval_date,approvalCommittee:resultData,approvalCommitteeMembers:selectedCommitteeMembers, ready: true})
  }


  /*callApprovaldata = () => {
    let record = this.props.current_approval_data === null ? this.state.record : this.props.current_approval_data;

    console.log("callApprovaldata record", record)
    let approval_date = this.props.current_approval_data != null ? new Date(this.props.current_approval_data["approval_date"]) : this.state.approval_date;
    this.setState({ record: record, showModal: this.props.show_modal, approval_date: approval_date,approvalCommittee:[],approvalCommitteeMembers:[]},()=>{
    let companyid = Store.getStoreData("company").ClientNumber;
    let postData = {'command' : 'list_committee','companyid':companyid};
    let api = new APICall();
    api.command(postData, this.processCommittee);


    })
   
  }

  processCommittee = (result) => {
        console.log('Committeemembers', result.result);
        let resultData=result.result;
        let selectedCommitteeMembers=[];

      
        for(var i=0;i<resultData.length;i++){
        
          if(this.state.record["approvar_json"]["approval_committee"]==resultData[i]['committeeId']){
               selectedCommitteeMembers=resultData[i].committeemembers;
          }

        }
        
         console.log('c1=',resultData);
         
        this.setState({ approvalCommittee: resultData,approvalCommitteeMembers:selectedCommitteeMembers,ready: true})
      }*/


  showApprovalModal = (event) => {
    event.preventDefault();
    let approval_date = new Date();
    let curmonth = parseInt(approval_date.getMonth());
    let currmonth = curmonth + 1;
    var dueDateStr = currmonth >= 10 ? approval_date.getFullYear() + "/" + currmonth + "/" : approval_date.getFullYear() + "/0" + currmonth + "/";
    dueDateStr += approval_date.getDate() < 10 ? "0" + approval_date.getDate() : approval_date.getDate();
    let record = this.state.record;
    record['add'] = 1;
    record['auto_id'] = Utils.genKey(5);
    record['doc_version'] = "1.0";
    record['approval_date'] = dueDateStr;
    record['approvar'] = "";
    record['comments'] = "";
    record['isCommitteeApproved']=0;
    record['approvar_json']={approval_committee:null,approval_committee_member:null};
    
    this.setState({ showModal: true, record: record, approval_date: approval_date});
  };

  closeApprovalModal = (event) => {
    event.preventDefault();
    this.setState({ showModal: false});
  };

  onDateChange = (approval_date) => {
    let curmonth = parseInt(approval_date.getMonth());
    let currmonth = curmonth + 1;
    var dueDateStr = currmonth >= 10 ? approval_date.getFullYear() + "/" + currmonth + "/" : approval_date.getFullYear() + "/0" + currmonth + "/";
    dueDateStr += approval_date.getDate() < 10 ? "0" + approval_date.getDate() : approval_date.getDate();
    let record = this.state.record;
    record['approval_date'] = dueDateStr;
    this.setState({ approval_date: approval_date, record: record });
  };

  handleChange = (evt) => {
    let record = this.state.record;
    record[evt.target.name] = evt.target.value;
    console.log("task ===>", record)
    this.setState({ record: record });
   
  };

  handleChange1 = (evt) => {
            const targetname=evt.target.name;
            const selindex=evt.target.selectedIndex-1;
             this.setState({[evt.target.name]: evt.target.value},function(){

              if(targetname=="approval_committee")
                 {
                        let record = this.state.record;
                      record['approvar'] ='';
                      let approvalCommitteeMembers=[];
                      record['approvar_json']['approval_committee']=selindex==-1?null:this.state.approvalCommittee[selindex].committeeId;
                      if(record['approvar_json']['approval_committee']!=null)
                          {
                           record['approvar'] = this.state.approvalCommittee[selindex].committeename; 
                           approvalCommitteeMembers=this.state.approvalCommittee[selindex].committeemembers;
                             }
                        this.setState({ record:record,approvalCommitteeMembers:approvalCommitteeMembers},()=>{console.log(this.state.approvalCommitteeMembers)});
                                
                 }
              if(targetname=="approval_committee_member")
                 {
                    let record = this.state.record;
                  record['approvar_json']['approval_committee_member']=selindex==-1?null:this.state.approvalCommitteeMembers[selindex].employeeid;
                  if(record['approvar_json']['approval_committee_member']!=null)
                    {
                     let obj = this.state.approvalCommittee.find(o => o.committeeId ===record['approvar_json']['approval_committee'] );
                     console.log('obj=',obj);
                   record['approvar'] = obj['committeename']+','+this.state.approvalCommitteeMembers[selindex].employeename+','+this.state.approvalCommitteeMembers[selindex].committeerolename;
                    }
                    this.setState({record:record})
                 

              }
              
         });
          
   
  };

  addApproval = (event) => {
    event.preventDefault();
    if(this.state.record.version === ""||this.state.record.approvar === ""){
      let alert_param = { title: "Alert", message: "Please fill the mandatory fields", ok_text: "OK", confirm: true, alertHandler: this.processORcancel, stack: {} };
      this.setState({alert_param:alert_param})
      return;
    }
    let record = this.state.record;
    console.log("this.state.record", record)
    this.setState({showModal: false})
    this.props.reloadApprovers(record);
  }

  processORcancel = (result, stack) => {
     this.setState({ alert_param: null });
  }



   checkedapprovedChange = (event) => {
   console.log(event);
  // 	event.preventDefault();

   	  if(this.state.approvalCommittee.length==0)
        {
            
            let alert_param = { title: "Alert", message: "Approval committee details not found!", ok_text: "OK", confirm: true, alertHandler: this.processORcancel, stack: {} };
            this.setState({alert_param:alert_param})
            return;
        }


      let record = this.state.record;
      record['isCommitteeApproved']=record['isCommitteeApproved']==1?0:1;
      record['approvar_json']['approval_committee']=null;
      record['approvar_json']['approval_committee_member']=null;
      record['approvar']="";
   this.setState({record: record},()=>{	})
   }
 

  render() {
    console.log("this.state==", this.state);
    if (!this.state.ready) {
      return <div></div>;
    }
    console.log("this.state===", this.state);
    return (
      <div>
      <OverviewInfoHeader>
          <div style={{float: "left", color: "#ffffff", fontWeight: "600"}} className="hd_ad_edit">Approval History</div>
          <div style={{float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer"}} onClick={this.showApprovalModal}><FaPlusCircle /></div>
          <div style={{clear: "both"}}></div>
      </OverviewInfoHeader>
      {
        (() => {
          if(this.state.showModal) {
            return(<ScoreContainer>
                <Style.DocHeader style={{ borderRadius: "0", height: "50px" }}>
                  <div style={{ fontSize: "14px", float: "left", marginTop: "15px", marginLeft: "10px" }}>Record Document Approval</div>
                  <Style.ModalHeaderCloseBtn onClick={this.closeApprovalModal}>
                    <FaTimes />
                  </Style.ModalHeaderCloseBtn>
                  <FormDescriptionText>Use this form to record the details of the approval. Please provide the document version number, approver and the date it was approved. A comment is optional but recommended.</FormDescriptionText>
                </Style.DocHeader>

                <ScoreBodyContainer>
                  <Style.ModalNameLabelDiv>Document Version <span style={{color: "red"}}>*</span></Style.ModalNameLabelDiv>
                  <Style.SpacerL></Style.SpacerL>
                  <Style.ModalNameInputDiv>
                    <Style.ModalInput type="text" name="doc_version" onChange={this.handleChange} value={this.state.record["doc_version"]} />
                  </Style.ModalNameInputDiv>
                  <Style.SpacerL></Style.SpacerL>
                <label style={{float: "right"}}>
                 Approved by a Committee <input type="checkbox" checked={this.state.record["isCommitteeApproved"]}  onChange={this.checkedapprovedChange}/>  
                </label>
                 <Style.ModalNameLabelDiv>Approver <span style={{color: "red"}}>*</span></Style.ModalNameLabelDiv>
                 <Style.SpacerL></Style.SpacerL>
                 <Style.ModalNameInputDiv>
                {
                  (() => {
                          if(this.state.record["isCommitteeApproved"]){
                            return( 
                                   <div>
                                     <Style.MRModalSelect onChange={this.handleChange1} name="approval_committee" value={this.state.record["approvar_json"]["approval_committee"]}  >

                                                                         {
                                        (() => {
                                            if(this.state.approvalCommittee.length !== 0) {
                                                let approvalCommittee = [];
                                                approvalCommittee.push(<option key={0} value={0}>Select Committee</option>);
                                                for(let item of this.state.approvalCommittee){
                                                    approvalCommittee.push(<option key={item.committeeId} value={item.committeeId}>{item.committeename}</option>);
                                                    
                                                }
                                                return approvalCommittee;
                                            }

                                        })()
                                    }
                                    
                                    </Style.MRModalSelect>

                                    <Style.ModalNameLabelDiv>Committee Member</Style.ModalNameLabelDiv>
                                    <Style.SpacerL></Style.SpacerL>
                                    <Style.MRModalSelect onChange={this.handleChange1} name="approval_committee_member" value={this.state.record["approvar_json"]["approval_committee_member"]}>

                                    {
                                        (() => {
                                            if(this.state.approvalCommitteeMembers.length !== 0) {
                                                let approvalCommitteeMembers = [];
                                                approvalCommitteeMembers.push(<option key={0} value={0}>Select Committee Member</option>);
                                                for(let item of this.state.approvalCommitteeMembers){
                                                    approvalCommitteeMembers.push(<option key={item.employeeid} value={item.employeeid}>{item.employeename}/{item.committeerolename}</option>);
                                                    
                                                }
                                                return approvalCommitteeMembers;
                                            }

                                        })()
                                    }
                           
                                    </Style.MRModalSelect>
                                   </div>  
                                
                                  )
                            }else{
                            return(
                                        
                         
                            <Style.ModalInput type="text" name="approvar" onChange={this.handleChange} value={this.state.record["approvar"]} />
                            )
                          }
                  })()
                } 
                </Style.ModalNameInputDiv> 

                 
                  <Style.SpacerL></Style.SpacerL>
                  <Style.ModalNameLabelDiv>Approval Date</Style.ModalNameLabelDiv>
                  <Style.SpacerL></Style.SpacerL>
                  <CSLDateCover>
                    <DatePicker onChange={this.onDateChange} clearIcon={null} calendarIcon={null} locale={"en-GB"} value={this.state.approval_date} />
                  </CSLDateCover>
                  <Style.SpacerL></Style.SpacerL>
                  <Style.ModalNameLabelDiv>Comments</Style.ModalNameLabelDiv>
                  <Style.SpacerL></Style.SpacerL>
                  <Style.ModalNameInputDiv>
                    <Style.ModalTextArea name="comments" onChange={this.handleChange} value={this.state.record["comments"]} />
                  </Style.ModalNameInputDiv>
                </ScoreBodyContainer>
                <Style.DocFooter style={{ textAlign: "right", height: "35px", padding: "10px" }}>
                  <MRModalNextBtn onClick={this.addApproval}>SAVE</MRModalNextBtn>
                  <MRModalCancelBtn onClick={this.closeApprovalModal}>CANCEL</MRModalCancelBtn>
                </Style.DocFooter>
                <AlertBox alertParam={this.state.alert_param} />
              </ScoreContainer>)
          }

        })()
      }
      </div>
    );
  }
}
export default AddApprovalModal;