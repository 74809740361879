
import React from 'react';

import styled from "styled-components";
import * as Style from "../Common/StyledComponents";
import { FaPlusCircle, FaTimes } from "react-icons/fa";
import DatePicker from "react-date-picker";
import AlertBox from "../Common/AlertBox";

const OverviewInfoHeader = styled.div`
  padding: 12px 15px;
  background-color: #04ada8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const MRModalCancelBtn = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #dadada;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  font-size: 11px;
  float: right;
  cursor: pointer;
`;
const MRModalNextBtn = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #37ada7;
  color: #ffffff;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #37ada7;
  margin-left: 5px;
  font-size: 11px;
  margin-left: 10px;
  float: right;
  cursor: pointer;
`;

const CSLDateCover = styled.div`
  margin-top: 8px;
  width: 200px;
  border-radius: 5px;
  background-color: #ffffff;
  height: 33px;
  padding-top: 5px;
  padding-left: 10px;
`;
const ScoreContainer = styled.div`
  background-color: #ecf0f1;
  height: auto;
  border: 1px solid #d4dcde;
  border-radius: 5px;
`;
const ScoreBodyContainer = styled.div`
  padding: 15px;
  text-align: left;
  overflow-y: scroll;
  height: 450px;
`;

const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #046de4;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 127px;
  font-size: 13px;
  height: 39px;
  border-radius: 4px;
  float: right;
  margin-top: -30px;
  cursor: pointer;
`;

const FormDescriptionText=styled.div`

font-weight: 600;
margin-top: 35px;
float: left;

 color: #929292;
 margin-left: 6px;
 font-size: 13px;
`

class AddRecordApprovalModal extends React.Component {
  
  
  constructor(props) {
    super(props);  
    this.state={
      id:0,
      version:'',
      approver:'',
      approvedate:new Date(),
      approvecomments:'',
      saving:false,
      alert_param:null
    } 
   
    
   
  }
  componentDidMount() {
    console.log('currentApprovalData=',this.props.currentApprovalData)
    if(this.props.currentApprovalData!=null){
      let approvaldate=this.props.currentApprovalData.approvedate.split("/");
      this.setState({
        id:this.props.currentApprovalData.id,
        version:this.props.currentApprovalData.version,
        approver:this.props.currentApprovalData.approver,
        approvedate:new Date(""+approvaldate[2]+"-"+approvaldate[1]+"-"+approvaldate[0]),
        approvecomments:this.props.currentApprovalData.approvecomments,
        alert_param:null
       },()=>{console.log('version=',this.state.version)})
    }

  }
  closeAddRecordApprovalModal = () => {
    this.props.refreshParentCallback('');
}
processORcancel = (result, stack) => {
   this.setState({ alert_param: null });

}
addApproval=()=>{
  if(this.state.version==''||this.state.approver==''){
    
    let alert_param = { title: "Alert", message: "Please fill the mandatory fields", ok_text: "OK", confirm: true, alertHandler: this.processORcancel, stack: {} };
    this.setState({alert_param:alert_param})
   return;
  }
  let approvedatestr=this.state.approvedate.getDate()+"/"+(this.state.approvedate.getMonth()+1)+"/"+this.state.approvedate.getFullYear();
  let approvalobj={
    id:this.state.id,
    version:this.state.version,
    approver:this.state.approver,
    approvedate:approvedatestr,
    approvecomments:this.state.approvecomments
}
console.log(approvalobj)
  this.props.refreshParentCallback(approvalobj);
}
handleChange=(event)=>{
event.preventDefault();
console.log(event.target.value);
this.setState({[event.target.name]:event.target.value})
}
onApprovedateChange = (date) => {
    this.setState({ approvedate: date });
  }
  render(){

  //  return <ScoreContainer>
  //   <h1>MODAL</h1>
  //   <button onClick={this.closeAddCustomerModal}>close</button>
  //  </ScoreContainer>

   return  <ScoreContainer>
   <Style.DocHeader style={{ borderRadius: "0", height: "50px" }}>
     <div style={{ fontSize: "14px", float: "left", marginTop: "15px", marginLeft: "10px" }}>
      {/* {this.props.headerText}  */}
     Record Document Approval</div>
     <Style.ModalHeaderCloseBtn onClick={this.closeAddRecordApprovalModal}>
       <FaTimes />
     </Style.ModalHeaderCloseBtn>
     <FormDescriptionText>Use this form to record the details of the approval.Please provide the document version number,
     approver and the date it was approved.A comment is optional but recommended</FormDescriptionText>
     
   </Style.DocHeader>

   <ScoreBodyContainer>
     <Style.ModalNameLabelDiv>Document Version <span style={{color: "red"}}>*</span></Style.ModalNameLabelDiv>
     <Style.SpacerL></Style.SpacerL>
     <Style.ModalNameInputDiv>
       <Style.ModalInput type="text" name="version" onChange={this.handleChange} value={this.state.version} />
     </Style.ModalNameInputDiv>

     <Style.ModalNameLabelDiv>Approver <span style={{color: "red"}}>*</span></Style.ModalNameLabelDiv>
     <Style.SpacerL></Style.SpacerL>
     <Style.ModalNameInputDiv>
       <Style.ModalInput type="text" name="approver" onChange={this.handleChange} value={this.state.approver} />
     </Style.ModalNameInputDiv>


     <Style.ModalNameLabelDiv>Approval Date</Style.ModalNameLabelDiv>
    <Style.SpacerL></Style.SpacerL>
    <CSLDateCover>
        <DatePicker onChange={this.onApprovedateChange} clearIcon={null} calendarIcon={null} locale={"en-GB"} value={this.state.approvedate} />
    </CSLDateCover>

    <Style.ModalNameLabelDiv>Comments</Style.ModalNameLabelDiv>
     <Style.SpacerL></Style.SpacerL>
     <Style.ModalNameInputDiv>
     <Style.ModalTextArea name="approvecomments" onChange={this.handleChange} value={this.state.approvecomments} />
     </Style.ModalNameInputDiv>


     
     

    
     
   </ScoreBodyContainer>
   <Style.DocFooter style={{ textAlign: "right", height: "30px", padding: "10px" }}>
     <MRModalNextBtn onClick={this.addApproval} disabled={this.state.saving} >{this.state.id==0?'SAVE':'UPDATE'}</MRModalNextBtn>
     <MRModalCancelBtn onClick={this.closeAddRecordApprovalModal}>CANCEL</MRModalCancelBtn>       
     
     </Style.DocFooter>
     <AlertBox alertParam={this.state.alert_param} />
  
 </ScoreContainer>
  }
}
export default AddRecordApprovalModal;