import React from "react";
import * as Style from "../Common/StyledComponents";
import APICall from "../../Common/APICall.js";
import Store from "../../Common/Store.js";
import styled from "styled-components";
import { FaTimes, FaPlusCircle, FaLock } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { IoIosArrowForward, IoIosLock, IoIosClose } from "react-icons/io";
import CSLLoader from "../Common/CSLLoader";
import AlertBox from "../Common/AlertBox";
import ConfirmationBox from "../Common/ConfirmationBox";
import CadrsLoader from "../../Common/CadrsLoader.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, thin } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

const FolderContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 55%;
  position: absolute;
  margin: 80px 100px;
  top: 8px;
  z-index: 1001;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 20px #a7a6a6;
`;

const ModalHeaderCloseBtn = styled.div`
  float: right;
  box-sizing: border-box;
  color: #282828;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
`;
const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #046de4;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 100px;
  font-size: 13px;
  height: 39px;
  border-radius: 4px;
  float: right;
  cursor: pointer;
`;

class DocAddToFolderModal extends React.Component {
  Folders = [];
  parentID = 0;
  constructor(props) {
    super(props);
    this.state = {
      folders: [],
      docs: {},
      selectedFolder: null,
      confirm_modal: null,
      documentAction: null,
      confirmMessage: null,
      folder_id: 0,
      parent_id: 0,
      selected_folder: 0,
      folder_order: {},
      ready: false,
      alert_param: null,
      restore: false,
      loading: false
    };
  }
  componentDidMount() {
    this.callDocLibrary();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.callDocLibrary();
      this.Folders = this.state.folders;
    }
  }

  callDocLibrary() {
    let api = new APICall();
    let postData = { command: "get_folders", folder_id: 0 };
    if(this.props.restore){
      postData['folder_id'] = 0;
    }

    api.command(postData, this.processDocuments);
    // let folder_order = this.state.folder_order;
    // folder_order['0'] = 1;
    this.setState({ folder_id: 0});
  }
  removeItemById = (array, id) => {
    return array.filter((item) => item.id !== id);
  }
  processDocuments = (result) => {
    console.log("result", result)
    let folders = result.result;
    if(result.folder_id > 0){
      folders = [...[{id: 'back', name: "...."}], ...folders]
    }
    let folder_order = this.state.folder_order;
    if(result.reorder === 1) folder_order[result.folder_id] = Object.keys(folder_order).length + 1;
    this.setState({ folders: folders, ready: true,docs: this.props.docs, restore: this.props.restore, folder_order: folder_order, parent_id: result.parent_id });
  };

  MoveToFolder = (event) => {
    if (event) event.preventDefault();
    // this.props.moveToFolder(this.state.folder_id);
    let documents = this.state.docs;
    let docs = {};
    let is_visible = 1;
    for (let key in documents) {
      if (documents[key].select && documents[key].type !== -1) {
        is_visible = is_visible * documents[key].visibility;
        docs[documents[key].doc_id] = this.state.folder_id;
      }
    }
    console.log("this.props.docs", is_visible);
    let msg = "Are you sure you want to move the document(s) ?";
    let title = "Confirm";
    let parms = {};
    parms["docs"] = docs;
    parms["visibility"] = is_visible;
    parms["folder_id"] = this.state.folder_id;
    let alert_param = { title: title, message: msg, ok_text: "OK", confirm: true, alertHandler: this.confirmmoveAlertHandler, stack: { parms: parms } };
    this.setState({ alert_param: alert_param });
  };

  confirmmoveAlertHandler = (result, stack) => {
    if (!result || stack.prevent) {
      this.setState({ alert_param: null });
      return;
    }
    this.setState({ alert_param: null, loading: true });
    let parms = stack.parms;
    let docs = parms.docs;
    let documents = Store.getStoreData('docs')
    console.log("this.props.documents1", documents, parms);
    for(let key in documents){
      if(documents[key].type !== -1){
        if(parseInt(docs[documents[key].doc_id]) === parseInt(parms["folder_id"])){
          documents[key].folder_id = parseInt(parms["folder_id"])
        }
      }
    }
    console.log("this.props.documents2", documents);
    Store.updateStore('docs', documents)
    let api = new APICall();
    let postData = {};
    postData["command"] = "movetofolder";
    postData["docs"] = parms["docs"];
    postData["visibility"] = parms["visibility"];
    postData["folder_id"] = parms["folder_id"];
    api.command(postData, this.processDocument);
  };


  RestoreToFolder = (event) => {
    if (event) event.preventDefault();
    let documents = this.state.docs;
    let doc_id = {};
    doc_id["doc"] = {};
    doc_id["folder"] = {};
    for (let key in documents) {
      if (documents[key].select) documents[key].type === -1 ? (doc_id["folder"][documents[key].id] = 1) : (doc_id["doc"][documents[key].doc_id] = 1);
    }
    let msg = "Are you sure you want to move the document(s) ?";
    let title = "Confirm";
    let parms = {};
    parms["docids"] = doc_id;
    parms["folder_id"] = this.state.folder_id;
    let alert_param = { title: title, message: msg, ok_text: "OK", confirm: true, alertHandler: this.confirmRestoreHandler, stack: { parms: parms } };
    this.setState({ alert_param: alert_param });
  }

  confirmRestoreHandler = (result, stack) => {
    if (!result || stack.prevent) {
      this.setState({ alert_param: null });
      return;
    }
    this.setState({ alert_param: null,loading: true });
    let parms = stack.parms;
    let documents = Store.getStoreData('docs');
    let doc = parms["docids"].doc;
    let folder = parms["docids"].folder;

    for(let key in documents){
        if(documents[key].type === -1){
          if(folder[documents[key].id] === 1){
            documents[key].is_deleted = 0
            documents[key].parent_id = parms["folder_id"]
          }
        }else{
            if(doc[documents[key].doc_id] === 1){
              documents[key].is_deleted = 0
              documents[key].folder_id = parms["folder_id"]
            }
        }
    }
    Store.updateStore('docs', documents)


    let api = new APICall();
    let postData = {};
    postData["command"] = "restoretofolder";
    postData["docids"] = parms["docids"];
    postData["folder_id"] = parms["folder_id"];
    console.log("postData restore", postData)
    api.command(postData, this.processrestoreDocument);
  };

  processrestoreDocument = (result) => {
    let alert_param = { title: "Success", message: "The document(s) has been moved", ok_text: "OK", confirm: false, alertHandler: this.AlertrestoreremoveHandler, stack: { id: 0 } };
    /*if ("cards" in result) {
      CadrsLoader.divideCards(result.cards);
    }*/
    CadrsLoader.divideCards();
    this.setState({ alert_param: alert_param, loading: false });
  }

  AlertrestoreremoveHandler = (result, stack) => {
    this.setState({ alert_param: null });
    // this.props.refreshParent();
    this.props.refreshRestoreParent();
  }


  processDocument = (result) => {
    let alert_param = { title: "Success", message: "The document(s) has been moved", ok_text: "OK", confirm: false, alertHandler: this.AlertremoveHandler, stack: { id: 0 } };
    this.setState({ alert_param: alert_param,loading: false });
  };

  AlertremoveHandler = (result, stack) => {
    this.setState({ alert_param: null });
    this.props.refreshParent();
  };

  closeModal = (event) => {
    if (event) event.preventDefault();
    this.props.closeUserDialog();
  };

  handleChange = (event) => {
    event.preventDefault();
    let { folder_id } = this.state;
    folder_id = event.target.value === "back" ? this.state.parent_id : event.target.value;
    let api = new APICall();
    let postData = { command: "get_folders", folder_id: folder_id, reorder: event.target.value === "back" ? 0 : 1 };
    api.command(postData, this.processDocuments);
    let folder_order = this.state.folder_order;
    if(event.target.value === "back"){
      delete folder_order[this.state.folder_id.toString()]
    }
    console.log("folder_order", folder_order)
    this.setState({ folder_id:folder_id, folder_order: folder_order});
  };

  render() {
    let folders_assoc = Store.getStoreData('folders_assoc')
    let y = '>'
    let i = 0;
    for(let k in this.state.folder_order){
      if(k > 0){
        y  = y.concat(" "+folders_assoc[k])
        if(i < Object.keys(this.state.folder_order).length - 1){
          y += ' > '
        }
      }
      i++
    }
    console.log("concat", y, Object.keys(this.state.folder_order).length, folders_assoc)




    if (!this.state.ready) {
      return <CSLLoader />;
    }
    return (
      <FolderContainer>
      {
        (() => {
          if(this.state.loading) return <CSLLoader />;
        })()
      }
        <div>
          <Style.DocHeader>
            <div style={{ fontSize: "14px", float: "left", marginTop: "24px", marginLeft: "10px" }}>Move to Folder</div>
            <ModalHeaderCloseBtn onClick={this.closeModal}>
              <MdClose />
            </ModalHeaderCloseBtn>
          </Style.DocHeader>
          <Style.DocBody>
            <Style.DocFormContainer>
              <Style.ModalNameLabelDiv style={{fontSize: "14px",  marginTop: "24px"}}> {y} </Style.ModalNameLabelDiv>
              <Style.ModalNameLabelDiv>Select Folder</Style.ModalNameLabelDiv>
              <Style.SpacerL></Style.SpacerL>
              <Style.MRModalSelect onChange={this.handleChange} name="folder_id" value={this.state.folder_id}>
                {(() => {
                  let regulatory_status = [<option value="0" disabled selected hidden>></option>]
                  for (let f of this.state.folders) {
                    regulatory_status.push(
                      <option key={f.id} value={f.id}>
                        {f.name}
                      </option>
                    );
                  }
                  return regulatory_status;
                })()}
              </Style.MRModalSelect>
            </Style.DocFormContainer>
          </Style.DocBody>
          <Style.DocFooter>
            <MRButton style={{ top: "20px", right: "140px", position: "absolute", backgroundColor: "#FFFFFF", color: "#757575" }} onClick={this.closeModal}>
              Cancel
            </MRButton>

            {
              (() => {
                if(this.state.restore){
                  return <MRButton style={{ top: "20px", right: "7px", position: "absolute", backgroundColor: "#1A3552", color: "white", width: "115px" }} onClick={this.RestoreToFolder}>Move</MRButton>
                }else{
                  return <MRButton style={{ top: "20px", right: "7px", position: "absolute", backgroundColor: "#1A3552", color: "white", width: "115px" }} onClick={this.MoveToFolder}>Move</MRButton>
                }
              })()
            }

          </Style.DocFooter>
        </div>
        <AlertBox alertParam={this.state.alert_param} />
      </FolderContainer>
    );
  }
}

export default DocAddToFolderModal;
