import React from 'react';
import {FaTimes} from 'react-icons/fa';
import styled from 'styled-components';
import AlertBox from '../Common/AlertBox';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
import Utils from '../../Common/Utils';
import * as Style from '../Common/StyledComponents';
import CslSimpleDropDown from './CslSimpleDropDown';
import APICall from '../../Common/APICall.js';
 import SearchableSelect from 'react-select';
import './SearchableSelect.css';

const EmailContainer = styled.div`
	width: 596px;
	top:100px;
	position: absolute;
	background-color: #f6f9fd;
    border-radius: 2px;
    box-shadow: 0 0 20px #a7a6a6;
	z-index: 1005;
	padding: 0px;
`;

const CancelBtn = styled.button`
    padding: 10px 30px;
	background-color: #ffffff;
	border: 1px solid #EBEBEB;
	border-radius: 2px;
	color: #282828;
	cursor: pointer;	
`;
const SubmitBtn = styled.button`
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 4px;
	padding: 10px 30px;
	cursor: pointer;
	color: #ffffff;
`;
const MRModalLabel = styled.div`
    font-family: "Roboto-Medium", "Roboto Medium", "Roboto", sans-serif;
    font-weight: 600;
    color: #1a3552;
    margin-top: 10px;
    margin-bottom: 10px;
`;
const MRModalInput = styled.input`
    height: 25px;
    width: 95%;
    border: 1px solid #ffffff;
    border-radius: 3px;
    box-shadow: 0 0 4px #c4c4c4;
    padding: 5px;
    padding-right: 10px;
    padding-left: 10px;
    color: #000000;
`;
const MRModalHeader = styled.div`
    display: block;
    box-sizing: border-box;
    width: 100%;
    padding: 40px 40px 15px 40px;
`;
const MRModalHeaderText = styled.div`
    box-sizing: border-box;
    float: left;
    color: #1b3452
    font-weight: 600;
    font-size: 20px;
    font-family: "Roboto-Bold", "Roboto Bold", "Roboto", sans-serif;
    font-weight: 700;
`;
const MRModalHeaderCloseBtn = styled.div`
    box-sizing: border-box;
    float: right;
    font-size: 16px;
    color: #1b3452
    cursor: pointer;
    font-size: 20px;
`;
const MRModalBody = styled.div`
    display: block;
    box-sizing: border-box;
    padding: 10px 40px 0px 40px;
`;

const SaveBtn = styled.button`
	padding: 10px 30px;
	background-color: #37ADA7;
	border: 1px solid #37ADA7;
	border-radius: 2px;
	color: #ffffff;
	cursor: pointer;
	margin-left: 15px;
`; 

const DocFooter = styled.div`
    position: relative;
    background-color: white;
    border-style: solid;
    border-width: 1px;
    border-color: #e9ecef;
    height: 79px;
    width: 100%;
    margin-left: 0px;
    border-bottom-radius: 2px;
`;
const FooterButtonsContainer = styled.div`
	text-align: right;
	padding-top: 25px;
	width: 95%;
`; 

const ScoreBodyContainer = styled.div`
  padding: 10px;
  text-align: left;
  overflow-y: scroll;
`;


class DocumentTypeModal extends React.Component
{
	state = { doctypename: "", isActive:false, alert_param: null, loading: false };
   componentDidMount()
    {
        this.manageDocumentType();
    }

    componentDidUpdate(prevProps)
    {
        if (prevProps !== this.props) {

            this.manageDocumentType();
        }
    }


    manageDocumentType = () => {
        if(this.props.curid !== 0) 
        {         
          this.setState({doctypename: this.props.content.name,isActive:this.props.content.is_active,ready:true});        
        }
        else
        {
          this.setState({doctypename: '',isActive:true,ready:true});
        }
    }


	handleChange = (event) => {
        event.preventDefault();
        this.setState({[event.target.name]: event.target.value});
    }

    isEmptyOrSpaces = (str) => {
      return str === null || str.match(/^ *$/) !== null;
    };

    saveDocType = (event) => {
        event.preventDefault();
        let check = this.isEmptyOrSpaces(this.state.doctypename);
        if (check) {
          let alert_param = { title: "Alert", message: "Document type name can not be empty", ok_text: "OK", confirm: false, alertHandler: this.alertremoveHandler, stack: {} };
          this.setState({ alert_param: alert_param });
          return false;
        } else {
          let postData = { command: "doctype_operation", mode: 'add', name: this.state.doctypename, is_active: this.state.isActive ? 1 : 0};
          if(this.props.curid > 0){
            postData['mode'] = "update"
            postData['id'] = this.props.curid;
          }
          const api = new APICall();
          this.setState({loading: true})
          api.command(postData, this.addCartag);
          console.log(postData)
        }
    }

    addCartag = (result) => {
      this.setState({loading: false})
      this.props.closereloadModal(result);
    };

	handleToggleChangeStatus = (arg) => {
        let {isActive} = this.state;
        isActive = isActive ? false : true;
        this.setState({isActive})
    }

	closeModal = (event) => {
		event.preventDefault();
		this.props.closeModal();
    }
    
    

  

	render()
	{
		if (!this.state.ready) {
			return (<div>Loading...</div>);
		}
		return (
            <div>
		<EmailContainer id="root_cause">
                <MRModalHeader>
                    <MRModalHeaderText>{this.props.headerText} Document Type</MRModalHeaderText>
                    <MRModalHeaderCloseBtn onClick={this.closeModal}><FaTimes /></MRModalHeaderCloseBtn>
                    <div style={{clear: "both"}}></div>                
                </MRModalHeader>    
                <ScoreBodyContainer>        
                <MRModalBody>
                    <MRModalLabel style={{display: "block"}}>NAME <span style={{marginLeft: "5px",color: "red"}}>*</span></MRModalLabel>
                    <MRModalInput name="doctypename" value={this.state.doctypename} onChange={this.handleChange} />
                    <MRModalLabel style={{marginTop: "30px",display: "block"}}>STATUS</MRModalLabel>
                    <Toggle name="isActive"
					    defaultChecked={this.state.isActive} 
					    icons={false}
					    onChange={()=> this.handleToggleChangeStatus('isActive')} />
                </MRModalBody>
                </ScoreBodyContainer>                
                <div style={{clear: "both"}}></div> 
                <DocFooter style={{float: "right",marginTop: "10px"}}>
                        <FooterButtonsContainer>   
                        <CancelBtn onClick={this.closeModal}>Cancel</CancelBtn>
                        <SaveBtn onClick={this.saveDocType}>Submit</SaveBtn>
                        </FooterButtonsContainer>   
                </DocFooter>
			</EmailContainer>
            <AlertBox alertParam = {this.state.alert_param}/>
            </div>
		);
	}
}

export default DocumentTypeModal;