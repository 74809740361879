import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { saveAs } from "file-saver";
import APICall from "../../../Common/APICall.js";
import Store from "../../../Common/Store.js";
let options = [];

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget);
  };
  const [Options, setOptions] = useState([]);
  useEffect(() => {
    if (props.is_main === 1) {
      options = ["View"];
    } else {
      if (props.lock_doc === 0) {
        options = ["View", "Promote to current version"];
      } else {
        options = ["View"];
      }
    }
    if (Store.getStoreData("role") === "team") {
      options = ["View"];
    }
    setOptions(options);
  }, []);

  const handleClose = (option, index) => {
    setAnchorEl(null);
    // alert(index);
    if (index === 0) {
      props.viewDocs();
    } else if (index == 1) {
      props.promoteVersion();
    }
  };

  return (
    <div>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick} style={{ position: "relative" }}>
        <span style={{ position: "absolute", top: 0 }}>
          <MoreVertIcon />
        </span>
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose} PaperProps={{ style: { maxHeight: ITEM_HEIGHT * 4.5, width: "35ch" } }}>
        {Options.map((option, index) => (
          <MenuItem key={option} selected={option === "Pyxis"} onClick={() => handleClose(option, index)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
