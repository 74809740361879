import React from "react";
import styled from "styled-components";
import Store from "../../Common/Store.js";
import { BiFolderPlus } from "react-icons/bi";
import { AiFillFolderAdd, AiFillDelete } from "react-icons/ai";
import { TiArrowBackOutline, TiArrowBack, TiArrowRightThick } from "react-icons/ti";
import {RiPushpin2Fill} from "react-icons/ri";
import { BsGrid, BsList } from "react-icons/bs";
import { FaTrashRestoreAlt } from "react-icons/fa";
import AddFolderModal from "../Document/AddFolderModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, thin } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import cookie from 'react-cookies';


const TopBody = styled.div`
  background-color: #fff;
  border: 1px solid #d7dddf;
  padding: 20px 20px;
  margin-top: 2px;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 700px;
  padding-left: 20px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0.8;
`;

const UploadButton = styled.div`
  background-color: #1a3552;
  color: #ffffff;
  padding: 10px 20px;
  border: 1px solid #1a3552;
  float: left;
  border-radius: 4px;
  cursor: pointer;
  margin-right: -25px;
`;
const Dropdown = styled.select`
  display: block;
  box-sizing: border-box;
  height: 38px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  font-family: Montserrat, sans-serif;
`;

const Label = styled.div`
  padding-bottom: 7px;
  font-weight: bolder;
  margin-right: 25px;
  margin-top: 10px;
`;

class TobHeader extends React.Component {
  state = {
    show_modal: null,
    show_filter: true,
    filters: {},
    view_count: 0,
    listview: true,
    gridview: false,
    view: {listview: true, gridview: false},
    confirm_modal: null,
    documentAction: null,
    confirmMessage: null,
    selected: "0",
    toggle: {pindoc: false, recyclebin: false, recentlydeleted: false}
  };

  setComponentState = () => {
    let filters = {};
    filters["uploaded_asc"] = "Uploaded Date (Ascending)";
    filters["uploaded_desc"] = "Uploaded Date (Descending)";
    filters["modified_asc"] = "Modified Date (Ascending)";
    filters["modified_desc"] = "Modified Date (Descending)";
    let view = cookie.load('view')
    view = typeof view !== 'undefined' ? view : this.state.view;
    console.log("toggle", this.props.toggle)
    let show_filter = (this.props.toggle['recyclebin'] || this.props.toggle['recentlydeleted']) ? true : false;
    // alert(show_filter)
    this.setState({ filters: filters, view: view, show_filter: show_filter});
  };

  componentDidMount = () => {
    this.setComponentState();
  };

  componentDidUpdate = (prev_props) => {
    if (this.props === prev_props) return;
    this.setComponentState();
  };

  AddDocument = (event) => {
    event.preventDefault();
    this.props.AddDocument("showdocument");
  };

  showBulkModal = (event) => {
    event.preventDefault()
    this.props.AddDocument("show_bulk");
  }

  AddFolder = (event) => {
    event.preventDefault();
    this.props.AddFolder('addfolder');
  };

  closereloadModal = (result) => {
    let alert_param = {
      title: "Alert",
      message: result.error_msg,
      ok_text: "OK",
      confirm: false,
      alertHandler: this.sameAlertremoveHandler,
      stack: { id: 0 },
    };
    let document_types =
      result.error_code === 0 ? result.types : this.state.document_types;
    this.setState({
      show_modal: null,
      document_types: document_types,
      alert_param: alert_param,
    });
  };

  closeModal = () => {
    this.setState({ show_modal: null });
  };

  MoveDocstoFolder = (event) => {
    event.preventDefault();
    this.props.MoveDocstoFolder("movetofolder");
  };

  BacktoFolder = (folder_id, parant_id) =>(event) =>  {
    event.preventDefault();
    this.props.backToListing(folder_id, parant_id)
  }

  DeleteDocs = (event) => {
    event.preventDefault();
    this.props.DeleteDocs(0, 0);
  };

  PinnedDocs = (event) => {
    event.preventDefault();
    this.props.PinnedDocs(0);
  };

  RestoreDeletedDocs = (event) => {
    event.preventDefault();
    this.props.restoreDeletedDocs(0);
  }


  toggleview = (n) => {
    if (n == 1) {
      this.props.toggleview(true);
      // this.setState({ listview: true, gridview: false });
      let view = this.state.view;
      view['listview'] = true
      view['gridview'] = false
      cookie.save('view', view)
    } else {
      this.props.toggleview(false);
      let view = this.state.view;
      view['listview'] = false
      view['gridview'] = true
      cookie.save('view', view)
    }
  };

  handleChange = (e) => {
    this.setState({selected: e.target.value})
    this.props.sortapply(e.target.value);
  };
  render() {
    return (
      <div style={{ height: "100%", marginLeft: 10, marginRight: 20 }}>
        {(() => {
          if (this.state.show_modal !== null) {
            return (
              <div>
                <InactiveOverlay style={{ top: "66px" }} />
                <AddFolderModal closereloadModal={this.closereloadModal} closeModal={this.closeModal} />
              </div>
            );
          }
        })()}

        <TopBody>
          <div style={{ float: "left" }}>
          {
            (() => {
              if(Store.getStoreData("role") === "team" || this.state.show_filter){
                return <UploadButton title="Upload document" style={{ color: "#c4c4c4"}}> UPLOAD DOCUMENT</UploadButton>
              }else{
                return <UploadButton onClick={this.AddDocument} title="Upload document"> UPLOAD DOCUMENT</UploadButton>
              }
            })()
          }
          
          
          {
            (() => {
              if(Store.getStoreData("role") === "admin_manager" || Store.getStoreData("role") === "document_manager"){
                return <UploadButton style={{marginLeft: "40px", cursor: "pointer"}} onClick={this.showBulkModal} title="Bulk Upload">BULK UPLOAD</UploadButton>
              }
            })()
          }






          <div style={{display: "flex",paddingLeft: "50px",paddingTop: "10px"}}>
          {
            (() => {
              if(this.props.folder_id === 0 ){
                {/*return <TiArrowBack style={{marginRight: "8px",fontSize: "30px", color: "#c4c4c4"}} title="Back to parent folder" />*/}
                return <FontAwesomeIcon icon={solid("arrow-left")} style={{marginRight: "8px",fontSize: "28px", color: "#c4c4c4"}} title="Back to parent folder" />
              }else{
                {/*return <TiArrowBack style={{marginRight: "8px",fontSize: "30px",cursor: "pointer"}} title="Back to parent folder" onClick={this.BacktoFolder(this.props.folder_id, this.props.parentID)}/>*/}
                return <FontAwesomeIcon icon={solid("arrow-left")} style={{marginRight: "8px",fontSize: "28px",cursor: "pointer"}} title="Back to parent folder" onClick={this.BacktoFolder(this.props.folder_id, this.props.parentID)} />
              }
            })()
          }
          {
            (() => {
              if(Store.getStoreData("role") !== "team" && !this.state.show_filter){
                return <FontAwesomeIcon icon={solid("arrow-right")} style={{ marginRight: "8px", fontSize: "28px", cursor: "pointer", color: "#1a3552" }} title="Move a document to a folder" onClick={this.MoveDocstoFolder} />
              }else{
                  return <FontAwesomeIcon icon={solid("arrow-right")} style={{ marginRight: "8px", fontSize: "28px", color: "#c4c4c4" }}/>
              }
            })()
          }
          {
            (() => {
              if(Store.getStoreData("role") !== "team" && !this.state.show_filter){
                return <FontAwesomeIcon icon={solid("folder-plus")} style={{ marginRight: "8px", fontSize: "28px", cursor: "pointer", color: "#1a3552" }} title="Add a new folder" onClick={this.AddFolder} />
              }else{
                return <FontAwesomeIcon icon={solid("folder-plus")} style={{ marginRight: "8px", fontSize: "28px", color: "#c4c4c4" }}/>
              }
            })()
          }
          {
            (() => {
              if (Store.getStoreData("role") === "admin_manager" && !this.state.show_filter) {
                return(<FontAwesomeIcon icon={solid("trash-can")}  style={{ marginRight: "8px",fontSize: "28px",cursor: "pointer" , color: "#1a3552"}} title="Delete document/folder" onClick={this.DeleteDocs}/>)
              }else{
                return(<FontAwesomeIcon icon={solid("trash-can")}  style={{ marginRight: "8px",fontSize: "28px",color: "#c4c4c4"}} title="Delete document/folder" />)
              }
            })()
          }
          {
            (() => {
              if (Store.getStoreData("role") === "admin_manager" && this.state.show_filter) {
                return(<FontAwesomeIcon icon={solid("trash-can-arrow-up")} style={{ marginRight: "8px",fontSize: "28px",cursor: "pointer" , color: "#1a3552"}} title="Restoring a document/folder removes it from the Recycle Bin" onClick={this.RestoreDeletedDocs}/>)
              }else{
                return(<FontAwesomeIcon icon={solid("trash-can-arrow-up")} style={{ marginRight: "8px",fontSize: "28px",color: "#c4c4c4"}} title="Delete document/folder" />)
              }
            })()
          }
          
          {
            (() => {
              if(this.state.show_filter){
                return <FontAwesomeIcon icon={solid("thumbtack")} style={{ marginRight: "8px",fontSize: "28px",color: "#c4c4c4"}} title="Pin documents" />
              }else{
                return <FontAwesomeIcon icon={solid("thumbtack")} style={{marginRight: "8px",fontSize: "28px",cursor: "pointer" , color: "#1a3552"}} title="Pin documents" onClick={this.PinnedDocs}/>
              }

            })()
          }
          </div>
          </div>
          <div style={{ float: "right" }}>
            <div style={{display: "flex",paddingLeft: "50px",paddingTop: "10px" }}>
              <Label>Sort</Label>
              <Dropdown value={this.state.selected} onChange={(e) => this.handleChange(e)}>
                <option value="0">Most Recent</option>
                {Object.keys(this.state.filters).map((id) => {
                  return (
                    <option key={id} value={id}>
                      {this.state.filters[id]}
                    </option>
                  );
                })}
              </Dropdown>

              <div style={{ display: "flex" }}>
                <BsList style={{ marginRight: "5px",  marginLeft: "20px", fontSize: "20px", cursor: "pointer", color: this.state.view['listview'] ? "" : "#c4c4c4"}} onClick={() => this.toggleview(1)}/>
                <BsGrid style={{ marginRight: "5px",fontSize: "20px",cursor: "pointer",color: this.state.view['gridview'] ? "" : "#c4c4c4" }} onClick={() => this.toggleview(2)}/>
              </div>

              
      
            </div>
          </div>
          
        </TopBody>
      </div>
    );
  }
}

export default TobHeader;
