import React, { Component } from "react";

import Iframe from "react-iframe";
import * as Style from "../../Common/StyledComponents";
import APICall from "../../../Common/APICall.js";
import Store from "../../../Common/Store.js";
import styled from "styled-components";
import AlertBox from "../../Common/AlertBox";
// import Dropzone from "../../Common/Dropzone.js";
import Dropzone from "../../Dropzone/Dropzone.js"
import { saveAs } from "file-saver";
import Utils from "../../../Common/Utils.js";
import ChildTask from "../Followup/ChildTask";
import ApprovarList from "../Approvar/ApprovarList";
import SendAttestationModal from "../Attestation/SendAttestationModal.js";
import CSLTable from "../../Common/CSLTable";
import CSLLoader from "../../Common/CSLLoader";
import HtmlEditor from '../../Common/HtmlEditor';
import DatePicker from "react-date-picker";

const moment = require("moment");
const last_action_time = moment().unix();

const FolderContainer = styled.div`
  display: block;
  box-sizing: border-box;
  height: auto;
  width: 60%;
  position: fixed;
  margin: 80px 50px;
  top: 8px;
  z-index: 1001;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 20px #a7a6a6;
`;

const ModalHeaderCloseBtn = styled.div`
  float: right;
  box-sizing: border-box;
  color: #282828;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
`;
const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #046de4;
  color: #ffffff;
  border: 1px solid #37ada7;
  font-size: 13px;
  height: 39px;
  border-radius: 4px;
  float: right;
  cursor: pointer;
`;
const MRButtonActive = styled.button`
  box-sizing: border-box;
  background-color: #19334d;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 127px;
  font-size: 13px;
  height: 39px;
  border-radius: 4px;
  float: right;
  margin-top: -30px;
  cursor: pointer;
`;
const SectionTab = styled.div`
  float: left;
  width: 143px;
  height: 16px;
  cursor: pointer;
  background-color: #1a3552;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: #c4c4c4;
  font-weight: 600;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-left: 4px;
`;
const FloatLeft = styled.div`
  float: left;
  margin-bottom: 10px;
  width: 40%
  text-align: left;
`;
const FloatLeftLabel = styled.div`
  float: left;
  margin-bottom: 10px;
  color: #929292;
  font-size: 13px;
  text-align: left;
`
const FloatRight = styled.div`
  float: right;
  margin-bottom: 10px;
  width: 60%
  text-align: left;
`;
const FullDiv = styled.div`
  margin-bottom: 10px;
  width: 100%
  overflow-y: auto;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 3000px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;
const ContactRadio = styled.input`
  border: 1px solid #ffffff;
  border-radius: 3px;
  color: #979797;
  font-family: montserrat;
  font-size: 14px;
  font-weight: 600;
`;
const RadioBlock = styled.div`
  margin-top: -5px;
`;
const SelectedRadio = styled.div`
  background-color: #37ada7;
  border: 1px solid #1f615e;
  padding: 5px 10px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
`;
const UnselectedRadio = styled.div`
  background-color: #f4f4f4;
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  color: #262626;
  display: inline-block;
  cursor: pointer;
`;

const OverviewInfoBody = styled.div`
  padding: 15px;
  background-color: #ffffff;
  border: 1px solid #d7dddf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const NoWrap = styled.div`
  white-space: nowrap;
  width: 400px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CSLDateCover = styled.div`
margin-top: 50px;
width: 160px;
border-radius: 5px;
background-color: #ffffff;
height: 25px;
padding-top: 10px;
// padding-left: 10px;
`;

class Property extends Component {
  state = { ready: false, fileSize: null, doc: null, alert_param: null, binFiles: [], cur_files_json: null,
    addnew: true, doc_info: { name: "", doc_type: 0, doc_owner: 0, description: "", file_name: "", lock_doc: 0 },
    types: {}, contacts: {}, edit: false, childtask: [], is_support: false, logo: null, show_attestation: null,
    childtasks: [], all_company: true, gcs: {}, show_edit: true, saving: false,
    loading: false, doc_version: null, users: [], review_date: null, approvars: []
  };

  constructor(props) {
    super(props);
  }

  changeEdit = () => {
    // let edit = this.state.edit;
    let edit = this.state.edit ? false : true;
    this.setState({ edit });
  };

  componentDidMount() {
    this.setComponentState(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props === prevProps) return;
    this.setComponentState(this.props);
  }

  filesLoaded = (files) => {
    let contact_id = Store.getStoreData("contact").ID;
    for (let i = 0; i < files.length; i++) {
      files[i].name = files[i].name.replace("&", "");
      if (!("user_id" in files[i])) {
        files[i].user_id = contact_id;
        files[i].upload_date = last_action_time;
      }
    }
    let doc_info = this.state.doc_info;
    doc_info["file_name"] = files.length > 0 ? files[0].name : "";
    this.setState({
      binFiles: files,
      addnew: false,
      doc_info: doc_info,
    });
  };

  alertRemove = (result, stack) => {
    this.setState({ alert_param: null });
  };




  updateDocument = (event) => {
    event.preventDefault();
    if (this.state.doc_info["file_name"] !== "") {
      /*doc["uploaded_doc"] = this.state.binFiles;
      doc["file_name"] = this.state.doc_info["file_name"];
      this.setState({saving:true})
      let bin_files = this.state.binFiles;
      this.refs.dropZone.uploadFilesFromChild(bin_files);*/


      let doc_version = this.state.doc_version
      if(isNaN(doc_version)){
        let alert_param = { title: "Alert", message: "The document version field is a numerical field and should contain up to 2 decimal places, for example: 1, 1.0 or 1.00", ok_text: "OK", confirm: false, alertHandler: this.alertRemove, stack: { id: 0 } };
        this.setState({ alert_param: alert_param });
        return false;
      }
      let index = doc_version.toString().indexOf(".");
      if(index > 0 && (doc_version.length - index - 1) > 2){
        let alert_param = { title: "Alert", message: "The document version field is a numerical field and should contain up to 2 decimal places, for example: 1, 1.0 or 1.00", ok_text: "OK", confirm: false, alertHandler: this.alertRemove, stack: { id: 0 } };
        this.setState({ alert_param: alert_param });
        return false;
      }

      let bin_files = this.state.binFiles;
      let dnld = bin_files[0].bin_file.replace(/ /g, "+");
      let binaryString = window.atob(dnld);
      var fileSize = binaryString.length;
      if(fileSize > 5000000){
        let alert_param = { title: "Alert", message: "This file may take some time to upload and for users to download. Would you like to continue to upload this document?", ok_text: "OK", confirm: true, alertHandler: this.processORcancel, stack: {} };
        this.setState({ alert_param: alert_param });
      }else{
        this.refs.dropZone.uploadFilesFromChild(bin_files);
      }
    }else{
      let doc = {};
       let description = Store.getStoreData('description');
       if(description === null){
           description = this.state.doc_info['description'];
       }
       let description_text = btoa(window.encodeURIComponent(description));
      doc["version_id"] = this.state.doc_info["version_id"];
      doc["doc_id"] = this.state.doc_info["doc_id"];
      doc["name"] = this.state.doc_info["name"];
      doc["description"] = description_text;
      doc["doc_owner"] = this.state.doc_info["doc_ownerin_no"];
      doc["typeinno"] = this.state.doc_info["typeinno"];
      doc["v_length"] = this.state.doc_info["versions"] + 1;
      doc["doc_version"] = this.state.doc_version;

      let gcs = this.state.gcs;
      let g = {};
      for (let key in gcs) {
        if (this.state.all_company) {
          g[key] = 1;
        } else {
          if (gcs[key].selected) g[key] = 1;
        }
      }
      doc["gc_ids"] = g;
      doc["lock_doc"] = this.state.doc_info["lock_doc"];
      doc["visibility"] = this.state.doc_info["visibility"];

      if(this.state.review_date!==null){
        let curmonth = parseInt(this.state.review_date.getMonth());
        let currmonth = curmonth + 1;
        var dueDateStr = currmonth >= 10 ? this.state.review_date.getFullYear() + "/" + currmonth + "/" : this.state.review_date.getFullYear() + "/0" + currmonth + "/";
        dueDateStr += this.state.review_date.getDate() < 10 ? "0" + this.state.review_date.getDate() : this.state.review_date.getDate();
        doc["review_date"] = dueDateStr;
      }



      let postData = { command: "upload_document_version" };
      postData["document_info"] = doc;
      postData["last_actin_time"] = Store.getStoreData('doc_last_actin_time');
      // console.log("postData", postData);
      let api = new APICall();
      this.setState({loading: true });
      api.command(postData, this.processDocument);
    }

  };

  processORcancel = (result, stack) => {
    if (!result || stack.prevent) {
      this.setState({ alert_param: null });
      return;
    }
    this.setState({ alert_param: null });
    let bin_files = this.state.binFiles;
    this.refs.dropZone.uploadFilesFromChild(bin_files);
  }

  onUploadComplete = (files) => {
    this.setState({loading: true });
    let returnedFiles = JSON.parse(files);
    let bin_files = this.state.binFiles;
    if (typeof bin_files !== 'undefined' && bin_files.length > 0) {
      bin_files.forEach((file) => {
        let fileName = file.name;
        let refID = returnedFiles.find((o) => o.inserted_file_name == fileName)['ref_id'];
        delete file.bin_file;
        file.ref_id = refID;
      });
    }

    let doc = {};
    doc["uploaded_doc"] = bin_files;
    doc["file_name"] = this.state.doc_info["file_name"];
    doc["version_id"] = this.state.doc_info["version_id"];
    doc["doc_id"] = this.state.doc_info["doc_id"];
    doc["name"] = this.state.doc_info["name"];
    let description = Store.getStoreData('description');
    if(description === null){
        description = this.state.doc_info['description'];
    }
    let description_text = btoa(window.encodeURIComponent(description));



    doc["description"] = description_text;
    doc["doc_owner"] = this.state.doc_info["doc_ownerin_no"];
    doc["typeinno"] = this.state.doc_info["typeinno"];
    doc["v_length"] = this.state.doc_info["versions"] + 1;
    doc["doc_version"] = this.state.doc_version;

    let gcs = this.state.gcs;
    let g = {};
    for (let key in gcs) {
      if (this.state.all_company) {
        g[key] = 1;
      } else {
        if (gcs[key].selected) g[key] = 1;
      }
    }
    doc["gc_ids"] = g;
    doc["lock_doc"] = this.state.doc_info["lock_doc"];
    doc["visibility"] = this.state.doc_info["visibility"];

    if(this.state.review_date!==null){
      let curmonth = parseInt(this.state.review_date.getMonth());
      let currmonth = curmonth + 1;
      var dueDateStr = currmonth >= 10 ? this.state.review_date.getFullYear() + "/" + currmonth + "/" : this.state.review_date.getFullYear() + "/0" + currmonth + "/";
      dueDateStr += this.state.review_date.getDate() < 10 ? "0" + this.state.review_date.getDate() : this.state.review_date.getDate();
      doc["review_date"] = dueDateStr;
    }

    let postData = { command: "upload_document_version" };
    postData["document_info"] = doc;
    postData["last_actin_time"] = Store.getStoreData('doc_last_actin_time');
    console.log("postData", postData);
    let api = new APICall();
    api.command(postData, this.processDocument);

  }


  processDocument = (result) => {
    let doc = result.doc;
    let doc_last_actin_time = doc.sort((a, b) => b.last_action_time - a.last_action_time)[0].last_action_time;
    Store.updateStore("doc_last_actin_time", doc_last_actin_time)
    let documents = Store.getStoreData('docs');

    for(let key in documents){
      if(parseInt(documents[key].doc_id) === parseInt(result.document_id)){
        documents[key] = doc[0]
      }
    }
    Store.updateStore('docs', documents);


    let alert_param = { title: "Success", message: result.error_msg, ok_text: "OK", confirm: false, alertHandler: this.afterUserUpdate, stack: { id: 0 } };
    this.setState({ alert_param: alert_param, loading: false });
  };
  afterUserUpdate = (result, stack) => {
    this.setState({ alert_param: null, edit: false });
    // window.location.reload();
    Store.updateStore('description', null)
    this.props.refreshParent();
  };

  handleDownload = (version_id) => (event) => {
    event.preventDefault();
    let api = new APICall();
    let postData = { command: "get_doc", doc_id: version_id };
    console.log("Attachment", postData);
    api.command(postData, this.processDownloadFile);
  };
  handleRemove(image_id, task_id) {
    let api = new APICall();
    let postData = { command: "remove_followup_attachment", image_id: image_id, task_id: task_id };
    console.log("postData", postData);
  }

  processDownloadFile = (result) => {
    let dbinfile = result.result;
    console.log("Attachment", dbinfile);
    let bin_file = JSON.parse(dbinfile.bin_file);
    let dnld = bin_file[0].bin_file.replace(/ /g, "+");
    let binaryString = window.atob(dnld);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, bin_file[0].name);
  };

  setComponentState = (propdata) => {
    console.log("propdata", propdata);
    // let doc_types = Store.getStoreData("selected_doc_types");
    let doc_types = Store.getStoreData("types");
    let contact_assoc = Store.getStoreData("contact_assoc");
    let list_contacts = Store.getStoreData("listcontacts");
    // if(!propdata.doc.doc_owner in contact_assoc){
    //   contact_assoc[propdata.doc.doc_owner] = list_contacts[propdata.doc.doc_owner]
    // }
    if(!contact_assoc[propdata.doc.doc_owner]){
      contact_assoc[propdata.doc.doc_ownerin_no] = propdata.doc.doc_owner
    }

    console.log("contact_assoc 123 ==>", contact_assoc, propdata.doc);

    let gc_users = Store.getStoreData("gc_users");
    let contact = Store.getStoreData("contact");
    let doc_info = this.state.doc_info;
    doc_info["version_id"] = propdata.doc.version_id;
    doc_info["doc_id"] = propdata.doc.doc_id;
    doc_info["name"] = propdata.doc.name;
    doc_info["doc_type"] = propdata.doc.type;
    doc_info["doc_owner"] = propdata.doc.doc_owner;
    doc_info["description"] = propdata.doc?.description;;
    doc_info["uploaded"] = propdata.doc.uploaded;
    doc_info["updated_at"] = propdata.doc.updated_at;
    doc_info["updated_by"] = propdata.doc.updated_by;
    doc_info["doc_ownerin_no"] = propdata.doc.doc_ownerin_no;
    doc_info["typeinno"] = propdata.doc.typeinno;
    doc_info["created_by"] = propdata.doc.created_by;
    doc_info["versions"] = propdata.versions;
    doc_info["doc_version"] = propdata.doc.doc_version;
    doc_info["is_attestation"] = propdata.doc.is_attestation;
    doc_info["attestation_id"] = propdata.doc.attestation_id;

    doc_info["is_pinned"] = propdata.doc.is_pinned;
    doc_info["gcs"] = propdata.doc.gcs;
    doc_info["lock_doc"] = propdata.doc.lock_doc;
    doc_info["visibility"] = propdata.doc.visibility;
 //   Store.updateStore("description",doc_info["description"]);
    // let edit = Store.getStoreData('role') === "admin_manager" || doc_info["lock_doc"]
    let show_edit = this.state.show_edit;
    if (propdata.doc.lock_doc === 1) {
      show_edit = Store.getStoreData("role") === "admin_manager" ? true : false;
    }


    let doc_version = propdata.doc.doc_version;
    let index = doc_version.indexOf(".");
    if(index > 0){
      switch(doc_version.length - index - 1){
          case 1:
            doc_version = (parseFloat(doc_version) + 0.1).toFixed(1);
          break;
          case 2:
            doc_version = (parseFloat(doc_version) + 0.01).toFixed(2);
          break;
      }
    }else{
      doc_version = parseInt(doc_version) + 1;
    }

    // alert(edit);

    let companies_assoc = Store.getStoreData("companies_assoc");
    let user_assignments = Store.getStoreData("user_assignments");
    let owner_gcs = Object.keys(user_assignments).length === 0 ? {} : user_assignments[propdata.doc.doc_ownerin_no.toString()].companies;
    let gcs = {};
    let gc = JSON.parse(propdata.doc.gcs);
    let user_roles = Store.getStoreData('user_roles');
    let is_manager = user_roles[propdata.doc.doc_ownerin_no.toString()] === "admin_manager" ? true : false;
    for (let k in companies_assoc) {
      if(is_manager){
        gcs[k] = { id: k, name: companies_assoc[k].company_name, selected: (k in gc) ? true: false };
      }else{
        if (k in owner_gcs) {
          gcs[k] = { id: k, name: companies_assoc[k].company_name, selected: k in gc ? true : false };
        }
      }
    }
    doc_info["gc_ids"] = gcs;
    let is_support = this.props.is_support;
    let logo = this.props.logo;
    // this.listFollowupTasks(propdata.doc.version_id)
    let childtasks = propdata.childTasks;
    let approvars = propdata.approvars;

    let show_all = Store.getStoreData("role") === "admin_manager" ? true : false;
    let user_list = {};
    console.log("contact_assoc ==>", contact_assoc);
    if (Object.keys(gc_users).length !== 0) {
      for (let key in contact_assoc) {
        if (show_all || this.hasCommonCompanyCheckFirst(contact.ID, key, gc_users)) {
          if (key in gc_users) {
            user_list[key.toString()] = contact_assoc[key.toString()];
          }
        }
      }
    } else {
      user_list = contact_assoc;
    }

    let users = this.sortAssociativeArray(user_list);
    users.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1; // a should come before b
      }
      if (nameA > nameB) {
        return 1; // a should come after b
      }
      return 0; // names are equal, no change in order
    });



    let review_date = ("review_date" in propdata.doc && propdata.doc["review_date"]!== null) ? new Date(propdata.doc["review_date"]) : this.state.review_date;
    console.log("users", review_date," docinfo-->",doc_info)

    this.setState({ ready: true, fileSize: propdata.size, doc_info: doc_info, types: doc_types, contacts: user_list, is_support: is_support, logo: logo, childtasks: childtasks, show_edit: show_edit, gcs: gcs, doc_version:doc_version, users: users, review_date: review_date, approvars: approvars  });
  };

  sortAssociativeArray = (obj) => {
    let x = [];
    for (let key in obj) {
      x.push({id: key, name: obj[key]});
    }
    return x;
  }

  hasCommonCompanyCheckFirst = (manager_id, user_id, gc_users) => {
    if (Object.keys(gc_users).length === 0) return true;
    if (manager_id === "") return true;
    let manager_companies = gc_users[manager_id];
    let user_companies = gc_users[user_id];
    for (let cid in manager_companies) {
      for (let uid in user_companies) {
        if (cid.toString() === uid.toString()) return true;
      }
    }
    return false;
  };

  listFollowupTasks = (doc_id) => {
    const postData = { command: "list_followup_tasks", document_id: doc_id };
    let api = new APICall();
    api.command(postData, this.arrangeFollowupTasks);
  };

  arrangeFollowupTasks = (result) => {
    this.setState({ childtasks: result.tasks });
  };

  listApprovals = (doc_id) => {
    const postData = { command: "list_approvals", doc_id: doc_id };
    let api = new APICall();
    api.command(postData, this.arrangeApprovals);
  }

  arrangeApprovals = (result) => {
    console.log("result", result)
    this.setState({ approvars: result.result });
  };

  handleChange = (event) => {
    if (event) event.preventDefault();
    let doc_info = this.state.doc_info;
    doc_info[event.target.name] = event.target.value;
    this.setState({ doc_info: doc_info });
  };

  handleinputChange = (event) => {
    if (event) event.preventDefault();
    let doc_info = this.state.doc_info;
    doc_info[event.target.name] = event.target.value;
    this.setState({ doc_info: doc_info });
    const input = event.target;
    input.style.height = "auto";
    input.style.height = input.scrollHeight + "px";
  }

  handleVersionChange = (event) => {
    if (event) event.preventDefault();
    let doc_version = event.target.value;
    this.setState({ doc_version: doc_version });
  }

  handleChangeDocOwner = (event) => {
    if (event) event.preventDefault();
    let companies_assoc = Store.getStoreData("companies_assoc");
    let user_assignments = Store.getStoreData("user_assignments");
    let doc_info = this.state.doc_info;
    doc_info[event.target.name] = event.target.value;
    let owner_gcs = Object.keys(user_assignments).length === 0 ? {} : user_assignments[event.target.value.toString()].companies;
    let gcs = {};
    let user_roles = Store.getStoreData('user_roles');
    let is_manager = user_roles[event.target.value.toString()] === "admin_manager" ? true : false;
    for (let k in companies_assoc) {
      if(is_manager){
        gcs[k] = { id: k, name: companies_assoc[k].company_name, selected: false };
      }else{
        if (k in owner_gcs) {
          gcs[k] = { id: k, name: companies_assoc[k].company_name, selected: false };
        }
      }
    }
    this.setState({ doc_info: doc_info, gcs: gcs });
  };

  sendAttestation = (event) => {
    event.preventDefault();
    // let alert_param = { title: "Warning", message: "Would you like to launch an attestation for this document? You must be an Attestation Manager to add new attestations.", ok_text: "Yes", cancel_text: "No", confirm: true, alertHandler: this.confirAttestationProcessor, stack: { doc_id: 0 } };
    // this.setState({ alert_param: alert_param });
    let is_attest_access = Store.getStoreData('is_attest_access')
    if(!is_attest_access){
      let alert_param = { title: "Warning", message: "You do not have the permissions to add attestations.", ok_text: "OK", confirm: false, alertHandler: this.alertRemove, stack: { doc_id: 0 } };
      this.setState({ alert_param: alert_param });
      return false;
    }
    this.props.openAttestationModal(this.state.doc_info["version_id"]);
  };

  alertRemove = (result, stack) => {
    this.setState({ alert_param: null });
  };

  sendAttestationLink = (event) => {
    event.preventDefault();
    // let alert_param = { title: "Warning", message: "Would you like to view the Attestation for this document? You must be an Attestation Manager to view open attestations.", ok_text: "Yes", cancel_text: "No", confirm: true, alertHandler: this.confirAttestationLink, stack: { doc_id: 0 } };
    // this.setState({ alert_param: alert_param });
    let is_attest_access = Store.getStoreData('is_attest_access')
    if(!is_attest_access){
      let alert_param = { title: "Warning", message: "You do not have the permissions to add attestations.", ok_text: "OK", confirm: false, alertHandler: this.alertRemove, stack: { doc_id: 0 } };
      this.setState({ alert_param: alert_param });
      return false;
    }
    let doc_info = this.state.doc_info;
    const url = process.env.REACT_APP_ATTEST_URL + doc_info["attestation_id"];
    window.location = url;
  };

  confirAttestationLink = (result, stack) => {
    if (!result || stack.prevent) {
      this.setState({ alert_param: null });
      return;
    }
    let doc_info = this.state.doc_info;
    const url = process.env.REACT_APP_ATTEST_URL +doc_info["attestation_id"];
    window.location = url;
    this.setState({ alert_param: null });
  };

  confirAttestationProcessor = (result, stack) => {
    if (!result || stack.prevent) {
      this.setState({ alert_param: null });
      return;
    }
    this.setState({ alert_param: null });
    this.props.openAttestationModal(this.state.doc_info["version_id"]);
  };

  closeUserDialog = () => {
    this.setState({ show_attestation: null });
  };

  handleRadio = (event) => {
    let doc_info = this.state.doc_info;
    doc_info["visibility"] = event.target.name === "public" ? 0 : 1;
    this.setState({ doc_info: doc_info });
  };
  switchRadio = (event) => {
    event.preventDefault();
    let all_company = this.state.all_company ? false : true;
    this.setState({ all_company: all_company });
  };
  selectGc = (event) => {
    event.preventDefault();
    let gc_id = event.target.value;
    let gcs = this.state.gcs;
    gcs[gc_id].selected = gcs[gc_id].selected ? false : true;
    this.setState({ gcs: gcs });
  };
  handlelockRadio = (event) => {
    let doc_info = this.state.doc_info;
    doc_info["lock_doc"] = event.target.name === "no" ? 0 : 1;
    this.setState({ doc_info: doc_info });
  };
  tableData = () => {
    let table = { data: [], columns: [] };
    let gcs = this.state.gcs;

    table.columns = [
      { Header: "Name", accessor: "name", headerStyle: { textAlign: "left" }, style: { display: "flex", alignItems: "center", justifyContent: "left" } },
      {
        Header: "Status",
        accessor: "status",
        width: 80,
        headerStyle: { textAlign: "left" },
        Cell: (row) => (
          <div>
            <input type="checkbox" checked={row.original.selected} value={row.original.id} onClick={this.selectGc} />
          </div>
        ),
        style: { display: "flex", alignItems: "center", justifyContent: "left" },
      },
    ];

    for (let key in gcs) {
      let elem = {
        name: gcs[key].name,
        id: key,
        selected: "selected" in gcs[key] ? gcs[key].selected : false,
      };
      table.data.push(elem);
    }
    return table;
  };

  onReviewdateChange = (date) => {
    this.setState({ review_date: date});
  }

  render() {
    console.log("this.state property", this.state)
    if (!this.state.ready) return <div></div>;
    return (
      <div style={{ float: "left", width: "100%", padding: "10px" }}>
      {
        (() => {
          if(this.state.loading) return <CSLLoader />;
        })()
      }
        <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
          <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Document Name</FloatLeft>
          {(() => {
            if (this.state.edit) {
              return (
                <span style={{ color: "#11ABA6", paddingLeft: "40px", cursor: "pointer" }} onClick={this.changeEdit}>
                  <u>Back</u>
                </span>
              );
            }
          })()}
          {(() => {
            if (this.state.edit) {
              return <Style.ModalInput type="text" name="name" onChange={this.handleChange} value={this.state.doc_info["name"]} />;
            } else {
              return (
                <FloatRight style={{ fontSize: "12px", display: "flex", fontWeight: "400", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>
                  <NoWrap style={{width:this.props.is_support ? "350px" : "1000px"}}>{this.state.doc_info["name"]}</NoWrap>

                  {(() => {
                    if (Store.getStoreData("role") !== "team" && this.state.show_edit) {
                      return (
                        <span style={{ color: "#11ABA6", paddingLeft: "40px", cursor: "pointer" }} onClick={this.changeEdit}>
                          <u>Edit</u>
                        </span>
                      );
                    }
                  })()}
                </FloatRight>
              );
            }
          })()}
        </Style.ModalNameLabelDiv>
        <Style.SpacerL></Style.SpacerL>

        <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
          <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Version</FloatLeft>
          <FloatRight style={{ fontSize: "12px", fontWeight: "400", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>{this.state.doc_info["doc_version"]}</FloatRight>
        </Style.ModalNameLabelDiv>
        <Style.SpacerL></Style.SpacerL>

        <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
          <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Document Type</FloatLeft>
          {(() => {
            if (this.state.edit) {
              return (
                <Style.MRModalSelect onChange={this.handleChange} name="typeinno" value={this.state.doc_info["typeinno"]}>
                  {(() => {
                    if (this.state.types.length !== 0) {
                      let doc_types = [];
                      for (let key in this.state.types) {
                        doc_types.push(
                          <option key={this.state.types[key].id} value={this.state.types[key].id}>
                            {this.state.types[key].name}
                          </option>
                        );
                      }
                      return doc_types;
                    }
                  })()}
                </Style.MRModalSelect>
              );
            } else {
              return <FloatRight style={{ fontSize: "12px", fontWeight: "400", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>{this.state.doc_info["doc_type"]}</FloatRight>;
            }
          })()}
        </Style.ModalNameLabelDiv>
        <Style.SpacerL></Style.SpacerL>
        <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
          <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Uploaded By</FloatLeft>
          <FloatRight style={{ fontSize: "12px", fontWeight: "400", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>{this.state.doc_info["created_by"]}</FloatRight>
        </Style.ModalNameLabelDiv>
        <Style.SpacerL></Style.SpacerL>
        <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
          <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Upload Date</FloatLeft>
          <FloatRight style={{ fontSize: "12px", fontWeight: "400", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>{this.state.doc_info["uploaded"]}</FloatRight>
        </Style.ModalNameLabelDiv>
        <Style.SpacerL></Style.SpacerL>
        <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
          <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>File Size</FloatLeft>
          <FloatRight style={{ fontSize: "12px", fontWeight: "400", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>{this.state.fileSize}</FloatRight>
        </Style.ModalNameLabelDiv>
        <Style.SpacerL></Style.SpacerL>
        <div style={{ clear: "both" }}></div>
        <Style.DivLine></Style.DivLine>
        <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
          <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Document Owner</FloatLeft>
          {(() => {
            if (this.state.edit) {
              return (
                <Style.MRModalSelect onChange={this.handleChangeDocOwner} name="doc_ownerin_no" value={this.state.doc_info["doc_ownerin_no"]}>
                {
                  (() => {
                    if(this.state.users.length !== 0) {
                      let drop_down = [];
                      for(let d of this.state.users) {
                        drop_down.push(<option key={d.id} value ={d.id}>{d.name}</option>)
                      }
                      return drop_down
                    }
                  })()
                }
                </Style.MRModalSelect>
              );
            } else {
              return <FloatRight style={{ fontSize: "12px", fontWeight: "400", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>{this.state.doc_info["doc_owner"]}</FloatRight>;
            }
          })()}
        </Style.ModalNameLabelDiv>
        <Style.SpacerL></Style.SpacerL>

        {
          (() => {
          if (this.state.show_edit && this.state.edit) {
            return (
              <div>
                <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
                  <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Visibility</FloatLeft>
                  <FloatRight style={{ fontSize: "12px", fontWeight: "400", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>
                    <ContactRadio type="radio" value="0" name="public" checked={this.state.doc_info["visibility"] === 0 ? true : false} onClick={this.handleRadio} /> Public
                    <ContactRadio type="radio" value="1" name="private" checked={this.state.doc_info["visibility"] === 1 ? true : false} onClick={this.handleRadio} /> Private
                  </FloatRight>
                </Style.ModalNameLabelDiv>
                <Style.SpacerL></Style.SpacerL>

                {
                  (() => {
                  if (Store.getStoreData("is_gc") && (Store.getStoreData("role") === "admin_manager" || Store.getStoreData("role") === "document_manager")) {
                    return (
                      <div>
                        <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
                          <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Apply to all companies</FloatLeft>
                          <FloatRight style={{ fontSize: "12px", fontWeight: "400", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>
                            {(() => {
                              if (this.state.all_company) {
                                return (
                                  <RadioBlock>
                                    <SelectedRadio>Yes</SelectedRadio>
                                    <UnselectedRadio onClick={this.switchRadio}>No</UnselectedRadio>
                                  </RadioBlock>
                                );
                              } else {
                                return (
                                  <RadioBlock>
                                    <UnselectedRadio onClick={this.switchRadio}>Yes</UnselectedRadio>
                                    <SelectedRadio>No</SelectedRadio>
                                  </RadioBlock>
                                );
                              }
                            })()}
                          </FloatRight>
                        </Style.ModalNameLabelDiv>

                        <Style.HomeText style={{ float: "left", marginLeft: "0px", marginBottom: "10px" }}>Companies are restricted based on the document owner's group company associations</Style.HomeText>
                        <Style.SpacerL></Style.SpacerL>
                        <FullDiv>
                          {(() => {
                            if (!this.state.all_company) {
                              return (
                                <OverviewInfoBody>
                                  <CSLTable add={false} processData={this.tableData} tableRows={10} offset={true} />
                                </OverviewInfoBody>
                              );
                            }
                          })()}
                        </FullDiv>
                      </div>
                    );
                  }
                })()
              }
                {
                  (() => {
                  if (Store.getStoreData("role") === "admin_manager") {
                    return (
                      <Style.ModalNameLabelDiv>
                        <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Lock document</FloatLeft>
                        <FloatRight style={{ fontSize: "12px", fontWeight: "400", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>
                          <ContactRadio type="radio" value="0" name="no" checked={this.state.doc_info["lock_doc"] === 0 ? true : false} onClick={this.handlelockRadio} /> No
                          <ContactRadio type="radio" value="1" name="yes" checked={this.state.doc_info["lock_doc"] === 1 ? true : false} onClick={this.handlelockRadio} /> Yes
                        </FloatRight>
                      </Style.ModalNameLabelDiv>
                    );
                  }
                })()
              }
              </div>
            );
          }else{
            return (
              <div>
                <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
                  <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Visibility</FloatLeft>
                  <FloatRight style={{ fontSize: "12px", fontWeight: "400", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>
                    <ContactRadio type="radio" value="0" name="public" checked={this.state.doc_info["visibility"] === 0 ? true : false} onClick={this.handleRadio} disabled = {true}  /> Public
                    <ContactRadio type="radio" value="1" name="private" checked={this.state.doc_info["visibility"] === 1 ? true : false} onClick={this.handleRadio} disabled = {true} /> Private
                  </FloatRight>
                </Style.ModalNameLabelDiv>
                <Style.SpacerL></Style.SpacerL>
                <Style.ModalNameLabelDiv>
                  <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Lock document</FloatLeft>
                  <FloatRight style={{ fontSize: "12px", fontWeight: "400", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>
                    <ContactRadio type="radio" value="0" name="no" checked={this.state.doc_info["lock_doc"] === 0 ? true : false} onClick={this.handlelockRadio} disabled = {true}  /> No
                    <ContactRadio type="radio" value="1" name="yes" checked={this.state.doc_info["lock_doc"] === 1 ? true : false} onClick={this.handlelockRadio} disabled = {true} /> Yes
                  </FloatRight>
                </Style.ModalNameLabelDiv>
                </div>
              )
          }
        })()}

        <Style.SpacerL></Style.SpacerL>
        <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
          <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Modified Date</FloatLeft>
          <FloatRight style={{ fontSize: "12px", fontWeight: "400", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>{this.state.doc_info["updated_at"]}</FloatRight>
        </Style.ModalNameLabelDiv>
        <Style.SpacerL></Style.SpacerL>
        <Style.ModalNameLabelDiv style={{ paddingTop: "20px" }}>
          <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Modified By</FloatLeft>
          <FloatRight style={{ fontSize: "12px", fontWeight: "400", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>{this.state.doc_info["updated_by"]}</FloatRight>
        </Style.ModalNameLabelDiv>
        <Style.SpacerL></Style.SpacerL>
        <div style={{ clear: "both" }}></div>
        <Style.DivLine></Style.DivLine>
        <Style.ModalNameLabelDiv style={{ paddingTop: "20px", fontWeight:"400" }}>
          <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Description</FloatLeft>
          {(() => {
            if (this.state.edit) {
              //return <Style.ModalTextArea name="description" onChange={this.handleChange} value={this.state.doc_info["description"]} />;
			  return <HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.doc_info["description"]}  readonly={false}/>
            } else {
              //return <Style.ModalTextArea  value={this.state.doc_info["description"]} readonly={true} />;
			  return <HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.doc_info["description"]} readonly={true}/>

            }
          })()}
        </Style.ModalNameLabelDiv>
        <div style={{ clear: "both" }}></div>

        {
          (() => {
            if((Store.getStoreData("record_doc_approval") && Store.getStoreData("role") !== "team") || (Store.getStoreData("record_doc_approval") && Store.getStoreData("role") === "team" && parseInt(Store.getStoreData('contact').ID) === parseInt(this.state.doc_info["doc_ownerin_no"]))) {
              return(<div>
                      <Style.DivLine></Style.DivLine>
                      <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Document Approval</FloatLeft>
                      <Style.SpacerL></Style.SpacerL>
                      <FloatLeft style={{ float: "left", marginLeft: "0px", fontSize: "13px", color: "#929292", width: "100%" }}>You can use this section to record approvals the document has been given</FloatLeft>
                      <Style.SpacerL></Style.SpacerL>
                      <FullDiv>
                        {(() => {
                          if (this.state.approvars === null) {
                            return <div>loading....</div>;
                          } else {
                            return <ApprovarList tableRows="5" doc_name={this.state.doc_info["name"]} document_id={this.state.doc_info["doc_id"]} approvars={this.state.approvars} updateApprovalListing={this.listApprovals} />;
                          }
                        })()}
                      </FullDiv>
                  </div>)
            }
          })()
        }





        <div style={{ clear: "both" }}></div>

            {
              (() => {
              if (Store.getStoreData("role") !== "team" || parseInt(Store.getStoreData('contact').ID) === parseInt(this.state.doc_info["doc_ownerin_no"])) {
                return (
                  <div>
                    <FloatLeft style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Next Review Date</FloatLeft>
                    <Style.SpacerL></Style.SpacerL>
                    <FloatLeft style={{ float: "left", marginLeft: "0px", fontSize: "13px", color: "#929292", width: "100%" }}>You can set a date when this document should be reviewed and re-approved. Once you have uploaded the document, assign a Follow-up Action if you would like a reminder.</FloatLeft>
                    <Style.SpacerL></Style.SpacerL>

                      <CSLDateCover>
                        <DatePicker onChange={this.onReviewdateChange} clearIcon={null} calendarIcon={null} locale={"en-GB"} value={this.state.review_date} />
                      </CSLDateCover>
                    <Style.DivLine></Style.DivLine>
                  </div>

                );
              }
            })()
          }

        <div style={{ clear: "both" }}></div>


        {(() => {
          if (!this.state.is_support) {
            return (
              <FullDiv>
                <div style={{ width: "70%" }}>
                  <Style.Flexbox style={{ width: "100%", backgroundColor: "#F1F6F7", padding: "10px" }}>
                    <div style={{ width: "20%" }}>
                      <img src={this.state.logo} style={{ height: "60px" }} />
                    </div>
                    <div style={{ width: "80%" }}>
                      <Style.SimpleLabelDiv>
                        <Style.ModalNameLabelDiv style={{ fontSize: "16px", fontWeight: "600", float: "left", marginLeft: "10px", color: "#27415c", fontFamily: "Montserrat', sans-serif" }} title={this.state.doc_info["name"]}>
                          {String(this.state.doc_info["name"]).substring(0, 30) + "..."}&nbsp; ({this.state.doc_info["doc_version"]})
                          <span style={{ color: "#11ABA6", cursor: "pointer", fontSize: "12px", marginLeft: "25px" }} onClick={this.handleDownload(this.state.doc_info["version_id"])}>
                            {" "}
                            Download
                          </span>
                        </Style.ModalNameLabelDiv>
                      </Style.SimpleLabelDiv>
                      <Style.SpacerL></Style.SpacerL>
                      <Style.SimpleLabelDiv>
                        <Style.ModalNameLabelDiv style={{ fontSize: "14px", fontWeight: "400", float: "left", paddingTop: "10px", color: "#8293a2", fontFamily: "DM Sans" }}>
                          Uploaded by {this.state.doc_info["updated_by"]} on {this.state.doc_info["uploaded"]}
                        </Style.ModalNameLabelDiv>
                      </Style.SimpleLabelDiv>
                    </div>
                  </Style.Flexbox>
                </div>
                {/*<Style.DivLine></Style.DivLine>*/}
              </FullDiv>
            );
          }
        })()}

          {
            (() => {
            if (Store.getStoreData("role") !== "team" && this.state.show_edit) {
              return (
                <FloatLeft style={{ width: "100%" }}>
                  {/*<Style.ModalNameLabelDiv style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Document Attachments</Style.ModalNameLabelDiv>*/}
                  <Style.ModalNameInputDiv style={{ width: "100%" }}>
                    <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={this.state.addnew} buttontxt="Upload new version" onUploadComplete={this.onUploadComplete} ref="dropZone" />

                  </Style.ModalNameInputDiv>
                </FloatLeft>
              );
            }
          })()
        }

        <Style.DivLine></Style.DivLine>

        <FullDiv>
          {(() => {
            if (this.state.childtasks === null) {
              return <div>loading....</div>;
            } else {
              return <ChildTask tableRows="5" document_id={this.state.doc_info["version_id"]} childtasks={this.state.childtasks} updateFollowupListing={this.listFollowupTasks} />;
            }
          })()}
        </FullDiv>
        <div style={{ clear: "both" }}></div>
        <Style.DivLine></Style.DivLine>

        <div style={{ clear: "both" }}></div>

        {
          (() => {
            if(!this.state.addnew){
              return(<FloatLeft style={{ width: "100%" }}>
                <Style.ModalNameLabelDiv style={{ fontSize: "12px", fontWeight: "600", color: "#243e5a", fontFamily: "Montserrat, sans-serif" }}>Document Version</Style.ModalNameLabelDiv>
                <Style.ModalNameInputDiv style={{ width: "49%" }}>
                  <Style.ModalInput type="text" name="doc_version" onChange={this.handleVersionChange} value={this.state.doc_version} />
                </Style.ModalNameInputDiv>
              </FloatLeft>)
            }
          })()
        }
        <div style={{ clear: "both" }}></div>
        {(() => {
          if (Store.getStoreData("role") !== "team") {
            return (
              <Style.DocFooter>
                {(() => {
                  if (this.state.doc_info["is_attestation"]) {
                    return (
                      <MRButton style={{ top: "20px", right: "140px", position: "absolute", backgroundColor: "#1A3552", color: "white" }} onClick={this.sendAttestationLink} disabled={this.state.saving}>
                        ATTESTATION LINK
                      </MRButton>
                    );
                  } else {
                    return (
                      <MRButton style={{ top: "20px", right: "140px", position: "absolute", backgroundColor: "#1A3552", color: "white" }} onClick={this.sendAttestation} disabled={this.state.saving}>
                        SEND FOR ATTESTATION
                      </MRButton>
                    );
                  }
                })()}

                {(() => {
                  if (this.state.show_edit) {
                    return (
                      <MRButton style={{ top: "20px", right: "7px", position: "absolute", backgroundColor: "#11aba6", color: "white", width: "115px" }} onClick={this.updateDocument} disabled={this.state.saving}>
                        UPDATE
                      </MRButton>
                    );
                  }
                })()}
              </Style.DocFooter>
            );
          }
        })()}
        <AlertBox alertParam={this.state.alert_param} />
      </div>
    );
  }
}

export default Property;
