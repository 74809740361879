import React from "react"
import {FaTimes} from 'react-icons/fa'
import Store from "../../../Common/Store"
import ContextMenu from "../Menu/ContextMenu"
import "./CustomTable.css"
import * as Style from "../../Common/StyledComponents";
import styled from "styled-components";
import CSLTable from "../../Common/CSLTable";
import CSLLoader from "../../Common/CSLLoader";
import EditSingledoc from "./EditSingle";
import APICall from "../../../Common/APICall.js";

const SpacerL = styled.div`
  height: 10px;
`;
const SpacerXL = styled.div`
  height: 20px;
`;
const RadioBlock = styled.div`
  float: right;
  margin-top: -5px;
`;
const SelectedRadio = styled.div`
  background-color: #37ada7;
  border: 1px solid #1f615e;
  padding: 5px 10px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
`;
const UnselectedRadio = styled.div`
  background-color: #f4f4f4;
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  color: #262626;
  display: inline-block;
  cursor: pointer;
`;

class BulkReview extends React.Component {

    state = {documents: [], all_company: true,gcs: {}, edit: false, single_obj: {}, folders: [],folder_id: 0,parent_id:0,folder_order: {}}

    componentDidMount() {
        this.setComponentState()
    }

    setComponentState = () => {
        let companies_assoc = Store.getStoreData("companies_assoc");
        let role = Store.getStoreData("role");
        let user_assignments = Store.getStoreData("user_assignments");
        let is_manager = role === "admin_manager" ? true : false;
        console.log('USER ASSIGNMENTS --->', user_assignments)
        let owner_gcs = Object.keys(user_assignments).length === 0 ? {} : user_assignments[Store.getStoreData("contact").ID].companies;
        let gcs = {};
        for (let k in companies_assoc) {
            if(is_manager){
              gcs[k] = { id: k, name: companies_assoc[k].company_name, selected: false };
            }else{
              if (k in owner_gcs) {
                gcs[k] = { id: k, name: companies_assoc[k].company_name, selected: false };
              }
            }

        }

        let folders = this.props.folders;
        let folder_order = this.props.folder_order;
        let parent_id = this.props.parent_id;
        let folder_id = this.props.folder_id;

        let attachments = JSON.parse(JSON.stringify(this.props.attachments))
        console.log("attachments", gcs)
        let documents = []
        let owner = Store.getStoreData("contact");
        for (let i=0; i<attachments.length; i++) {
            let name_ext = this.getNameAndExtension(attachments[i].name)
            let x = {}
            x.id = attachments[i].id
            x.name = name_ext.name
            x.doc_type = 0
            x.doc_owner = owner.ID
            x.visibility = 0
            x.description = ""
            x.file_name = attachments[i].name
            x.lock_doc = 0
            x.doc_version = "1.0"
            x.gc_ids = {}
            x.folder_id = folder_id
            x.folder_order = folder_order
            x.folders = folders
            x.parent_id = parent_id
            documents.push(x)
        }
        console.log("documents", documents)
        this.setState({documents, gcs, folders: folders, folder_order: folder_order, parent_id: parent_id, folder_id: folder_id})
    }

    getNameAndExtension = (file_name) => {
        const file_parts = file_name.split(/\.(?=[^\.]+$)/)
        let name = ""
        for (let i=0; i<file_parts.length - 1; i++) {
            name = `${name}${file_parts[i]}`
        }
        let ext = "PDF";
        switch (file_parts[file_parts.length - 1]) {
        case "jpeg":
        case "jpg":
            ext = "JPG"; break;
        case "png":
            ext = "PNG"; break;
        case "docx":
            ext = "DOC"; break;
        case "doc":
            ext = "DOC"; break;
        case "msg":
            ext = "MSG"; break;
        case "txt":
            ext = "TXT"; break;
        case "ppt":
            ext = "PPT"; break;
        case "pptx":
            ext = "PPT"; break;
        case "xls":
            ext = "XLS"; break;
        case "xlsx":
            ext = "XLSX"; break;
        default:
            ext = "PDF"; break;
        }
        return {name, ext}
    }

    editDocument = (doc_id) => {
        let state_documents = JSON.parse(JSON.stringify(this.state.documents))
        console.log("state_documents", state_documents)
        let sin = state_documents.find(x => x.id === doc_id);
        this.setState({edit: true, single_obj:sin})
    }

    removeDocument = (doc_id) => {
        console.log("removeDocument id:", doc_id)
        const state_documents = JSON.parse(JSON.stringify(this.state.documents))
        let documents = []
        for (let item of state_documents) {
            if (item.id !== doc_id) documents.push(item)
        }
        this.setState({documents})
    }

    closeModal = (event) => {
        event.preventDefault()
        this.props.closeModal()
    }

    upload = (event) => {
        event.preventDefault()
        // console.log("upload button clicked", this.state)
        this.props.uploadDocument(this.state)
    }

    tableData = () => {
      let table = { data: [], columns: [] };
      let gcs = this.state.gcs;

      table.columns = [
        { Header: "Name", accessor: "name", headerStyle: { textAlign: "left" }, style: { whiteSpace: "unset" } },
        {
          Header: "Status",
          accessor: "status",
          width: 80,
          headerStyle: { textAlign: "center" },
          Cell: (row) => (
            <div>
              <input type="checkbox" checked={row.original.selected} value={row.original.id} onClick={this.selectGc} />
            </div>
          ),
          style: { display: "flex", alignItems: "center", justifyContent: "center" },
        },
      ];

      for (let key in gcs) {
        let elem = {
          name: gcs[key].name,
          id: key,
          selected: "selected" in gcs[key] ? gcs[key].selected : false,
        };
        table.data.push(elem);
      }
      return table;
    };

    switchRadio = (event) => {
      event.preventDefault();
      let all_company = this.state.all_company ? false : true;
      this.setState({ all_company: all_company });
    };
    selectGc = (event) => {
      event.preventDefault();
      let gc_id = event.target.value;
      let gcs = this.state.gcs;
      gcs[gc_id].selected = gcs[gc_id].selected ? false : true;
      this.setState({ gcs: gcs });
    };

    closeSingleModal = () => {
        this.setState({edit: false})
    }

    saveDocInfo = (obj) => {
        
        console.log("saveDocInfo", obj)

        let doc_id = obj.id;
        let state_documents = JSON.parse(JSON.stringify(this.state.documents))
        let documents = []
        for (let item of state_documents) {
            if (item.id !== doc_id) {
                documents.push(item)
            }else{
                documents.push(obj)
            }
            
        }
        console.log("saveDocInfo", documents)
        this.setState({documents, edit: false, single_obj:{}})

    }

    processFolders = (result) => {
        console.log("result", result, this.state.documents)
        let folders = result.result;
        if(result.folder_id > 0){
          folders = [...[{id: 'back', name: "...."}], ...folders]
        }
        let folder_order = this.state.folder_order;
        if(result.reorder === 1) folder_order[result.folder_id] = Object.keys(folder_order).length + 1;
        
        let documents = this.state.documents;
        for(let k in documents){
          documents[k].folder_id = result.folder_id
          documents[k].folder_order = folder_order
          documents[k].folders = folders
          documents[k].parent_id = result.parent_id
        }

        console.log("folder_order", documents)
        this.setState({ folders: folders,folder_order: folder_order, parent_id: result.parent_id, documents: documents });
    }

    handleChange = (event) => {
      event.preventDefault();
      let { folder_id } = this.state;
      folder_id = event.target.value === "back" ? this.state.parent_id : event.target.value;
      let api = new APICall();
      let postData = { command: "get_folders", folder_id: folder_id, reorder: event.target.value === "back" ? 0 : 1 };
      api.command(postData, this.processFolders);
      let folder_order = this.state.folder_order;
      if(event.target.value === "back"){
        delete folder_order[this.state.folder_id.toString()]
      }
      this.setState({ folder_id:folder_id, folder_order: folder_order});
    };

    render() {
        // console.log("documents:", this.state.documents)
        
        let folders_assoc = Store.getStoreData('folders_assoc')
        let y = ''
        let i = 0;
        for(let k in this.state.folder_order){
          if(k > 0){
            y  = y.concat(folders_assoc[k])
            if(i < Object.keys(this.state.folder_order).length - 1){
              y += ' / '
            }
          }
          i++
        }

        const menu_options = [
            {name: "edit", nick_name: "Edit", clickHandler: this.editDocument},
            {name: "remove", nick_name: "Remove", clickHandler: this.removeDocument}
        ]
        let contact_assoc = Store.getStoreData("listcontacts");
        let doc_types = Store.getStoreData("doc_types");
        let default_gc_rows = Object.keys(this.state.gcs).length > 10 ? 10 : Object.keys(this.state.gcs).length;
        return (
            <div className="bulk-review-container">
                {
                  (() => {
                    if(this.props.is_loading) return <CSLLoader />;
                  })()
                }

                {
                    this.state.edit === true &&
                    <div className="inactive-overlay">
                        <EditSingledoc  closeSingleModal={this.closeSingleModal} saveDocInfo={this.saveDocInfo} single_obj={this.state.single_obj}/>
                    </div>
                }


                <div className="bulk-header">
                    <div style={{float: "left", width: "95%", boxSizing: "border-box"}}>
                        <div style={{fontWeight: "600", paddingBottom: "10px", fontSize: "16px"}}>Bulk Document Upload</div>
                        <div>You can edit the details of your documents before clicking the 'Upload' button to complete the process.</div>
                    </div>
                    <div style={{float: "left", width: "5%", boxSizing: "border-box", fontSize: "19px", color: "#8f8f8f"}}>
                        <FaTimes style={{cursor: "pointer"}} onClick={this.closeModal} />
                    </div>
                    <div style={{clear: "both"}}></div>
                </div>
                <div className="bulk-body">
                
                <SpacerL />
                {(() => {
                  if (Store.getStoreData("is_gc")) {
                    return (
                      <div>
                        <Style.ModalNameLabelDiv>Apply to all companies</Style.ModalNameLabelDiv>
                        <SpacerL />
                        <SpacerL />
                        <div style={{ float: "left" }}>
                          {(() => {
                            if (this.state.all_company) {
                              return (
                                <RadioBlock>
                                  <SelectedRadio>Yes</SelectedRadio>
                                  <UnselectedRadio onClick={this.switchRadio}>No</UnselectedRadio>
                                </RadioBlock>
                              );
                            } else {
                              return (
                                <RadioBlock>
                                  <UnselectedRadio onClick={this.switchRadio}>Yes</UnselectedRadio>
                                  <SelectedRadio>No</SelectedRadio>
                                </RadioBlock>
                              );
                            }
                          })()}
                        </div>
                        <SpacerL />
                        <SpacerL />
                        <Style.HomeText style={{ float: "none", marginLeft: "0px" }}>Companies are restricted based on the document owner's group company associations</Style.HomeText>
                        <SpacerL />
                        {(() => {
                          if (!this.state.all_company) {
                            return <CSLTable add={false} processData={this.tableData} tableRows={default_gc_rows} offset={true} />;
                          }
                        })()}
                      </div>
                    );
                  }
                })()}
                <SpacerL />


                {
                    this.state.documents.length !== 0 &&
                    <table className="cs-table">
                        <thead className="cs-thead">
                            <tr>
                                <th className="cs-th">FILENAME</th><th className="cs-th">DOC NAME</th><th className="cs-th">DOC TYPE</th><th className="cs-th">DOC OWNER</th><th className="cs-th">VISIBILITY</th><th className="cs-th">DESCRIPTION</th><th className="cs-th">FOLDER</th><th className="cs-th" style={{borderRight: "1px solid #c3c1c1"}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.documents.map(item => {

                                    let d1 = 0;
                                    let y1 = '';
                                    for(let k1 in item.folder_order){
                                      if(k1 > 0){
                                        y1  = y1.concat(" "+folders_assoc[k1])
                                        if(d1 < Object.keys(item.folder_order).length - 1){
                                          y1 += ' / '
                                        }
                                      }
                                      d1++
                                    }
                                    return (
                                        <tr key={item.id}>
                                            <td className="cs-td">{item.file_name}</td>
                                            <td className="cs-td">{item.name}</td>
                                            <td className="cs-td">{doc_types[item.doc_type]}</td>
                                            <td className="cs-td">{contact_assoc[item.doc_owner.toString()]}</td>
                                            <td className="cs-td">{item.visibility === 0 ? "Public" : "Private"}</td>
                                            <td className="cs-td">{item.description}</td>
                                            <td className="cs-td">{y1}</td>
                                            <td className="cs-td-last"><ContextMenu row_id={item.id} options={menu_options} /></td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                }

                <div style={{ display: "flex", flexDirection: "column", height: "100px"}}>
                <Style.DocFormContainer>
                  <div style={{ float: "left", width: "95%", boxSizing: "border-box" }}>
                      <div style={{ fontWeight: "600", paddingBottom: "10px", fontSize: "16px" }}>Select Folder</div>
                      <div style={{paddingBottom: "5px"}}>Files will be placed in the folder shown. You can select different folder here.</div>
                      <div style={{ fontWeight: "600", paddingBottom: "5px"}}> {y} </div>
                  </div>
                  <Style.SpacerL></Style.SpacerL>
                  <Style.MRModalSelect onChange={this.handleChange} name="folder_id" value={this.state.folder_id}>
                    {(() => {
                      let regulatory_status = [<option value="0" disabled selected hidden>Select Folder</option>]
                      for (let f of this.state.folders) {
                        regulatory_status.push(
                          <option key={f.id} value={f.id}>
                            {f.name}
                          </option>
                        );
                      }
                      return regulatory_status;
                    })()}
                  </Style.MRModalSelect>
                </Style.DocFormContainer>
                </div>
                </div>
                <div className="bulk-footer">
                    <div style={{float: "right"}}>
                        <button className="cancel-btn" onClick={this.closeModal}>CLOSE</button>
                        <button className="save-btn" onClick={this.upload} style={{marginLeft: "10px"}}>UPLOAD</button>
                    </div>
                    <div style={{clear: "both"}}></div>
                </div>
            </div>
        )
    }

}

export default BulkReview