import React from 'react';
import styled from 'styled-components';
import {FaTimes, FaPlusCircle, FaLock} from 'react-icons/fa';
import {IoIosArrowForward, IoIosLock} from 'react-icons/io';
import Store from '../../Common/Store';
import General from './General';
import Email from './Email';
import Permissions from './Permissions';
import Documents from './Documents';

import APICall from '../../Common/APICall.js';
import { Link } from "react-router-dom";
import CSLLoader from '../Common/CSLLoader';
import AlertBox from '../Common/AlertBox';
import Reload from '../../Common/Reload.js';

const ReviewBodyContainer = styled.div`
    padding-top: 25px;
    width: 100%;
`;
const ReviewLeftContainer = styled.div`
    float: left;
    padding-right: 20px;
    width: 20%;
    box-sizing: border-box;
`;
const ReviewRightContainer = styled.div`
    float: right;
    padding: 20px;
    width: 80%;
    box-sizing: border-box;
    background-color: #dee2e6;
`;
const ReviewTab = styled.div`
    background-color: #ffffff;
    color: #222222;
    padding: 10px 20px;
    cursor: pointer;
`;
const CloseBtn = styled.div`
    float: right;
    font-size: 20px;
    color: #9c9c9c;
    cursor: pointer;
    padding-top: 10px;
    padding-right: 5px;
`;
const FooterButtonsContainer = styled.div`
    text-align: right;
    padding-top: 25px;
    width: 90%;
`;
const CancelBtn = styled.button`
    padding: 10px 20px;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 2px;
    color: #333333;
    cursor: pointer;
`;
const SaveBtn = styled.button`
    padding: 10px 30px;
    background-color: #37ADA7;
    border: 1px solid #37ADA7;
    border-radius: 2px;
    color: #ffffff;
    cursor: pointer;
    margin-left: 15px;
`;
const ErrorBar = styled.div`
    background-color: #F6DBDB;
    color: #DE8D8E;
    font-weight: 600;
    padding: 10px 20px;
`;

class ModuleConfigHOC extends React.Component
{
    state = {
        active_section_id: "general",
        module_config: null,
        requester: "",
        dirty: false,
        permissions: {},
        all_company_users: null,
        changed_user_roles: {},
        requester: "",
        used_tags: {},
        alert_param: null,
        role: '',
        loading: false
    };


    constructor(props) {
        super(props);
    }

    componentDidMount() {
      this.callApi()
    }

    callApi = () => {
        const postData = { command: "list_company_and_users" };
        const api = new APICall();
        api.command(postData, this.getInitialPermission);
    }

    getInitialPermission = (result) => {
        console.log("result ===>", result)
        let contactlist = localStorage.getItem("contactlist")!==null ? JSON.parse(localStorage.getItem("contactlist")) : {};
        let user_roles = contactlist.result.user_roles['docmgmt'];
        let cls = contactlist.result.contactlist;

        let company_users = [];
        for(let key in cls){
            if(cls[key].IsActive === true && cls[key].IsDeleted === false){
                let cu = {};
                cu['email'] = cls[key].EmailAddress;
                cu['user_id'] = key;
                cu['name'] = cls[key].ContactName;
                cu['role'] = key in user_roles ? user_roles[key] : 'no_access';
                company_users.push(cu)
            }
        }
        const permissions = {
            user_roles: user_roles,
            roles: result.roles,
            company_users: company_users

        };
        let requester = this.props.match ? this.props.match.params.requester : "";
        this.setState({permissions:permissions, module_config: result.moduleconfig, all_company_users: company_users, role: result.role});
    }

    getInitialPermission_obsolute = (result) => {
        console.log("result ===>", result)
        let requester = this.props.match ? this.props.match.params.requester : "";
        const permissions = {
            user_roles: result.user_roles,
            roles: result.roles,
            company_users: result.company_users
        };
        const all_company_users = JSON.parse(JSON.stringify(result.company_users));
        this.setState({permissions:permissions, module_config: result.moduleconfig, requester, all_company_users: all_company_users, role: result.role});
    }

    returnSection = (section) => (event) => {
        event.preventDefault();
        this.setState({active_section_id: section});
    }

    updateGeneral = (obj) => {
        let {module_config, dirty} = this.state;
        // console.log("old_config", this.state);
        const old_config = {...module_config};
        // console.log("old_config", old_config);
        module_config.general = obj;
        const new_config = {...module_config};
        // console.log("new_config", new_config);
        if (JSON.stringify(old_config) !== JSON.stringify(new_config)) {
            dirty = true;
        }
        console.log("module_config", module_config);
        this.setState({module_config});
    }

    afterUpdate = (result) => {
        let alert_param = {title: 'Alert', message: result.error_msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {}}
        this.setState({alert_param: alert_param})
        // console.log("Swoham==>",result);
        // alert(result.error_msg);

        this.setState({module_config: result.result, loading: false});
    }

    afterRoleUpdate = (result) => {
        let alert_param = {title: 'Alert', message: result.error_msg, ok_text: 'OK', confirm: false, alertHandler: this.sameAlertremoveHandler, stack: {}}
        this.setState({alert_param: alert_param, loading: false})
        // alert(result.error_msg);
    }

   updateEmail = (obj) => {
        let {module_config} = this.state;
        module_config.email = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        this.setState({loading: true})
        api.command(postData, this.afterUpdate);
    }

    includeExcludeEmail = (obj) => {
        let {module_config} = this.state;
        module_config.email = obj;
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        this.setState({loading: true})
        api.command(postData, this.afterincludeExclude);
    }

    afterincludeExclude = (result) => {
        this.setState({module_config: result.result, loading: false});
    }

    updateEmailRemainder = (obj) =>{
        let {module_config, dirty} = this.state;
        console.log("old_config", this.state);
        const old_config = {...module_config};
        // console.log("old_config", old_config);
        module_config.general = obj;
        const new_config = {...module_config};
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        this.setState({loading: true})
        api.command(postData, this.afterUpdate);
    }

    topicIncludedExclude = (obj) => {
        let {module_config, dirty} = this.state;
        console.log("old_config", this.state);
        const old_config = {...module_config};
        // console.log("old_config", old_config);
        module_config.general = obj;
        const new_config = {...module_config};
        const postData = {command: "save_module_config", module_config: module_config};
        const api = new APICall();
        this.setState({loading: true})
        api.command(postData, this.UpdatetopicIncludedExclude);
    }

    UpdatetopicIncludedExclude = (result) => {
        this.setState({module_config: result.result, loading: false});
    }

    updatePermissions = (obj, single_obj) => {
        console.log("single_obj:", single_obj);
        let changed_user_roles = JSON.parse(JSON.stringify(this.state.changed_user_roles));
        changed_user_roles[single_obj.user_id] = single_obj.role;
        console.log("changed_user_roles:", changed_user_roles);
        this.setState({permissions: obj, changed_user_roles});
        // console.log("Updated Permission:", obj);
    }

    searchUsers = (search_string) => {
        if (search_string === "") {
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = this.state.all_company_users;
            this.setState({permissions});
        } else {
            let all_company_users = JSON.parse(JSON.stringify(this.state.all_company_users));
            let filtered_company_users = [];
            all_company_users.forEach((user) => {
                if (user.name.toLowerCase().includes(search_string.toLowerCase()) || user.role.includes(search_string.toLowerCase())) {
                    filtered_company_users.push(user);
                }
            })
            let permissions = JSON.parse(JSON.stringify(this.state.permissions));
            permissions.company_users = filtered_company_users;
            // console.log("in search:", permissions.company_users);
            this.setState({permissions});
        }
    }

    changeAllRoles = (role) => {
        let permissions = JSON.parse(JSON.stringify(this.state.permissions));
        if (role !== "0") {
            permissions.company_users.forEach((user) => {
                user.role = role;
            })
        }
        let changed_user_roles = {};
        permissions.company_users.forEach((user) => {
            changed_user_roles[user.user_id] = user.role;
        })
        // console.log("change all roles:", permissions.company_users);
        this.setState({permissions, changed_user_roles});
    }

    updateModuleconfig = () => {
        let {module_config, changed_user_roles} = this.state;
        this.setState({loading: true})
        const api = new APICall();
        let postData = {command: "save_module_config", module_config: module_config};
        if (this.state.active_section_id === "permissions") {
            postData = {command: "save_user_role", changed_user_roles: changed_user_roles};
            api.command(postData, this.afterRoleUpdate);
            Reload.RestoreStorage(changed_user_roles);
        } else {
            api.command(postData, this.afterUpdate);
        }
        // console.log("changed_user_roles", changed_user_roles)
        // let postData = {command: "save_user_role", changed_user_roles: changed_user_roles};
        // api.command(postData, this.afterRoleUpdate);
    }

    updateCustomFields = (obj) => {
        let {module_config} = this.state;
        module_config.general.custom_fields = obj;
        this.setState({module_config});
    }

    closePage = (event) => {
        event.preventDefault();
        let url = "/"
        window.location.replace(url);
    }
    alertHandler = (result, stack) => {
        this.setState({alert_param: null});
        window.close();
    }
    /*closePage = (event) => {
            event.preventDefault();
            window.close();
        }*/

    sameAlertremoveHandler = (result,stack) => {
            this.setState({alert_param: null})
        }

    render()
    {
        if (Store.getStoreData('role') !== 'admin_manager') {
            return (
                <ErrorBar>
                    <div style={{float: "left"}}>You are not authorised to access this page. If you think this is in error, please contact your administrator.</div>
                    <div style={{float: "right", fontSize: "16px", cursor: "pointer"}} onClick={this.closePage}>x</div>
                    <div style={{clear: "both"}}></div>
                </ErrorBar>
            );
        }
        if (Object.keys(this.state.permissions).length === 0) {
            return <div><CSLLoader /></div>
        }
        return (
            <div style={{padding: "10px 10px 20px 10px"}}>
                <CloseBtn onClick={this.closePage}><FaTimes /></CloseBtn>
                <div style={{clear: "both"}}></div>
                <ReviewBodyContainer>
                    <ReviewLeftContainer style={{position: "sticky", top: "2px"}}>

                        <ReviewTab onClick={this.returnSection("general")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "general") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>General</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>General</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSection("email")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "email") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Email</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Email</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        <ReviewTab onClick={this.returnSection("document_type")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "document_type") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Document Types</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Document Types</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>
                        {/*<ReviewTab onClick={this.returnSection("permissions")} style={{marginTop: "2px"}}>
                            {
                                (() => {
                                    if (this.state.active_section_id === "permissions") {
                                        return (<div style={{float: "left", fontWeight: "600"}}>Permissions</div>);
                                    } else {
                                        return (<div style={{float: "left"}}>Permissions</div>);
                                    }
                                })()
                            }
                            <div style={{float: "right", color: "#39b3ad", marginTop: "2px", paddingLeft: "30px"}}><IoIosArrowForward /></div>
                            <div style={{clear: "both"}}></div>
                        </ReviewTab>*/}
                    </ReviewLeftContainer>
                        <ReviewRightContainer>

                            {
                                (() => {
                                    if (this.state.active_section_id === "general") {
                                        return (<General general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneral={this.updateGeneral} />);
                                    }
                                    if (this.state.active_section_id === "email") {
                                        return (<Email variables={this.state.module_config.variables} email={JSON.parse(JSON.stringify(this.state.module_config.email))} updateEmail={this.updateEmail} includeExcludeEmail={this.includeExcludeEmail} general={JSON.parse(JSON.stringify(this.state.module_config.general))} updateGeneralSave={this.updateEmailRemainder}/>);
                                    }
                                    if (this.state.active_section_id === "document_type") {
                                        return (<Documents />);
                                    }
                                    if (this.state.active_section_id === "permissions") {
                                        return (<Permissions permissions={JSON.parse(JSON.stringify(this.state.permissions))} updatePermissions={this.updatePermissions} searchUsers={this.searchUsers} changeAllRoles={this.changeAllRoles} />);
                                    }
                                })()
                            }
                            {
                                (() => {
                                    if (this.state.active_section_id !== "email" && this.state.active_section_id !=='document_type' ) {
                                        return (
                                            <FooterButtonsContainer>
                                                <CancelBtn onClick={this.closePage}>Cancel</CancelBtn><SaveBtn onClick={() => this.updateModuleconfig()}>Save</SaveBtn>
                                            </FooterButtonsContainer>
                                        );
                                    }
                                })()
                            }
                        </ReviewRightContainer>

                    <div style={{clear: "both"}}></div>
                </ReviewBodyContainer>
                {
                  (() => {
                    if(this.state.loading) return <CSLLoader />;
                  })()
                }
                <AlertBox alertParam = {this.state.alert_param} />
            </div>
        );
    }
}

export default ModuleConfigHOC;
