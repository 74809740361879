import React from "react";
import styled from "styled-components";
import * as Style from "../../Common/StyledComponents";
import Store from "../../../Common/Store";
import Utils from "../../../Common/Utils";
import APICall from "../../../Common/APICall";
import DatePicker from "react-date-picker";
import { FaPlusCircle, FaTimes } from "react-icons/fa";
import { FiDownload } from "react-icons/fi";
import { saveAs } from "file-saver";
import { MdClear } from "react-icons/md";
// import Dropzone from "../../Common/Dropzone.js";
import CSLLoader from "../../Common/CSLLoader.js";
import AlertBox from "../../Common/AlertBox";
import "./Followup.css";
import Dropzone from "../../Dropzone/Dropzone.js"
const moment = require("moment");
const last_action_time = moment().unix();

const OverviewInfoHeader = styled.div`
  padding: 12px 15px;
  background-color: #04ada8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const MRModalCancelBtn = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #909090;
  border: 2px solid #dadada;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  font-size: 11px;
  float: right;
  cursor: pointer;
`;
const MRModalNextBtn = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #37ada7;
  color: #ffffff;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #37ada7;
  margin-left: 5px;
  font-size: 11px;
  margin-left: 10px;
  float: right;
  cursor: pointer;
`;

const CSLDateCover = styled.div`
  margin-top: 8px;
  width: 200px;
  border-radius: 5px;
  background-color: #ffffff;
  height: 33px;
  padding-top: 5px;
  padding-left: 10px;
`;
const ScoreContainer = styled.div`
  background-color: #ecf0f1;
  height: auto;
  border: 1px solid #d4dcde;
  border-radius: 5px;
`;
const ScoreBodyContainer = styled.div`
  padding: 15px;
  text-align: left;
  overflow-y: scroll;
  height: 680px;
`;

class CSLAtteachmentViewer extends React.Component {
  state = { ready: false, files: null, task_id: null };

  setComponentState = () => {
    console.log("this.props", this.props);
    if (this.props.task_id) {
      this.setState({ ready: true, files: this.props.files, task_id: this.props.task_id });
    }
  };
  componentDidMount = () => {
    this.setComponentState();
  };
  componentDidUpdate = (prevprops) => {
    if (this.props === prevprops) return;
    this.setComponentState();
  };

  handleDownload(image_id, task_id) {
    let api = new APICall();
    let postData = { command: "download_followup_attachment", image_id: image_id, task_id: task_id };
    console.log("postData", postData);
    api.command(postData, this.processDownloadFile);
  }
  handleRemove(image_id, task_id) {
    let api = new APICall();
    let postData = { command: "remove_followup_attachment", image_id: image_id, task_id: task_id };
    console.log("postData", postData);
  }

  processDownloadFile = (result) => {
    console.log("Attachment", result);
    let dbinfile = result.result;
    let dnld = dbinfile.bin_file.replace(/ /g, "+");
    let binaryString = window.atob(dnld);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, dbinfile.name);
  };

  processVersionBySingleDocument = (result) => {
    let versions = result.result;
    let version_main = result.result.filter((x) => {
      return x.is_main == 1;
    });
    let bin_file = JSON.parse(version_main[0].bin_file)[0].bin_file;
    let file_parts = JSON.parse(version_main[0].bin_file)[0].name.split(".");
    let mime_type = file_parts[file_parts.length - 1].toLowerCase();
    this.setState({ ready: true, filedata_b64: bin_file, mime_type: mime_type, doc_id: this.props.docId });
  };

  imageUrl(file) {
    const file_parts = file.split(/\.(?=[^\.]+$)/);
    let ext = "PDF";
    let extention = file_parts[file_parts.length - 1];
    switch (extention) {
      case "jpeg":
      case "JPG":
      case "jpg":
        ext = "JPG";
        break;
      case "png":
        ext = "PNG";
        break;
      case "docx":
        ext = "DOC";
        break;
      case "doc":
        ext = "DOC";
        break;
      case "msg":
        ext = "MSG";
        break;
      case "txt":
        ext = "TXT";
        break;
      case "ppt":
        ext = "PPT";
        break;
      case "pptx":
        ext = "PPTX";
        break;
      case "xls":
        ext = "XLS";
        break;
      case "xlsx":
        ext = "XLS";
        break;
      default:
        ext = "PDF";
        break;
    }
    console.log("ext", ext);
    let file_icon_var = ext === "DOCX" ? "DOC" : ext;
    file_icon_var = ext === "XLSX" ? "XLS" : ext;
    const file_icon = `/dropzoneImages/${ext}.png`;
    return file_icon;
  }

  render() {
    if (!this.state.ready) return <div></div>;
    return (
      <div style={{ width: "100%" }}>
        {(() => {
          let i = 0;
          for (let key in this.state.files) {
            return (
              <div style={{ width: "50%", boxSizing: "border-box", padding: i === 0 || i % 2 === 0 ? "10px 10px 10px 0px" : "10px 0px 10px 10px" }}>
                <div style={{ boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)" }}>
                  <div style={{ float: "left", padding: "10px", boxSizing: "border-box" }}>
                    <img src={this.imageUrl(this.state.files[key])} style={{ height: "100px", width: "auto" }} />
                  </div>
                  <div style={{ float: "left", padding: "10px", boxSizing: "border-box" }}>
                    <div>
                      <strong title={this.state.files[key]} style={{ color: "#000000" }}>
                        {this.state.files[key].length > 20 ? this.state.files[key].substring(0, 20) : this.state.files[key]}
                      </strong>
                      <br />
                      <br />
                    </div>
                    <div style={{ cursor: "pointer" }} onClick={() => this.handleDownload(key, this.state.task_id)}>
                      <FiDownload /> Download
                    </div>
                    <div style={{ cursor: "pointer", marginTop: "10px" }} onClick={() => this.handleRemove(key, this.state.task_id)}>
                      <MdClear /> Remove
                    </div>
                  </div>
                  <div style={{ clear: "both" }}></div>
                </div>
              </div>
            );
            i++;
          }
        })()}
      </div>
    );
  }
}

class AddTaskModal extends React.Component {
  state = {
    showModal: false,
    contact_list: [],
    ready: false,
    due_date: new Date(),
    document_id: null,
    binFiles: [],
    curBinFiles: [],
    cur_files_json: null,
    addnew: true,
    is_complete: false,
    task: { name: "", description: "",completed_note:"", assigned_to: 0, doc_id: 0, task_id: 0 },
    alert_param: null,
    iconurl: null,
    saving: false,
    loading: false
  };

  hasCommonCompanyCheckFirst = (manager_id, user_id, gc_users) => {
    if (Object.keys(gc_users).length === 0) return true;
    if (manager_id === "") return true;
    let manager_companies = gc_users[manager_id];
    let user_companies = gc_users[user_id];
    for (let cid in manager_companies) {
      for (let uid in user_companies) {
        if (cid.toString() === uid.toString()) return true;
      }
    }
    return false;
  };

  componentDidMount() {
    console.log("followup tasks", this.props.task);
    let addnew = "file_name" in this.props.task && this.props.task["file_name"] !== "" ? false : true;

    let due_date = "due_date" in this.props.task ? new Date(this.props.task["due_date"]) : this.state.due_date;

    let contact_assoc = Store.getStoreData("contact_assoc");



    let list_contacts = Store.getStoreData("listcontacts");
    if(!contact_assoc[this.props.task['assigned_to']]){
      contact_assoc[this.props.task['assigned_to']] = list_contacts[this.props.task['assigned_to']]
    }










    let gc_users = Store.getStoreData("gc_users");
    let contact = Store.getStoreData("contact");
    let show_all = Store.getStoreData("role") === "admin_manager" ? true : false;
    let user_list = {};
    if (Object.keys(gc_users).length !== 0) {
      for (let key in contact_assoc) {
        if (show_all || this.hasCommonCompanyCheckFirst(contact.ID, key, gc_users)) {
          if (key in gc_users) {
            user_list[key.toString()] = contact_assoc[key.toString()];
          }
        }
      }
    } else {
      user_list = contact_assoc;
    }
    this.setState({ contact_list: user_list, ready: true, doc_id: this.props.document_id, showModal: this.props.show_modal, task: this.props.task, due_date: due_date, addnew: addnew });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      console.log("followup tasks", this.props.task);
      let due_date = "due_date" in this.props.task ? new Date(this.props.task["due_date"]) : this.state.due_date;
      let addnew = "file_name" in this.props.task && this.props.task["file_name"] !== "" ? false : true;

      let contact_assoc = Store.getStoreData("contact_assoc");
      let list_contacts = Store.getStoreData("listcontacts");
      if(!contact_assoc[this.props.task['assigned_to']]){
        contact_assoc[this.props.task['assigned_to']] = list_contacts[this.props.task['assigned_to']]
      }
      let gc_users = Store.getStoreData("gc_users");
      let contact = Store.getStoreData("contact");
      let show_all = Store.getStoreData("role") === "admin_manager" ? true : false;
      let user_list = {};
      if (Object.keys(gc_users).length !== 0) {
        for (let key in contact_assoc) {
          if (show_all || this.hasCommonCompanyCheckFirst(contact.ID, key, gc_users)) {
            if (key in gc_users) {
              user_list[key.toString()] = contact_assoc[key.toString()];
            }
          }
        }
      } else {
        user_list = contact_assoc;
      }

      this.setState({ contact_list: user_list, ready: true, doc_id: this.props.document_id, showModal: this.props.show_modal, task: this.props.task, due_date: due_date, addnew: addnew });
    }
  }

  showFollowupTaskModal = (event) => {
    event.preventDefault();
    let task = {};
    task["name"] = "";
    task["description"] = "";

    let contact_assoc = Store.getStoreData("contact_assoc");
    let gc_users = Store.getStoreData("gc_users");
    let contact = Store.getStoreData("contact");
    let show_all = Store.getStoreData("role") === "admin_manager" ? true : false;
    let user_list = {};
    if (Object.keys(gc_users).length !== 0) {
      for (let key in contact_assoc) {
        if (show_all || this.hasCommonCompanyCheckFirst(contact.ID, key, gc_users)) {
          if (key in gc_users) {
            user_list[key.toString()] = contact_assoc[key.toString()];
          }
        }
      }
    } else {
      user_list = contact_assoc;
    }

    task["assigned_to"] = Object.keys(user_list)[0];
    task["doc_id"] = this.props.document_id;
    task["task_id"] = 0;
    let due_date = new Date();
    this.setState({ showModal: true, task: task, due_date: due_date,contact_list: user_list, addnew: true });
  };

  closeFollowupTaskModal = (event) => {
    event.preventDefault();
    this.setState({ showModal: false, is_complete: false });
  };
  handleChange = (evt) => {
    let task = this.state.task;
    task[evt.target.name] = evt.target.value;
    console.log("task ===>", task)
    this.setState({ task: task });
  };

  onDateChangeDueBy = (date) => {
    this.setState({ due_date: date });
  };

  addCompleteNote = (event) => {
    this.setState({is_complete: true})
  }

  addSubTask = (mode) => (event) => {
    event.preventDefault();
    let task = this.state.task;
    if ("file_name" in task && Object.keys(task["file_name"]).length > 0 && this.state.binFiles.length > 0) {
      // task["uploaded_doc"] = this.state.binFiles;
      /*let alert_param = { title: "Alert", message: "This file may take some time to upload and for users to download", ok_text: "OK", confirm: false, alertHandler: this.AlertCompleteHandler, stack: { id: 0 } }
      this.setState({saving:true, alert_param: alert_param})
      let bin_files = this.state.binFiles;
      this.refs.dropZone.uploadFilesFromChild(bin_files);*/
      let bin_files = this.state.binFiles;
      let dnld = bin_files[0].bin_file.replace(/ /g, "+");
      let binaryString = window.atob(dnld);
      var fileSize = binaryString.length;
      if(fileSize > 5000000){
        let alert_param = { title: "Alert", message: "This file may take some time to upload and for users to download. Would you like to continue to upload this document?", ok_text: "OK", confirm: true, alertHandler: this.processORcancel, stack: {} };
        this.setState({ alert_param: alert_param });
      }else{
        this.refs.dropZone.uploadFilesFromChild(bin_files);
      }
    }else{
      this.setState({loading: true})
      let postData = { command: "add_followup_task" };
      if(!this.state.is_complete)delete task["completed_note"]
      let curmonth = parseInt(this.state.due_date.getMonth());
      let currmonth = curmonth + 1;
      var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear() + "/" + currmonth + "/" : this.state.due_date.getFullYear() + "/0" + currmonth + "/";
      dueDateStr += this.state.due_date.getDate() < 10 ? "0" + this.state.due_date.getDate() : this.state.due_date.getDate();
      task["due_date"] = dueDateStr;
      postData["task"] = task;
      postData["mode"] = mode;
      console.log("postData", postData);
      let api = new APICall();
      api.command(postData, this.processFollowup);
    }
  };

  AlertCompleteHandler = (result, stack) => {
    this.setState({ alert_param: null });
  };

  processORcancel = (result, stack) => {
    if (!result || stack.prevent) {
      this.setState({ alert_param: null });
      return;
    }
    this.setState({ alert_param: null });
    let bin_files = this.state.binFiles;
    this.refs.dropZone.uploadFilesFromChild(bin_files);
  }

  onUploadComplete = (files) => {
    let returnedFiles = JSON.parse(files);
    this.setState({loading: true });
    let bin_files = this.state.binFiles;
    if (typeof bin_files !== 'undefined' && bin_files.length > 0) {
      bin_files.forEach((file) => {
        let fileName = file.name;
        let refID = returnedFiles.find((o) => o.inserted_file_name == fileName)['ref_id'];
        delete file.bin_file;
        file.ref_id = refID;
      });
    }
    this.setState({ alert_param: null });
    let postData = { command: "add_followup_task" };
    let task = this.state.task;
    task["uploaded_doc"] = bin_files;
    if(!this.state.is_complete)delete task["completed_note"]
    let curmonth = parseInt(this.state.due_date.getMonth());
    let currmonth = curmonth + 1;
    var dueDateStr = currmonth >= 10 ? this.state.due_date.getFullYear() + "/" + currmonth + "/" : this.state.due_date.getFullYear() + "/0" + currmonth + "/";
    dueDateStr += this.state.due_date.getDate() < 10 ? "0" + this.state.due_date.getDate() : this.state.due_date.getDate();
    task["due_date"] = dueDateStr;
    postData["task"] = task;
    // postData["mode"] = mode;
    postData["mode"] = 0;
    console.log("postData", postData);
    let api = new APICall();
    api.command(postData, this.processFollowup);

  }

  processFollowup = (result) => {
    console.log("result processFollowup", result);
    let msg = "The follow-up task has been added.";
    if ("mode" in result) {
      switch (result["mode"]) {
        case 1:
          msg = "The follow-up task has been updated.";
          break;
        case 2:
          msg = "The follow-up task has been completed.";
          break;
      }
    }

    let alert_param = { title: "Success", message: msg, ok_text: "OK", confirm: false, alertHandler: this.AlertremoveHandler, stack: { id: 0 } };
    this.setState({ alert_param: alert_param, loading: false });
  };

  AlertremoveHandler = (result, stack) => {
    this.setState({ alert_param: null, showModal: false, binFiles: [] });
    this.props.reloadChildTasks(this.state.doc_id);
  };

  filesLoaded = (files) => {
    let contact_id = Store.getStoreData("contact").ID;
    let t = {};
    for (let i = 0; i < files.length; i++) {
      files[i].name = files[i].name.replace("&", "");
      if (!("user_id" in files[i])) {
        files[i].user_id = contact_id;
        files[i].upload_date = last_action_time;
      }
      t[files[i].uid.toString()] = files[i].name;
    }

    let task = this.state.task;
    task["file_name"] = t;
    this.setState({ binFiles: files, addnew: false, task: task });
  };

  render() {
    if (!this.state.ready) {
      return <div></div>;
    }
    console.log("this.state", this.state);
    return (
      <div>
        {
          (() => {
            if(this.state.loading) return <CSLLoader style={{ position: "absolute" }} />;
          })()
        }
        <OverviewInfoHeader>
          <div style={{ float: "left", color: "#ffffff", fontWeight: "600" }}>Follow-up Actions</div>
          {(() => {
            if (Store.getStoreData("role") !== "team") {
              return (
                <div style={{ float: "right", color: "#ffffff", fontSize: "17px", cursor: "pointer" }} onClick={this.showFollowupTaskModal}>
                  <FaPlusCircle />
                </div>
              );
            }
          })()}
          <div style={{ clear: "both" }}></div>
        </OverviewInfoHeader>
        {(() => {
          if (this.state.showModal) {
            return (
              <ScoreContainer>
                <Style.DocHeader style={{ borderRadius: "0", height: "50px" }}>
                  <div style={{ fontSize: "14px", float: "left", marginTop: "15px", marginLeft: "10px" }}>Add Follow-up Task</div>
                  <Style.ModalHeaderCloseBtn onClick={this.closeFollowupTaskModal}>
                    <FaTimes />
                  </Style.ModalHeaderCloseBtn>
                </Style.DocHeader>

                <ScoreBodyContainer>
                  <Style.ModalNameLabelDiv>Task Name</Style.ModalNameLabelDiv>
                  <Style.SpacerL></Style.SpacerL>
                  <Style.ModalNameInputDiv>
                    <Style.ModalInput type="text" name="name" onChange={this.handleChange} value={this.state.task["name"]} />
                  </Style.ModalNameInputDiv>
                  <Style.SpacerL></Style.SpacerL>
                  <Style.ModalNameLabelDiv>Description</Style.ModalNameLabelDiv>
                  <Style.SpacerL></Style.SpacerL>
                  <Style.ModalNameInputDiv>
                    <Style.ModalTextArea name="description" onChange={this.handleChange} value={this.state.task["description"]} />
                  </Style.ModalNameInputDiv>
                  <Style.SpacerL></Style.SpacerL>

                  <Style.ModalNameLabelDiv>Assign To</Style.ModalNameLabelDiv>
                  <Style.SpacerL></Style.SpacerL>
                  <Style.MRModalSelect onChange={this.handleChange} name="assigned_to" value={this.state.task["assigned_to"]}>
                    {(() => {
                      if (Object.keys(this.state.contact_list).length !== 0) {
                        let c = [];
                        for (let key in this.state.contact_list) {
                          c.push(
                            <option key={key} value={key}>
                              {this.state.contact_list[key]}
                            </option>
                          );
                        }
                        return c;
                      }
                    })()}
                  </Style.MRModalSelect>
                  <Style.SpacerL></Style.SpacerL>
                  <Style.ModalNameLabelDiv>Due Date</Style.ModalNameLabelDiv>
                  <Style.SpacerL></Style.SpacerL>
                  <CSLDateCover>
                    <DatePicker onChange={this.onDateChangeDueBy} clearIcon={null} calendarIcon={null} locale={"en-GB"} value={this.state.due_date} />
                  </CSLDateCover>
                  <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={this.state.addnew} buttontxt="Add an attachment" onUploadComplete={this.onUploadComplete} ref="dropZone" />

                  {(() => {
                    if (this.state.task["id"] > 0 && !this.state.addnew && this.state.binFiles.length === 0) {
                      return <CSLAtteachmentViewer files={this.state.task["file_name"]} task_id={this.state.task["id"]} />;
                    }
                  })()}
                  <Style.SpacerL></Style.SpacerL>
                  {
                    (() => {
                      if (this.state.is_complete) {
                        return(<div>
                          <Style.ModalNameLabelDiv>Comment</Style.ModalNameLabelDiv>
                          <Style.SpacerL></Style.SpacerL>
                          <Style.ModalNameInputDiv>
                            <Style.ModalTextArea name="completed_note" onChange={this.handleChange} value={this.state.task["completed_note"]} />
                          </Style.ModalNameInputDiv>
                        </div>)
                      }
                    })()
                  }
                  {
                    (() => {
                      if (this.state.task["is_completed"]) {
                        return(<div>
                          <Style.ModalNameLabelDiv>Completion Comments</Style.ModalNameLabelDiv>
                          <Style.SpacerL></Style.SpacerL>
                          <Style.ModalNameInputDiv>
                            <Style.ModalTextArea value={this.state.task["completed_note"]} />
                          </Style.ModalNameInputDiv>
                        </div>)
                      }
                    })()
                  }

                </ScoreBodyContainer>
                <Style.DocFooter style={{ textAlign: "right", height: "35px", padding: "10px" }}>
                  {(() => {
                    if (!this.state.task["is_completed"]) {
                      return (
                        <div>
                         {
                          (() => {
                            if(this.state.is_complete){
                              return <MRModalNextBtn onClick={this.addSubTask(2)} disabled={this.state.saving} >SUBMIT</MRModalNextBtn>;
                            }else{
                              return(<div>
                                  {
                                    (() => {
                                    if (this.state.task["id"] > 0) {
                                      return (
                                        <div>
                                          <MRModalNextBtn onClick={this.addSubTask(1)} disabled={this.state.saving} >UPDATE</MRModalNextBtn>
                                          <MRModalNextBtn onClick={this.addCompleteNote} disabled={this.state.saving} >COMPLETE</MRModalNextBtn>
                                        </div>
                                      );
                                    } else {
                                      return <MRModalNextBtn onClick={this.addSubTask(0)} disabled={this.state.saving} >SUBMIT</MRModalNextBtn>;
                                    }
                                  })()
                                }
                              </div>)
                            }
                          })()
                         }
                        </div>
                      );
                    }
                  })()}

                  <MRModalCancelBtn onClick={this.closeFollowupTaskModal}>CANCEL</MRModalCancelBtn>
                </Style.DocFooter>
                <AlertBox alertParam={this.state.alert_param} />
              </ScoreContainer>
            );
          }
        })()}
      </div>
    );
  }
}
export default AddTaskModal;
