import React from "react";
import { FaTimes, FaTrashAlt, FaQuestionCircle } from "react-icons/fa";
import { MdClose } from "react-icons/md";
import APICall from "../../Common/APICall.js";
import * as Style from "../Common/StyledComponents";
import styled from "styled-components";
import AlertBox from "../Common/AlertBox";
import Store from "../../Common/Store.js";
import CSLLoader from "../Common/CSLLoader";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

const FolderContainer = styled.div`
    display: block;
    box-sizing: border-box;
    max-height: 90vh;
    width: 90%;
    max-width: 800px;
    position: fixed;
    overflow-y: auto;
    margin: auto;
    z-index: 1001;
    background-color: #f7f7f7;
    border-radius: 5px;
    box-shadow: 0 0 20px #a7a6a6;
`;

const ModalHeaderCloseBtn = styled.div`
  float: right;
  box-sizing: border-box;
  color: #282828;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
`;

const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #046de4;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 100px;
  font-size: 13px;
  height: 39px;
  border-radius: 4px;
  float: right;
  cursor: pointer;
`;

class AddFolderModal extends React.Component {
  state = { name: "", alert_param: null, err: false, loading: false };

  closeModal = (event) => {
    if (event) event.preventDefault();
    this.props.closeUserDialog();
  };

  handleChange = (event) => {
    event.preventDefault();
    let { name } = this.state;
    name = event.target.value;
    this.setState({ name });
  };

  AddFolder = (event) => {
    event.preventDefault();
    if (this.state.name === "") {
      let alert_param = { title: "Alert", message: "Please fill the field", ok_text: "OK", confirm: false, alertHandler: this.alertRemove, stack: { id: 0 } };
      this.setState({ alert_param: alert_param });
      return false;
    }
    const postData = { command: "add_folder", name: this.state.name, parentID: this.props.folder_id };
    postData['last_actin_time'] = Store.getStoreData('folder_last_actin_time');
    postData['role'] = Store.getStoreData('role');
    console.log("postData", postData)
    this.setState({loading: true})
    const api = new APICall();
    api.command(postData, this.processDocument);
  };

  alertRemove = (result, stack) => {
    this.setState({ alert_param: null });
  };

  processDocument = (result) => {
    let folders = result.folders;
    console.log("folders", folders)
    let folder_last_actin_time = folders.sort((a, b) => b.last_action_time - a.last_action_time)[0].last_action_time;
    Store.updateStore("folder_last_actin_time", folder_last_actin_time)
    let documents = Store.getStoreData('docs');
    let docs = [...folders, ...documents];
    Store.updateStore('docs', docs);
    // for(let key in documents)
    
    let folders_assoc = Store.getStoreData('folders_assoc')
    for(let k in folders) {
      if(folders[k].is_deleted === 0) folders_assoc[folders[k].id.toString()] = folders[k].name;
    }
    Store.updateStore('folders_assoc', folders_assoc);


    let alert_param = { title: "Success", message: "Your folder has been added", ok_text: "OK", confirm: false, alertHandler: this.AlertremoveHandler, stack: { id: 0 } };
    this.setState({ alert_param: alert_param, loading: false });
    // console.log("add folder", folders)
  };

  AlertremoveHandler = (result, stack) => {
    this.setState({ alert_param: null });
    this.props.refreshParent();
  };

  render() {
    if (this.state.content === null) return <div>Loading...</div>;
    
    return (
    <Wrapper>
      <FolderContainer>
        <div>
        {
          (() => {
            if(this.state.loading) return <CSLLoader />;
          })()
        }
          <Style.DocHeader>
            <div style={{ fontSize: "14px", float: "left", marginTop: "24px", marginLeft: "10px" }}>Add Folder</div>
            <ModalHeaderCloseBtn onClick={this.closeModal}>
              <MdClose />
            </ModalHeaderCloseBtn>
          </Style.DocHeader>
          <Style.DocBody>
            <Style.DocFormContainer>
              <div style={{ padding: "10px" }}>
                <Style.ModalNameLabelDiv>Name</Style.ModalNameLabelDiv>
              </div>
              <Style.SpacerL></Style.SpacerL>
              <Style.ModalNameInputDiv>
                <Style.ModalInput type="text" name="name" onChange={this.handleChange} value={this.state.name} />
              </Style.ModalNameInputDiv>
            </Style.DocFormContainer>
          </Style.DocBody>
          <Style.DocFooter>
            <MRButton style={{ top: "20px", right: "140px", position: "absolute", backgroundColor: "#FFFFFF", color: "#757575" }} onClick={this.closeModal}>
              Cancel
            </MRButton>
            <MRButton style={{ top: "20px", right: "7px", position: "absolute", backgroundColor: "#1A3552", color: "white", width: "115px" }} onClick={this.AddFolder}>
              Submit
            </MRButton>
          </Style.DocFooter>
        </div>
        <AlertBox alertParam={this.state.alert_param} />
      </FolderContainer>
    </Wrapper>
    );
  }
}

export default AddFolderModal;
