import React from "react";
import Store from "../../Common/Store.js";
import styled from "styled-components";
import { FaPlus, FaTimes } from "react-icons/fa";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 5000px;
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.8;
`;

const OkButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #04aca7;
  color: #ffffff;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #37ada7;
  margin-left: 5px;
  font-size: 11px;
  cursor: pointer;
  float: right;
  font-size: 18px;
`;

const OkDangerButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: #ea5a6e;
  color: #ffffff;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #37ada7;
  margin-left: 5px;
  font-size: 11px;
  cursor: pointer;
  float: right;
  font-size: 18px;
`;

const CancelButton = styled.button`
  display: inline-block;
  box-sizing: border-box;
  background-color: white;
  color: #646a7e;
  width: 100px;
  height: 35px;
  border-radius: 4px;
  border: 1px solid #37ada7;
  margin-left: 5px;
  font-size: 11px;
  cursor: pointer;
  float: right;
  font-size: 18px;
`;

const AlertBoxContainer = styled.div`
  position: fixed;
  top: 40vh;
  left: 40vw;
  width: 450px;
  box-shadow: 8px 8px 8px 8px rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: #f7f7f7;
  color: #2d2d2d;
  border-radius: 10px;
  border-style: solid;
  border-color: #f7f7f7;
  z-index: 2000;
`;
const AlertBoxHeader = styled.div`
  width: calc(100% - 15px);
  background-color: #f7f7f7;
  color: #646a7e;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  height: 35px;
  padding-left: 15px;
  padding-top: 15px;
  font-size: 20px;
  font-weight: 600;
`;
const AlertBoxBody = styled.div`
  width: calc(100% - 30px);
  background-color: #f7f7f7;
  color: #646a7e;
  font-size: 18px;
  min-height: 100px;
  text-align: center;
  padding-top: 20px;
  padding-left: 15px;
  padding-right: 15px;
`;
const AlertBoxFooter = styled.div`
  width: calc(100% - 15px);
  background-color: #edf1f2;
  color: #2d2d2d;
  height: 50px;
  padding-right: 15px;
  padding-top: 10px;
`;

class ConfirmationBox extends React.Component {
  state = { view_count: 0 };

  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.setState({
      display: null,
    });
  }
  componentDidUpdate(prev_props) {
    if (this.props != prev_props)
      if (this.props.confirm_modal) {
        this.setState({ display: true });
      }
  }

  processCancel = () => {
    this.setState({ display: false });
    this.props.cancelConfirm();
  };

  render() {
    if (!this.state.display) return <div></div>;

    return (
      <div>
        <InactiveOverlay />
        <AlertBoxContainer id="CSLAlertBox">
          <AlertBoxHeader>Alert </AlertBoxHeader>
          <AlertBoxBody>{this.props.confirmMessage} </AlertBoxBody>
          <AlertBoxFooter>
            {(() => {
              return <OkButton onClick={this.props.confirm}>Confirm</OkButton>;
            })()}
            {(() => {
              return (
                <CancelButton onClick={this.processCancel}>Cancel</CancelButton>
              );
            })()}
          </AlertBoxFooter>
        </AlertBoxContainer>
      </div>
    );
  }
}
export default ConfirmationBox;
