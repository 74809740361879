import React, { version } from "react";
import styled from "styled-components";
import Store from "../../Common/Store.js";
import CadrsLoader from "../../Common/CadrsLoader.js";
import * as Style from "../Common/StyledComponents";
import APICall from "../../Common/APICall.js";
import ComDecom from '../../Common/ComDecom.js';
import CSLTable from "../Common/CSLTable";
import GridView from "./Gridview.js";
import AddDocumentModal from "./AddDocumentModal.js";
import AddFolderModal from "./AddFolderModal";
import CSLLoader from "../Common/CSLLoader";
import AlertBox from "../Common/AlertBox";
import DOCcards from "./DOCcards";
import AdvancedFilter from "../Partials/AdvancedFilter";
import TobHeader from "../Partials/TobHeader";
import Utils from "../../Common/Utils.js";
import { FaFolder } from "react-icons/fa";
import DocumentViewModal from "./DocumentViewModal.js";
import { FaEllipsisV } from "react-icons/fa";
import { RiPushpin2Fill } from "react-icons/ri";
import FolderViewModal from "./FolderViewModal.js";
import DocAddToFolderModal from "./DocAddToFolderModal.js";
import VEllipsisMenu from "./Menu/VerticalEllipsisMenu";
import VEllipsisMenuFolder from "./Menu/VerticalEllipsisMenuFolder";
import cookie from "react-cookies";
import BulkModal from "./Bulk/BulkModal.js";
import ContextMenu from "../Moduleconfig/ContextMenu";
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';


const moment = require("moment");

const FloatLeft = styled.div`
  float: left;
`;
const FloatRight = styled.div`
  float: right;
  top: 0;
  right: 5px;
`;

const IndexContainer = styled.div`
  padding-top: 8px;
  padding-left: 5px;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 3000px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;
const pinstyle = {
  "font-size": "12px",
  color: "#11ABA6",
};

class DocLibrary extends React.Component {
  state = {
    ready: false,
    documents: [],
    listview: true,
    rowchecked: false,
    modal_view: null,
    folderAdd_view: null,
    documentdetails: [],
    folderdetails: {},
    maindocument: null,
    documentversion: [],
    alert_param: null,
    filedata: null,
    view_count: 0,
    doc_id: 0,
    version_id: 0,
    folder_id: 0,
    parentID: 0,
    view_count: 0,
    disabled: false,
    folder_struct: {},
    restore: false,
    toggle: {pindoc: false, recyclebin: false, recentlydeleted: false},
    loading: false,
    export_visiable: true
  };
  parentID;
  constructor(props) {
    super(props);
    this.parentID = 0;
  }
  componentDidMount = () => {
    this.callDocLibrary();
  };
  componentDidUpdate = (prevprops) => {
      if(this.props === prevprops) return
      this.callDocLibrary();
  }

  callDocs() {
    let companies_assoc = Store.getStoreData('companies_assoc');
    let documents = Store.getStoreData('documents');
    let d = [];
    for (let key in documents) {
      let gc = JSON.parse(documents[key].gcs);
      let include = this.isGCinclude(companies_assoc, gc);
      if(include){
        documents[key].select = false;
        d.push(documents[key]);
      }
    }
    let view = cookie.load("view");
    let listview = typeof view !== "undefined" ? view["listview"] : this.state.listview;
    let folder_struct = {};
    this.setState({ documents: d, ready: true, view_count: this.state.view_count + 1, listview: listview, folder_struct:folder_struct });
  }

  callDocLibrary(obj=null) {
    let companies_assoc = Store.getStoreData('companies_assoc');
    let documents = Store.getStoreData('docs');
    console.log("documents", documents)
    let contact = Store.getStoreData('contact');
    let folder_id = ( obj!== null && 'folder_id' in obj) ? obj.folder_id : this.state.folder_id;
    let parentID = ( obj!== null && 'parent_id' in obj) ? obj.parent_id : this.state.parentID;
    let export_visiable = false;
    console.log("folder_id ####", folder_id, parentID)
    let is_pin = false;
    let d = [];
    for (let key in documents) {
      if(documents[key].is_deleted === 0){
        console.log("documents[key].is_deleted", documents[key].is_deleted, documents[key].doc_id)
        if(documents[key].type === -1){
          // console.log("folder_id .type####", documents[key].type, documents[key].parent_id, folder_id)
          if(parseInt(documents[key].parent_id) === parseInt(folder_id)){
            documents[key].select = false;
            d.push(documents[key]);
          }
        }else{
          if(parseInt(documents[key].folder_id) === parseInt(folder_id)){
            let gc = JSON.parse(documents[key].gcs);
            let include = this.isGCinclude(companies_assoc, gc);
            if(include){
              documents[key].select = false;
              d.push(documents[key]);
            }
          }
        }

      }
    }
    for(let xt in d) {
      if(d[xt].doc_id > 0){
        export_visiable = true;
      }
    }

   console.log("folder_id ####", d)
    let view = cookie.load("view");
    let listview = typeof view !== "undefined" ? view["listview"] : this.state.listview;
    let folder_struct = {};
    this.setState({ documents: d, ready: true, view_count: this.state.view_count + 1, listview: listview, folder_struct:folder_struct, folder_id: folder_id, parentID: parentID, restore: false, export_visiable: export_visiable });
  }

  isGCinclude(gcs, doc_gcs){
    // console.log("hasOwnProperty", gcs, doc_gcs)
    let is_include = true;
    for(let key in doc_gcs){
      is_include = false
      is_include = gcs.hasOwnProperty(key);
      if(is_include)break;
    }
    return is_include;
  }

  isonlyGCinclude(gcs, doc_gcs){
    // console.log("hasOwnProperty", gcs, doc_gcs)
    let is_include = false;
    for(let key in doc_gcs){
      // is_include = false
      is_include = gcs.hasOwnProperty(key);
      if(is_include)break;
    }
    return is_include;
  }

  processDocuments = (result) => {
    console.log("result.docs", result)
    let companies_assoc = Store.getStoreData('companies_assoc');
    let documents = ('docs' in result) ? result.docs : result.result;
    if('fields' in documents && 'rows' in documents){
       documents = ComDecom.deCompress(documents, false, 'id');
    }
    
    console.log("processDocuments result", documents)
    let is_pin = ('pinned_doc' in result) ? true : false;
    let contact = Store.getStoreData('contact');

    let d = [];
    for (let key in documents) {
      let gc = JSON.parse(documents[key].gcs);
      let include = this.isGCinclude(companies_assoc, gc);
      if(include){
        documents[key].select = false;
        if(is_pin){
          let user_pins = JSON.parse(documents[key].user_pins);
          if(user_pins[contact.ID.toString()]){
            d.push(documents[key]);
          }
        }else{
          d.push(documents[key]);
        }
      }
    }
    if ("gc_filters" in result) {
      d = [];
      for(let k in documents){
        if(this.isonlyGCinclude(result.gc_filters, JSON.parse(documents[k].gcs))){
          d.push(documents[k])
        }
      }
    }
    // let cards = result.cards;
    // Store.updateStore("doc_cards", cards);
    // CadrsLoader.divideCards(result.cards);
    if ("cards" in result) {
      CadrsLoader.divideCards(result.cards);
    }
    let view = cookie.load("view");
    let listview = typeof view !== "undefined" ? view["listview"] : this.state.listview;
    let folder_struct = ('parent_folders' in result) ? result.parent_folders : {};
    this.setState({ documents: d, ready: true, view_count: this.state.view_count + 1, listview: listview, folder_struct:folder_struct });
  };

  selectAll = (event) => {
    let rowchecked = this.state.rowchecked;
    rowchecked = rowchecked ? false : true;
    let documents = this.state.documents;
    for (let key in documents) {
      documents[key].select = rowchecked;
    }
    this.setState({ documents: documents, rowchecked: rowchecked });
  };

  selectDocument = (event) => {
    let index = event.target.value;
    let documents = this.state.documents;
    documents[index].select = documents[index].select ? false : true;
    this.setState({ documents: documents });
  };

  processDocumentsData = () => {
    let doc_types = Store.getStoreData("doc_types");
    let contact_assoc = Store.getStoreData("listcontacts");
    let documents = this.state.documents;
    console.log("documents", documents.length);
    let ret = { data: [], columns: [] };
    let dis = (Store.getStoreData('role') === 'admin_manager' || Store.getStoreData('role') === 'document_manager') ? false : false
    let i = 1;
    ret.columns = [
      {
        Header: (
          <div>
            <input type="checkbox" checked={this.state.rowchecked} onClick={this.selectAll} disabled = {this.state.disabled}  />
          </div>
        ),
        Cell: (row) => (
          <div>
            <input type="checkbox" checked={row.original.select} value={row.original.index} onClick={this.selectDocument} disabled={this.state.disabled} />
          </div>
        ),

        width: 50,
        headerStyle: { textAlign: "left" },
      },

      {
        Header: "NAME",
        accessor: "name",
        minWidth: 130,
        Cell: (row) => (
          <div>{
            (() => {
              if(row.original.is_deleted === 1){
                return(<div>
                  <span style={{ paddingRight: 5 }}>
                    {row.original.document_type == null ? <FaFolder /> : ""}
                    {row.original.is_pinned == 1 ? <RiPushpin2Fill style={pinstyle} /> : ""}
                  </span>

                  {row.original.name}
                </div>)
              }else{
                return(<div style={{ cursor: "pointer" }} onClick={() => this.onClickDocumentName("showdocument", row.original)}>
                  <span style={{ paddingRight: 5 }}>
                    {row.original.document_type == null ? <FaFolder /> : ""}
                    {row.original.is_pinned == 1 ? <RiPushpin2Fill style={pinstyle} /> : ""}
                  </span>

                  {row.original.name}
                </div>)
              }
            })()
          }

          </div>

        ),
        headerStyle: { textAlign: "left" },
      },
      {
        Header: "TYPE",
        accessor: "document_type",
        minWidth: 130,
        headerStyle: { textAlign: "left" },
      },
      {
        Header: "DOC OWNER",
        accessor: "document_owner",
        minWidth: 100,
        headerStyle: { textAlign: "left" },
      },
      {
        Header: "UPLOADED",
        accessor: "uploaded_date",
        minWidth: 80,
        headerStyle: { textAlign: "left" },
        sortMethod: (a, b) => {
          if (a === b) {
            return 0;
          }
          let adue = a.split("/");
          const aReverse = parseInt(adue[2]) * 10000 + parseInt(adue[1]) * 100 + parseInt(adue[0]);
          let bbdue = b.split("/");
          const bReverse = parseInt(bbdue[2]) * 10000 + parseInt(bbdue[1]) * 100 + parseInt(bbdue[0]);
          return aReverse > bReverse ? 1 : -1;
        },
      },
      {
        Header: "MODIFIED BY",
        accessor: "modified_by",
        minWidth: 80,
        headerStyle: { textAlign: "left", visible: "false" },
      },
      {
        Header: "MODIFIED",
        accessor: "modified",
        minWidth: 80,
        headerStyle: { textAlign: "left", visible: "false" },
      },
      {
        Header: "VERSION",
        accessor: "version",
        minWidth: 50,
        Cell: (row) => (
          <div style={{ position: "relative" }}>
            <FloatLeft>{row.original.version} </FloatLeft>
            {
              (() => {
                if(row.original.is_deleted === 0){
                  return(<FloatRight style={{ cursor: "pointer" }} onClick={() => console.log(row)}>
              {row.original.document_type == null ? <VEllipsisMenuFolder viewdoc={() => this.ViewDocDetail("showdocument", row.original)} DeleteDocs={() => this.DeleteDocs(row.original.folder_id, -1)} type={row.original.document_type} info={row.original} options={["View Folder", "Delete"]} /> : <VEllipsisMenu viewdoc={() => this.ViewDocDetail("showdocument", row.original)} MoveDocstoFolder={this.MoveDocstoFolder} DeleteDocs={() => this.DeleteDocs(row.original.doc_id, 1)} PinnedDocs={() => this.PinnedDocs(row.original.doc_id)} UnpinnedDocs={() => this.UnpinnedDocs(row.original.doc_id)} type={row.original.document_type} info={row.original} options={["View Details", "Delete", "Pin Document", "Download"]} is_pinned={row.original.is_pinned} />}
            </FloatRight>)
                }

              })()
            }  
          </div>
        ),
        headerStyle: { textAlign: "left", visible: "false" },
      },
    ];

    for (let k = 0; k < documents.length; k++) {
      let user_pins = JSON.parse(documents[k].user_pins);
      let contact = Store.getStoreData('contact')
      console.log("user_pins[Store.getStoreData('contact').ID]", user_pins[Store.getStoreData('contact').ID])
      let elem = {
        index: k,
        name: documents[k].name,
        document_type: documents[k].type == -1 ? null : Object.keys(doc_types).length == 0 || documents[k].type == 0 ? "N/A" : doc_types[documents[k].type.toString()],
        document_owner: contact_assoc[documents[k].doc_owner.toString()],
        uploaded_date: documents[k].uploaded == -1 ? "" : Utils.unixtimeTodate(documents[k].uploaded),
        modified_by: documents[k].updated_by === 0 ? "N/A" : contact_assoc[documents[k].updated_by.toString()],
        modified: documents[k].updated_at == 0 ? "" : Utils.unixtimeTodate(documents[k].updated_at),
        version: documents[k].doc_version == -1 ? "" : documents[k].doc_version,
        select: documents[k].select,
        folder_id: documents[k].id,
        doc_id: documents[k].doc_id,
        visibility: documents[k].visibility == 0 ? "Public" : "Private",
        version_id: documents[k].version_id,
        id: documents[k].id,
        parent_id: documents[k].parent_id,
        is_pinned: user_pins[Store.getStoreData('contact').ID] === undefined ? 0 : 1,
        is_deleted: ('is_deleted' in documents[k]) ? documents[k].is_deleted : 0
      };
      ret.data.push(elem);
    }
    ret.reords = documents.length;
    return ret;
  };

  checkDocument = (id, type) => {
    let documents = this.state.documents;
    for (let key in documents) {
      if (type === "folder" && documents[key].id === id) {
        documents[key].select = documents[key].select ? false : true;
      } else if (type === "file" && documents[key].doc_id === id) {
        documents[key].select = documents[key].select ? false : true;
      }
    }
    this.setState({ documents: documents });
  };

  AddDocument = (view) => {
    console.log(view)
    this.setState({ modal_view: view });
  };

  onClickDocumentName = (view, obj) => {
    if (obj.document_type !== null && obj.document_type != -1) {
      this.ViewDocDetail(view, obj);
    } else {
      this.callDocLibrary(obj)
    }
  };

  ViewDocDetail = (view, obj) => {
    if (obj.document_type == null || obj.document_type == -1) {
      this.setState({ modal_view: "folder_view", folder_id: obj.id, ready: true });
    } else {
      this.setState({ modal_view: "doc_view", doc_id: obj.doc_id, version_id: obj.version_id, ready: true });
    }
  };

  backToListing = (folder_id, parent_id) => {
    let docs = Store.getStoreData('docs');
    let doc = docs.find(x => x.id.toString() === parent_id.toString()) ? docs.find(x => x.id.toString() === parent_id.toString()) : {};
    console.log("doc", doc, docs, parent_id)
    let obj = {};
    obj['folder_id'] = ('id' in doc) ? doc.id : 0;
    obj['parent_id'] = ('parent_id' in doc) ? doc.parent_id : 0;
    if(parent_id===0)obj['folder_id'] = 0
    this.callDocLibrary(obj)
  };

  UploadToFolder = (view) => {
    let documents = this.state.documents;
    let is_empty = true;
    for (let key in documents) {
      if (documents[key].select && documents[key].type === -1) {
        let alert_param = { title: "Alert", message: "Please select  document only", ok_text: "OK", confirm: false, alertHandler: this.AlertCloseHandler, stack: { id: 0 } };
        this.setState({ alert_param: alert_param });
        return false;
      }
      if (documents[key].select) {
        is_empty = false;
      }
    }
    if (is_empty) {
      let alert_param = { title: "Alert", message: "Please select  at least one document ", ok_text: "OK", confirm: false, alertHandler: this.AlertCloseHandler, stack: { id: 0 } };
      this.setState({ alert_param: alert_param });
      return false;
    }
    this.setState({ modal_view: view });
  };

  AddFolder = (view) => {
    this.setState({ modal_view: view });
  };

  DeleteDocs = (docid, type) => {
    let documents = this.state.documents;
    console.log("documents", documents);
    let doc_id = {};
    doc_id["doc"] = {};
    doc_id["folder"] = {};
    //alert(type);
    if (docid !== 0) {
      type !== -1 ? (doc_id["doc"][docid] = 1) : (doc_id["folder"][docid] = 1);
    } else {
      for (let key in documents) {
        if (documents[key].select) documents[key].type === -1 ? (doc_id["folder"][documents[key].id] = 1) : (doc_id["doc"][documents[key].doc_id] = 1);
      }
    }
    //  alert(JSON.stringify(doc_id));

    if (Object.keys(doc_id["doc"]).length === 0 && Object.keys(doc_id["folder"]).length === 0) {
      let alert_param = { title: "Alert", message: "Please select a document", ok_text: "OK", confirm: false, alertHandler: this.AlertCloseHandler, stack: { id: 0 } };
      this.setState({ alert_param: alert_param });
      return false;
    }
    let msg = "";
    if (Object.keys(doc_id.doc).length === 0) {
      msg = "Are you sure you want to delete this folder?";
    } else if (Object.keys(doc_id.folder).length === 0) {
      msg = "Are you sure you want to delete this document?";
    } else {
      msg = "Are you sure you want to delete";
    }

    let title = "Confirm";
    let alert_param = { title: title, message: msg, ok_text: "OK", confirm: true, alertHandler: this.confirmDeleteAlertHandler, stack: { parms: doc_id } };
    this.setState({ alert_param: alert_param });
  };

  confirmDeleteAlertHandler = (result, stack) => {
    if (!result || stack.prevent) {
      this.setState({ alert_param: null });
      return;
    }
    this.setState({ alert_param: null, loading: true });
    let parms = stack.parms;
    let docs = parms.doc;
    let folders = parms.folder;
    let documents = Store.getStoreData('docs')
    for(let key in documents){
      if(documents[key].type === -1){
        if(folders[documents[key].id] === 1){
          documents[key].is_deleted = 1
        }
      }else{
        if(docs[documents[key].doc_id] === 1){
          documents[key].is_deleted = 1
        }
      }
    }
    Store.updateStore('docs', documents)
    let api = new APICall();
    let postData = {};
    postData["command"] = "document_operation";
    postData["mode"] = "delete";
    postData["docids"] = parms;
    console.log("document_operation", postData)
    api.command(postData, this.processDocument);
  };

  restoreDeletedDocs = (docid) => {
    let documents = this.state.documents;
    let doc_id = {};
    doc_id["doc"] = {};
    doc_id["folder"] = {};

    for (let key in documents) {
      if (documents[key].select && documents[key].is_deleted === 1) documents[key].type === -1 ? (doc_id["folder"][documents[key].id] = 1) : (doc_id["doc"][documents[key].doc_id] = 1);
    }

    console.log("postData", doc_id);

    if (Object.keys(doc_id["doc"]).length === 0 && Object.keys(doc_id["folder"]).length === 0) {
      let alert_param = { title: "Alert", message: "Please select a deleted document", ok_text: "OK", confirm: false, alertHandler: this.AlertCloseHandler, stack: { id: 0 } };
      this.setState({ alert_param: alert_param });
      return false;
    }
    this.setState({modal_view: "movetofolder", restore: true})

    /*let msg = "Would you like to restore this document to its original location (all parent folders and documents that have been changed will be recreated), or choose a different location?";
    let title = "Confirm";
    let alert_param = { title: title, message: msg, ok_text: "OK", confirm: true, multi_button: true, alertHandler: this.confirmRestoreAlertHandler, stack: { parms: doc_id } };
    this.setState({ alert_param: alert_param });*/

  };

  confirmRestoreAlertHandler = (result, stack) => {
    if (!result || stack.prevent) {
      this.setState({ alert_param: null });
      return;
    }
    this.setState({ alert_param: null });
    if('original' in stack){
      if(stack['original']){
        let parms = stack.parms;
        let api = new APICall();
        let postData = {};
        postData["command"] = "document_operation";
        postData["mode"] = "restore";
        postData["docids"] = parms;
        console.log("postData", postData);
        api.command(postData, this.processDocument);
      }else{
        this.setState({modal_view: "movetofolder", restore: true})
      }
    }else{
      
      let parms = stack.parms;
      let api = new APICall();
      let postData = {};
      postData["command"] = "document_operation";
      postData["mode"] = "restore";
      postData["docids"] = parms;
      console.log("postData", postData);
      api.command(postData, this.processDocument);
    }

  };

  UnpinnedDocs = (docid) => {
    let doc_id = {};
    doc_id[docid] = 1;
    let msg = "Are you sure you want to unpin document(s)?";
    let title = "Confirm";
    let alert_param = { title: title, message: msg, ok_text: "OK", confirm: true, alertHandler: this.confirmunPinAlertHandler, stack: { parms: doc_id } };
    this.setState({ alert_param: alert_param });
  }

  confirmunPinAlertHandler = (result, stack) => {
    if (!result || stack.prevent) {
      this.setState({ alert_param: null });
      return;
    }
    this.setState({ alert_param: null});
    let parms = stack.parms;
    console.log("pin parms", parms)
    
    let documents = Store.getStoreData('docs')
    let contact = Store.getStoreData('contact')
    for(let key in documents){
        if(documents[key].type !== -1 ){
          if(parms[documents[key].doc_id] === 1){
            let user_pins = JSON.parse(documents[key].user_pins);
            delete user_pins[contact.ID.toString()];
            documents[key].user_pins = JSON.stringify(user_pins)
          }
          
        }
    }
    Store.updateStore('docs', documents)
    let api = new APICall();
    let postData = {};
    postData["command"] = "document_operation";
    postData["mode"] = "unpinned";
    postData["docids"] = parms;
    console.log("postData", postData)
    api.command(postData, this.processDocument);
  }

  PinnedDocs = (docid) => {
    let documents = this.state.documents;
    let doc_id = {};
    if (docid != 0) {
      doc_id[docid] = 1;
    } else {
      for (let key in documents) {
        if (documents[key].select && documents[key].type != -1) {
          doc_id[documents[key].doc_id] = 1;
        }
      }
    }

    if (Object.keys(doc_id).length === 0) {
      let alert_param = { title: "Alert", message: "Please select a document", ok_text: "OK", confirm: false, alertHandler: this.AlertCloseHandler, stack: { id: 0 } };
      this.setState({ alert_param: alert_param });
      return false;
    }

    let msg = "Are you sure you want to pin document(s)?";
    let title = "Confirm";
    let alert_param = { title: title, message: msg, ok_text: "OK", confirm: true, alertHandler: this.confirmPinAlertHandler, stack: { parms: doc_id } };
    this.setState({ alert_param: alert_param });
  };

  confirmPinAlertHandler = (result, stack) => {
    if (!result || stack.prevent) {
      this.setState({ alert_param: null });
      return;
    }
    this.setState({ alert_param: null, loading: true });
    let parms = stack.parms;
    // let doc_ids = parms.docids;
    // console.log("doc_ids", parms)
    let documents = Store.getStoreData('docs')
    let contact = Store.getStoreData('contact')
    for(let key in documents){
        if(documents[key].type !== -1 ){
          if(parms[documents[key].doc_id] === 1){
            let user_pins = JSON.parse(documents[key].user_pins);
            user_pins[contact.ID.toString()] = 1;
            documents[key].user_pins = JSON.stringify(user_pins)
          }
          
        }
    }
    Store.updateStore('docs', documents)

    let api = new APICall();
    let postData = {};
    postData["command"] = "document_operation";
    postData["mode"] = "pinned";
    postData["docids"] = parms;
    console.log("postData", postData)
    api.command(postData, this.processDocument);
  };

  AlertCloseHandler = (result, stack) => {
    this.setState({ alert_param: null });
  };

  processDocument = (result) => {
    CadrsLoader.divideCards();
    let alert_param = {
      title: "Success",
      message: result.error_msg,
      ok_text: "OK",
      confirm: false,
      alertHandler: this.AlertremoveHandler,
      stack: { id: 0, mode: result.mode },
    };
    this.setState({ alert_param: alert_param, loading: false });
  };

  AlertremoveHandler = (result, stack) => {
    this.setState({ alert_param: null, modal_view: null });
    console.log("result stack", stack, this.state.toggle);
    // this.callDocLibrary();
    switch (stack.mode) {
      case "restore":
        this.filtersRecycleCards(true);
        break;
      case "delete":
      case "pinned":
      case "unpinned":
        this.filtersCards(this.state.toggle['pindoc']);
      break;
      default:
        this.callDocLibrary();
        break;
    }
  };

  closeUserDialog = () => {
    this.setState({ modal_view: null, documentdetails: null, documentversion: null });
    this.callDocLibrary();
  };
  closeUserDialogFolder = (folder_id) => {
    let obj = {};
    obj['folder_id'] = folder_id;
    this.setState({ modal_view: null, documentdetails: null, documentversion: null});
    this.callDocLibrary(obj);
  };
  refreshParent = () => {
    this.setState({ modal_view: null });
    this.callDocLibrary();
  };

  refreshRestoreParent = () => {
    let toggle = this.state.toggle;
    this.setState({ modal_view: null});
    if(toggle['recyclebin']){
      this.filtersRecycleCards(true);
    }else if(toggle['recentlydeleted']){
      this.filtersrecentlydeletedCards(true)
    }else{
      toggle['recyclebin'] = false;
      toggle['recentlydeleted'] = false;
      this.setState({ toggle: toggle });
      this.callDocLibrary();
    }
  }

  closeUploadToFolder = () => {
    this.setState({ modal_view: null });
  };

  filterDocumentss(docs, filter) {
    delete filter['gcs']
    delete filter['doc_date']
    delete filter['modified_date']

    return docs.filter(row => {
      return Object.keys(filter).every(propertyName => row[propertyName].toString().toLowerCase().indexOf(filter[propertyName].toString().toLowerCase()) > -1);
    })

  }
  applyFilter = (apply_filter) => {
    console.log("apply_filter", apply_filter)
    let gcs = ('gcs' in apply_filter)? apply_filter['gcs'] : "";
    let filter = apply_filter;
    let documents = Store.getStoreData('docs');
    let d = [];
    for(let key in documents){
      if(documents[key].is_deleted === 0) {
        if(Object.keys(apply_filter).length > 0){
          d.push(documents[key])
        }else{
          if(documents[key].type === -1){
              if(parseInt(documents[key].parent_id) === parseInt(this.state.folder_id)){
                d.push(documents[key])
              }
            }else{
              if(parseInt(documents[key].folder_id) === parseInt(this.state.folder_id)){
                d.push(documents[key])
              }
            }
        }
      }
    }

    let filteredData = this.filterDocumentss(d, filter);
    // console.log("apply_filter", filter, gcs)
    let d3 = [];
    if(gcs!==""){
      console.log("apply_filter1", gcs)
      for(let k in filteredData){
        if(this.isonlyGCinclude(gcs, JSON.parse(filteredData[k].gcs))){
          d3.push(filteredData[k])
        }
      }
    }else{
      d3 = filteredData;
    }


    this.setState({ documents: d3, ready: true, view_count: this.state.view_count + 1})

  };

  filtersCards = (tog) => {
    let toggle = this.state.toggle;
    toggle['pindoc'] = tog;
    toggle['recyclebin'] = false;
    toggle['recentlydeleted'] = false;
    if (tog) {
      let companies_assoc = Store.getStoreData('companies_assoc');
      let contact = Store.getStoreData('contact');
      let documents = Store.getStoreData('docs');
      let d = [];
      for (let key in documents) {
        let gc = JSON.parse(documents[key].gcs);
        let include = this.isGCinclude(companies_assoc, gc);
        if(documents[key].is_deleted === 0 && include && documents[key].type !==-1){
          let user_pins = JSON.parse(documents[key].user_pins);
          if(user_pins[contact.ID.toString()]){
            documents[key].select = false;
            d.push(documents[key]);
          }
        }
      }
      this.setState({ documents: d, ready: true, view_count: this.state.view_count + 1, disabled: false, toggle: toggle})
    }else{
      this.setState({view_count: this.state.view_count + 1, disabled: false, toggle: toggle})
      this.callDocLibrary()
    }
  };

  filtersRecycleCards = (tog) => {
    let toggle = this.state.toggle;
    toggle['pindoc'] = false;
    toggle['recyclebin'] = tog;
    toggle['recentlydeleted'] = false;
    if (tog) {
      let companies_assoc = Store.getStoreData('companies_assoc');
      let contact = Store.getStoreData('contact');
      let documents = Store.getStoreData('docs');
      let d = [];
      for (let key in documents) {
        let gc = JSON.parse(documents[key].gcs);
        let include = this.isGCinclude(companies_assoc, gc);
        if(include && documents[key].is_deleted === 1){
          documents[key].select = false;
          d.push(documents[key]);
        }
      }
      this.setState({ documents: d, ready: true, view_count: this.state.view_count + 1, disabled: false, toggle: toggle})
    }else{
      this.setState({view_count: this.state.view_count + 1, disabled: false, toggle: toggle})
      this.callDocLibrary()
    }
    
  };

  filtersrecentlydeletedCards = (tog) => {
    let period = Store.getStoreData('period') === null ? 'no_filter' : Store.getStoreData('period')
    let filter = Utils.timespanRecentDocument(period)
    let toggle = this.state.toggle;
    toggle['pindoc'] = false;
    toggle['recyclebin'] = false;
    toggle['recentlydeleted'] = tog;
    if (tog) {
      let companies_assoc = Store.getStoreData('companies_assoc');
      let contact = Store.getStoreData('contact');
      let documents = Store.getStoreData('docs');
      let d = [];
      for (let key in documents) {
        let gc = JSON.parse(documents[key].gcs);
        let include = this.isGCinclude(companies_assoc, gc);
        if(include && documents[key].is_deleted === 1){
          if(period!=='no_filter'){
            if(filter.start >= documents[key].last_action_time && filter.end <= documents[key].last_action_time){
              documents[key].select = false;
              d.push(documents[key]);
            }
          }else{
            documents[key].select = false;
            d.push(documents[key]);
          }
        }
      }
      this.setState({ documents: d, ready: true, view_count: this.state.view_count + 1, disabled: false, toggle: toggle})
    }else{
      this.setState({view_count: this.state.view_count + 1, disabled: false, toggle: toggle})
      this.callDocLibrary()
    }
  };

  toggleview = (value) => {
    this.setState({ listview: value });
  };
  sortapply = (id) => {
    let arr = this.state.documents;
    console.log("this.state.documents", id);

    switch (id) {
      case "uploaded_asc":
      case "0":
        arr.sort(function (a, b) {
          if (a["uploaded"] < b["uploaded"]) {
            return -1;
          }
          if (a["uploaded"] > b["uploaded"]) {
            return 1;
          }
        });
        this.setState({ documents: arr, view_count: this.state.view_count + 1 });
        break;

      case "uploaded_desc":
        arr.sort(function (a, b) {
          if (a["uploaded"] < b["uploaded"]) {
            return 1;
          }
          if (a["uploaded"] > b["uploaded"]) {
            return -1;
          }
        });
        this.setState({ documents: arr, view_count: this.state.view_count + 1 });
        break;

      case "modified_asc":
        arr.sort(function (a, b) {
          if (a["updated_at"] < b["updated_at"]) {
            return 1;
          }
          if (a["updated_at"] > b["updated_at"]) {
            return -1;
          }
        });

        this.setState({ documents: arr, view_count: this.state.view_count + 1 });
        break;

      case "modified_desc":
        arr.sort(function (a, b) {
          if (a["updated_at"] < b["updated_at"]) {
            return -1;
          }
          if (a["updated_at"] > b["updated_at"]) {
            return 1;
          }
        });

        this.setState({ documents: arr, view_count: this.state.view_count + 1 });
        break;
    }
  };

  exportToExcel = () => {
    let postData = {'command' : 'list_latest_approval'};
    let api = new APICall();
    api.command(postData, this.processApproval);
  }
  processApproval = (result) => {
      let contact_assoc = Store.getStoreData("listcontacts");
      let doc_types = Store.getStoreData("doc_types");
      


      var data=[

                ["Row number","Document Name","Document Version","Document Type","Document Owner","Uploaded By","Uploaded Date","Visibility","Lock document","Modified By","Modified Date", "Next Review Date"]    

               ];
       
      if(Store.getStoreData('record_doc_approval')){
        data=[
                ["Row number","Document Name","Document Version","Document Type","Document Owner","Uploaded By","Uploaded Date","Visibility","Lock document","Modified By","Modified Date", "Next Review Date", "Approver","Approval date","Comments"]
            ];
      }

      console.log("processApproval", data)



       var i=1;
       for(let record of this.state.documents) {
        if(record.doc_id > 0) {
          let slno=i++;
          let name=record.name;
          let type=doc_types[record.type.toString()]; 
          let docowner=contact_assoc[record.doc_owner.toString()]; 
          let uploadedby = contact_assoc[record.created_by];
          let uploadeddate=Utils.unixtimeTodate(record.uploaded); 
          let visibility=record.visibility === 0 ? "Public" : "Private";
          let lockdocument= record.lock_doc === 0 ? "Open" : "Locked";
          let modifiedby = contact_assoc[record.updated_by.toString()];
          let modifieddate=Utils.unixtimeTodate(record.updated_at);
          let version=record.doc_version;  
          let app =  result[record.doc_id] ? result[record.doc_id] : {};  
          let approver=app?.approvar;
          let approvaldate=Utils.getDate(app?.approval_date);
          let review_date = Utils.getDate(record?.review_date);  
          let comments=app?.comments;         
          let elem=[slno,name,version,type,docowner,uploadedby,uploadeddate,visibility,lockdocument,modifiedby,modifieddate, review_date];
          if(Store.getStoreData('record_doc_approval')){
            elem=[slno,name,version,type,docowner,uploadedby,uploadeddate,visibility,lockdocument,modifiedby,modifieddate,review_date, approver,approvaldate,comments];
          }
          data.push(elem);
        }
       }
       const format = "DD-MM-YYYY";
       let dtString = moment().format(format);
       var fileName="Document Library Export ";                      
       const ws: XLSX.WorkSheet =XLSX.utils.aoa_to_sheet(data);
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Document Library');    
       const excelBuffer: any = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
       const data1: Blob = new Blob([excelBuffer], {type: fileType});
       FileSaver.saveAs(data1, fileName + dtString+ fileExtension)
  }

  render() {
    // if (this.state.loading) {
    //   return <CSLLoader />;
    // }

    console.log("DocLibraryID1 this.state", this.state);

     

    return (
      <IndexContainer>
        {
          (() => {
            if(this.state.loading) return <CSLLoader />;
          })()
        }
        <DOCcards filtersCards={this.filtersCards} filtersRecycleCards={this.filtersRecycleCards} filtersrecentlydeletedCards={this.filtersrecentlydeletedCards} toggle={this.state.toggle} />
        <div style={{ boxSizing: "border-box", padding: "10px 10px 10px 10px" }}>
          <AdvancedFilter applyFilter={this.applyFilter} />
        </div>
        <div style={{ boxSizing: "border-box", padding: "10px 10px 10px 10px" }}>
          <TobHeader AddDocument={this.AddDocument} AddFolder={this.AddFolder} DeleteDocs={this.DeleteDocs} PinnedDocs={this.PinnedDocs} toggleview={this.toggleview} sortapply={(id) => this.sortapply(id)} MoveDocstoFolder={this.UploadToFolder} folder_id={this.state.folder_id} parentID={this.state.parentID} backToListing={this.backToListing} restoreDeletedDocs={this.restoreDeletedDocs} toggle={this.state.toggle} />
        </div>

        <div style={{marginLeft:"10px", display:"flex"}}>
        
        {
          (() => {
            if(Object.keys(this.state.folder_struct).length > 0){
              let x = []
              for(let key in this.state.folder_struct){
               x.push(<div style={{marginLeft:"10px", cursor:"pointer"}} onClick={() => this.backToListing(key,this.state.folder_struct[key].parent_id)}>{this.state.folder_struct[key].name}</div>)
              }
              return x;
              
            }
          })()
        }
        </div>

        <div style={{ boxSizing: "border-box", padding: "25px 25px 10px 10px" }}>
          {(() => {
             const menu_options = [
                                    {name: "export", nick_name: "Export", clickHandler: this.exportToExcel}
                                  ];
            if (this.state.listview) {
               return(
               <div>
               {
                (() => {
                  if(this.state.export_visiable){
                    return <div style={{float: "right", cursor: "pointer",fontSize: "25px",color: "#e4e4e4",marginBottom:"-40px"}}> <ContextMenu  options={menu_options} /> </div>
                  }
                })()
               }
               
               <div style={{ clear: "both" }}></div>
              <CSLTable add={false} processData={this.processDocumentsData} tableRows={10} offset={true} records={this.state.documents.length} />
              </div>);
            } else {
              return <GridView Data={this.state.documents} viewdoc={(data) => this.ViewDocDetail("showdocument", data)} onClickDocumentName={(data) => this.onClickDocumentName("showdocument", data)} MoveDocstoFolder={this.MoveDocstoFolder} DeleteDocs={(id, type) => this.DeleteDocs(id, type)} PinnedDocs={(id) => this.PinnedDocs(id)} UnpinnedDocs={(id) => this.UnpinnedDocs(id)} checkDocument={(id, type) => this.checkDocument(id, type)} />;
            }
          })()}
        </div>
        {(() => {
          if (this.state.modal_view !== null) {
            switch (this.state.modal_view) {
              case "showdocument":
                return (
                  <div>
                    <InactiveOverlay />
                    <AddDocumentModal closeUserDialog={this.closeUserDialog} refreshParent={this.refreshParent} folder_id={this.state.folder_id} />
                  </div>
                );
                break;
              case "addfolder":
                return (
                  <div>
                    <InactiveOverlay />
                    <AddFolderModal closeUserDialog={this.closeUserDialog} refreshParent={this.refreshParent} folder_id={this.state.folder_id} />
                  </div>
                );
                break;
              case "movetofolder":
                return (
                  <div>
                    <InactiveOverlay />
                    <DocAddToFolderModal refreshParent={this.refreshParent} closeUserDialog={this.closeUserDialog} docs={this.state.documents} folder_id={this.state.folder_id} restore={this.state.restore} toggle={this.state.toggle} refreshRestoreParent={this.refreshRestoreParent} />
                  </div>
                );
                break;
              case "doc_view":
                {
                  /*if( this.state.documentversion === null || this.state.maindocument === null || this.state.filedata === null)return(<div></div>)*/
                }
                return (
                  <div>
                    <InactiveOverlay />
                    <DocumentViewModal refreshParent={this.refreshParent} closeUserDialog={this.closeUserDialog} doc_id={this.state.doc_id} version_id={this.state.version_id} />
                  </div>
                );
                break;
              case "folder_view":
                if (this.state.folder_id === 0) return <div></div>;
                return (
                  <div>
                    <InactiveOverlay />
                    <FolderViewModal folder_id={this.state.folder_id} refreshParent={this.refreshParent} closeUserDialog={this.closeUserDialog} closeUserDialogFolder={this.closeUserDialogFolder} ViewonlyDoc={this.ViewonlyDoc} />
                  </div>
                );
                break;
              case "show_bulk":
                return (
                  <div>
                    <InactiveOverlay />
                    <BulkModal closeUserDialog={this.closeUserDialog} closeUserDialogFolder={this.closeUserDialogFolder} />
                  </div>
                );
                break;
            }
          }
        })()}
        <AlertBox alertParam={this.state.alert_param} />
      </IndexContainer>
    );
  }
}

export default DocLibrary;
