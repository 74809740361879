import Store from '../../../Common/Store.js';
import http from 'mcc-front-aux/dist/httpCommon'


class UploadFile {
    constructor() {
        this.is_debug = Store.getStoreData('is_debug');
        this.api_url = Store.getStoreData('base_api_url');
        this.contact_id = Store.getStoreData('contact')["ID"];
        this.company_id = Store.getStoreData('company')["ClientNumber"];
    }

    async command(postData, callback, uploadCallback = null, obj = null) {
        let url = this.api_url + "/uploaddata"

        let jsonDataString = JSON.stringify(postData);
        const payload = JSON.parse(jsonDataString);
        const data = new FormData();



        for (var i = 0; i < payload.length; i++) {
            data.append(`model[${i}].name`, payload[i].name);
            data.append(`model[${i}].bin_file`, payload[i].bin_file);
            data.append(`model[${i}].company_id`, this.company_id);
            data.append(`model[${i}].contact_id`, this.contact_id);

        }



        var config = {
            method: 'post',
            url: url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data,
            onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                console.log('UploadProgress loaded, total', loaded, total)
                if (uploadCallback !== null) uploadCallback(progressEvent);
            }
        };
        try {
            const response = await http.request(config);
            console.log("response.data", response.data);
            return response.data;
            // You can also perform other actions here if needed
        } catch (error) {
            console.log("Upload Error:", error);
            // console.log("Data", error?.response?.data);
            // console.log("Headers", error.response.headers);
            return null;
        }
    }
}

export default UploadFile;
