import React from "react"
import { FaTimes } from 'react-icons/fa'
import { AiOutlineCloudUpload } from "react-icons/ai"
import ContextMenu from "../Menu/ContextMenu"
import BulkReview from "./BulkReview"
import "./Bulk.css"
import UploadFile from './UploadFile';
import AlertBox from "../../Common/AlertBox.js";
import CSLProgressbar from './CSLProgressbar';
import Store from '../../../Common/Store.js';
import APICall from "../../../Common/APICall.js";
import CSLLoader from "../../Common/CSLLoader";
import CadrsLoader from "../../../Common/CadrsLoader.js";
import * as Style from "../../Common/StyledComponents";
import styled from "styled-components";

const moment = require("moment");
const last_action_time = moment().unix();

const sleep = ms => new Promise(
  resolve => setTimeout(resolve, ms)
);

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

const Container = styled.div`
  display: block;
  box-sizing: border-box;
  max-height: 90vh;
  width: 90%;
  max-width: 800px;
  position: fixed;
  overflow-y: auto;
  margin: auto;
  z-index: 1001;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 20px #a7a6a6;
  @media only screen and (min-width: 640px) {
    max-width: 80%;
  }

  @media only screen and (min-width: 768px) {
    max-width: 70%;
  }
  @media only screen and (min-width: 1024px) {
    max-width: 60%;
  }
  @media only screen and (min-width: 1280px) {
    max-width: 50%;
  }
`;

class BulkModal extends React.Component {

    state = { attachments: [], files_content: {}, show_bulk_review_modal: false, isShowProgressBar: false, percentLoaded: 0, alert_param: null, upload: true,
    folders: [],folder_id: 0,parent_id:0,selected_folder: 0,folder_order: {}, is_loading: false }

    constructor(props) {
        super(props)
        this.fileInputRef = React.createRef();
        this.openFileDialog = this.openFileDialog.bind(this);
        this.uploadApi = new UploadFile();
    }

    componentDidMount() {
      this.callFolderLibrary();
    }

    componentDidUpdate(prevProps) {
      if (prevProps !== this.props) {
        this.callFolderLibrary();
      }
    }

    callFolderLibrary() {
      let api = new APICall();
      let postData = { command: "get_folders", folder_id: 0 };
      if(this.props.restore){
        postData['folder_id'] = 0;
      }

      api.command(postData, this.processFolders);
      this.setState({ folder_id: 0});
    }

    processFolders = (result) => {
        console.log("result", result)
        let folders = result.result;
        if(result.folder_id > 0){
          folders = [...[{id: 'back', name: "...."}], ...folders]
        }
        let folder_order = this.state.folder_order;
        if(result.reorder === 1) folder_order[result.folder_id] = Object.keys(folder_order).length + 1;
        this.setState({ folders: folders,folder_order: folder_order, parent_id: result.parent_id });
    }

    openFileDialog() {
        this.fileInputRef.current.click();
    }

    onFileDrop = async (e) => {
        e.preventDefault()
        e.stopPropagation()
        let attachments = JSON.parse(JSON.stringify(this.state.attachments))
        let files_content = JSON.parse(JSON.stringify(this.state.files_content))
        for (let file of e.dataTransfer.files) {
            const content = await this.readFile(file)
            const id = this.genKey(16).toString()
            attachments.push({ id: id, name: file.name, size: file.size, size_hr: this.getHumanReadableFileSize(file.size) })
            files_content[id] = content
        }
        this.setState({ attachments, files_content })
    }

    onDragOver = (event) => {
        event.preventDefault();
    }

    onDragEnter = (e) => {
        e.stopPropagation();
    }

    onFilesAdded = async (e) => {
        let attachments = JSON.parse(JSON.stringify(this.state.attachments))
        let files_content = JSON.parse(JSON.stringify(this.state.files_content))
        for (let file of e.target.files) {
            const content = await this.readFile(file)
            const id = this.genKey(16).toString()
            attachments.push({ id: id, name: file.name, size: file.size, size_hr: this.getHumanReadableFileSize(file.size) })
            files_content[id] = content
        }
        this.setState({ attachments, files_content })
    }

    readFile = async (file) => {
        const promise = new Promise((resolve, reject) => {
            let reader = new FileReader()
            reader.readAsBinaryString(file)
            reader.onload = () => {
                resolve(window.btoa(reader.result))
            }
        })
        return promise;
    }

    goNext = (event) => {
        event.preventDefault()
        this.setState({ show_bulk_review_modal: true })
    }

    uploadDocument = async (obj) => {
        let files = []
        let documents = obj.documents;
        let msg =  ""
        let sub_msg = ""
        let i =0
        this.setState({ is_loading: true});
        let upload_inprogress = false
        for (let d in documents) {
            i = i + 1
            console.log("message", i)
            msg =  "Your documents are being registered with the Library. This may take a few seconds per document."
            sub_msg = "Uploaded " + i + " of "+documents.length + " Documents"
            let alert_param = { title: "Uploading Your Documents", message: msg, sub_message: sub_msg, no_button: false, cancel_text: "CANCEL", confirm: true, alertHandler: this.AlertCancelHandler, stack: {id: 0} };
            this.setState({ is_loading: false, alert_param });
            let x = []
            let f = {};
            f.user_id = Store.getStoreData("contact").ID
            f.upload_date = last_action_time
            f.name = documents[d].file_name
            f.uid = documents[d].id
            f.bin_file = this.state.files_content[documents[d].id]
            x.push(f);
            console.log("CALLING UPLOAD")
            const ret = await this.uploadApi.command(x, this.returnTempFilesInfo);
            console.log("ret: ", ret)
            // console.log("returnedFiles", ret)
            let returnedFiles = JSON.parse(ret);
            let refID = returnedFiles.find((o) => o.inserted_file_name == documents[d].file_name)['ref_id'];
            let uploaded_doc = [];
            uploaded_doc.push({
                "uid": documents[d].id,
                "name": documents[d].file_name,
                "user_id": Store.getStoreData("contact").ID,
                "upload_date": f.upload_date,
                "ref_id": refID
            })
            documents[d].uploaded_doc = uploaded_doc
            documents[d].is_uploaded = true
        }
        obj.documents = documents
        console.log("attachments2:", obj)
        this.uploadFilesFromChild(obj);
    }

    alertReturn = (msg, sub_message) => {
        return false
    }

    AlertremoveHandler = (result, stack) => {
        // this.getSleep(stack.id)
        console.log("result in AlertremoveHandler", result)
        this.setState({ alert_param: null });
        this.props.closeUserDialog()
  };

  AlertCancelHandler =(result, stack) => {
    this.props.closeUserDialog()
    this.setState({ alert_param: null});
  }

  confirmDeleteAlertHandler = (result, stack) => {
    console.log("confirmDeleteAlertHandler", result, stack, this.state.is_loading)
    if (!result || stack.prevent) {
      this.setState({ alert_param: null, upload: true});
      return;
      // this.props.closeUserDialog()
    }
    // this.setState({ alert_param: null});
    // return false;
    this.props.closeUserDialog()
  };


    uploadFilesFromChild = (obj) => {
        // this.setState({ alert_param: null });
        this.setState({loading: true });
        let postData = { command: "bulk_upload" };
        let documents = obj.documents;
        let gcs = obj.gcs;
        let g = {};
        for (let key in gcs) {
          if (obj.all_company) {
            g[key] = 1;
          } else {
            if (gcs[key].selected) g[key] = 1;
          }
        }
        postData["gc_ids"] = g;
        postData["documents"] = documents;
        postData["last_actin_time"] = Store.getStoreData('doc_last_actin_time');
        let api = new APICall();
        console.log("onUploadComplete postData", postData, this.state.upload);
        if(this.state.upload)api.command(postData, this.processDocument);

    }

    processDocument = (result) => {
        let alert_param = { title: "Success", message: "Your documents have been uploaded and will be shown in the module shortly", ok_text: "OK", confirm: false, alertHandler: this.AlertremoveHandler, stack: { id: 0 } };
        this.setState({ alert_param: alert_param, loading: false });
        /*let api = new APICall();
        let postData = { command: "check_process", process_id: result.result.process_id };
        postData["last_actin_time"] = Store.getStoreData('doc_last_actin_time');
        api.command(postData, this.checkProcess);
        this.setState({ loading: true })*/

    };


    returnTempFilesInfo = (result) => {
        console.log('returnTempFilesInfo result', result)
        this.setState({ alert_param: null });
        // this.onUploadComplete(result);
    };

    onUploadComplete = (files) => {
        let returnedFiles = JSON.parse(files);
        this.setState({ loading: true });
        console.log("hello", returnedFiles, this.props)
    }

    closeModal = (event) => {
        event.preventDefault()
        this.props.closeUserDialog()
    }

    removeAttachment = (attach_id) => {
        console.log("removeAttachment attach_id:", attach_id)
        let files_content = JSON.parse(JSON.stringify(this.state.files_content))
        let attachments = []
        for (let item of this.state.attachments) {
            if (item.id !== attach_id) attachments.push(item)
        }
        delete files_content[attach_id]
        this.setState({ attachments, files_content })
    }

    getExtension = (file_name) => {
        const file_parts = file_name.split(/\.(?=[^\.]+$)/)
        let ext = "PDF";
        switch (file_parts[file_parts.length - 1]) {
            case "jpeg":
            case "jpg":
                ext = "JPG"; break;
            case "png":
                ext = "PNG"; break;
            case "docx":
                ext = "DOC"; break;
            case "doc":
                ext = "DOC"; break;
            case "msg":
                ext = "MSG"; break;
            case "txt":
                ext = "TXT"; break;
            case "ppt":
                ext = "PPT"; break;
            case "pptx":
                ext = "PPT"; break;
            case "xls":
                ext = "XLS"; break;
            case "xlsx":
                ext = "XLSX"; break;
            default:
                ext = "PDF"; break;
        }
        return ext
    }

    getHumanReadableFileSize = (number_of_bytes) => {
        const units = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
        const exponent = Math.min(Math.floor(Math.log(number_of_bytes) / Math.log(1024)), units.length - 1)
        const approx = number_of_bytes / 1024 ** exponent
        const output = exponent === 0 ? `${number_of_bytes} bytes` : `${approx.toFixed(3)} ${units[exponent]}`
        return output
    }

    genKey = (length) => {
        var result = "";
        var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    };

    closeBulkReviewModal = () => {
        this.setState({ show_bulk_review_modal: false })
    }

    handleChange = (event) => {
      event.preventDefault();
      let { folder_id } = this.state;
      folder_id = event.target.value === "back" ? this.state.parent_id : event.target.value;
      let api = new APICall();
      let postData = { command: "get_folders", folder_id: folder_id, reorder: event.target.value === "back" ? 0 : 1 };
      api.command(postData, this.processFolders);
      let folder_order = this.state.folder_order;
      if(event.target.value === "back"){
        delete folder_order[this.state.folder_id.toString()]
      }
      console.log("folder_order", folder_order)
      this.setState({ folder_id:folder_id, folder_order: folder_order});
    };

    render() {
        // console.log("attachments:", this.state.attachments)
        console.log("files_content:", this.state)

        let height = this.state.attachments.length === 0 ? "10px" : "300px";
        let bodyheight = this.state.attachments.length === 0 ? "500px" : "600px";

        let folders_assoc = Store.getStoreData('folders_assoc')
        let y = ''
        let i = 0;
        for(let k in this.state.folder_order){
          if(k > 0){
            y  = y.concat(folders_assoc[k])
            if(i < Object.keys(this.state.folder_order).length - 1){
              y += ' / '
            }
          }
          i++
        }

        const menu_options = [
            { name: "remove", nick_name: "Remove", clickHandler: this.removeAttachment }
        ]
        return (
        <Wrapper>
            <Container>
                {
                  (() => {
                    if(this.state.loading) return <CSLLoader />;
                  })()
                }
                {
                    this.state.show_bulk_review_modal === true &&
                    <div className="inactive-overlay">
                        <BulkReview attachments={this.state.attachments} closeModal={this.closeBulkReviewModal} uploadDocument={this.uploadDocument} is_loading={this.state.is_loading}
                        folder_id={this.state.folder_id} parent_id={this.state.parent_id} folder_order={this.state.folder_order} folders={this.state.folders}/>
                    </div>
                }
                {
                    (() => {
                        if (this.state.isShowProgressBar === true) {
                            return (
                                <div>
                                    <CSLProgressbar style={{ position: 'absolute' }} value={this.state.percentLoaded} />
                                </div>
                            );
                        }
                    })()
                }
                <AlertBox alertParam={this.state.alert_param} />
                <div className="bulk-header">
                    <div style={{ float: "left", width: "95%", boxSizing: "border-box" }}>
                        <div style={{ fontWeight: "600", paddingBottom: "10px", fontSize: "16px" }}>Bulk Document Upload</div>
                        <div>Please select the documents you would like to upload</div>
                    </div>
                    <div style={{ float: "left", width: "5%", boxSizing: "border-box", fontSize: "19px", color: "#8f8f8f" }}>
                        <FaTimes style={{ cursor: "pointer" }} onClick={this.closeModal} />
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>
                <div className="bulk-body"  style={{height: bodyheight}}>
                    <div className="upload-box"
                        onDrop={this.onFileDrop}
                        onDragEnter={this.onDragEnter}
                        onDragOver={this.onDragOver}
                    >
                        <div className="upload-inner-box">
                            <div style={{ fontSize: "50px", color: "#a5a5a5", boxSizing: "border-box" }}><AiOutlineCloudUpload /></div>
                            <div style={{ fontWeight: "600", paddingBottom: "20px", boxSizing: "border-box" }}>Drag & Drop files here</div>
                            <div><button className="upload-btn" onClick={this.openFileDialog}>Or browse...</button></div>
                            <input
                                ref={this.fileInputRef}
                                style={{ display: "none" }}
                                type="file"
                                multiple
                                accept=".pdf,.jpg,.jpeg,.png,.docx,.doc,.msg,.txt,.ppt,.pptx,.xls,.xlsx"
                                onChange={this.onFilesAdded}
                            />
                        </div>
                    </div>
                    <div>
                        {
                            (() => {
                                if(this.state.attachments.length > 0) {
                                    return <div style={{ fontWeight: "600", marginTop: "30px", fontSize: "14px" }}>Ready to Upload</div>
                                }
                            })()
                        }

                        <div style={{ display: "flex", flexDirection: "column", height: height, overflowY: "auto" }}>
                            {
                                this.state.attachments.map(item => {
                                    return (
                                        <div key={item.id} style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", marginTop: "10px" }}>
                                            <div style={{ display: "flex", columnGap: "25px" }}>
                                                <div className="ext">{this.getExtension(item.name)}</div>
                                                <div>
                                                    <div style={{ fontWeight: "600" }}>{item.name}</div>
                                                    <div style={{ color: "#6e6d6d" }}>{item.size_hr}</div>
                                                </div>
                                            </div>
                                            <ContextMenu row_id={item.id} options={menu_options} />
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", height: "100px"}}>
                        <Style.DocFormContainer>
                          <div style={{ float: "left", width: "95%", boxSizing: "border-box" }}>
                              <div style={{ fontWeight: "600", paddingBottom: "10px", fontSize: "16px" }}>Select Folder</div>
                              <div style={{paddingBottom: "5px"}}>Files will be placed in the home folder by default. You can select different folder here.</div>
                              <div style={{ fontWeight: "600", paddingBottom: "5px"}}> {y} </div>
                          </div>
                          <Style.SpacerL></Style.SpacerL>
                          <Style.MRModalSelect onChange={this.handleChange} name="folder_id" value={this.state.folder_id}>
                            {(() => {
                              let regulatory_status = [<option value="0" disabled selected hidden>Select Folder</option>]
                              for (let f of this.state.folders) {
                                regulatory_status.push(
                                  <option key={f.id} value={f.id}>
                                    {f.name}
                                  </option>
                                );
                              }
                              return regulatory_status;
                            })()}
                          </Style.MRModalSelect>
                        </Style.DocFormContainer>
                        </div>
                    </div>
                </div>
                <div className="bulk-footer">
                    <div style={{ float: "right" }}>
                        <button className="cancel-btn" onClick={this.closeModal}>CLOSE</button>
                        <button className="save-btn"
                            disabled={this.state.attachments.length === 0 ? true : false}
                            onClick={this.goNext}
                            style={{
                                marginLeft: "10px",
                                filter: this.state.attachments.length === 0 ? "grayscale(1)" : "none",
                                cursor: this.state.attachments.length === 0 ? "not-allowed" : "pointer"
                            }}
                        >NEXT</button>
                    </div>
                    <div style={{ clear: "both" }}></div>
                </div>
            </Container>
        </Wrapper>
        )
    }
}

export default BulkModal;
