import React, { Component, PureComponent } from "react";
import * as Style from "../Common/StyledComponents";
import styled from "styled-components";
import APICall from "../../Common/APICall.js";
import Store from "../../Common/Store.js";
import { MdClose } from "react-icons/md";
import { saveAs } from "file-saver";
import Utils from "../../Common/Utils.js";
import Property from "./Include/Property";
import Version from "./Include/Version";
import AlertBox from "../Common/AlertBox";
import SendAttestationModal from "./Attestation/SendAttestationModal.js";
import CSLLoader from "../Common/CSLLoader";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.js";

// pdfjs.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js'

const ext_map = { pdf: 1, txt: 1, png: 1, bmp: 1, gif: 1, jpeg: 1, jpe: 1, jpg: 1 };
const visibility = ["Public", "Private"];

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

const FolderContainer = styled.div`
  display: block;
  box-sizing: content-box;
  max-height: 90vh;
  height: 100%;
  width: 90%;
  max-width: 1000px;
  position: fixed;
  margin: auto;
  top: 60px;
  z-index: 1001;
  background-color: #f7f7f7;
  border-radius: 5px;
  border-style: solid;
  border-color: #495e75;
  border-width: 3px;
  box-shadow: 0 0 20px #a7a6a6;
  overflow-y: auto;
`;

const ModalHeaderCloseBtn = styled.div`
  float: right;
  box-sizing: border-box;
  color: #282828;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
`;
const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #046de4;
  color: #ffffff;
  border: 1px solid #37ada7;
  font-size: 13px;
  height: 39px;
  border-radius: 4px;
  float: right;
  cursor: pointer;
`;
const MRButtonActive = styled.button`
  box-sizing: border-box;
  background-color: #19334d;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 127px;
  font-size: 13px;
  height: 39px;
  border-radius: 4px;
  float: right;
  margin-top: -30px;
  cursor: pointer;
`;
const SectionTab = styled.div`
  float: left;
  width: 143px;
  height: 16px;
  cursor: pointer;
  background-color: #1a3552;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  color: #c4c4c4;
  font-weight: 600;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  margin-left: 4px;
`;
const FloatLeft = styled.div`
  float: left;
  margin-bottom: 10px;
  width: 40%;
  text-align: left;
`;
const FloatRight = styled.div`
  float: right;
  margin-bottom: 10px;
  width: 60%;
  text-align: left;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 3000px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;

const NoWrap = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

class CSLDocViewer extends PureComponent {
  state = { ready: false, filedata_b64: null, mime_type: null, doc_id: null };

  setComponentState = () => {
    if (this.props.docId) this.getFilebyId(this.props.docId);
  };
  componentDidMount = () => {
    this.setComponentState();
  };
  componentDidUpdate = (prevprops) => {
    if (this.props === prevprops) return;
    this.setComponentState();
  };

  getFilebyId(id) {
    let api = new APICall();
    let postData = { command: "get_doc", doc_id: id };
    api.command(postData, this.processVersionBySingleDocument);
  }

  processVersionBySingleDocument = (result) => {
    console.log("result", result);
    let versions = result.result;
    console.log("versions[0].bin_file", versions);
    /*let version_main = result.result.filter((x) => {
      return x.is_main == 1;
    });*/
    let bin_file = JSON.parse(versions.bin_file)[0].bin_file;
    let file_parts = JSON.parse(versions.bin_file)[0].name.split(".");
    let mime_type = file_parts[file_parts.length - 1].toLowerCase();
    this.setState({ ready: true, filedata_b64: bin_file, mime_type: mime_type, doc_id: this.props.docId });
  };

  render() {
    if (!this.state.ready) return <div><CSLLoader /></div>;
    return (
      <div style={{ width: "100%" }}>
        {(() => {
          console.log('mime_type ===>' ,  this.state.mime_type)
          switch (this.state.mime_type) {
            case "pdf":
              return <object data={"data:application/pdf;base64," + this.state.filedata_b64} style={{ width: "95%", height: 500 }}></object>;
              break;
            case "jpe":
            case "jpg":
            case "jpeg":
              return <object data={"data:image/jpeg;base64," + this.state.filedata_b64} style={{ width: "95%", height: 500 }}></object>;
              break;

            case "gif":
              return <object data={"data:image/gif;base64," + this.state.filedata_b64} style={{ width: "95%", height: 500 }}></object>;
              break;
            case "bmp":
              return <object data={"data:image/bmp;base64," + this.state.filedata_b64} style={{ width: "95%", height: 500 }}></object>;
              break;
            case "png":
              return <object data={"data:image/png;base64," + this.state.filedata_b64} style={{ width: "95%", height: 500 }}></object>;
              break;
            case "txt":
              return <object data={"data:text/plain;base64," + this.state.filedata_b64} style={{ width: "95%", height: 500 }}></object>;
              break;
            default:
              return <p>Sorry. Filetype not supported. Please use the download link above to download a copy.</p>;
          }
        })()}
      </div>
    );
  }
}

class DocumentViewModal extends Component {
  state = {
    ready: false,
    alert_param: null,
    document_info: null,
    versions: null,
    childtasks: null,
    screen: { property: 1, version: 0 },
    current_view: "property",
    show_attestation: null,
    bin_file: [],
    approvars: null
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.getDocDetails(this.props.doc_id, this.props.version_id);
  }
  componentDidUpdate(prevProps) {
    if (this.props === prevProps) return;
    this.getDocDetails(this.props.doc_id, this.props.version_id);
  }

  getDocDetails = (doc_id, version_id) => {
    let postData = { command: "document_details", doc_id: doc_id, version_id: version_id };
    let api = new APICall();
    api.command(postData, this.processDocumentDetails);
  };

  isGCinclude(gcs, doc_gcs){
    console.log("gcs, doc_gcs", gcs, doc_gcs)
    let is_include = true;
    for(let key in doc_gcs){
      is_include = false
      is_include = gcs.hasOwnProperty(key);
      if(is_include)break;
    }
    return is_include;
  }
changeStringToAtob = (str_value) => {
	//let utils = new Utils();
	let str_text = '';
	if(typeof str_value !== 'undefined'){
		str_text = str_value;
		if(Utils.isBase64(str_value)){
			str_text = window.decodeURIComponent(atob(str_value));
		}
	}
	return str_text;
}
  processDocumentDetails = (result) => {
    console.log("result doc", result);
    let doc_types = Store.getStoreData("doc_types");
    let contact_assoc = Store.getStoreData("contact_assoc");
    let list_contacts = Store.getStoreData("listcontacts");
    let companies_assoc = Store.getStoreData('companies_assoc');
    let r = result.result;
    let doc_info = r.document;

    let gc = JSON.parse(doc_info.gcs);
    let is_gc = this.isGCinclude(companies_assoc,gc);
    if(is_gc){
      let d = {};
      d["typeinno"] = doc_info.doc_type;
      d["type"] = doc_types[doc_info.doc_type.toString()];
      d["doc_ownerin_no"] = doc_info.doc_owner;
      d["doc_owner"] = list_contacts[doc_info.doc_owner];
      d["uploaded"] = Utils.unixtimeTodate(doc_info.created_at);
      d["doc_version"] = doc_info.doc_version;
      d["name"] = doc_info.name;
	  //console.log("GGGGGG,",this.changeStringToAtob(doc_info.description));
      //d["description"] = doc_info.description;


      let description = doc_info?.description;
      if(Utils.isBase64(description)){
        description = window.decodeURIComponent(atob(description))
      }

      d["description"] = description;
      d["created_by"] = contact_assoc[doc_info.contact_id.toString()];
      d["updated_by"] = doc_info.updated_by === 0 ? "N/A" : contact_assoc[doc_info.updated_by.toString()];
      d["updated_at"] = Utils.unixtimeTodate(doc_info.updated_at);
      d["version_id"] = doc_info.id;
      d["doc_id"] = doc_info.document_id;
      d["is_support"] = this.getExtention(doc_info.file_name);
      d["fileSize"] = this.getTotalfileSize(doc_info.json_data);
      d["logo"] = this.getIconurl(doc_info.file_name);
      d["versions"] = r.versions.length;
      d["is_attestation"] = this.isAttestation(doc_info.json_data);
      d["attestation_id"] = this.getAttestation(doc_info.json_data);


      d["is_pinned"] = doc_info.is_pinned;
      d["gcs"] = doc_info.gcs;
      d["lock_doc"] = doc_info.lock_doc;
      d["visibility"] = doc_info.visibility;
      d["review_date"] = doc_info.review_date;
      console.log("main_document +++>", d);


      let gc_users = Store.getStoreData("gc_users");
      let contact = Store.getStoreData("contact");
      let show_all = Store.getStoreData("role") === "admin_manager" ? true : false;
      let user_list = {};
      console.log("contact_assoc ==>", contact_assoc);
      if (Object.keys(gc_users).length !== 0) {
        for (let key in contact_assoc) {
          if (show_all || this.hasCommonCompanyCheckFirst(contact.ID, key, gc_users)) {
            if (key in gc_users) {
              user_list[key.toString()] = contact_assoc[key.toString()];
            }
          }
        }
      } else {
        user_list = contact_assoc;
      }

      let followup_tasks = r.childtasks;
      let fc_tasks = [];
      for(let key in followup_tasks){
        if(show_all){
          fc_tasks.push(followup_tasks[key])
        }else{
          if(followup_tasks[key].cur_assigned_to in user_list){
            fc_tasks.push(followup_tasks[key])
          }
        }

      }

      let approvars = r.approvars;

      this.setState({ document_info: d, versions: r.versions, childtasks: fc_tasks,approvars: approvars, ready: true });
    }else{
      let alert_param = { title: "No Access", message: "You are not authorised to access this document. If you think this is in error, please contact your administrator.", ok_text: "Ok", confirm: false, alertHandler: this.alertHandler, stack: { id: 0 } };
      this.setState({ ready: true, alert_param: alert_param });
      return false;
    }
  };

  alertHandler = (result, stack) => {
    this.setState({ alert_param: null });
    this.props.closeUserDialog();
  };

  hasCommonCompanyCheckFirst = (manager_id, user_id, gc_users) => {
    if (Object.keys(gc_users).length === 0) return true;
    if (manager_id === "") return true;
    let manager_companies = gc_users[manager_id];
    let user_companies = gc_users[user_id];
    for (let cid in manager_companies) {
      for (let uid in user_companies) {
        if (cid.toString() === uid.toString()) return true;
      }
    }
    return false;
  };

  getIconurl = (file) => {
    const file_parts = file.split(/\.(?=[^\.]+$)/);
    let ext = "PDF";
    let extention = file_parts[file_parts.length - 1];
    switch (extention) {
      case "jpeg":
      case "jpg":
        ext = "JPG";
        break;
      case "png":
        ext = "PNG";
        break;
      case "docx":
        ext = "DOC";
        break;
      case "doc":
        ext = "DOC";
        break;
      case "msg":
        ext = "MSG";
        break;
      case "txt":
        ext = "TXT";
        break;
      case "ppt":
        ext = "PPT";
        break;
      case "pptx":
        ext = "PPT";
        break;
      case "xls":
        ext = "XLS";
        break;
      case "xlsx":
        ext = "XLS";
        break;
      default:
        ext = "PDF";
        break;
    }
    let file_icon_var = ext === "DOCX" ? "DOC" : ext;
    file_icon_var = ext === "XLSX" ? "XLS" : ext;
    let file_icon = `/dropzoneImages/${ext}.png`;
    return file_icon;
  };

  getTotalfileSize = (data) => {
    let d = data === null ? {} : JSON.parse(data);
    let sum = 0;
    if ("size" in d) {
      sum = d.size;
    }
    return this.formatSize(sum);
  };

  isAttestation = (data) => {
    let d = data === null ? {} : JSON.parse(data);
    let is_attest = "task_id" in d ? true : false;
    return is_attest;
  };

  getAttestation = (data) => {
    let d = data === null ? {} : JSON.parse(data);
    let task_id = "task_id" in d ? d['task_id'] : 0;
    return task_id;
  }

  formatSize = (data) => {
    if (data > 1000000) {
      let fileSizeinMB = (data / 1000000).toFixed(2);
      let fileSize = fileSizeinMB.toString().concat("MB");
      return fileSize;
    } else if (data > 1000) {
      let fileSizeinKb = (data / 1000).toFixed(2);
      let fileSize = fileSizeinKb.toString().concat("KB");
      return fileSize;
    } else {
      let fileSize = data.toString().concat("Byte");
      return fileSize;
    }
  };

  handleDownload = (version_id) => (event) => {
    event.preventDefault();
    let api = new APICall();
    let postData = { command: "get_doc", doc_id: version_id };

    api.command(postData, this.processDownloadFile);
  };

  processDownloadFile = (result) => {
    let dbinfile = result.result;
    console.log("Attachment", dbinfile);
    let bin_file = JSON.parse(dbinfile.bin_file);
    let dnld = bin_file[0].bin_file.replace(/ /g, "+");
    let binaryString = window.atob(dnld);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    var blob = new Blob([bytes]);
    saveAs(blob, bin_file[0].name);
  };

  closeModal = (event) => {
    if (event) event.preventDefault();
    this.props.closeUserDialog();
  };

  quickmenuClick = (doc_id, version_id, option) => {
    console.log("doc_id, version_id, option", doc_id, version_id, option);
    // let document_info = this.state.document_info;

    switch (option) {
      case "view":
        this.getDocDetails(doc_id, version_id);
        break;
      case "promote":
        this.promoteVersion(doc_id, version_id);
        break;
    }
    // console.log("document_info", document_info)
    // this.setState({document_info: document_info})
  };

  promoteVersion = (doc_id, version_id) => {
    let postData = { command: "promote_doc" };
    postData["document_id"] = doc_id;
    postData["version_id"] = version_id;
    let api = new APICall();
    console.log("postData", postData);
    api.command(postData, this.processpromotedDocument);
  };

  processpromotedDocument = (result) => {
    let r = {};
    r["document_id"] = result.document_id;
    r["version_id"] = result.version_id;
    let alert_param = { title: "Success", message: "Document version has been changed successfully", ok_text: "OK", confirm: false, alertHandler: this.removepromoteHandler, stack: r };
    this.setState({ alert_param: alert_param });
  };

  removepromoteHandler = (result, stack) => {
    console.log("stack", stack);
    this.setState({ alert_param: null });
    // this.getDocDetails(stack.document_id, stack.version_id)
    // window.location.reload();

    let documents = Store.getStoreData('docs');

    for(let key in documents){
      if(parseInt(documents[key].doc_id) === parseInt(stack.document_id)){
        documents[key].version_id = parseInt(stack.version_id)
      }
    }
    Store.updateStore('docs', documents);

    this.props.refreshParent();
  };

  refreshParent = () => {
    this.props.refreshParent();
  };

  getExtention = (file_name) => {
    let file_parts = file_name.split(/\.(?=[^\.]+$)/);
  console.log("file_parts", file_parts)
    let extention = file_parts[file_parts.length - 1].toLowerCase();
    let is_valid_extention = false;
    if (extention in ext_map) {
      is_valid_extention = true;
    }
    return is_valid_extention;
  };

  handleButton = (event) => {
    event.preventDefault();
    var screen = this.state.screen;
    var current_view = null;
    screen.property = 0;
    screen.version = 0;
    if ([event.target.id] == "property") {
      screen.property = 1;
      current_view = "property";
    }
    if ([event.target.id] == "version") {
      screen.version = 1;
      current_view = "version";
    }

    this.setState({ screen: screen, current_view: current_view });
  };

  openAttestation = (id) => {
    let api = new APICall();
    let postData = { command: "get_doc", doc_id: id };
    api.command(postData, this.processVersionBySingleDocument);
  };

  processVersionBySingleDocument = (result) => {
    let doc = result.result;
    let bin_file = JSON.parse(doc.bin_file);
    this.setState({ bin_file: bin_file, show_attestation: "attestation" });
  };

  closeUserDialog = () => {
    this.setState({ show_attestation: null });
  };

  reloadDocuments = (doc_id, version_id) => {
    this.getDocDetails(doc_id, version_id);
    this.setState({ show_attestation: null });
  };

  render() {
    console.log("CSLDocViewerID4 this.state.document_info", this.state);
    if (this.state.alert_param !== null) {
      return <AlertBox alertParam={this.state.alert_param} />;
    }
    if (!this.state.ready) return <div></div>;

    return (
    <Wrapper>
      <FolderContainer style={{ marginLeft: this.state.document_info["is_support"] ? 0 : "8%" }}>
        <div>
          <Style.DocHeader style={{ borderLeftColor: "#11ABA6", borderLeftStyle: "solid", borderLeftWidth: "10px", borderRadius: "0px" }}>
            <div style={{ fontSize: "14px", float: "left", marginTop: "24px", marginLeft: "10px" }}>Document Details</div>
            <ModalHeaderCloseBtn onClick={this.closeModal}>
              <MdClose />
            </ModalHeaderCloseBtn>
          </Style.DocHeader>
          <Style.DocBody style={{ padding: "0px" }}>
            <Style.Flexbox style={{ backgroundColor: "#F1F6F7" }}>
              {(() => {
                if (this.state.document_info["is_support"]) {
                  return (
                    <div style={{ width: "46%", border: "1px solid #e6e6e6" }}>
                      <Style.Flexbox style={{ width: "100%", backgroundColor: "#F1F6F7", padding: "10px" }}>
                        <div style={{ width: "20%" }}>
                          <img src={this.state.document_info["logo"]} style={{ height: "60px" }} />
                        </div>
                        <div style={{ width: "80%" }}>
                          <Style.SimpleLabelDiv>
                            <Style.ModalNameLabelDiv style={{ fontSize: "16px", fontWeight: "600", float: "left", marginLeft: "10px", display: "flex", color: "#27415c", fontFamily: "Montserrat', sans-serif" }} title={this.state.document_info.name}>
                              <NoWrap style={{width: "50%", textAlign:"left"}}>{this.state.document_info["name"]} </NoWrap>&nbsp; ({this.state.document_info["doc_version"]})
                              <span style={{ color: "#11ABA6", cursor: "pointer", fontSize: "12px", marginLeft: "25px" }} onClick={this.handleDownload(this.state.document_info["version_id"])}>
                                {" "}
                                Download
                              </span>
                            </Style.ModalNameLabelDiv>
                          </Style.SimpleLabelDiv>
                          <Style.SpacerL></Style.SpacerL>
                          <Style.SpacerL></Style.SpacerL>
                          <Style.SimpleLabelDiv>
                            <Style.ModalNameLabelDiv style={{ fontSize: "14px", fontWeight: "400", textAlign: "left", padding: "10px 10px", color: "#8293a2", fontFamily: "DM Sans" }}>
                              Uploaded by {this.state.document_info["created_by"]} on {this.state.document_info["uploaded"]}
                            </Style.ModalNameLabelDiv>
                          </Style.SimpleLabelDiv>
                        </div>
                      </Style.Flexbox>

                      <Style.Flexbox style={{ width: "100%", backgroundColor: "#FFF", height: "520px", border: "1px solid #e6e6e6", padding: "10px" }}>
                        <CSLDocViewer docId={this.state.document_info["version_id"]} />
                      </Style.Flexbox>
                    </div>
                  );
                }
              })()}
              <div style={{ width: this.state.document_info["is_support"] ? "58%" : "100%", paddingLeft: "10px", overflowX: "hidden", height: "600px" }}>
                <Style.Flexbox style={{ width: "100%", backgroundColor: "#F1F6F7" }}>
                  <SectionTab id="property" style={{ backgroundColor: this.state.screen.property === 1 ? "#1a3552" : "#fff" }} onClick={this.handleButton}>
                    Properties
                  </SectionTab>
                  <SectionTab id="version" style={{ backgroundColor: this.state.screen.version === 1 ? "#1a3552" : "#fff" }} onClick={this.handleButton}>
                    Version History
                  </SectionTab>
                </Style.Flexbox>

                <Style.Flexbox style={{ width: "100%", backgroundColor: "#F1F6F7", paddingRight: "1rem" }}>
                  {(() => {
                    switch (this.state.current_view) {
                      case "property":
                        return <Property is_support={this.state.document_info["is_support"]} doc={this.state.document_info} size={this.state.document_info["fileSize"]} versions={this.state.document_info["versions"]} is_support={this.state.document_info["is_support"]} logo={this.state.document_info["logo"]} openAttestationModal={this.openAttestation} childTasks={this.state.childtasks} approvars={this.state.approvars} refreshParent={this.refreshParent} />;
                        break;
                      case "version":
                        return <Version versions={this.state.versions} quickmenuClick={this.quickmenuClick} lock_doc={this.state.document_info['lock_doc']} />;
                        break;
                      default:
                        return <Style.NoPermission>Sorry, you do not have permission to view this page. </Style.NoPermission>;
                    }
                  })()}
                </Style.Flexbox>
              </div>
              {/*<AlertBox alertParam={this.state.alert_param} />*/}
            </Style.Flexbox>
          </Style.DocBody>
        </div>
        {(() => {
          if (this.state.show_attestation !== null && this.state.show_attestation === "attestation") {
            return (
              <div>
                <InactiveOverlay />
                <SendAttestationModal closeUserDialog={this.closeUserDialog} doc_info={this.state.document_info} bin_files={this.state.bin_file} reloadDocuments={this.reloadDocuments} is_add={false} />
              </div>
            );
          }
        })()}
      </FolderContainer>
      </Wrapper>
    );
  }
}

export default DocumentViewModal;
