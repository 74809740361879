import React from "react";
import APICall from "../../Common/APICall.js";
import Store from "../../Common/Store.js";
import * as Style from "../Common/StyledComponents";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import CSLLoader from "../Common/CSLLoader";
import AlertBox from "../Common/AlertBox";
// import Dropzone from "../Common/Dropzone.js";
import CSLTable from "../Common/CSLTable";
import CSLToggle from "./Include/CSLToggle";
import SendAttestationModal from "./Attestation/SendAttestationModal.js";
import Dropzone from "../Dropzone/Dropzone.js"
import DatePicker from "react-date-picker";
import RecordApprovalHeader from "./RecordApprovalHeader.js";
import AddRecordApprovalModal from "./AddRecordApprovalModal.js";
import AddApprovalModal from "./AddApprovalModal.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, thin } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used
import HtmlEditor from '../Common/HtmlEditor';
const moment = require("moment");
const last_action_time = moment().unix();

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
`;

const FolderContainer = styled.div`
  display: block;
  box-sizing: border-box;
  max-height: 90vh;
  width: 90%;
  max-width: 800px;
  position: fixed;
  overflow-y: auto;
  margin: auto;
  z-index: 1001;
  background-color: #f7f7f7;
  border-radius: 5px;
  box-shadow: 0 0 20px #a7a6a6;
  @media only screen and (min-width: 640px) {
    max-width: 80%;
  }

  @media only screen and (min-width: 768px) {
    max-width: 70%;
  }
  @media only screen and (min-width: 1024px) {
    max-width: 60%;
  }
  @media only screen and (min-width: 1280px) {
    max-width: 50%;
  }
`;

const DocHeader = styled.div`
  border-width: 1px;
  background-color: white;
  border-color: #e9e9e9;
  display: block;
  font-size: 14px;
  font-weight: 700;
  height: 50px;
  border-radius: 5px;
`;
const ModalHeaderCloseBtn = styled.div`
  float: right;
  box-sizing: border-box;
  color: #282828;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
`;

const DocBody = styled.div`
  display: block;
  box-sizing: border-box;
  background-color: #f3f7fb;
  padding: 10px;
  border-radius: 4px;
`;

const DocFormContainer = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  position: relative;
`;

const SpacerL = styled.div`
  height: 10px;
`;
const SpacerXL = styled.div`
  height: 20px;
`;

const ContactRadio = styled.input`
  border: 1px solid #ffffff;
  border-radius: 3px;
  color: #979797;
  font-family: montserrat;
  font-size: 14px;
  font-weight: 600;
  margin: 10px;
`;

const UserFormFooter = styled.div`
  position: relative;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #e9ecef;
  height: 79px;
  border-radius: 5px;
`;

const MRButton = styled.button`
  box-sizing: border-box;
  background-color: #046de4;
  color: #ffffff;
  border: 1px solid #37ada7;
  width: 127px;
  font-size: 13px;
  height: 39px;
  border-radius: 4px;
  float: right;
  margin-top: -30px;
  cursor: pointer;
`;

const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 3000px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;

const RadioBlock = styled.div`
  float: right;
  margin-top: -5px;
`;
const SelectedRadio = styled.div`
  background-color: #37ada7;
  border: 1px solid #1f615e;
  padding: 5px 10px;
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
`;
const UnselectedRadio = styled.div`
  background-color: #f4f4f4;
  border: 1px solid #8c8c8c;
  padding: 5px 10px;
  color: #262626;
  display: inline-block;
  cursor: pointer;
`;

const CSLDateCover = styled.div`
  margin-top: 8px;
  width: 200px;
  border-radius: 5px;
  background-color: #ffffff;
  height: 33px;
  padding-top: 5px;
  padding-left: 10px;
`;

const OverviewInfoBody = styled.div`
  padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;

class AddDocumentModal extends React.Component {
  state = {
    view_count: 0,
    ready: false,
    alert_param: null,
    saving: false,
    binFiles: [],
    cur_files_json: null,
    types: {},
    contacts: {},
    addnew: true,
    document_info: {
      name: "",
      doc_type: 0,
      doc_owner: 0,
      doc_ownerin_no: 0,
      description: "",
      visibility: 0,
      lock_doc: 0,
      file_name: "",
      doc_version: "1.0",
    },
    formvalid: null,
    show_attestation: false,
    all_company: true,
    gcs: {},
    loading: false,
    users: [],
    review_date: null,
    record_approval_history:[],
    current_approval_data:null,
    approval_data: [],
    show_modal: false

  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.callDefaultinfo();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.callDefaultinfo();
    }
  }

  filesLoaded = (files) => {
    console.log("files", files)
    let contact_id = Store.getStoreData("contact").ID;
    for (let i = 0; i < files.length; i++) {
      files[i].name = files[i].name.replace("&", "");
      if (!("user_id" in files[i])) {
        files[i].user_id = contact_id;
        files[i].upload_date = last_action_time;
      }
    }
    let document_info = this.state.document_info;
    document_info["file_name"] = files.length > 0 ? files[0].name : "";
    this.setState({
      binFiles: files,
      addnew: false,
      document_info: document_info,
    });
  };

  sortAssociativeArrayByValues = (obj) => {
      let x = [];
      for (let key in obj) {
        x.push({id: key, name: obj[key]});
      }
      return x;
  }

  arraySortByname = (arr) => {
    arr.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return 1; 
      }
      if (nameA > nameB) {
        return -1;
      }
      return 0; 
    });
    return arr;
  }

  callDefaultinfo() {
    // let doc_types = Store.getStoreData("selected_doc_types");
    let doc_types = Store.getStoreData("types");
    let contact_assoc = Store.getStoreData("contact_assoc");
    let companies_assoc = Store.getStoreData("companies_assoc");
    let gc_users = Store.getStoreData("gc_users");
    let role = Store.getStoreData("role");
    let contact = Store.getStoreData("contact");
    let user_assignments = Store.getStoreData("user_assignments");
    let show_all = role === "admin_manager" ? true : false;
    let document_info = this.state.document_info;
    document_info["doc_type"] = doc_types.length === 0 ? 0 : doc_types[0].id;

    let user_list = {};
    console.log("contact_assoc ==>", contact_assoc, gc_users);
    if (Object.keys(gc_users).length !== 0) {
      for (let key in contact_assoc) {
        if (show_all || this.hasCommonCompanyCheckFirst(contact.ID, key, gc_users)) {
          if (key in gc_users) {
            user_list[key.toString()] = contact_assoc[key.toString()];
          }
        }else{
          if (key in gc_users) {
            user_list[key.toString()] = contact_assoc[key.toString()];
          }
        }
      }
    } else {
      user_list = contact_assoc;
    }
    
    // let valuesArray = Object.values(user_list);
    // let sortedValues = valuesArray.slice().sort();
    // let sortedObj = {};
    // for (let key in user_list) {
    //   if (user_list.hasOwnProperty(key)) {
    //     let originalValue = user_list[key];
    //     let sortedValue = sortedValues.shift(); 
    //     sortedObj[key] = sortedValue;
    //   }
    // }

    let users = this.sortAssociativeArrayByValues(user_list);
    users.sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();

      if (nameA < nameB) {
        return -1; // a should come before b
      }
      if (nameA > nameB) {
        return 1; // a should come after b
      }
      return 0; // names are equal, no change in order
    });

    document_info["doc_owner"] = users[0].id;
    let user_roles = Store.getStoreData('user_roles');
    let is_manager = user_roles[document_info["doc_owner"].toString()] === "admin_manager" ? true : false; 

    // console.log("user_list", user_assignments[Object.keys(user_list)[0].toString()].companies)
    let owner_gcs = Object.keys(user_assignments).length === 0 ? {} : user_assignments[users[0].id.toString()].companies;
    let gcs = {};
    for (let k in companies_assoc) {
      // if(companies_assoc[k].is_active === 1){
        if(is_manager){
          gcs[k] = { id: k, name: companies_assoc[k].company_name, selected: false };
        }else{
          if (k in owner_gcs) {
            gcs[k] = { id: k, name: companies_assoc[k].company_name, selected: false };
          }
        }
      // }

    }

    document_info["folder_id"] = this.props.folder_id;

    this.setState({
      ready: true,
      types: doc_types,
      contacts: user_list,
      document_info: document_info,
      gcs: gcs,
      users: users
    });
  }

  hasCommonCompanyCheckFirst = (manager_id, user_id, gc_users) => {
    if (Object.keys(gc_users).length === 0) return true;
    if (manager_id === "") return true;
    let manager_companies = gc_users[manager_id];
    let user_companies = gc_users[user_id];
    for (let cid in manager_companies) {
      for (let uid in user_companies) {
        if (cid.toString() === uid.toString()) return true;
      }
    }
    return false;
  };

  processData = (result) => {
    let cur_contact_info = result.contact_info;
    this.contact_info = cur_contact_info;
    this.cur_contact_info = cur_contact_info;
    let contact_name = cur_contact_info.ContactName;
    let job_titles = result.job_titles;
    let regulatory_status = result.regulatory_status;
    this.setState({
      ready: true,
      cur_contact_info: cur_contact_info,
      regulatory_status: regulatory_status,
      job_titles: job_titles,
      contact_name: contact_name,
      userId: this.props.userId,
    });
  };

  processIndexData = (result) => {
    window.location.reload();
  };

  refreshState = (cur_view) => {
    this.setState({ view_count: this.state.view_count + 1 });
  };

  returnSection = (section) => (event) => {
    event.preventDefault();
    this.setState({ active_section_id: section });
  };

  afterUserUpdate = (result, stack) => {
    this.setState({ alert_param: null });
    window.location.reload();
  };

  closeModal = (event) => {
    if (event) event.preventDefault();
    this.props.closeUserDialog();
  };

  setDirtyFlag = (contact_info) => {
    this.user_is_dirty = true;
    this.contact_info = contact_info;
  };

  handleChangeDocOwner = (event) => {
    if (event) event.preventDefault();
    let companies_assoc = Store.getStoreData("companies_assoc");
    let user_assignments = Store.getStoreData("user_assignments");
    let document_info = this.state.document_info;
    document_info[event.target.name] = event.target.value;
    // let owner_gcs = user_assignments[event.target.value.toString()].companies;
    let owner_gcs = Object.keys(user_assignments).length === 0 ? {} : user_assignments[event.target.value.toString()].companies;
    let gcs = {};
    let user_roles = Store.getStoreData('user_roles');
    let is_manager = user_roles[event.target.value.toString()] === "admin_manager" ? true : false; 

    for (let k in companies_assoc) {
      // if(companies_assoc[k].is_active === 1){
      if(is_manager){
        gcs[k] = { id: k, name: companies_assoc[k].company_name, selected: false };
      }else{
        if (k in owner_gcs) {
          gcs[k] = { id: k, name: companies_assoc[k].company_name, selected: false };
        }
      }
    // }

    }

    console.log("event.target.value", event.target.value);

    this.setState({ document_info: document_info, gcs: gcs });
  };

  handleChange = (event) => {
    let document_info = this.state.document_info;
    document_info[event.target.name] = event.target.value;
    // alert(JSON.stringify(document_info));
    this.setState({ document_info: document_info });
  };
  handleRadio = (event) => {
    let document_info = this.state.document_info;
    document_info["visibility"] = event.target.name === "public" ? 0 : 1;
    this.setState({ document_info: document_info });
  };
  handlelockRadio = (event) => {
    let document_info = this.state.document_info;
    document_info["lock_doc"] = event.target.name === "no" ? 0 : 1;
    this.setState({ document_info: document_info });
  };

  handleUploadDoc = (event) => {
    event.preventDefault();

    let is_empty = false;
    let is_empty_upload = false;
	let description_txt = Store.getStoreData("description");
    for (let key in this.state.document_info) {
      if(this.state.document_info['file_name'] === ""){
        is_empty_upload = true;
      }else{
        if (this.state.document_info[key] === "") {
          is_empty = true;
        }
        if (key === "description") {
          is_empty = false;
		  if(description_txt === null || description_txt===""){
			  is_empty = true;
		  }
        }
      }
    }
    if (is_empty_upload) {
      let alert_param = { title: "Alert", message: "Please upload a document", ok_text: "OK", confirm: false, alertHandler: this.alertRemove, stack: { id: 0 } };
      this.setState({ alert_param: alert_param });
      return false;
    }
    if (is_empty) {
      let alert_param = { title: "Alert", message: "Please fill all the fields", ok_text: "OK", confirm: false, alertHandler: this.alertRemove, stack: { id: 0 } };
      this.setState({ alert_param: alert_param });
      return false;
    }
    let doc_version = this.state.document_info['doc_version']
    if(isNaN(doc_version)){
      let alert_param = { title: "Alert", message: "The document version field is a numerical field and should contain up to 2 decimal places, for example: 1, 1.0 or 1.00", ok_text: "OK", confirm: false, alertHandler: this.alertRemove, stack: { id: 0 } };
      this.setState({ alert_param: alert_param });
      return false;
    }
    let index = doc_version.indexOf(".");
    if(index > 0 && (doc_version.length - index - 1) > 2){
      let alert_param = { title: "Alert", message: "The document version field is a numerical field and should contain up to 2 decimal places, for example: 1, 1.0 or 1.00", ok_text: "OK", confirm: false, alertHandler: this.alertRemove, stack: { id: 0 } };
      this.setState({ alert_param: alert_param });
      return false;
    }
    // alert(doc_version)
    let bin_files = this.state.binFiles;
    let dnld = bin_files[0].bin_file.replace(/ /g, "+");
    let binaryString = window.atob(dnld);
    var fileSize = binaryString.length;
    if(fileSize > 5000000){
      let alert_param = { title: "Alert", message: "This file may take some time to upload and for users to download. Would you like to continue to upload this document?", ok_text: "OK", confirm: true, alertHandler: this.processORcancel, stack: {} };
      this.setState({ alert_param: alert_param });
    }else{
      this.refs.dropZone.uploadFilesFromChild(bin_files);
    }

  };

  processORcancel = (result, stack) => {
    if (!result || stack.prevent) {
      this.setState({ alert_param: null });
      return;
    }
    this.setState({ alert_param: null });
    let bin_files = this.state.binFiles;
    this.refs.dropZone.uploadFilesFromChild(bin_files);
  }

  AlertCompleteHandler = (result, stack) => {
    this.setState({ alert_param: null });
  };

  onUploadComplete = (files) => {
    console.log("hello files", files)
    let returnedFiles = JSON.parse(files);
    // let returnedFiles = files;
    this.setState({loading: true });
    let bin_files = this.state.binFiles;
    if (typeof bin_files !== 'undefined' && bin_files.length > 0) {
      bin_files.forEach((file) => {
        let fileName = file.name;
        let refID = returnedFiles.find((o) => o.inserted_file_name == fileName)['ref_id'];
        delete file.bin_file;
        file.ref_id = refID;
      });
    }
    // this.setState({loading: true})
    this.setState({ alert_param: null });
    let postData = { command: "upload_document" };
    let document_info = this.state.document_info;
	let description = Store.getStoreData('description');
	if(description === null){
		description = '<p></p>'
	}
	let description_text = btoa(window.encodeURIComponent(description));
	document_info["description"] = description_text;
    let gcs = this.state.gcs;
    let g = {};
    for (let key in gcs) {
      if (this.state.all_company) {
        g[key] = 1;
      } else {
        if (gcs[key].selected) g[key] = 1;
      }
    }
    
    if(this.state.review_date!==null){
      let curmonth = parseInt(this.state.review_date.getMonth());
      let currmonth = curmonth + 1;
      var dueDateStr = currmonth >= 10 ? this.state.review_date.getFullYear() + "/" + currmonth + "/" : this.state.review_date.getFullYear() + "/0" + currmonth + "/";
      dueDateStr += this.state.review_date.getDate() < 10 ? "0" + this.state.review_date.getDate() : this.state.review_date.getDate();
      let document_info = this.state.document_info;
      document_info["review_date"] = dueDateStr;
    }
    document_info["gc_ids"] = g;
    postData["document_info"] = document_info;
    postData["uploaded_doc"] = bin_files;
    postData["approval_data"] = this.state.approval_data;
    postData["last_actin_time"] = Store.getStoreData('doc_last_actin_time');
    let api = new APICall();
    console.log("onUploadComplete postData", postData);
    api.command(postData, this.processDocument);

  }

  alertRemove = (result, stack) => {
    this.setState({ alert_param: null });
  };

  processDocument = (result) => {
    console.log("processDocument result", result)
    let doc = result.doc;
    let doc_last_actin_time = doc.sort((a, b) => b.last_action_time - a.last_action_time)[0].last_action_time;
    Store.updateStore("doc_last_actin_time", doc_last_actin_time)
    let documents = Store.getStoreData('docs');
    let docs = [...documents, ...doc];
    Store.updateStore('docs', docs);
    let alert_param = { title: "Success", message: "Your document has been added", ok_text: "OK", confirm: false, alertHandler: this.AlertremoveHandler, stack: { id: 0 } };
    this.setState({ alert_param: alert_param, loading: false });
  };

  AlertremoveHandler = (result, stack) => {
    this.setState({ alert_param: null });
    Store.updateStore('description', null)
    this.props.refreshParent();
  };

  sendAttestation = (event) => {
    event.preventDefault();
    let is_attest_access = Store.getStoreData('is_attest_access')
    if(!is_attest_access){
      let alert_param = { title: "Warning", message: "You do not have the permissions to add attestations.", ok_text: "OK", confirm: false, alertHandler: this.alertRemove, stack: { doc_id: 0 } };
      this.setState({ alert_param: alert_param });
      return false;
    }
    let is_empty = false;
    let is_empty_upload = false;
    for (let key in this.state.document_info) {
      if(this.state.document_info['file_name'] === ""){
        is_empty_upload = true;
      }else{
        if (this.state.document_info[key] === "") {
          is_empty = true;
        }
      }
    }
    if (is_empty_upload) {
      let alert_param = { title: "Alert", message: "Please upload a document", ok_text: "OK", confirm: false, alertHandler: this.alertRemove, stack: { id: 0 } };
      this.setState({ alert_param: alert_param });
      return false;
    }
    if (is_empty) {
      let alert_param = { title: "Alert", message: "Please fill all the fields", ok_text: "OK", confirm: false, alertHandler: this.alertRemove, stack: { id: 0 } };
      this.setState({ alert_param: alert_param });
      return false;
    }

    let doc_types = Store.getStoreData("doc_types");
    let document_info = this.state.document_info;
    document_info["doc_ownerin_no"] = document_info["doc_owner"];
    document_info["doc_id"] = 0;
    document_info["version_id"] = 0;
    document_info["type"] = doc_types[document_info["doc_type"]];
    document_info["typeinno"] = document_info["doc_type"];
    let gcs = this.state.gcs;
    let g = {};
    for (let key in gcs) {
      if (this.state.all_company) {
        g[key] = 1;
      } else {
        if (gcs[key].selected) g[key] = 1;
      }
    }
    document_info["gc_ids"] = g;
    this.setState({ show_attestation: "attestation", alert_param: null, document_info: document_info });
  };

  confirAttestationProcessor = (result, stack) => {
    if (!result || stack.prevent) {
      this.setState({ alert_param: null });
      return;
    }
    let doc_types = Store.getStoreData("doc_types");
    let document_info = this.state.document_info;
    document_info["doc_ownerin_no"] = document_info["doc_owner"];
    document_info["doc_id"] = 0;
    document_info["version_id"] = 0;
    document_info["type"] = doc_types[document_info["doc_type"]];
    document_info["typeinno"] = document_info["doc_type"];
    let gcs = this.state.gcs;
    let g = {};
    for (let key in gcs) {
      if (this.state.all_company) {
        g[key] = 1;
      } else {
        if (gcs[key].selected) g[key] = 1;
      }
    }
    document_info["gc_ids"] = g;
    this.setState({ show_attestation: "attestation", alert_param: null, document_info: document_info });
  };

  closeUserDialog = () => {
    this.setState({ show_attestation: null });
  };

  reloadDocuments = (doc_id, version_id) => {
    // this.getDocDetails(doc_id, version_id);
    this.setState({ show_attestation: null });
    this.props.refreshParent();
  };

  switchRadio = (event) => {
    event.preventDefault();
    let all_company = this.state.all_company ? false : true;
    this.setState({ all_company: all_company });
  };

  selectGc = (event) => {
    event.preventDefault();
    let gc_id = event.target.value;
    let gcs = this.state.gcs;
    gcs[gc_id].selected = gcs[gc_id].selected ? false : true;
    // let document_info = this.state.document_info;
    // let g = {};
    // if (!this.state.all_company) {
    //   for (let k in gcs) {
    //     if (gcs[k].selected) g[k] = 1;
    //   }
    // }
    // document_info["gc_ids"] = g;
    this.setState({ gcs: gcs });
  };

  tableData = () => {
    let table = { data: [], columns: [] };
    let gcs = this.state.gcs;

    table.columns = [
      { Header: "Name", accessor: "name", headerStyle: { textAlign: "left" }, style: { whiteSpace: "unset" } },
      {
        Header: "Status",
        accessor: "status",
        width: 80,
        headerStyle: { textAlign: "center" },
        Cell: (row) => (
          <div>
            <input type="checkbox" checked={row.original.selected} value={row.original.id} onClick={this.selectGc} />
          </div>
        ),
        style: { display: "flex", alignItems: "center", justifyContent: "center" },
      },
    ];

    for (let key in gcs) {
      let elem = {
        name: gcs[key].name,
        id: key,
        selected: "selected" in gcs[key] ? gcs[key].selected : false,
      };
      table.data.push(elem);
    }
    return table;
  };

  /**new code */

  deleteApproval = (id) => {
     const state_approvals = JSON.parse(JSON.stringify(this.state.approval_data))
     let approval_data = []
     for (let item of state_approvals) {
         if (item.auto_id !== id) approval_data.push(item)
     }
     this.setState({approval_data:approval_data, current_approval_data: null,show_modal: false})
  }

  editApproval = (id) => {
    let state_approvals = JSON.parse(JSON.stringify(this.state.approval_data))
    let sin = state_approvals.find(x => x.auto_id === id);
    sin.add = 0;
    this.setState({show_modal: true, current_approval_data:sin})
  }



  recordApprovalHistoryData = () => {
    let table = { data: [], columns: [] };
   // let gcs = this.state.gcs;

    table.columns = [
      { Header: "#", accessor: "index", headerStyle: { textAlign: "left" }, style: { whiteSpace: "unset" } },
      { Header: "Version", accessor: "version", headerStyle: { textAlign: "left" }, style: { whiteSpace: "unset" } },
      { Header: "Approver", accessor: "approver", headerStyle: { textAlign: "left" }, style: { whiteSpace: "unset" } },
      { Header: "Date", accessor: "approvedate", headerStyle: { textAlign: "left" }, style: { whiteSpace: "unset" } },
      { Header: "Comments", accessor: "approvecomments", headerStyle: { textAlign: "left" }, style: { whiteSpace: "unset" } },
      {
        Header: "Actions",      
        width: 80,
        headerStyle: { textAlign: "center" },
        Cell: (row) => (
          <div>
          <FontAwesomeIcon icon={solid('pen')} style={{cursor: "pointer"}} onClick={()=>{this.editApproval(row.original.id)}}/>
          
         <FontAwesomeIcon icon={solid('trash-can')} style={{cursor: "pointer", marginLeft:"7px"}} onClick={() => {this.deleteApproval(row.original.id)}}/>
      </div>
        ),
        style: { display: "flex", alignItems: "center", justifyContent: "center" },
      },
    ];
    let i=0;
    for(let record of this.state.approval_data) {           
      i++;              
      let elem = {'index' : i, 
                  'id':record.auto_id,
                  'version':record.doc_version,
                  'approver' : record.approvar,
                  'approvedate':this.getDate(record.approval_date),
                  'approvecomments' : record.comments,
             };
             table.data.push(elem);
  }
    return table;
  };

  getDate = (input_date) => {
    if(input_date != null){
      let dateParts = input_date.split('/'); 
      let ret = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
      return ret;
    }else{
      return '';
    }
   
  }

  onReviewdateChange = (date) => {
    this.setState({ review_date: date});
  }
  
  reloadApprovers = (record) => {
    console.log('reload approvars=',record);
    let auto_id = record.auto_id;
    let state_approval = JSON.parse(JSON.stringify(this.state.approval_data));
    if(record.add === 1){
      state_approval.push(JSON.parse(JSON.stringify(record)))
    }else{
      state_approval = state_approval.map((obj) => {
          if (obj.auto_id === auto_id) {
            return { ...obj, ...record };
          }
          return obj;
        });
    }
    this.setState({approval_data:state_approval,current_approval_data:null, show_modal: false})

  }

  refreshAddEditRecordApprovalHistory=(approvalobj)=>{

    var fieldarray=[... this.state.record_approval_history];

    console.log('id=',approvalobj.id)
 
 
    console.log('approvalobj=',approvalobj)
 
    if(approvalobj.id==0){//
      let newid=1;
      if(fieldarray.length!=0){
      let cdata=fieldarray[fieldarray.length-1];
       newid=cdata.id+1;}
      let newcdata={
        'id':newid,
        'version':approvalobj.version,
        'approver':approvalobj.approver,
        'approvedate':approvalobj.approvedate,
        'approvecomments':approvalobj.approvecomments,
        'isCommitteeApproved':approvalobj.isCommitteeApproved,
        'approvar_json':approvalobj.approvar_json
      }
      fieldarray.push(newcdata);
    }
    else{//update
     for(let cd of fieldarray) {
        if(cd.id==approvalobj.id){
         cd.version=approvalobj.version;
         cd.approver=approvalobj.approver;
         cd.approvedate=approvalobj.approvedate;
         cd.approvecomments=approvalobj.approvecomments;

         cd.isCommitteeApproved=approvalobj.isCommitteeApproved;
         cd.approvar_json=approvalobj.approvar_json;
         break;
        }
    }
 
   }
   console.log('fieldaray=',fieldarray)
   this.setState({record_approval_history:fieldarray,current_approval_data:null})

  }

  render() {
    console.log("contact_info", this.state);
    return (
    <Wrapper>
      <FolderContainer>
        <div>
          <DocHeader>
            <div style={{ fontSize: "14px", float: "left", marginTop: "15px", marginLeft: "10px" }}>Upload Document</div>
            <ModalHeaderCloseBtn onClick={this.closeModal}>
              <MdClose />
            </ModalHeaderCloseBtn>
          </DocHeader>
          <DocBody>
            <DocFormContainer>
              {
                (() => {
                  if(this.state.loading) return <CSLLoader />;
                })()
              }
              <div style={{ padding: "0px" }}>
                <Style.ModalNameLabelDiv>Uploaded Document</Style.ModalNameLabelDiv>
                <Style.ModalNameInputDiv>
                  <Dropzone onFilesAdded={this.filesLoaded} initFiles={this.state.cur_files_json} addnew={this.state.addnew} buttontxt="Upload document" onUploadComplete={this.onUploadComplete} ref="dropZone" />
                </Style.ModalNameInputDiv>
              </div>
              <Style.ModalNameLabelDiv>Document Name</Style.ModalNameLabelDiv>
              <SpacerL />
              <Style.ModalNameInputDiv>
                <Style.ModalInput type="text" name="name" onChange={this.handleChange} value={this.state.document_info["name"]} />
              </Style.ModalNameInputDiv>
              <SpacerL />
              <Style.ModalNameLabelDiv>Document Version</Style.ModalNameLabelDiv>
              <SpacerL />
              <Style.ModalNameInputDiv>
                <Style.ModalInput type="text" name="doc_version" onChange={this.handleChange} value={this.state.document_info["doc_version"]} />
              </Style.ModalNameInputDiv>
              <SpacerL />
              <Style.ModalNameLabelDiv>Document Type</Style.ModalNameLabelDiv>
              <SpacerL />
              <Style.ModalNameInputDiv>
                <Style.MRModalSelect onChange={this.handleChange} name="doc_type" value={this.state.document_info["doc_type"]}>
                  {(() => {
                    if (this.state.types.length !== 0) {
                      let regulatory_status = [];
                      for (let key in this.state.types) {
                        regulatory_status.push(
                          <option key={this.state.types[key].id} value={this.state.types[key].id}>
                            {this.state.types[key].name}
                          </option>
                        );
                      }
                      return regulatory_status;
                    }
                  })()}
                </Style.MRModalSelect>
              </Style.ModalNameInputDiv>
              <SpacerL />
              <Style.ModalNameLabelDiv>Document Owner</Style.ModalNameLabelDiv>
              <SpacerL />
              <Style.MRModalSelect onChange={this.handleChangeDocOwner} name="doc_owner" value={this.state.document_info["doc_owner"]}>
              {(() => {
                if (this.state.users.length !== 0) {
                  let regulatory_status = [];
                  for (let key in this.state.users) {
                    regulatory_status.push(
                      <option key={this.state.users[key].id} value={this.state.users[key].id}>
                        {this.state.users[key].name}
                      </option>
                    );
                  }
                  return regulatory_status;
                }
              })()}
              </Style.MRModalSelect>
              <SpacerL />
              <Style.ModalNameLabelDiv>Document Description</Style.ModalNameLabelDiv>
              <SpacerL />
                <Style.ModalNameInputDiv>

                {
                    (() => {
                        //if(1){
                            {/*return(<ModalTextArea name="faq_answer" defaultValue={this.state.faq_answer.replace(/(&rsquo;)/g, "\'").replace(/'+/g,"'")} onChange={this.handleChange} />)*/}
                            return <HtmlEditor style={{width: '98%', padding: "5px"}} content={this.state.document_info["description"]} readonly={false}/>
                        //}else{
                        //    return <div dangerouslySetInnerHTML={{__html: this.state.document_info["description"]}} style={{textAlign: "justify"}}></div> 
                        //}
                    })()
                 }

              </Style.ModalNameInputDiv>
              <SpacerL />
              <Style.ModalNameLabelDiv>Visibility</Style.ModalNameLabelDiv>
              <Style.ModalNameInputDiv>
                <ContactRadio type="radio" value="0" name="public" checked={this.state.document_info["visibility"] === 0 ? true : false} onClick={this.handleRadio} /> Public
                <ContactRadio type="radio" value="1" name="private" checked={this.state.document_info["visibility"] === 1 ? true : false} onClick={this.handleRadio} /> Private
              </Style.ModalNameInputDiv>
              <SpacerL />
              {(() => {
                if (Store.getStoreData("is_gc")) {
                  return (
                    <div>
                      <Style.ModalNameLabelDiv>Apply to all companies</Style.ModalNameLabelDiv>
                      <SpacerL />
                      <SpacerL />
                      <div style={{ float: "left" }}>
                        {(() => {
                          if (this.state.all_company) {
                            return (
                              <RadioBlock>
                                <SelectedRadio>Yes</SelectedRadio>
                                <UnselectedRadio onClick={this.switchRadio}>No</UnselectedRadio>
                              </RadioBlock>
                            );
                          } else {
                            return (
                              <RadioBlock>
                                <UnselectedRadio onClick={this.switchRadio}>Yes</UnselectedRadio>
                                <SelectedRadio>No</SelectedRadio>
                              </RadioBlock>
                            );
                          }
                        })()}
                      </div>
                      <SpacerL />
                      <SpacerL />
                      <Style.HomeText style={{ float: "none", marginLeft: "0px" }}>Companies are restricted based on the document owner's group company associations</Style.HomeText>
                      <SpacerL />
                      {(() => {
                        if (!this.state.all_company) {
                          return <CSLTable add={false} processData={this.tableData} tableRows={10} offset={true} />;
                        }
                      })()}
                    </div>
                  );
                }
              })()}
              <SpacerL />
              <SpacerL />
              {
                (() => {
                if (Store.getStoreData("role") === "admin_manager") {
                  return (
                    <div>
                      <Style.ModalNameLabelDiv>Lock document</Style.ModalNameLabelDiv>
                      <Style.ModalNameInputDiv>
                        <ContactRadio type="radio" value="0" name="no" checked={this.state.document_info["lock_doc"] === 0 ? true : false} onClick={this.handlelockRadio} /> No
                        <ContactRadio type="radio" value="1" name="yes" checked={this.state.document_info["lock_doc"] === 1 ? true : false} onClick={this.handlelockRadio} /> Yes
                      </Style.ModalNameInputDiv>
                    </div>
                  );
                }
              })()
            }


            {
              (() => {
                if(Store.getStoreData("role") !== "team" && Store.getStoreData("record_doc_approval")) {
                  return(<div>
                          <Style.ModalNameLabelDiv>Document Approval</Style.ModalNameLabelDiv>
                          <Style.HomeText style={{ float: "none", marginLeft: "0px" }}>You can use this section to record approvals the document has been given</Style.HomeText>  
                          <SpacerL /> 
                          <AddApprovalModal current_approval_data={this.state.current_approval_data} show_modal={this.state.show_modal} doc_id = {0} reloadApprovers={this.reloadApprovers} />
                          <OverviewInfoBody>
                              <CSLTable add={false} processData={this.recordApprovalHistoryData} headerText={''} tableRows="5" />
                          </OverviewInfoBody>
                      </div>)
                }
              })()
            }

            {
              (() => {
              if (Store.getStoreData("role") !== "team") {
                return (
                  <div>
                    <Style.ModalNameLabelDiv>Review Date</Style.ModalNameLabelDiv>
                    <Style.HomeText style={{ float: "none", marginLeft: "0px" }}>You can set a date when this document should be reviewed and re-approved. Once you have uploaded the document, assign a Follow-up Action if you would like a reminder.</Style.HomeText>
                    <CSLDateCover>
                      <DatePicker onChange={this.onReviewdateChange} clearIcon={null} calendarIcon={null} locale={"en-GB"} value={this.state.review_date} />
                    </CSLDateCover>
                  </div>
                );
              }
            })()
          }




            </DocFormContainer>
          </DocBody>
          <UserFormFooter>
            <MRButton style={{ top: "55px", right: "350px", position: "absolute", backgroundColor: "#FFFFFF", color: "#757575" }} onClick={this.closeModal} disabled={this.state.saving}>
              CANCEL
            </MRButton>
            <MRButton style={{ top: "55px", right: "150px", position: "absolute", backgroundColor: "#1A3552", color: "white", width: "190px" }} onClick={this.sendAttestation} disabled={this.state.saving}>
              SEND FOR ATTESTATION
            </MRButton>
            <MRButton style={{ top: "55px", right: "10px", position: "absolute", backgroundColor: "#11ABA6", color: "white" }} onClick={this.handleUploadDoc} disabled={this.state.saving}>
              UPLOAD
            </MRButton>
          </UserFormFooter>
        </div>
        <AlertBox alertParam={this.state.alert_param} />
        {(() => {
          if (this.state.show_attestation !== null && this.state.show_attestation === "attestation") {
            return (
              <div>
                <InactiveOverlay />
                <SendAttestationModal closeUserDialog={this.closeUserDialog} doc_info={this.state.document_info} bin_files={this.state.binFiles} reloadDocuments={this.reloadDocuments} is_add={true} />
              </div>
            );
          }
        })()}
      </FolderContainer>
    </Wrapper>
    );
  }
}

export default AddDocumentModal;