import React from "react";
import styled from "styled-components";
import Store from "../Common/Store.js";
import * as Style from "./Common/StyledComponents";
import APICall from "../Common/APICall.js";
import CSLHeader from "./CSLHeader";
import WelcomeBanner from "./WelcomeBanner";
import Tabheader from "./Tabheader";
import DocLibrary from "./Document/DocLibrary";
import DocumentViewModal from "./Document/DocumentViewModal.js";
// import DocumentHOC from "./Tasks/document/DocumentHOC.js";
const NoPermission = styled.div`
  display: block;
  box-sizing: border-box;
  height: 40px;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  background-color: #ffffff;
  position: absolute;
  top: 100px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  border: 2px solid #37ada7;
  border-radius: 10px;
  color: #37ada7;
  z-index: 1000;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 3000px;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1000;
  opacity: 0.6;
`;

class Index extends React.Component {
  state = {
    view_count: 0,
    ready: false,
    configs: null,
    cur_view: "doclibrary",
  };

  constructor(props) {
    super(props);
  }
  componentDidMount = () => {
    this.setState({ ready: true });
  };

  toogleModule = (cur_view) => {
    this.setState({ cur_view: cur_view });
  };

  closeUserDialog = () => {
    let homeurl = "/";
    window.location = homeurl;
  };

  render() {
    let doc_id = this.props.match ? this.props.match.params.docId : 0;
    let version_id = this.props.match ? this.props.match.params.versionId : 0;
    let task_id = this.props.match ? this.props.match.params.taskId : 0;
    console.log(task_id, "====>", task_id);
    return (
      <div style={{ backgroundColor: "#f2f2f3", position: "relative" }}>
        <CSLHeader companyText={"EDMS"} headerText={"Document Management"} refresfParentState={this.refreshState} />
        <WelcomeBanner welcome={`Welcome back, ` + Store.getStoreData("contact").ContactName} />
        <Tabheader toogleModule={this.toogleModule} />

        {(() => {
          switch (this.state.cur_view) {
            case "dochome":
              return "Document Home";
              break;
            case "doclibrary":
              return <DocLibrary doc_id={doc_id} />;
              break;
            case "register":
              return "REgister Home";
              break;
            default:
              return <NoPermission>Sorry, you do not have permission to view this page. </NoPermission>;
          }
        })()}
        {(() => {
          if (doc_id > 0 && version_id > 0) {
            return (
              <div>
                <InactiveOverlay />
                <DocumentViewModal closeUserDialog={this.closeUserDialog} doc_id={doc_id} version_id={version_id} />
              </div>
            );
          }
        })()}
        {(() => {
          if (task_id > 0) {
            // return (
            //   <div>
            //     <InactiveOverlay />
            //     <DocumentHOC task_id={task_id} closeUserDialog={this.closeUserDialog} />
            //   </div>
            // );
          }
        })()}
      </div>
    );
  }
}

export default Index;
