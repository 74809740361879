import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { saveAs } from "file-saver";
import APICall from "../../../Common/APICall.js";
import Store from "../../../Common/Store.js";
let options = [];

const ITEM_HEIGHT = 48;

export default function LongMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(event.currentTarget);
  };
  const [Options, setOptions] = useState([]);
  useEffect(() => {
    options = ["View Folder", "Delete"];
    if(Store.getStoreData('role') === "document_manager" || Store.getStoreData('role') === "team"){
      options = ["View Details"];
    }
    setOptions(options);
  }, []);
  const handleClose = (option, index) => {
    setAnchorEl(null);
    let role = Store.getStoreData('role');
    switch(role){
      case "admin_manager":
      if (index == 0) {
        props.viewdoc();
      } else if (index == 1) {
        DeleteDocs();
      } 
      break;
      case "document_manager":
      if (index == 0) {
        props.viewdoc();
      } 
      break;
      case "team":
      if (index == 0) {
        props.viewdoc();
      } 
    }

  

  };



  const DeleteDocs = (event) => {
    //event.preventDefault();
    props.DeleteDocs("deletedocs");
  };


  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: "0px"}}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
      >
        {Options.map((option, index) => (
          <MenuItem
            key={option}
            selected={option === "Pyxis"}
            onClick={() => handleClose(option, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}