import React from "react";
import styled from "styled-components";
import Store from "../Common/Store.js";
import * as Style from "./Common/StyledComponents";
import { FaAngleRight, FaAngleUp, FaAngleDown } from "react-icons/fa";

const TabDiv = styled.div`
  display: block;
  box-sizing: border-box;
  width: 100%;
`;

const ModalContainertop = styled.div`
  padding: 15px;
  background-color: #ecf1f2;
  border: 1px solid #d7dddf;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const TabDivContainer = styled.div`
  padding-top: 0px;
  padding-left: 0px;
`;

const SectionTab = styled.div`
  float: left;
  width: 150px;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-weight: 600;
  margin-left: 5px;
`;

class Tabheader extends React.Component {
  state = {
    cur_view: null,
    screen: { home: 0, library: 1, register: 0 },
  };

  constructor(props) {
    super(props);
  }

  handleButton = (event) => {
    event.preventDefault();
    var screen = this.state.screen;
    var current_view = null;
    screen.home = 0;
    screen.library = 0;
    screen.register = 0;
    if ([event.target.id] == "home") {
      screen.home = 1;
      current_view = "dochome";
    }
    if ([event.target.id] == "library") {
      screen.library = 1;
      current_view = "doclibrary";
    }
    if ([event.target.id] == "register") {
      screen.register = 1;
      current_view = "register";
    }
    this.props.toogleModule(current_view);
    this.setState({ screen: screen });
  };

  render() {
    return (
      <ModalContainertop
        style={{
          backgroundColor: "#e7f0f7",
          borderRadius: "2px",
          marginTop: "35px",
        }}
      >
        <TabDiv style={{ marginLeft: "5px" }}>
          {/*
                            <SectionTab id = "home" onClick={this.handleButton} style={{color: this.state.screen.home === 1 ? "#6fc9c9": "#1a3552"}}>EDMS HOME</SectionTab>
                           */}
          <SectionTab id="library" onClick={this.handleButton} style={{color: this.state.screen.library === 1 ? "#6fc9c9" : "#1a3552" }}>DOCUMENT LIBRARY</SectionTab>

          {/*
                            <SectionTab id = "register" onClick={this.handleButton} style={{color: this.state.screen.register === 1 ? "#6fc9c9": "#1a3552"}}>REGISTER</SectionTab>
                            */}
          <div style={{ clear: "both" }}></div>
        </TabDiv>
      </ModalContainertop>
    );
  }
}

export default Tabheader;
