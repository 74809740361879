import React from "react";
import styled from "styled-components";
import {
  IoIosArrowUp,
  IoIosArrowDown,
  IoIosClose,
  IoIosTrash,
} from "react-icons/io";
import SingleSelect from "./SingleSelect";
import CSLMultiSelect from "./CSLMultiSelect";
import Store from "../../Common/Store.js";

const FilterTopBar = styled.div`
  background-color: #ffffff;
  font-weight: 600;
  color: #000000;
  padding: 10px 20px;
`;
const FilterText = styled.div`
  float: left;
`;
const InfoText = styled.div`
  font-size: 11px;
  font-weight: 400;
`;
const FilterChevron = styled.div`
  float: right;
  font-size: 16px;
  cursor: pointer;
`;
const FilterBody = styled.div`
  background-color: #ecf1f2;
  border: 1px solid #d7dddf;
  padding: 20px 20px;
  margin-top: 2px;
  width: 100%;
  box-sizing: border-box;
`;
const FilterCell1 = styled.div`
  padding: 5px;
  width: calc(20% - 5px);
  float: left;
  box-sizing: border-box;
  height: 75px;
`;
const FilterCell2 = styled.div`
  padding: 5px;
  width: calc(33% - 5px);
  float: left;
  box-sizing: border-box;
  height: 75px;
`;
const FilterCell3 = styled.div`
  padding: 5px;
  width: calc(50% - 5px);
  float: left;
  box-sizing: border-box;
  height: 75px;
`;
const FilterCell4 = styled.div`
  padding: 5px;
  width: calc(67% - 5px);
  float: left;
  box-sizing: border-box;
  height: 75px;
`;
const FilterCell5 = styled.div`
  padding: 5px;
  width: calc(84% - 5px);
  float: left;
  box-sizing: border-box;
  height: 75px;
`;
const FilterCell6 = styled.div`
  padding: 5px;
  width: calc(100% - 5px);
  float: left;
  box-sizing: border-box;
  height: 75px;
`;
const Label = styled.div`
  padding-bottom: 7px;
  font-weight: bolder;
`;
const FilterButtonsDiv = styled.div`
  padding: 20px;
  float:left;
`;
const AddFilterDiv = styled.div`
  padding-left: 30px;
  padding-top: 20px;
  float:left;
`
const ClearButton = styled.div`
  background-color: #ffffff;
  color: #000000;
  padding: 10px 20px;
  border: 1px solid #000000;
  float: right;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
`;
const ApplyButton = styled.div`
  background-color: #1a3552;
  color: #ffffff;
  padding: 10px 20px;
  border: 1px solid #1a3552;
  border-radius: 4px;
  cursor: pointer;
  margin-right: -25px;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: calc(100vh-200px);
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  z-index: 1000;
  opacity: 0.8;
  top: 0px;
`;
const ModalInput = styled.input`
  height: 35px;
  width: 98%;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  color: #979797;
`;
const Dropdown = styled.select`
  display: block;
  box-sizing: border-box;
  height: 38px;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ffffff;
  border-radius: 3px;
  box-shadow: 0 0 4px #c4c4c4;
  font-family: Montserrat, sans-serif;
`;

const filters = {
  keyword: {name: "keyword",nickname: "Keywords",type: "text",options: {},selections: {},size: 2,hidden: false},
  doc_type: {name: "doc_type",nickname: "Type",type: "single",options: {},selections: {},size: 1,hidden: false},
  doc_owner: {name: "doc_owner",nickname: "Document Owner",type: "single",options: {},selections: {},size: 1,hidden: false},
  doc_date: {name: "doc_date",nickname: "Uploaded Date",type: "single",options: {},selections: {},size: 1,hidden: false},
  modified_date: {name: "modified_date",nickname: "Modified date",type: "single",options: {},selections: {},size: 1,hidden: true},
  modified_by: {name: "modified_by",nickname: "Modified by",type: "single",options: {},selections: {},size: 1,hidden: true},
  doc_version: {name: "doc_version",nickname: "Document version",type: "single",options: {},selections: {},size: 1,hidden: true},
  gcs: {name: "gcs",nickname: "Company",type: "multi",options: {},selections: {},size: 2,hidden: true},
};

class TextInput extends React.Component {
  state = { text: "" , view_count: 0};

  setComponentState = () => {
    this.setState({ text: this.props.selectedItems.text });
  };

  componentDidMount = () => {
    this.setComponentState();
  };

  componentDidUpdate = (prev_props) => {
    if (prev_props === this.props) return;
    this.setComponentState();
  };

  onInputChange = (event) => {
    event.preventDefault();
    this.props.selectCallback(event.target.value, this.props.stack);

    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div style={{ padding: "5px" }}>
        <ModalInput
          name="text"
          value={this.state.text}
          onChange={this.onInputChange}
        />
      </div>
    );
  }
}

class AdvanceFilter extends React.Component {
  state = { show_filter: false, filters: {}, view_count: 0 };

  init_filters = null;

  setComponentState = () => {
    filters["doc_type"].options = Store.getStoreData("doc_types");
    filters["doc_owner"].options = Store.getStoreData("contact_assoc");
    filters["doc_date"].options = {last_year: "last year", last_month:"last month"};
    filters["modified_date"].options = {last_year: "last year", last_month:"last month"};
    filters["modified_by"].options = Store.getStoreData("modify_assoc");
    filters["doc_version"].options = Store.getStoreData("doc_v");
    
    if(Store.getStoreData('is_gc')){
      let gcs = Store.getStoreData("companies_assoc");
      let gc = [];
      for(let key in gcs){
        gc.push({id: key, name: gcs[key].company_name})
      }
      filters["gcs"].options = gc;
    }else{
      delete filters["gcs"];
    }
    this.setState({ filters: filters });
  };

  componentDidMount = () => {
    this.setComponentState();
  };

  componentDidUpdate = (prev_props) => {
    if (this.props === prev_props) return;
    this.setComponentState();
  };

  toggleFilter = () => {
    // event.preventDefault();
    let show_filter = this.state.show_filter ? false : true;
    this.setState({ show_filter });
  };

  selectCallback = (selected, stack) => {
    console.log("selected", selected, stack);

    let type = stack.type;
    let filter_id = stack.filter_id;
    let filters = this.state.filters;
    switch (type) {
      case 'multi': filters[filter_id].selections[selected] = filters[filter_id].options[selected]; break;
      case "text":
        filters[filter_id].selections =
          selected === "" ? {} : { text: selected };
        break;
      case "single":
        filters[filter_id].selections =
          selected === "All" ? {} : { [selected]: 1 };
        break;
      // default: filters[filter_id].selections.selected = filters[filter_id].options[selected];
    }

    console.log("filters", filters);

    this.setState({ filters: filters });
  };
  deselectCallback = (deselected, stack) => {
    let type = stack.type;
    let filter_id = stack.filter_id;
    let filters = this.state.filters;
    delete filters[filter_id].selections[deselected];
    this.setState({ filters });
  };

  clearAdvancedFilter = () => {
    // window.location.reload(true);
    // // console.log('clearAdvancedFilter clear called',  this.init_filters)
    this.setState({view_count: this.state.view_count + 1, filters: JSON.parse(this.init_filters)})
  };

  applyFilter = () => {
    console.log("this.state.filters", this.state.filters);
    let filter_value = {};
    if("doc_owner" in this.state.filters && Object.keys(this.state.filters["doc_owner"].selections)[0] > 0){
      filter_value["doc_owner"] = Object.keys(this.state.filters["doc_owner"].selections)[0];
    }

    if("doc_type" in this.state.filters && Object.keys(this.state.filters["doc_type"].selections)[0] > 0){
      filter_value["type"] = Object.keys(this.state.filters["doc_type"].selections)[0];
    }

    if("modified_by" in this.state.filters && Object.keys(this.state.filters["modified_by"].selections)[0] > 0){
      filter_value["updated_by"] = Object.keys(this.state.filters["modified_by"].selections)[0];
    }

    if("doc_version" in this.state.filters && (Object.keys(this.state.filters["doc_version"].selections)[0])){
      if(Object.keys(this.state.filters["doc_version"].selections)[0] !=="0")filter_value["doc_version"] = Object.keys(this.state.filters["doc_version"].selections)[0];
      
    }

    if("keyword" in this.state.filters){
      let txt = Object.keys(this.state.filters["keyword"].selections).length === 0 ? "" : this.state.filters["keyword"].selections["text"]
      if(txt!=="")filter_value["name"] = this.state.filters["keyword"].selections["text"];
    }

    if("gcs" in this.state.filters && Object.keys(this.state.filters["gcs"].selections).length > 0){
      let g = {};
      for(let key in this.state.filters["gcs"].selections){
        g[this.state.filters["gcs"].selections[key].id] = 1;
      }
      filter_value["gcs"] = g
    }

    if("doc_date" in this.state.filters && (Object.keys(this.state.filters["doc_date"].selections)[0])){
      if(Object.keys(this.state.filters["doc_date"].selections)[0] !=="0")filter_value["doc_date"] = Object.keys(this.state.filters["doc_date"].selections)[0];
    }

    if("modified_date" in this.state.filters && (Object.keys(this.state.filters["modified_date"].selections)[0])){
      if(Object.keys(this.state.filters["modified_date"].selections)[0] !=="0")filter_value["modified_date"] = Object.keys(this.state.filters["modified_date"].selections)[0];
    }
    

    

    console.log("filter_value", filter_value);

    this.props.applyFilter(filter_value);
  };

  applyFilter_obsulate = () => {
    console.log("this.state.filters", this.state.filters);
    let filter_value = {};
    filter_value["doc_date"] =
      Object.keys(this.state.filters["doc_date"].selections).length === 0
        ? ""
        : Object.keys(this.state.filters["doc_date"].selections)[0];
    filter_value["doc_owner"] =
      Object.keys(this.state.filters["doc_owner"].selections).length === 0
        ? ""
        : Object.keys(this.state.filters["doc_owner"].selections)[0];
    filter_value["doc_type"] =
      Object.keys(this.state.filters["doc_type"].selections).length === 0
        ? ""
        : Object.keys(this.state.filters["doc_type"].selections)[0];

    filter_value["modified_date"] =
      Object.keys(this.state.filters["modified_date"].selections).length === 0
        ? ""
        : Object.keys(this.state.filters["modified_date"].selections)[0];

    filter_value["modified_by"] =
      Object.keys(this.state.filters["modified_by"].selections).length === 0
        ? ""
        : Object.keys(this.state.filters["modified_by"].selections)[0];

    filter_value["doc_version"] =
      Object.keys(this.state.filters["doc_version"].selections).length === 0
        ? ""
        : Object.keys(this.state.filters["doc_version"].selections)[0];

    filter_value["keyword"] = Object.keys(this.state.filters["keyword"].selections).length === 0 ? "" : this.state.filters["keyword"].selections["text"];
    // filter_value["gcs"] = Object.keys(this.state.filters["gcs"].selections).length === 0 ? {} : this.state.filters["gcs"].selections;
    if("gcs" in this.state.filters && Object.keys(this.state.filters["gcs"].selections).length > 0){
      let g = {};
      for(let key in this.state.filters["gcs"].selections){
        g[this.state.filters["gcs"].selections[key].id] = 1;
      }
      filter_value["gcs"] = g
    }
    

    

    console.log("filter_value", filter_value);

    this.props.applyFilter(filter_value);
  };

  filterShow = () => {
    filters.modified_by.hidden = false;
    filters.modified_date.hidden = false;
    filters.doc_version.hidden = false;
    if(Store.getStoreData("is_gc"))filters.gcs.hidden = false;
    
    this.setState({ filters: this.state.filters });
  };

  render() {
    if (this.state.filters === null) {
      return <div>Loading</div>;
    }
    console.log("clearAdvancedFilter this.state", this.state);
    // let filters = this.state.filters['filters'];
    return (
      <div style={{ height: "100%", marginLeft: 10, marginRight: 20 }}>
        <FilterBody>
          {Object.keys(this.state.filters).map((id, i) => {
            let filter = this.state.filters[id];

            const filterCells = {
              1: FilterCell1,
              2: FilterCell2,
              3: FilterCell3,
              4: FilterCell4,
              5: FilterCell5,
              6: FilterCell6,
            };
            const FilterCell = filterCells[filter.size.toString()];
            const filterInputs = { text: TextInput, single: SingleSelect, multi : CSLMultiSelect };
            const FilterInput = filterInputs[filter.type];
            const visibility = !filter.hidden;
            {
              /*console.log('AdvanceFilter id', FilterInput)*/
            }
            //// console.log('AdvanceFilter filterInputs, FilterInput', filterInputs, FilterInput)

            return (
              <div>
                {visibility ? (
                  <FilterCell key={i}>
                    <Label>{filter.nickname}</Label>

                    {(() => {
                      return (
                        <FilterInput
                          items={filter.options}
                          selectedItems={filter.selections}
                          stack={{ type: filter.type, filter_id: id }}
                          selectCallback={this.selectCallback}
                          deselectCallback={this.deselectCallback}
                        />
                      );
                    })()}
                  </FilterCell>
                ) : (
                  <p></p>
                )}
              </div>
            );
          })}

          <FilterButtonsDiv style={filters.modified_by.hidden ? {} : {padding : "30px"}}>
            <ApplyButton onClick={() => this.applyFilter()}>FILTER</ApplyButton>
          </FilterButtonsDiv>
          {filters.modified_by.hidden ? (
            <AddFilterDiv
              style={{ cursor: "pointer", color:"#1a3552"}}
              onClick={() => {
                this.filterShow();
              }}
            >
              <u>Add filter options</u>
            </AddFilterDiv>
          ) : (
            <p></p>
          )}
          <div style={{ clear: "both" }}></div>
        </FilterBody>
      </div>
    );
  }
}

export default AdvanceFilter;
