import React from 'react';
import styled from 'styled-components';
import {FaPlusCircle} from 'react-icons/fa';
import CSLTable from '../../Common/CSLTable';
import AddTaskModal from './AddTaskModal';
import APICall from '../../../Common/APICall';
import Store from '../../../Common/Store';
import AlertBox from '../../Common/AlertBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, thin } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import styles to be used

const OverviewInfoBody = styled.div`
	padding: 15px;
    background-color: #ffffff;
    border: 1px solid #d7dddf;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
`;
const MRButton = styled.button`
    box-sizing: border-box;
    background-color: #37ada7;
    color: #ffffff;
    border: 1px solid #37ada7;
    // width: 84px;
    font-size: 13px;
    height: 25px;
    border-radius: 4px;
`;

class ChildTask extends React.Component
{
    state = {   childtasks: [], ready: false, document_id: 0, show_modal: false, 
                task:{name:"",description:"", assigned_to: 0, doc_id: 0},
                alert_param: null
            };

	componentDidMount()
	{
        this.manageFollowupTasks();
    }

    componentDidUpdate(prevProps)
	{
		if (prevProps !== this.props) {

            this.manageFollowupTasks();
		}
	}

    manageFollowupTasks = () => {
        let contact_assoc = Store.getStoreData("contact_assoc");
        let gc_users = Store.getStoreData("gc_users");
        let contact = Store.getStoreData("contact");
        let show_all = Store.getStoreData("role") === "admin_manager" ? true : false;
        let user_list = {};
        if (Object.keys(gc_users).length !== 0) {
          for (let key in contact_assoc) {
            if (show_all || this.hasCommonCompanyCheckFirst(contact.ID, key, gc_users)) {
              if (key in gc_users) {
                user_list[key.toString()] = contact_assoc[key.toString()];
              }
            }
          }
        } else {
          user_list = contact_assoc;
        }
        let task = this.state.task;
        task['assigned_to'] = Object.keys(user_list)[0]
        task['doc_id'] = this.props.document_id;
        console.log("tasks", task)

        this.setState({childtasks: this.props.childtasks, task: task, ready: true, show_modal: false, document_id: this.props.document_id});
    }

    hasCommonCompanyCheckFirst = (manager_id, user_id, gc_users) => {
      if (Object.keys(gc_users).length === 0) return true;
      if (manager_id === "") return true;
      let manager_companies = gc_users[manager_id];
      let user_companies = gc_users[user_id];
      for (let cid in manager_companies) {
        for (let uid in user_companies) {
          if (cid.toString() === uid.toString()) return true;
        }
      }
      return false;
    };

    listFollowupTasks = (document_id) => {
        this.props.updateFollowupListing(document_id)
    }


    showFollowupModal = (index) => (event) => {
        event.preventDefault();
        let tasks = this.state.childtasks;
        // console.log("task", tasks, index);
        let t = tasks[index];
        // console.log("task", t)
        let task_json = JSON.parse(t.task_json);
        let task = {};
        task['id'] = t.id;
        task['name'] = task_json.object_data.name;
        task['description'] = task_json.object_data.description;
        task['assigned_to'] = t.cur_assigned_to;
        task['file_name'] = task_json.object_data.file_name === null ? "" : task_json.object_data.file_name;
        task['due_date'] = t.due_date;
        task['doc_id'] = this.state.document_id;
        task['completed_note'] = task_json.object_data.completed_note === null ? "" : task_json.object_data.completed_note;
        task['is_completed'] = t.cur_lane === "COMP_DOC_FOLLOWUP_COMPLETE" ? true : false;

        this.setState({show_modal: true, task: task})

    }

    deleteFollowupTask = (index) => (event) => {
        event.preventDefault();
        let msg = "Are you sure you want to delete this task?";
        let title = "Confirm";
        let alert_param = { title: title, message: msg, ok_text: "OK", confirm: true, alertHandler: this.confirmunHandler, stack: { task_id: index } };
        this.setState({ alert_param: alert_param });
    }

    
    confirmunHandler = (result, stack) => {
      if (!result || stack.prevent) {
        this.setState({ alert_param: null, show_modal: false });
        return;
      }
      this.setState({ alert_param: null});
      let task_id = stack.task_id;
      let postData = { command: "delete_followup_task", task_id: task_id };
      let api = new APICall();
      api.command(postData, this.removeFollowupTask);

    }

    removeFollowupTask = (result) => {
        console.log("deleteFollowupTask", result)
        let alert_param = { title: "Success", message: "Task has been deleted", ok_text: "OK", confirm: false, alertHandler: this.AlertremoveHandler, stack: { id: 0 } };
        this.setState({ alert_param: alert_param, loading: false });
    }

    AlertremoveHandler = (result, stack) => {
      this.setState({ alert_param: null });
      this.props.updateFollowupListing(this.state.document_id)
    };

    processTasksData = () => {
        

        let ret = {data: [], columns: []};
        
        ret.columns =[
            {Header: '#', accessor: 'index', width: 30, headerStyle: {textAlign: 'left'}},
            {Header: 'Title', accessor: 'subject', minWidth: 250, headerStyle: {textAlign: 'left'}},
            {Header: 'Assigned to', accessor: 'assigned_to', minWidth: 120, headerStyle: {textAlign: 'left'}}, 
            {Header: 'Status', accessor: 'status', minWidth: 80, headerStyle: {textAlign: 'left'}, Cell: cellInfo => (<div><MRButton style={{cursor: 'default',backgroundColor: `${cellInfo.value.color}`}}>{cellInfo.value.text}</MRButton></div>)}, 
            {'Header' : 'Action', Cell: row => (
              <div>
                  {/*<MRButton style={{cursor: "pointer"}} onClick={this.showFollowupModal(row.original.index)}>Edit</MRButton>*/}
                  <FontAwesomeIcon icon={solid('pen')} style={{cursor: "pointer"}} onClick={this.showFollowupModal(row.original.index)}/>
                  <FontAwesomeIcon icon={solid('trash-can')} style={{cursor: "pointer", marginLeft:"7px"}} onClick={this.deleteFollowupTask(row.original.id)}/>
                  {/*<MRButton style={{cursor: "pointer", marginLeft:"5px"}} onClick={this.deleteFollowupTask(row.original.id)}>Delete</MRButton>*/}
              </div>
            ), minWidth: 80, headerStyle: {textAlign: 'left'}} 
        ];
        let i = 0;
        for(let d of this.state.childtasks) {
            const task_json = JSON.parse(d.task_json);
            let due_date_str = d.due_date ? d.due_date : '1970-01-01T00:00:00.000Z';
            var dd   = parseInt(due_date_str.substring(8,12));
            var mm  = parseInt(due_date_str.substring(5,7));
            var yyyy   = parseInt(due_date_str.substring(0,4));
            // console.log('day, month, year', dd,mm,yyyy)
            var due_date = new Date(yyyy, mm-1, dd);
            due_date.setDate(due_date.getDate() + 1);
            let today = new Date();

            let text = due_date > today ? 'Pending' : 'Overdue';
            let color = due_date > today ? '#2E8B57' : '#FF0000';

            text = d.cur_lane === "COMP_DOC_FOLLOWUP_COMPLETE" ? "Complete" : text;
            color = d.cur_lane === "COMP_DOC_FOLLOWUP_COMPLETE" ? "#2e8b57" : color;

            let elem = {
                'index' : i,
                'subject': task_json.object_data.name,
                'assigned_to' : Store.getStoreData("listcontacts")[d.cur_assigned_to.toString()],
                'status' : {text: text, color: color},
                'id': d.id
            };
            i = i + 1;
            ret.data.push(elem);
        }
        return ret;
    }
    showFollowupTaskModal = (event) => {
        event.preventDefault()
        this.setState({'show_tasks_modal' : true})
    }
    
    render()
	{
        if (this.state.alert_param !== null) {
          return <AlertBox alertParam={this.state.alert_param} />;
        }
		return (
            <div>
                                
                <AddTaskModal document_id ={this.state.document_id} reloadChildTasks={this.listFollowupTasks} show_modal={this.state.show_modal} task={this.state.task} />
                
                <OverviewInfoBody>
                    <CSLTable add={false} processData={this.processTasksData} headerText={''} tableRows="5" />
                </OverviewInfoBody>
            </div>
        );
    }
}

export default ChildTask;