import React from 'react';
import styled from 'styled-components';
import Toggle from 'react-toggle';
import "./ReactToggle.css";
 import { FaPlusCircle, FaEllipsisV } from "react-icons/fa";
import CSLTable from '../Common/CSLTablemodule';
import DocumentTypeModal from './DocumentTypeModal';
 import ContextMenu from "./ContextMenu";
 import AlertBox from '../Common/AlertBox';
 import CSLLoader from '../Common/CSLLoader';
 import APICall from '../../Common/APICall.js';
 import Store from '../../Common/Store';

const EmailContainer = styled.div`
  width: 100%;
`;
const EmailInnerContainer = styled.div`
  background-color: #ffffff;
  padding: 30px;
`;
const FooterButtonsContainer = styled.div`
  text-align: right;
  padding-top: 25px;
  width: 97%;
    margin-bottom: 25px;
`;
const CancelBtn = styled.button`
  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 2px;
  color: #333333;
  cursor: pointer;
`;
const SaveBtn = styled.button`
  padding: 10px 30px;
  background-color: #37ADA7;
  border: 1px solid #37ADA7;
  border-radius: 2px;
  color: #ffffff;
  cursor: pointer;
  margin-left: 15px;
`;

const MRModalLabel = styled.div`
  color: #1a3552;
  font-family: "DMSans-Medium", "DM Sans Medium", "DM Sans", sans-serif;
  font-weight: 600;
  margin-top: 10px;
  display: inline-block;
`;
const EmailTopHeader = styled.div`
  font-weight: 600;
  color: #595959;
  margin-top: 5px;
  width:100%;
  height: 58px;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #ffffff;
  box-sizing: border-box;
`;
const InactiveOverlay = styled.div`
  display: block;
  box-sizing: border-box;
  height: 210%;
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  left: 0%;
  top: -46px;
  z-index: 1000;
  opacity: 0.8;
`;

class Documents extends React.Component
{
  state = {
    document_types: null, 
    show_document_type_modal: false, 
    curid: 0, 
    headerText: "", 
    content: null, 
    alert_param: null
  };

  setComponentState = () => {
    const postData = { command: "list_document_types", is_selected: false };
    const api = new APICall();
    api.command(postData, this.getDocumentTypes);
  }

  getDocumentTypes = (result) => {
    console.log("result ==>", result);
    this.setState({ document_types: result});
  };

  componentDidMount()
  {   
    this.setComponentState()
  }

  componentDidUpdate(prevProps)
  {
    if (this.props !== prevProps) {     
      this.setComponentState()
    }
  }


  addDocumentType = (event) => {
    event.preventDefault();     
    this.setState({show_document_type_modal: true, curid: 0, headerText: "Add"})  
  }

  closeModal = () => {    
    this.setState({show_document_type_modal: false})
  }

  closereloadModal = (result) => {
    this.setState({ show_document_type_modal: false, document_types: result.types});
  }


  removeDocumentType = (id) => {
    console.log(id);
     let alert_param = {title: 'Remove Document Type', message: 'You have chosen to remove this Document Type. This cannot be undone but it can be added again easily. Please confirm you would like to remove this Document Type.', ok_text: 'Confirm', confirm: true, 
               alertHandler: this.deleteDocumentType, stack: {id: id}}
    let used_doc_type = Store.getStoreData('used_doc_type');
    if(used_doc_type[id.toString()]){
      alert_param = {title: 'Document Type in use', message: 'This Document Type is in use and cannot be removed. You can mark this Document Type as Inactive, or change the existing documents so that this Type is no longer in use.', ok_text: 'Cancel', confirm: false, 
                     alertHandler: this.closeDialoag, stack: {id: id}}
    }
     this.setState({alert_param: alert_param})
  }

  closeDialoag = (result, stack) => {  
    if (!result || stack.prevent) {
      this.setState({ alert_param: null});
      return;
    }
    this.setState({alert_param: null}) 
  }

  deleteDocumentType = (result, stack) => {
    if (!result || stack.prevent) {
      this.setState({ alert_param: null});
      return;
    }
    const postData = { command: "doctype_operation", mode: 'delete', id: stack.id};
    console.log("postData", postData)
    const api = new APICall();
    api.command(postData, this.processDocumentType);
  }

  

  editDocumentType = (id) => {
    let content = this.state.document_types[id.toString()];
     this.setState({content,curid: id, show_document_type_modal: true, headerText: "Edit"}); 
  }

  handlenaSectionIncluded = (section_id) => (event) => {
    console.log("handlenaSectionIncluded", section_id, event.target.checked)
    let is_active = event.target.checked ? 1 : 0;
    const postData = { command: "doctype_operation", mode: 'toggle', id: section_id, is_active: is_active };
    const api = new APICall();
    api.command(postData, this.processDocumentType);
  }

  processDocumentType = (result) => {
    this.setState({ alert_param: null, document_types: result.types});
  }

  tableData = () => {
    const menu_options = [
        {name: "edit", nick_name: "Edit", clickHandler: this.editDocumentType},
        {name: "delete", nick_name: "Delete", clickHandler: this.removeDocumentType}
    ]
        let ret = {data: [], columns: []};
        ret.columns =[
                        {Header: 'NAME', accessor: 'cname', minWidth: 150, headerStyle: {textAlign: 'left'}},                          
                        {'Header' : 'STATUS', accessor: 'cstatus', Cell: row => (
                              <div> 
                              <Toggle
                              style={{float:'left'}}
                              checked={row.original.cstatus}
                              icons={false}        
                              onChange={this.handlenaSectionIncluded(row.original.id)}                                    
                            />
                            </div>
                             ), headerStyle:{textAlign: 'center'},style: {textAlign: 'center'}
                       },            
                       {'Header' : '', accessor: 'cblnk', Cell: row => (
                           <div>                                
                                <ContextMenu row_id={row.original.id} options={menu_options} /> 
                           </div>
                          ), minWidth: 40, headerStyle:{textAlign: 'left'},style: {textAlign: 'center'}
                       }
                       ];
    

      

    for (let id of Object.keys(this.state.document_types)) {     
    
       ret.data.push({ cname: this.state.document_types[id.toString()].name,id: id,  cstatus:this.state.document_types[id.toString()].is_active });
     
    }

     ret.data.sort(function (a, b) {
      return a.cname > b.cname ? 1 : b.cname > a.cname ? -1 : 0;
    });
        return ret;
    }   


  



  render()
  {
    if (this.state.document_types===null) {
      return (<div>Loading...</div>);
    }
    return (
      <div>
      {
          (() => {
            if (this.state.show_document_type_modal) {
              return (<div ><InactiveOverlay style={{height: "105%"}}/><DocumentTypeModal headerText={this.state.headerText}  closeModal={this.closeModal} closereloadModal={this.closereloadModal} data={this.state.document_types} curid={this.state.curid} content={JSON.parse(JSON.stringify(this.state.content))} /></div>);
            }
          })()
      }
      {
              (() => {
                if (this.state.document_types) {
                  return(<div>
                  <EmailTopHeader style={{display: "inline-block" , fontWeight: 700}}>
                    Document Types                             
                    <div style={{float:'right'}}>
                      <div onClick={this.addDocumentType}  style={{float: "left", cursor: "pointer",fontSize: "25px",color: "#04ADA8"}}>
                        <FaPlusCircle  /> 
                      </div>                            
                      <div style={{float: "right", cursor: "pointer",fontSize: "25px",color: "#e4e4e4"}}>
                        <FaEllipsisV  /> 
                      </div>
                    </div>
                  </EmailTopHeader>
                  <CSLTable add={false} processData={this.tableData} tableRows={10} />
                  </div>)
                }
              })()
            } 
            <AlertBox alertParam = {this.state.alert_param}/>
      </div>
    );
  }
}

export default Documents;